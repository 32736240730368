import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiStat,
  EuiTitle,
} from "@elastic/eui";
import { useWatch } from "react-hook-form";
import NumberFormat from "react-number-format";

import useRCEstimate from "../../../../hooks/queries/useRCEstimate";
import { RCschema } from "../schemas/RCEstimator.schema";
import RCEstimator, { RCEstimatorFormProvider } from "./RCEstimator";
import { RCInputs } from "./RCEstimator.types";

const RCEstimatorFlyout = ({
  isFlyoutVisible,
  onSubmit,
  onClose,
  defaultValues,
}: {
  isFlyoutVisible: boolean;
  onSubmit: any;
  onClose: any;
  defaultValues: any;
}) => {
  if (isFlyoutVisible) {
    return (
      <RCEstimatorFormProvider defaultInputValues={defaultValues}>
        <EuiFlyout ownFocus onClose={onClose} aria-labelledby="flyoutTitle">
          <EuiFlyoutHeader hasBorder>
            <EuiTitle size="m">
              <h2 id="flyoutTitle">Replacement Cost Estimator</h2>
            </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            <RCEstimator onFinishEstimator={onSubmit} />
          </EuiFlyoutBody>
          <EuiFlyoutFooter>
            <EuiFlexGroup justifyContent="spaceBetween">
              <EuiFlexItem grow={false}>
                <EuiButton type="submit" form="rcestimator">
                  Submit
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <RCResult />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlyoutFooter>
        </EuiFlyout>
      </RCEstimatorFormProvider>
    );
  } else {
    return null;
  }
};

export default RCEstimatorFlyout;

const RCResult = () => {
  const watch = useWatch() as unknown as RCInputs;
  const isValid = RCschema.isValidSync(watch);
  const { data, isLoading } = useRCEstimate(watch, isValid);
  return (
    <EuiStat
      title={<NumberFormat value={data} displayType={"text"} thousandSeparator={true} prefix={"$"} />}
      titleColor="success"
      description="Replacement Cost"
      textAlign="right"
      isLoading={isLoading || data! === undefined}
    />
  );
};
