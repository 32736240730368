import {
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingContent,
  EuiLoadingLogo,
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageTemplate,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import { useParams } from "react-router-dom";

import useInsured from "../../hooks/queries/useInsured";
import InsuredDetails from "./Insured/InsuredDetails";
import Policies from "./Insured/Policies";

type PageParams = {
  insuredID: string;
};

const Insured: React.FC = () => {
  const { insuredID } = useParams<PageParams>();
  const { data, isLoading, isError } = useInsured(insuredID);

  if (isError) {
    return (
      <EuiPage>
        <EuiPageBody restrictWidth="1300px">
          <EuiPageTemplate template="centeredBody" pageContentProps={{ paddingSize: "none" }}>
            <EuiEmptyPrompt
              iconType="lock"
              title={<h2>An error has occurred</h2>}
              body={<p>Please check the insured you are looking up or contact your admin.</p>}
            />
          </EuiPageTemplate>
        </EuiPageBody>
      </EuiPage>
    );
  }

  if (isLoading) {
    return (
      <EuiPage>
        <EuiPageBody restrictWidth="1300px">
          <EuiPanel hasBorder>
            <EuiEmptyPrompt icon={<EuiLoadingLogo logo="filebeatApp" size="xl" />} title={<h2>Loading</h2>} />
          </EuiPanel>
        </EuiPageBody>
      </EuiPage>
    );
  }

  return (
    <EuiPage>
      <EuiPageBody restrictWidth="1300px">
        {isLoading ? (
          <EuiLoadingContent lines={2} />
        ) : (
          <EuiPageHeader iconType="user" pageTitle={`${data?.displayName}`} />
        )}
        <EuiFlexGroup wrap>
          <EuiFlexItem>{data ? <InsuredDetails insured={data} /> : "Whatever"}</EuiFlexItem>
          <EuiFlexItem></EuiFlexItem>
          <EuiFlexItem>{/* <AgentInfo agent={data?.agent} /> */}</EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />
        {data?.policies ? <Policies policies={data?.policies} /> : "Whatever"}

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiSpacer />
            {/* {data?.invoices ? <Invoices invoices={data?.invoices} /> : "Whatever"} */}
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiSpacer />
            {/* {data?.invoices ? <Documents documents={data?.invoices} /> : "Whatever"} */}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPageBody>
    </EuiPage>
  );
};

export default Insured;
