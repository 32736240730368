import ErrorPage from "components/ErrorPage";
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";

import { useAppSelector } from "../app/hooks";
import FullHeader from "../components/full_header";
import { selectAdministrator, selectAuth } from "../features/authorization/authSlice";
import Onboarding from "../pages/Onboarding";
// import HomeownersRater from "../pages/underwriting/HomeownersRater";
import LoginPage from "../pages/login";
import OTPPage from "../pages/otp";
import {
  administrationRoutes,
  claimsRoutes,
  contactRoutes,
  dashboardRoutes,
  libraryRoutes,
  underwritingRoutes,
} from "./index";

// function PrivateRoute({ component: Component, ...rest }: { component: any }) {
//   const auth = useAppSelector(selectAuth);
//   return (
//     <Route
//       {...rest}
//       render={({ location, ...routeRest }) => {
//         return auth !== "none" ? (
//           Component ? (
//             <Component {...routeRest} />
//           ) : (
//             rest.render(routeRest)
//           )
//         ) : (
//           <Navigate
//             to={{
//               pathname: "/login",
//               state: { from: location },
//             }}
//           />
//         );
//       }}
//     />
//   );
// }

const childRoutes = (Layout: any, routes: any) =>
  routes.map(
    (
      {
        children,
        path,
        component: Component,
        requireAdmin = false,
      }: { children: any; path: string; component: any; requireAdmin: boolean },
      index: number
    ) =>
      children ? (
        // Route item with children
        children.map(
          (
            {
              path,
              component: Component,
              requireAdmin = false,
            }: { children: any; path: string; component: any; requireAdmin: boolean },
            index: number
          ) => (
            <Route
              key={1}
              path={path}
              element={
                // Good! Do your composition here instead of wrapping <Route>.
                // This is really just inverting the wrapping, but it's a lot
                // more clear which components expect which props.
                <RequireAuth redirectTo="/login" requireAdmin={requireAdmin}>
                  <Layout>
                    <Component />
                  </Layout>
                </RequireAuth>
              }
            />
            // <PrivateRoute
            //   key={1}
            //   path={path}
            //   exact
            //   render={(props) => (
            //     <Layout>
            //       <Component {...props} />
            //     </Layout>
            //   )}
            // />
          )
        )
      ) : (
        // Route item without children
        <Route
          key={1}
          path={path}
          element={
            // Good! Do your composition here instead of wrapping <Route>.
            // This is really just inverting the wrapping, but it's a lot
            // more clear which components expect which props.
            <RequireAuth redirectTo="/login" requireAdmin={requireAdmin}>
              <Layout>
                <Component />
              </Layout>
            </RequireAuth>
          }
        />
        // <PrivateRoute
        //   key={1}
        //   path={path}
        //   exact
        //   render={(props) => (
        //     <Layout>
        //       <Component {...props} />
        //     </Layout>
        //   )}
        // />
      )
  );

function RequireAuth({
  children,
  redirectTo,
  requireAdmin,
}: {
  children: any;
  redirectTo: string;
  requireAdmin: boolean;
}) {
  const isAuthenticated = useAppSelector(selectAuth);
  const isAdmin = useAppSelector(selectAdministrator);
  const location = useLocation();
  if (isAuthenticated === "full") {
    if (requireAdmin) {
      if (isAdmin) {
        return children;
      } else {
        return <ErrorPage title="Admin" />; //404
      }
    } else {
      return children;
    }
  } else {
    return <Navigate to={redirectTo} state={{ from: location.pathname }} />;
  }
}

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {childRoutes(FullHeader, dashboardRoutes)}
        {childRoutes(FullHeader, underwritingRoutes)}
        {childRoutes(FullHeader, claimsRoutes)}
        {childRoutes(FullHeader, administrationRoutes)}
        {childRoutes(FullHeader, libraryRoutes)}
        {childRoutes(FullHeader, contactRoutes)}
        {/* <Route path={"/"} exact>
          <DashboardLayout>
            <HomeownersRater />
          </DashboardLayout>
        </Route> */}
        <Route path={"/login"} element={<LoginPage />} />
        <Route path={"/otp"} element={<OTPPage />} />
        <Route path={"/onboarding/:secret"} element={<Onboarding />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
