import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiPanel,
  EuiSpacer,
  EuiTableSortingType,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import moment from "moment";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { Receipt } from "types/apiTypes";

import pageAndSort from "../../../services/pageAndSort";

const PaymentHistory: React.FC<{ receipts: Receipt[] }> = ({ receipts, children }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof Receipt>("receiptDate");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const columns: EuiBasicTableColumn<Receipt>[] = [
    {
      field: "receiptDate",
      name: "Received Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "paymentType",
      name: "Type",
    },
    {
      field: "invoiceID",
      name: "Invoice",
    },
    {
      field: "totalPaid",
      name: "Amount Paid",
      dataType: "number",
      render: (totalPaid: number) => {
        return (
          <NumberFormat
            displayType={"text"}
            style={{ paddingLeft: "10px" }}
            value={totalPaid / 100}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        );
      },
    },
    {
      field: "invoiceDueDate",
      name: "Status",
      render: (date: string, receipt: Receipt) => {
        if(moment(receipt.receiptDate).isSameOrBefore(date)){
          return <EuiTextColor color="success">On Time</EuiTextColor>;
        } else {
          return <EuiTextColor color="danger">Late</EuiTextColor>;
        }
      },
    },
  ];

  const { pageOfItems, totalItemCount } = pageAndSort(receipts, { pageIndex, pageSize, sortField, sortDirection });

  const getRowProps = (receipt: Receipt) => {
    const { recordID } = receipt;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      // onClick: () => {
      //   console.log("clicked on this: ", invoice);
      //   // setInvoiceModalID(invoice);
      // },
    };
  };

  const getCellProps = (receipt: Receipt, column: any) => {
    const { recordID } = receipt;
    const { field } = column;
    return {
      className: "wcicTableCellPreWrap",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
      // style: {"white-space": "pre"}
    };
  };
  const sorting: EuiTableSortingType<Receipt> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [15, 10, 5],
    hidePerPageOptions: !showPerPageOptions,
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<Receipt>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPanel hasBorder>
      <EuiTitle>
        <h3>Payment History</h3>
      </EuiTitle>
      <EuiSpacer size="m" />
      <EuiBasicTable
        items={pageOfItems}
        itemId="recordID"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
        pagination={pagination}
        onChange={onTableChange}
        tableLayout="auto"
        sorting={sorting}
      />
    </EuiPanel>
  );
};

export default PaymentHistory;
