import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiDatePicker,
  EuiDatePickerRange,
  EuiFormRow,
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
  EuiTableSortingType,
} from "@elastic/eui";
import { useAppSelector } from "app/hooks";
import EuiCustomLink from "components/EuiCustomLink";
import { selectClusterAgencies } from "features/authorization/authSlice";
import { useAPIURL } from "hooks/useAPIURL";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import authAxios from "services/authAxios";
import pageAndSort from "services/pageAndSort";
import { PolicyDocument } from "types/apiTypes";

interface TableData {
  policyID: string;
  insuredDisplayName: string;
  agentID: string;
  docType: string;
  docDatetime: string;
  policyEffectiveDate: string;
  policyInceptionDate: string;
  policyDocument: PolicyDocument;
}

const getPolicyDocuments = async (start: string, end: string) => {
  const { data } = await authAxios.post(`/policy_documents`, { start: start, end: end });
  return data;
};

function useNewPolicyDocuments(start: string, end: string) {
  const useQueryReturn = useQuery<PolicyDocument[]>(
    ["policy_documents_query", start, end],
    () => getPolicyDocuments(start, end),
    {
      placeholderData: [],
      initialData: [],
      initialDataUpdatedAt: 0,
      staleTime: 1000 * 60 * 15,
    }
  );
  return { ...useQueryReturn, data: useQueryReturn.data! };
}
const PolicyDocuments: React.FC = () => {
  const clusterAgents = useAppSelector(selectClusterAgencies);
  const [startDate, setStartDate] = useState(() => {
    const saved = localStorage.getItem("pd_start");
    return saved || moment().format("YYYY-MM-DD");
  });
  const [endDate, setEndDate] = useState(() => {
    const saved = localStorage.getItem("pd_end");
    return saved || moment().format("YYYY-MM-DD");
  });
  const { data, isLoading } = useNewPolicyDocuments(startDate, endDate);
  //   console.log("data: ", data);

  const dataTable: TableData[] = data.map((record) => {
    return {
      policyID: record.policy.policyID,
      insuredDisplayName: record.policy.insured?.displayName || "",
      agentID: record.agentID,
      docType: record.docType,
      docDatetime: record.docDatetime,
      policyEffectiveDate: record.policyEffectiveDate,
      policyInceptionDate: record.policyInceptionDate,
      policyDocument: record,
    };
  });

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof TableData>("docDatetime");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const defaultURL = useAPIURL();

  const columns: EuiBasicTableColumn<TableData>[] = [];

  if (clusterAgents.length > 1) {
    columns.push({
      field: "agentID",
      name: "Agent ID",
    });
  }
  columns.push(
    {
      field: "policyID",
      name: "Policy Number",
      render: (policyID: string, rowData: TableData) => {
        return (
          <EuiCustomLink to={`/policy/${rowData.policyDocument.policy.recordID}`} external={true}>
            {policyID}
          </EuiCustomLink>
        );
      },
    },
    {
      field: "insuredDisplayName",
      name: "Insured Name",
      render: (insuredDisplayName: string, rowData: TableData) => {
        if (rowData.policyDocument.policy.insured) {
          return (
            <EuiCustomLink to={`/insured/${rowData.policyDocument.policy.insured?.recordID}`} external={true}>
              {insuredDisplayName}
            </EuiCustomLink>
          );
        }
        return { insuredDisplayName };
      },
    },
    {
      field: "docType",
      name: "Document Type",
      truncateText: true,
      render: (type: string) => {
        switch (type) {
          case "DEC":
            return "Declarations Page";
          default:
            return type;
        }
      },
    },
    {
      field: "docDatetime",
      name: "Document Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "policyEffectiveDate",
      name: "Effective Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "policyInceptionDate",
      name: "Inception Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "",
      name: "View",
      actions: [
        {
          name: "View",
          description: "View this document",
          type: "icon",
          icon: "copy",
          onClick: (rowData: TableData) => {
            window.open(`${defaultURL}/document/${rowData.policyDocument.recordID}`, "_blank");
          },
        },
      ],
    }
  );
  const { pageOfItems, totalItemCount } = pageAndSort(dataTable, { sortField, sortDirection, pageIndex, pageSize });
  const onTableChange = ({ page, sort }: CriteriaWithPagination<TableData>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [20, 100, 0],
    hidePerPageOptions: !showPerPageOptions,
  };
  const sorting: EuiTableSortingType<TableData> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };
  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageHeader pageTitle="Policy Documents" restrictWidth="1300px" />
        <EuiPanel hasBorder>
          <EuiFormRow label="Select a Range">
            <EuiDatePickerRange
              startDateControl={
                <EuiDatePicker
                  selected={moment(startDate, "YYYY-MM-DD")}
                  onChange={(date: Moment) => {
                    setStartDate(date.format("YYYY-MM-DD"));
                    localStorage.setItem("pd_start", date.format("YYYY-MM-DD"));
                  }}
                  startDate={moment(startDate, "YYYY-MM-DD")}
                  endDate={moment(endDate, "YYYY-MM-DD")}
                  isInvalid={startDate > endDate}
                  aria-label="Start date"
                />
              }
              endDateControl={
                <EuiDatePicker
                  selected={moment(endDate, "YYYY-MM-DD")}
                  onChange={(date: Moment) => {
                    setEndDate(date.format("YYYY-MM-DD"));
                    localStorage.setItem("pd_end", date.format("YYYY-MM-DD"));
                  }}
                  startDate={moment(startDate, "YYYY-MM-DD")}
                  endDate={moment(endDate, "YYYY-MM-DD")}
                  isInvalid={startDate > endDate}
                  aria-label="End date"
                />
              }
            />
          </EuiFormRow>
        </EuiPanel>
        <EuiSpacer />
        <EuiPanel>
          <EuiBasicTable
            tableCaption="Policy Documents"
            items={pageOfItems}
            itemId="recordID"
            columns={columns}
            pagination={pagination}
            sorting={sorting}
            onChange={onTableChange}
            loading={isLoading}
          />
        </EuiPanel>
      </EuiPageBody>
    </EuiPage>
  );
};

export default PolicyDocuments;
