import {
  EuiBetaBadge,
  EuiButtonIcon,
  EuiDescribedFormGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import useRater from "hooks/queries/useRater";
import { FunctionComponent } from "react";

import { FormDataType } from "./FormDataTypes";
import RaterFormInputs from "./RaterFormInputs";
import ShowRateNumberStat from "./ShowRateNumberStat";

interface RaterFormProps {
  index: number;
  formsPrefix: string;
  removeForm: (index?: number | number[] | undefined) => void;
  formData: FormDataType;
  formName: string;
  formID: string;
}

const RaterForm: FunctionComponent<RaterFormProps> = ({
  index,
  formsPrefix,
  removeForm,
  formData,
  formName,
  formID,
}) => {
  const description = formData.description;
  const formTitle = formData.formTitle;
  const displayFormName = formData.displayFormName;
  const inputs = formData.inputs;
  const policyForm = formData.policyForm;
  return (
    <EuiFlexItem>
      <EuiPanel className="wcicPolicyForm wcicPolicyForm--hasBetaBadge" paddingSize="l" color="subdued">
        <EuiFlexGroup gutterSize="l" direction="row">
          <EuiFlexItem grow={false} style={{ marginRight: "0px" }}>
            <EuiIcon size="xl" type={"reportingApp"} style={{ marginRight: "16px" }} />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle size="s">
              <span>{formTitle}</span>
            </EuiTitle>
            {/* {description ? <div>{description}</div> : null} */}
            {inputs.length !== 0 ? (
              <RaterFormInputs inputs={inputs} description={description} formPrefix={`${formsPrefix}.${index}`} />
            ) : (
              <EuiDescribedFormGroup title={<span></span>} description={description} />
            )}
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="column" alignItems="flexEnd">
              <EuiFlexItem grow={false}>
                <EuiButtonIcon
                  aria-label="Remove Form"
                  display="base"
                  iconType="trash"
                  color="danger"
                  disabled={formData.automated}
                  onClick={() => {
                    removeForm(index);
                  }}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <PremiumStat formID={formID} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
          <FormError formID={formID} />
        <span className="wcicPolicyForm__betaBadgeWrapper">
          {policyForm ? (
            <EuiBetaBadge
              label={displayFormName}
              className="wcicPolicyForm__betaBadge wcicPolicyForm__betaBadge--blue"
              tooltipContent={<span>This form applies to all locations.</span>}
              color="subdued"
            />
          ) : (
            <EuiBetaBadge label={displayFormName} className="wcicPolicyForm__betaBadge " />
          )}
        </span>
      </EuiPanel>
    </EuiFlexItem>
  );
};
export default RaterForm;

type PremiumStatProps = {
  formID: string;
};

const PremiumStat: React.FunctionComponent<PremiumStatProps> = ({ formID }) => {
  // const formPremium = useAppSelector((state) => selectFormRate(state, formID), shallowEqual);
  // const isFetching = useAppSelector(selectIsFetching);
  const {
    data: formPremium,
    isFetching,
    stale,
    valid: formValid,
  } = useRater((data) => {
    return data.form_premium[formID];
  });
  const { data: formError } = useRater((data) => {
    return data.form_errors[formID];
  });
  const isLoading = stale || !formValid || isFetching || formPremium === undefined || !!formError;
  return (
    <ShowRateNumberStat
      data={formPremium as unknown as number}
      isLoading={isLoading}
      valid={formValid && !formError}
      description="Premium"
    />
  );
};

const FormError: React.FunctionComponent<PremiumStatProps> = ({ formID }) => {
  // const formPremium = useAppSelector((state) => selectFormRate(state, formID), shallowEqual);
  // const isFetching = useAppSelector(selectIsFetching);
  const { data: formError } = useRater((data) => {
    return data.form_errors[formID];
  });
  if (!formError) return null;
  return <EuiFlexGroup alignItems="stretch"><EuiFlexItem>
  <EuiText color="danger" size="m" textAlign="center">
    <span><b>Error: </b>{formError}</span>
  </EuiText>
  </EuiFlexItem>
  </EuiFlexGroup>;
};
