import { createSlice } from "@reduxjs/toolkit";
import { QuoteInput } from "pages/underwriting/rating/types/Rater.types";
import { HORaterReturn } from "types/apiTypes";

import { RootState } from "../app/store";

export interface RaterState {
  currentFormValues: QuoteInput | undefined;
  stale: boolean;
  valid: boolean;
  isFetching: boolean;
  rate: HORaterReturn | undefined;
  rateType: "HO" | "LP" | undefined;
}

// const initialState: HORaterState = {
//   firstName: "",
//   lastName: "",
//   insuredAddress: "",
//   insuredZip: "",
//   propertyAddress: "",
//   propertyZip: "",
//   constructionType: "1",
//   constructionYear: "",
//   protectionClass: "1",
//   numberFamilies: "1",
//   valuation: "1",
//   perils: "1",
//   coverageA: "100000",
//   coverageB: "10000",
//   coverageC: "50000",
//   coverageD: "20000",
//   deductible: "250",
//   liability: 100000,
//   medpay: 0,
//   forms: {},
// };

export const raterSlice = createSlice({
  name: "rater",
  initialState: {
    currentFormValues: undefined,
    stale: true,
    valid: false,
    isFetching: false,
    rate: undefined,
    rateType: undefined,
  } as RaterState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setStale: (state) => {
      state.stale = true;
    },
    setFormValues: (state, action) => {
      state.stale = false;
      state.valid = true;
      state.currentFormValues = action.payload;
    },
    setInvalid: (state) => {
      state.valid = false;
      state.stale = false;
    },
    setRate: (state, action) => {
      state.rate = action.payload;
    },
    setFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setRateType: (state, action) => {
      state.rateType = action.payload;
    },
  },
});

export const { setStale, setFormValues, setInvalid, setRate, setFetching, setRateType } = raterSlice.actions;

export const selectCurrentFormValues = (state: RootState) => state.rater.currentFormValues;
export const selectStale = (state: RootState) => state.rater.stale;
export const selectValid = (state: RootState) => state.rater.valid;
export const selectIsFetching = (state: RootState) => state.rater.isFetching;
export const selectRate = (state: RootState) => state.rater.rate;
export const selectFormRate = (state: RootState, formID: string) => state.rater.rate?.form_premium[formID];
export const selectRatingSlice = (state: RootState) => {
  return { currentFormValues: state.rater.currentFormValues, stale: state.rater.stale, valid: state.rater.valid, type: state.rater.rateType };
};

export default raterSlice.reducer;
