import { EuiLoadingContent, EuiPanel, EuiSpacer, EuiText, EuiTextColor, EuiTitle } from "@elastic/eui";
import moment from "moment";
import { Insured, Policy } from "types/apiTypes";

import EuiCustomLink from "../EuiCustomLink";

const PolicyInfo: React.FC<{ policy: Policy; insured: Insured; isLoading: boolean }> = ({
  policy,
  insured,
  isLoading,
}) => {
  const PolicyInfoComponent: React.FC<{ policy: Policy; insured: Insured }> = ({ policy, insured }) => {
    function nameFormat(firstName: string, lastName: string) {
      const keyString = "insured-name";
      let result = "";
      if (firstName !== null && lastName !== null) {
        result = firstName + " " + lastName;
      } else if (firstName !== null && lastName === null) {
        result = firstName;
      } else if (firstName === null && lastName !== null) {
        result = lastName;
      }
      // else neither exist, this shouldn't happen
      else {
        result = "Insured Info";
      }
      return (
        <EuiText key={keyString} style={{ paddingLeft: "10px" }}>
          {result}
        </EuiText>
      );
    }

    function addressFormat(insured: Insured) {
      if (insured.street2 === null) {
        return (
          <>
            <label className="euiFormLabel euiFormRow__label">
              <EuiTextColor color="subdued">
                <strong>Insured Address</strong>
              </EuiTextColor>
            </label>
            <EuiText style={{ paddingLeft: "10px" }}>{insured.street1 ? insured.street1 : "Not Provided"}</EuiText>
            <EuiText style={{ paddingLeft: "10px" }}>
              {insured.city ? insured.city + ", " : "Not Provided"}
              {insured.state ? insured.state + " " : "Not Provided"}
              {insured.zip ? insured.zip : "Not Provided"}
            </EuiText>
            <EuiSpacer size="s" />
          </>
        );
      }

      return (
        <>
          <label className="euiFormLabel euiFormRow__label">
            <EuiTextColor color="subdued">
              <strong>Address</strong>
            </EuiTextColor>
          </label>
          <EuiText style={{ paddingLeft: "10px" }}>{insured.street1 ? insured.street1 : "Not Provided"}</EuiText>
          <EuiText style={{ paddingLeft: "10px" }}>{insured.street2 ? insured.street2 : <></>}</EuiText>
          <EuiText style={{ paddingLeft: "10px" }}>
            {insured.city ? insured.city + ", " : "Not Provided"}
            {insured.state ? insured.state + " " : "Not Provided"}
            {insured.zip ? insured.zip : "Not Provided"}
          </EuiText>
        </>
      );
    }

    function cancelFormat(cancelDate: string) {
      if (cancelDate !== null) {
        return (
          <div key={"policy-cancel"}>
            <label className="euiFormLabel euiFormRow__label">
              <EuiTextColor color="subdued">
                <strong>Cancel Date</strong>
              </EuiTextColor>
            </label>
            <EuiText style={{ paddingLeft: "10px" }}>
              {cancelDate ? moment(cancelDate).format("l") : "Not Provided"}
            </EuiText>
            <EuiSpacer size="s" />
          </div>
        );
      }
    }

    return (
      <EuiPanel hasBorder>
        <EuiTitle size="s">
          <h3>Policy Details</h3>
        </EuiTitle>
        <EuiSpacer size="s" />

        <label className="euiFormLabel euiFormRow__label">
          <EuiTextColor color="subdued">
            <strong>Policy ID</strong>
          </EuiTextColor>
        </label>
        <EuiText style={{ paddingLeft: "10px" }}>
          <EuiCustomLink external={true} to={`/policy/${policy.recordID}`}>
            {policy.policyID ? policy.policyID : "Not Provided"}
          </EuiCustomLink>
        </EuiText>
        <EuiSpacer size="s" />

        {/* {annualPremiumFormat(policy.annualPremium)}
        <EuiSpacer size="s" />

        <label className="euiFormLabel euiFormRow__label">
          <EuiTextColor color="subdued">
            <strong>Issue Date</strong>
          </EuiTextColor>
        </label>
        <EuiText style={{ paddingLeft: "10px" }}>
          {policy.issueDate ? moment(policy.issueDate).format("l") : "Not Provided"}
        </EuiText>
        <EuiSpacer size="s" />

        <label className="euiFormLabel euiFormRow__label">
          <EuiTextColor color="subdued">
            <strong>Inception Date</strong>
          </EuiTextColor>
        </label>
        <EuiText style={{ paddingLeft: "10px" }}>
          {policy.inceptionDate ? moment(policy.inceptionDate).format("l") : "Not Provided"}
        </EuiText>
        <EuiSpacer size="s" />

        <label className="euiFormLabel euiFormRow__label">
          <EuiTextColor color="subdued">
            <strong>Expiration Date</strong>
          </EuiTextColor>
        </label>
        <EuiText style={{ paddingLeft: "10px" }}>
          {policy.expirationDate ? moment(policy.expirationDate).format("l") : "Not Provided"}
        </EuiText>
        <EuiSpacer size="s" />
         */}

        {cancelFormat(policy.cancelDate)}

        <label className="euiFormLabel euiFormRow__label">
          <EuiTextColor color="subdued">
            <strong>Insured Name</strong>
          </EuiTextColor>
        </label>
        {nameFormat(insured.firstName, insured.lastName)}
        <EuiSpacer size="s" />
        {addressFormat(insured)}
      </EuiPanel>
    );
  };

  const LoadingContent: React.FC = () => {
    return (
      <EuiPanel>
        <EuiTitle>
          <h3>Policy Details</h3>
        </EuiTitle>
        <EuiSpacer />
        <EuiLoadingContent lines={1} />
      </EuiPanel>
    );
  };

  if (isLoading) {
    return <LoadingContent />;
  }
  return <PolicyInfoComponent policy={policy} insured={insured} />;
};

export default PolicyInfo;
