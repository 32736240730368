import { useMutation } from "react-query";

import authAxios from "../../../services/authAxios";

const useChangePassword = () => {
  return useMutation(
    ({
      currentPassword,
      newPassword,
      confirmPassword,
    }: {
      currentPassword: string;
      newPassword: string;
      confirmPassword: string;
    }) =>
      authAxios.post("/change_password", {
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      })
  );
};

export default useChangePassword;
