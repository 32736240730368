import { EuiFieldNumber, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow } from "@elastic/eui";
import { FormSelect } from "components/react-hook-form/SelectInput";
import { Fragment, FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

const CreditCardView: FunctionComponent = () => {
  return (
    <Fragment key="card">
      <EuiFormRow label="Cardholder Name">
        <Controller
          name={`fullName` as `${string}`}
          render={({ field: { ref, ...rest } }) => {
            return <EuiFieldText placeholder="Name on the Card" {...rest} />;
          }}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label={"Card Number"}>
        <Controller
          name={`cardNumber` as `${string}`}
          render={({ field }) => {
            const { onChange, value, ...rest } = field;
            return (
              <NumberFormat
                placeholder="Card Number"
                customInput={EuiFieldText}
                decimalScale={0}
                value={value}
                onValueChange={(target) => {
                  onChange(target.value);
                }}
                {...rest}
              />
            );
          }}
        />
      </EuiFormRow>
      <EuiFormRow>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="Expiry Month" fullWidth>
              <FormSelect
                name={`month`}
                label={"Payment Source"}
                rules={{ required: true }}
                options={[
                  { value: "1", text: "January" },
                  { value: "2", text: "February" },
                  { value: "3", text: "March" },
                  { value: "4", text: "April" },
                  { value: "5", text: "May" },
                  { value: "6", text: "June" },
                  { value: "7", text: "July" },
                  { value: "8", text: "August" },
                  { value: "9", text: "September" },
                  { value: "10", text: "October" },
                  { value: "11", text: "November" },
                  { value: "12", text: "December" },
                ]}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="Expiry Year">
              <Controller
                name={`year` as `${string}`}
                render={({ field: { ref, ...rest } }) => {
                  return <EuiFieldNumber {...rest} />;
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    </Fragment>
  );
};

export default CreditCardView;
