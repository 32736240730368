import { EuiFieldText, EuiLink, EuiPanel, EuiSpacer, EuiText, EuiTitle } from "@elastic/eui";
import { WCICTextLabel } from "components";
import NumberFormat from "react-number-format";
import { Agent } from "types/apiTypes";

const AgentInfo: React.FC<{ agent: Agent }> = ({ agent }) => {
  function addressFormat(agent: Agent) {
    if (agent.street2) {
      return (
        <>
          <EuiText style={{ paddingLeft: "10px" }}>{agent.street1 ? agent.street1 : ""}</EuiText>
          <EuiText style={{ paddingLeft: "10px" }}>{agent.street2 ? agent.street2 : ""}</EuiText>
          <EuiText style={{ paddingLeft: "10px" }}>
            {agent.city ? agent.city + ", " : ""}
            {agent.state ? agent.state + " " : ""}
            {agent.zip ? agent.zip : ""}
          </EuiText>
        </>
      );
    }
    return (
      <>
        <EuiText style={{ paddingLeft: "10px" }}>{agent.street1 ? agent.street1 : ""}</EuiText>
        <EuiText style={{ paddingLeft: "10px" }}>
          {agent.city ? agent.city + ", " : ""}
          {agent.state ? agent.state + " " : ""}
          {agent.zip ? agent.zip : ""}
        </EuiText>
        <EuiSpacer size="s" />
      </>
    );
  }

  function contactFormat(agent: Agent) {
    const contact = [];

    if (agent.email !== null) {
      contact.push(
        <div key={"agent-email"}>
          <WCICTextLabel label="Email">
            {agent.email ? (
              <EuiLink style={{ paddingLeft: "10px" }} href={`mailto:${agent.email}`}>
                {agent.email}
              </EuiLink>
            ) : (
              <EuiText style={{ paddingLeft: "10px" }}>Not Provided</EuiText>
            )}
          </WCICTextLabel>
          <EuiSpacer size="s" />
        </div>
      );
    }
    if (agent.phone !== null) {
      contact.push(
        <div key={"agent-phone-number"}>
          <WCICTextLabel label="Phone Number">
            <NumberFormat
              customInput={EuiFieldText}
              style={{ paddingLeft: "10px" }}
              value={agent.phone}
              displayType={"text"}
              format="(###) ###-####"
            />
          </WCICTextLabel>
        </div>
      );
    }

    return contact.map((element) => {
      return element;
    });
  }
  return (
    <EuiPanel hasBorder>
      <EuiTitle>
        <h3>Agent</h3>
      </EuiTitle>

      <EuiSpacer size="s" />
      <WCICTextLabel label="Agent Name">
        <EuiText style={{ paddingLeft: "10px" }}>{agent ? agent.name : "Not Provided"}</EuiText>
      </WCICTextLabel>

      <EuiSpacer size="s" />
      <WCICTextLabel label="Agent ID">
        <EuiText style={{ paddingLeft: "10px" }}>
          {agent?.agentID ? agent.agentID.split("*")[1] : "Not Provided"}
        </EuiText>
      </WCICTextLabel>

      <EuiSpacer size="s" />
      <WCICTextLabel label="Address" />
      {agent ? addressFormat(agent) : null}

      {agent ? contactFormat(agent) : null}
    </EuiPanel>
  );
};

export default AgentInfo;
