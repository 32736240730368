import {
  Criteria,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiPage,
  EuiPageBody,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
  EuiTableSortingType,
} from "@elastic/eui";
import { EditableTableSelect } from "components/EditableTableSelect";
import { EditableTableText } from "components/EditableTableText";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import authAxios from "services/authAxios";
import pageAndSort from "services/pageAndSort";
import { Agent, AgentCluster } from "types/apiTypes";

type AgentTableType = Pick<Agent, "agentID" | "displayName" | "clusterID">;

const getAgents = async () => {
  const { data } = await authAxios.get(`/agents`);
  return data;
};

const getClusters = async () => {
  const { data } = await authAxios.get(`/clusters`);
  return data;
};

function useClusters() {
  const useQueryReturn = useQuery<AgentCluster[]>(["clusters"], () => getClusters(), {
    placeholderData: [],
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
  });
  return {
    ...useQueryReturn,
    data: useQueryReturn.data!,
  };
}

function useAgents() {
  const useQueryReturn = useQuery<AgentTableType[]>(["agents"], () => getAgents(), {
    placeholderData: [],
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
  });
  return {
    ...useQueryReturn,
    data: useQueryReturn.data!,
  };
}

const Agents: React.FC = () => {
  const queryClient = useQueryClient();

  const setAgentDisplayName = useMutation({
    mutationFn: (agentNameData: { agentID: string; displayName: string }) => {
      return authAxios.post("/change-agent-display-name", {
        agentID: agentNameData.agentID,
        name: agentNameData.displayName,
      });
    },
    onMutate: async (updateAgentName) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ["agents"] });
      // Snapshot the previous value
      const previouspassKeys = queryClient.getQueryData(["agents"]);
      // Optimistically update to the new value
      queryClient.setQueryData(["agents"], (old: AgentTableType[] | undefined) => {
        if (old === undefined) {
          return [];
        }
        const updatedAgent = {
          ...old.find((item) => {
            return item.agentID === updateAgentName.agentID;
          })!,
          displayName: updateAgentName.displayName,
        };
        return [
          ...old.filter((item: any) => {
            return item.agentID !== updateAgentName.agentID;
          }),
          updatedAgent,
        ];
      });
      // Return a context object with the snapshotted value
      return { previouspassKeys };
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["agents"] });
    },
  });
  const setAgentsClusterID = useMutation({
    mutationFn: (agentClusterData: { agentID: string; clusterID: string }) => {
      return authAxios.post("/agent-cluster", {
        agentID: agentClusterData.agentID,
        clusterID: agentClusterData.clusterID,
      });
    },
    onMutate: async (updateAgentCluster) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ["agents"] });
      // Snapshot the previous value
      const previouspassKeys = queryClient.getQueryData(["agents"]);
      // Optimistically update to the new value
      queryClient.setQueryData(["agents"], (old: AgentTableType[] | undefined) => {
        if (old === undefined) {
          return [];
        }
        const updatedAgent = {
          ...old.find((item) => {
            return item.agentID === updateAgentCluster.agentID;
          })!,
          clusterID: updateAgentCluster.clusterID,
        };
        return [
          ...old.filter((item: any) => {
            return item.agentID !== updateAgentCluster.agentID;
          }),
          updatedAgent,
        ];
      });
      // Return a context object with the snapshotted value
      return { previouspassKeys };
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["agents"] });
      queryClient.invalidateQueries({ queryKey: ["clusters"] });
    },
  });

  const [sortField, setSortField] = useState<keyof AgentTableType>("agentID");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const { data, isLoading } = useAgents();
  const { data: clusterData } = useClusters();
  const clusterOptions = [
    ...clusterData.map((item) => {
      return { text: item.name, value: item.recordID.toString() };
    }),
    { text: "None", value: "None" },
  ];

  const columns: EuiBasicTableColumn<AgentTableType>[] = [
    { field: "agentID", name: "Agent ID", width: "100px" },
    { field: "name", name: "Name", width: "300px" },
    {
      field: "displayName",
      name: "Display Name",
      width: "800px",
      render: (name: string, agent: AgentTableType) => {
        return (
          <EditableTableText
            text={name}
            setNewText={(newText) => {
              setAgentDisplayName.mutate({
                agentID: agent.agentID,
                displayName: newText,
              });
            }}
          />
        );
      },
    },
    {
      field: "clusterID",
      name: "Cluster Name",
      width: "800px",
      render: (clusterID: number | undefined, agent: AgentTableType) => {
        return (
          <EditableTableSelect
            options={clusterOptions}
            value={clusterID?.toString() || "None"}
            setNewOption={(newOption) => {
              setAgentsClusterID.mutate({
                agentID: agent.agentID,
                clusterID: newOption,
              });
            }}
          />
        );
      },
    },
  ];

  const { pageOfItems } = pageAndSort(data, {
    sortField,
    sortDirection,
  });

  const getRowProps = (record: AgentTableType) => {
    const { agentID } = record;
    return {
      "data-test-subj": `row=${agentID}`,
      className: "customRowClass",
    };
  };

  const getCellProps = (record: AgentTableType, column: any) => {
    const { agentID } = record;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${agentID}-${field}`,
      textOnly: true,
    };
  };

  const sorting: EuiTableSortingType<AgentTableType> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
  };

  const onTableChange = ({ page, sort }: Criteria<AgentTableType>) => {
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
  };

  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageHeader pageTitle="Agent Management" restrictWidth="1300px" />
        <EuiPageContentBody restrictWidth="1300px">
          <EuiSpacer />
          <EuiPanel hasBorder>
            <EuiBasicTable
              items={pageOfItems}
              itemId="ID"
              columns={columns}
              rowProps={getRowProps}
              cellProps={getCellProps}
              onChange={onTableChange}
              tableLayout="auto"
              sorting={sorting}
              loading={isLoading}
            />
          </EuiPanel>
        </EuiPageContentBody>
      </EuiPageBody>
    </EuiPage>
  );
};

export default Agents;
