import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFieldPassword,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiLoadingLogo,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageTemplate,
  EuiSpacer,
} from "@elastic/eui";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { useToast } from "../hooks/useToasts";
import authAxios from "../services/authAxios";

type PageParams = {
  secret: string;
};

interface VerifySecret {
  result: string;
  message?: string;
  firstName?: string;
  lastName?: string;
}
interface SetPasswordForm {
  password1: string;
  password2: string;
}

interface SetPasswordMutation {
  secret?: string;
  password1: string;
  password2: string;
}

const NewUserSchema = yup
  .object({
    password1: yup
      .string()
      .min(8, "Password must be at least 8 characters long")
      .matches(/[^a-zA-Z\d]/, "Password requires at least 1 special character")
      .matches(/^(?=.*[a-z])/, "Password requires at least 1 lowercase letter")
      .matches(/^(?=.*[A-Z])/, "Password requires at least 1 uppercase letter")
      .required()
      .label("New Password"),
    password2: yup
      .string()
      .required()
      .oneOf([yup.ref("password1"), null], "Must match new password")
      .label("Confirm Password"),
  })
  .required();

function Onboarding() {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { secret } = useParams<PageParams>();
  const methods = useForm<SetPasswordForm>({
    mode: "onTouched",
    resolver: yupResolver(NewUserSchema),
  });
  const { handleSubmit, control, setError } = methods;
  const { isLoading, isError, error } = useQuery<VerifySecret, AxiosError>(
    ["onboardSecret", secret],
    async () => {
      const { data } = await authAxios.post("/verify_secret", { secret });
      return data;
    },
    {
      retry: (failureCount, error) => {
        return error?.response?.status !== 400;
      },
    }
  );
  const setPasswordMutation = useMutation((passwords: SetPasswordMutation) => {
    return authAxios.post("/verify_forgot_password", passwords);
  });

  const onSubmit: SubmitHandler<SetPasswordForm> = (data) => {
    setPasswordMutation.mutate(
      { ...data, secret },
      {
        onSuccess: (data) => {
          addToast({
            title: "Password activated",
            color: "success",
            text: <p>You may now login</p>,
          });
          navigate("/login");
        },
        onError: (error) => {
          if (error instanceof Error) {
            const axiosError = error as AxiosError;
            setError(axiosError.response?.data.input, {
              type: "manual",
              message: axiosError.response?.data.message,
            });
          }
        },
      }
    );
  };

  const onError = (errors: any, e: any) => console.log(errors, e);

  if (isLoading) {
    return (
      <div style={{ minHeight: "100vh", display: "flex" }}>
        <EuiPageTemplate
          template="centeredContent"
          pageContentProps={{
            paddingSize: "none",
            role: null, // For passing a11y tests in EUI docs only
          }}
        >
          <EuiEmptyPrompt icon={<EuiLoadingLogo logo="logoKibana" size="xl" />} title={<h2>Loading</h2>} />
        </EuiPageTemplate>
      </div>
    );
  }
  if (isError) {
    if (error) {
      return (
        <div style={{ minHeight: "100vh", display: "flex" }}>
          <EuiPageTemplate
            template="centeredContent"
            pageContentProps={{
              paddingSize: "none",
              role: null, // For passing a11y tests in EUI docs only
            }}
          >
            <EuiEmptyPrompt color="danger" iconType="alert" title={<h2>Onboarding link is not valid</h2>} />
          </EuiPageTemplate>
        </div>
      );
    }
  }
  return (
    <EuiPage paddingSize="none" style={{ minHeight: "100vh" }}>
      <EuiPageBody paddingSize="none">
        <EuiPageContent id="wcic_login_panel" verticalPosition="center" horizontalPosition="center" paddingSize="l">
          <EuiEmptyPrompt
            title={<span>Set Your Password</span>}
            body={
              <EuiForm component="form" onSubmit={handleSubmit(onSubmit, onError)}>
                <EuiHorizontalRule size="full" />
                <Controller
                  name="password1"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                    return (
                      <EuiFormRow fullWidth label="Password" isInvalid={!!error} error={error?.message}>
                        <EuiFieldPassword
                          onChange={onChange}
                          value={value}
                          onBlur={onBlur}
                          isInvalid={!!error}
                          placeholder="Password"
                          type="dual"
                          fullWidth
                        />
                      </EuiFormRow>
                    );
                  }}
                />
                <EuiSpacer size="l" />
                <Controller
                  name="password2"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                    return (
                      <EuiFormRow fullWidth label="Confirm Password" isInvalid={!!error} error={error?.message}>
                        <EuiFieldPassword
                          onChange={onChange}
                          value={value}
                          onBlur={onBlur}
                          isInvalid={!!error}
                          placeholder="Password"
                          type="dual"
                          fullWidth
                        />
                      </EuiFormRow>
                    );
                  }}
                />
                <EuiFormRow>
                  <EuiButton type="submit" fill>
                    Set Password
                  </EuiButton>
                </EuiFormRow>
              </EuiForm>
            }
          />
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
}

export default Onboarding;
