import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import raterSlice from "features/raterSlice";
import themeSlice from "features/themeSlice";

import authReducer from "../features/authorization/authSlice";
import homeownerRaterReducer from "../features/homeownerRaterSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    homeownerRater: homeownerRaterReducer,
    rater: raterSlice,
    theme: themeSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
