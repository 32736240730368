import { CommonProps } from "@elastic/eui";
import { FunctionComponent, MouseEventHandler } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useDebounce } from "use-debounce";
import WCICTab from "./WCICTab";

interface LocationTabProps extends CommonProps {
    index: number;
    isSelected: boolean;
    onCloseTab?: MouseEventHandler<HTMLButtonElement>;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    error?: boolean;
  }
  
  const LocationTab: FunctionComponent<LocationTabProps> = ({ index, isSelected, onCloseTab, onClick, error = false }) => {
    const { control } = useFormContext();
    const watch: string = useWatch({ control, name: `locations.${index}.streetAddress` as any });
    const [watchDebounced] = useDebounce(watch, 500);
    return (
      <>
        <WCICTab isSelected={isSelected} onClick={onClick} onCloseTab={onCloseTab} closeButton error={error}>
          {watchDebounced === "" ? "New Location" : watchDebounced}
        </WCICTab>
      </>
    );
  };
  
  export default LocationTab;  