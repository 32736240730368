import { useAppSelector } from "app/hooks";
import { selectWCICEmployee } from "features/authorization/authSlice";

import UploadedDocumentsAgent from "./UploadedDocumentsAgent";
import UploadedDocumentsEmployee from "./UploadedDocumentsEmployee";

const UploadedDocuments: React.FC = () => {
  const employee = useAppSelector(selectWCICEmployee);

  return employee ? <UploadedDocumentsEmployee /> : <UploadedDocumentsAgent />;
};

export default UploadedDocuments;
