import useQuote from "hooks/queries/useQuote";
import { useParams } from "react-router-dom";

import { HomeownersRater } from ".";
import { LandlordsRater } from "./landlord";

type PageParams = {
  quoteID?: string;
};

const RaterController = () => {
  const { quoteID } = useParams<PageParams>();
  const { data } = useQuote(quoteID);
  if (!!quoteID) {
    if (!!data) {
      if (data.rateType === "HO") {
        return <HomeownersRater quote={data} />;
      }
      if (data.rateType === "LP") {
        return <LandlordsRater quote={data} />;
      }
    } else {
      return <div></div>;
    }
  } else {
  }
};

export default RaterController;
