import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiPanel,
  EuiSpacer,
  EuiTableSortingType,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import EuiCustomLink from "components/EuiCustomLink";
import moment from "moment";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { useQuery } from "react-query";
import authAxios from "services/authAxios";
import pageAndSort from "services/pageAndSort";
import { Invoice } from "types/apiTypes";

interface TableData {
  policyID: string;
  invoiceType: string;
  dueDate: string;
  balance: number;
  invoice: Invoice;
}

const getInvoicesComingDue = async () => {
  const { data } = await authAxios.get(`/invoices-coming-due`);
  return data;
};

function useInvoicesComingDue() {
  const useQueryReturn = useQuery<Invoice[]>(["invoicesComingDue"], () => getInvoicesComingDue(), {
    placeholderData: [],
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
  });
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

const InvoicesComingDue = () => {
  const { data, isLoading } = useInvoicesComingDue();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof TableData>("dueDate");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const dataTable: TableData[] = data.map((record) => {
    return {
      policyID: record.policyID,
      invoiceType: record.type,
      dueDate: record.dueDate,
      balance: record.balance,
      invoice: record,
    };
  });

  const columns: EuiBasicTableColumn<TableData>[] = [
    {
      field: "policyID",
      name: "Policy ID",
      render: (policyID: string, rowData: TableData) => {
        return <EuiCustomLink to={`/policy/${rowData.invoice.policy.recordID}`}>{policyID}</EuiCustomLink>;
      },
    },
    {
      field: "invoiceType",
      name: "Type",
      render: (invoiceType: string) => {
        switch (invoiceType) {
          case "R":
            return <EuiTextColor color="success">Renewal</EuiTextColor>;
          case "C":
            return <EuiTextColor color="danger">Cancellation</EuiTextColor>;
          case "D":
            return <EuiTextColor color="danger">Earned Premium</EuiTextColor>;
          case "I":
            return <EuiTextColor color="warning">Installment</EuiTextColor>;
          case "N":
            return <EuiTextColor color="success">New Policy</EuiTextColor>;
          case "E":
            return <EuiTextColor color="success">Endorsement</EuiTextColor>;
          case "W":
            return <EuiTextColor color="success">Write Off</EuiTextColor>;

          default:
            return <EuiTextColor color="default">{invoiceType}</EuiTextColor>;
        }
      },
      //   width: "10%"
    },
    {
      field: "dueDate",
      name: "Due Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "balance",
      name: "Balance",
      align: "right",
      render: (balance: number) => {
        return (
          <NumberFormat
            displayType={"text"}
            style={{ paddingLeft: "10px" }}
            value={balance / 100} // TODO check this?
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        );
      },
    },
  ];

  const { pageOfItems, totalItemCount } = pageAndSort(dataTable, { pageIndex, pageSize, sortField, sortDirection });

  const getRowProps = (record: TableData) => {
    const { recordID } = record.invoice;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      // onClick: () => {
      //   console.log("clicked on this: ", invoice);
      // },
    };
  };

  const getCellProps = (record: TableData, column: any) => {
    const { recordID } = record.invoice;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };
  const sorting: EuiTableSortingType<TableData> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [15, 10, 5],
    hidePerPageOptions: !showPerPageOptions,
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<TableData>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPanel hasBorder>
      <EuiTitle>
        <h3>Payments Coming Due</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiBasicTable
        items={pageOfItems}
        itemId="ID"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
        pagination={pagination}
        onChange={onTableChange}
        tableLayout="auto"
        sorting={sorting}
        loading={isLoading}
      />
    </EuiPanel>
  );
};

export default InvoicesComingDue;
