import { EuiEmptyPrompt, EuiLoadingLogo, EuiPage, EuiPageBody, EuiPanel } from "@elastic/eui";
import { useAPI_LOGIN_ID, useAuthorizeEnv, useCLIENT_KEY } from "hooks/useAPIURL";
import { useReducer } from "react";
import { DispatchDataResponse, useAcceptJs } from "react-acceptjs";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import authAxios from "services/authAxios";
import { Invoice } from "types/apiTypes";
import PaymentAmount from "utilities/PaymentAmountUtil";

import PaymentView from "./PaymentView";

// import ReceiptView from "./ReceiptView";

export interface TokenPaymentData extends Partial<DispatchDataResponse> {
  invoiceRecordID: string;
  paymentPlan: string;
  paymentSource: string;
  amount: number;
}

interface PaymentCreditType {
  paymentSource: "card";
  paymentPlan: string;
  fullName: string;
  cardNumber: string;
  month: string;
  year: string;
  cardCode: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email?: string;
  waivedLateFee?: boolean;
  accountNumber?: string;
  routingNumber?: string;
  nameOnAccount?: string;
  accountType?: "checking" | "savings" | "businessChecking";
}

interface PaymentBankType {
  paymentSource: "bank";
  paymentPlan: string;
  fullName: string;
  accountNumber: string;
  routingNumber: string;
  nameOnAccount: string;
  accountType: "checking" | "savings" | "businessChecking";
  cardNumber?: string;
  month?: string;
  year?: string;
  cardCode?: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email?: string;
  waivedLateFee?: boolean;
}

interface PaymentAgentType {
  paymentSource: "agent";
  paymentPlan: string;
  fullName?: string;
  accountNumber?: string;
  routingNumber?: string;
  nameOnAccount?: string;
  accountType?: "checking" | "savings" | "businessChecking";
  cardNumber?: string;
  month?: string;
  year?: string;
  cardCode?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  email?: string;
  waivedLateFee?: boolean;
}

export type PaymentFormInputs = PaymentCreditType | PaymentBankType | PaymentAgentType;

enum PaymentActionType {
  RESET = "RESET",
  TOKENIZING = "TOKENIZING",
  CHARGING = "CHARGING",
  CHARGED = "CHARGED",
  ERROR = "ERROR",
}

interface PaymentAction {
  type: PaymentActionType;
  payload?: string;
}

interface PaymentState {
  entry: boolean;
  loading: boolean;
  tokenized: boolean;
  charged: boolean;
  error: boolean;
  errorMessage: string;
}

function payInvoiceReducer(state: PaymentState, action: PaymentAction) {
  switch (action.type) {
    case PaymentActionType.RESET:
      return {
        ...state,
        entry: true,
        loading: false,
        tokenized: false,
        charged: false,
        error: false,
        errorMessage: "",
      };
    case PaymentActionType.TOKENIZING:
      return {
        ...state,
        loading: true,
        tokenizing: true,
      };
    case PaymentActionType.CHARGING:
      return {
        ...state,
        loading: true,
        tokenizing: false,
        charging: true,
      };
    case PaymentActionType.CHARGED:
      return {
        ...state,
        entry: false,
        loading: false,
        charging: false,
        charged: true,
      };
    case PaymentActionType.ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload || "Unknown Error",
      };

    default:
      return state;
  }
}
const PayInvoice: React.FC<{ invoice: Invoice; waivedLateFee: boolean }> = ({ invoice, waivedLateFee, children }) => {
  const navigate = useNavigate();
  const API_LOGIN_ID = useAPI_LOGIN_ID();
  const CLIENT_KEY = useCLIENT_KEY();
  const authorizeEnv = useAuthorizeEnv();

  const [state, dispatch] = useReducer(payInvoiceReducer, {
    entry: true,
    loading: false,
    tokenized: false,
    charged: false,
    error: false,
    errorMessage: "",
  });

  const payInvoiceMutation = useMutation((paymentData: TokenPaymentData) => {
    return authAxios.post("/pay_invoice", paymentData);
  });

  const { dispatchData } = useAcceptJs({
    authData: {
      apiLoginID: API_LOGIN_ID,
      clientKey: CLIENT_KEY,
    },
    environment: authorizeEnv,
  });

  const onSubmit = (data: PaymentFormInputs) => {
    // console.log("paymentPlan: ", data.paymentPlan);
    dispatch({ type: PaymentActionType.TOKENIZING });
    var paymentData;

    if (data.paymentSource === "card") {
      paymentData = {
        cardData: {
          cardNumber: data.cardNumber || "",
          month: data.month || "",
          year: data.year || "",
          cardCode: data.cardCode || "",
        },
      };
    } else {
      paymentData = {
        bankData: {
          accountNumber: data.accountNumber || "",
          routingNumber: data.routingNumber || "",
          nameOnAccount: data.nameOnAccount || "",
          accountType: data.accountType!,
        },
      };
    }
    dispatchData(paymentData)
      .then((opaqueData: any) => {
        dispatch({ type: PaymentActionType.CHARGING });
        payInvoiceMutation.mutate(
          {
            ...opaqueData,
            paymentPlan: data.paymentPlan,
            invoiceRecordID: invoice.recordID,
            paymentSource: data.paymentSource,
            amount: PaymentAmount({
              paymentPlan: data.paymentPlan,
              waivedLateFee,
              invoice,
            }),
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            address: data.address || "",
            city: data.city || "",
            state: data.state || "",
            zip: data.zip || "",
            email: data.email || "",
            waivedLateFee: waivedLateFee || false,
            payoffInstallments: data.paymentPlan === "-1" ? true : false,
          },
          {
            onSuccess: (data: any) => {
              // console.log("data after transaction: ", data);
              dispatch({ type: PaymentActionType.CHARGED });
              navigate(`/transaction/${data.data.recordID}`);
            },
            onError: (error: any) => {
              dispatch({ type: PaymentActionType.ERROR, payload: error.response.data.message });
            },
          }
        );
      })
      .catch((error: any) => {
        console.log("🚀 ~ file: PayInvoice.tsx ~ line 125 ~ onSubmit ~ error", error);
        dispatch({
          type: PaymentActionType.ERROR,
          payload: error.messages?.message?.[0]?.text || "Error with AcceptJS",
        });
      });
  };

  return (
    <>
      {state.entry ? (
        <PaymentView
          invoice={invoice}
          waivedLateFee={waivedLateFee}
          onSubmit={onSubmit}
          loading={state.loading}
          showError={state.error}
          errorMessage={state.errorMessage}
        />
      ) : (
        <EuiPage>
          <EuiPageBody restrictWidth="1300px">
            <EuiPanel hasBorder>
              <EuiEmptyPrompt icon={<EuiLoadingLogo logo="filebeatApp" size="xl" />} title={<h2>Loading</h2>} />
            </EuiPanel>
          </EuiPageBody>
        </EuiPage>
      )}
    </>
  );
};

export default PayInvoice;
