import {
  EuiAvatar,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonGroup,
  EuiCommentList,
  EuiCommentProps,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingContent,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectClaimsActivityLayout, setClaimsActivityLayout } from "features/authorization/authSlice";
import moment from "moment";
import NumberFormat from "react-number-format";
import { useMutation } from "react-query";
import authAxios from "services/authAxios";
import { ClaimActivity, ClaimTransaction } from "types/apiTypes";

const columns: EuiBasicTableColumn<any>[] = [
  {
    field: "name",
    name: "Name",
    align: "left",
  },
  {
    field: "",
    name: "Description",
    align: "left",
    render: (rowData: any) => {
      if (typeof "" == typeof rowData.description) {
        return rowData.description;
      } else {
        return (
          <NumberFormat
            value={rowData.description / 100}
            displayType="text"
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            decimalSeparator="."
            prefix={"$"}
          />
        );
      }
    },
  },
  {
    field: "date",
    name: "Date",
    align: "right",
  },
  // {
  //   field: "id",
  //   name: "ID",
  // },
];

const ClaimsActivity: React.FC<{ comments: (ClaimTransaction | ClaimActivity)[]; isLoading: boolean }> = ({
  comments,
  isLoading,
}) => {
  const selected = useAppSelector(selectClaimsActivityLayout);
  const dispatch = useAppDispatch();

  const useClaimsActivityLayout = () => {
    return useMutation({
      mutationFn: (layout: string) => authAxios.post("/table-timeline", { layout: layout }),
      onMutate: async (newLayout) => {
        dispatch(setClaimsActivityLayout(newLayout));
        return { selected };
      },
      onError: (err, newLayout, context: { selected: "table" | "timeline" } | undefined) => {
        if (context?.selected) {
          dispatch(setClaimsActivityLayout(context.selected));
        }
      },
    });
  };

  const setActivity = useClaimsActivityLayout();
  const ClaimsActivityComponent: React.FC<{ comments: (ClaimTransaction | ClaimActivity)[] }> = ({ comments }) => {
    const sortedCommentList: EuiCommentProps[] = comments
      ?.sort((item1: ClaimTransaction | ClaimActivity, item2: ClaimTransaction | ClaimActivity) => {
        let item1Moment;
        let item2Moment;

        if ("timestamp" in item1) {
          item1Moment = moment(item1.timestamp);
        } else {
          item1Moment = moment(item1.date);
        }
        if ("timestamp" in item2) {
          item2Moment = moment(item2.timestamp);
        } else {
          item2Moment = moment(item2.date);
        }
        return item2Moment.diff(item1Moment);
      })
      .filter((comment: ClaimTransaction | ClaimActivity) => {
        if ("checkID" in comment && comment.checkID === null) {
          return null;
        } else return comment;
      })
      .map((comment: ClaimTransaction | ClaimActivity) => {
        if ("timestamp" in comment) {
          // console.log('comment: ', comment)

          return {
            username: comment.employee.displayName,
            event: "",
            timestamp: moment(comment.timestamp).calendar(),
            subject: comment.subject, //
            children: (
              <EuiText size="s">
                <p>{comment.subject}</p>
              </EuiText>
            ),
            timelineIcon: <EuiAvatar size="l" name={comment.employee.displayName} />,
          } as EuiCommentProps;
        } else {
          // console.log('comment: ', comment)

          return {
            username: comment.description,
            type: "update",
            amount: comment.amount,
            event: (
              <NumberFormat
                displayType={"text"}
                style={{ paddingLeft: "10px" }}
                value={comment.amount / 100}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            ),
            timestamp: moment(comment.date).calendar(),
            timelineIcon: "dot",
          } as EuiCommentProps;
        }
      });
    // console.log("commentList:", sortedCommentList);
    const tableData = sortedCommentList.map((item: any) => {
      if (item.subject === undefined) {
        return { name: item.username, description: item.amount, date: item.timestamp };
      } else {
        return { name: item.username, description: item.subject, date: item.timestamp };
      }
    });
    return (
      <EuiPanel hasBorder>
        <EuiFlexGroup>
          <EuiFlexItem grow={8}>
            <EuiTitle>
              <h3>Claims Activity</h3>
            </EuiTitle>
            <EuiSpacer size="s" />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButtonGroup
              legend={"legend"}
              name={"name"}
              idSelected={selected}
              onChange={(optionId) => {
                setActivity.mutate(optionId);
              }}
              options={[
                {
                  id: "table",
                  label: "Table",
                },
                {
                  id: "timeline",
                  label: "Timeline",
                },
              ]}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="s" />
        {selected === "table" ? (
          <EuiBasicTable
            items={tableData}
            itemId="claimsActivity"
            columns={columns}
            // rowProps={getRowProps}
            // cellProps={getCellProps}
            // tableLayout="auto"
          />
        ) : (
          <EuiCommentList comments={sortedCommentList} />
        )}
        <EuiSpacer size="s" />
      </EuiPanel>
    );
  };

  const LoadingContent: React.FC = () => {
    return (
      <EuiPanel>
        <EuiTitle>
          <h3>Claims Activity</h3>
        </EuiTitle>
        <EuiSpacer />
        <EuiLoadingContent lines={1} />
      </EuiPanel>
    );
  };

  if (isLoading) {
    return <LoadingContent />;
  }
  return <ClaimsActivityComponent comments={comments} />;
};

export default ClaimsActivity;
