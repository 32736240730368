import * as yup from "yup";

export const RCschema = yup
  .object({
    // TODO these three need labels? and type errors?
    name: yup.string().typeError("Full name is required").required().label("Full name"),
    streetAddress: yup.string().typeError("Street address is required").required().label("Street Address"),
    zip: yup.number().positive().integer().typeError("Zip must be a number").required().label("Zip"),
    constructionYear: yup
      .number()
      .positive()
      .integer()
      .min(1776)
      .max(2100)
      .typeError("Construction Year must be a number")
      .required()
      .label("Construction Year"),
    squareFootage: yup
      .number()
      .positive()
      .integer()
      .typeError("Square Footage must be a number")
      .required()
      .label("Square Footage"),
    constructionClass: yup.number().oneOf([1, 2, 3, 4], "Please select a class").integer(),

    basement: yup.object().shape({
      slab: yup.number().min(0).integer().typeError("Slab must be a number").required().label("Slab"),
      concrete: yup.number().min(0).integer().typeError("Concrete must be a number").required().label("Concrete"),
      reinforcedConcrete: yup
        .number()
        .min(0)
        .integer()
        .typeError("Reinforced Concrete must be a number")
        .required()
        .label("Reinforced Concrete"),
      partiallyFinished: yup
        .number()
        .min(0)
        .integer()
        .typeError("Partially Finished must be a number")
        .required()
        .label("Partially Finished"),
      recRoom: yup.number().min(0).integer().typeError("Rec Room must be a number").required().label("Rec Room"),
      finished: yup.number().min(0).integer().typeError("Finished must be a number").required().label("Finished"),
    }),
    exteriorFinish: yup
      .object()
      .shape({
        siding: yup
          .number()
          .min(0)
          .max(100)
          .integer()
          .typeError("Siding must be a number")
          .default(0)
          .required()
          .label("Siding"),
        stucco: yup
          .number()
          .min(0)
          .max(100)
          .integer()
          .typeError("Stucco must be a number")
          .default(0)
          .required()
          .label("Stucco"),
        brickVeneer: yup
          .number()
          .min(0)
          .max(100)
          .integer()
          .typeError("Brick Veneer must be a number")
          .default(0)
          .required()
          .label("Brick Veneer"),
        concrete: yup
          .number()
          .min(0)
          .max(100)
          .integer()
          .typeError("Concrete must be a number")
          .required()
          .default(0)
          .label("Concrete"),
        brickConcrete: yup
          .number()
          .min(0)
          .max(100)
          .integer()
          .typeError("Brick Concrete must be a number")
          .default(0)
          .required()
          .label("Brick Concrete"),
        stoneVeneer: yup
          .number()
          .min(0)
          .max(100)
          .integer()
          .typeError("Stone Veneer must be a number")
          .default(0)
          .required()
          .label("Stone Veneer"),
      })
      .test("totals100", "Exterior finish must total 100", (value) => {
        return (
          (value.siding || 0) +
            (value.stucco || 0) +
            (value.brickVeneer || 0) +
            (value.concrete || 0) +
            (value.brickConcrete || 0) +
            (value.stoneVeneer || 0) ===
          100
        );
      }),
    roofType: yup.number().positive().integer().required(),
    stories: yup.number().positive().integer().required(),
    laborType: yup.number().positive().integer().required(),
    attic: yup.number().min(0).integer().typeError("Attic must be a number").required().label("Attic"),
    fullBath: yup.number().min(0).integer().typeError("Full bath must be a number").required().label("Full Bath"),
    halfBath: yup.number().min(0).integer().typeError("Half bath must be a number").required().label("Half Bath"),
    openBreezeway: yup
      .number()
      .min(0)
      .integer()
      .typeError("Open Breezeway must be a number")
      .required()
      .label("Open Breezeway"),
    closedBreezeway: yup
      .number()
      .min(0)
      .integer()
      .typeError("Closed Breezeway must be a number")
      .required()
      .label("Closed Breezeway"),
    carport1Bay: yup
      .number()
      .min(0)
      .integer()
      .typeError("Carport One must be a number")
      .required()
      .label("Carport One"),
    carport2Bay: yup
      .number()
      .min(0)
      .integer()
      .typeError("Carport Two must be a number")
      .required()
      .label("Carport Two"),
    centralAir: yup.boolean().required(),
    centralVac: yup.boolean().required(),
    deck: yup.number().min(0).integer().typeError("Deck must be a number").required().label("Deck"),
    dormersDecorative: yup.number().min(0).integer().typeError("Dormers must be a number").required().label("Dormers"),
    dormers: yup.array().of(
      yup.object().shape({
        type: yup.number().min(0).integer().required(),
        squareFoot: yup
          .number()
          .min(0)
          .integer()
          .typeError("Dormer requires square footage")
          .required()
          .label("Dormer"),
      })
    ),
    fireplaceMasonry: yup
      .number()
      .min(0)
      .integer()
      .typeError("Fireplace Masonry must be a number")
      .required()
      .label("Fireplace Masonry"),
    fireplaceOther: yup
      .number()
      .min(0)
      .integer()
      .typeError("Fireplace Other must be a number")
      .required()
      .label("Fireplace Other"),
    garage1: yup.number().min(0).integer().typeError("Garage One must be a number").required().label("Garage One"),
    garage2: yup.number().min(0).integer().typeError("Garage Two must be a number").required().label("Garage Two"),
    garage3: yup.number().min(0).integer().typeError("Garage Three must be a number").required().label("Garage Three"),
    garageFinish: yup
      .number()
      .min(0)
      .integer()
      .typeError("Garage Finish must be a number")
      .required()
      .label("Garage Finish"),
    generator: yup.boolean().required(),
    hotWaterHeat: yup.boolean().required(),
    hotTub: yup.number().min(0).integer().typeError("Hottub must be a number").required().label("Hottub"),
    kitchenUpgrade: yup
      .number()
      .min(0)
      .integer()
      .typeError("Kitchen Upgrade must be a number")
      .required()
      .label("Kitchen Upgrade"),
    mudrooms: yup.number().min(0).integer().typeError("Mudrooms must be a number").required().label("Mudrooms"),
    laundry: yup.number().min(0).integer().typeError("Laundry must be a number").required().label("Laundry"),
    multiFamily: yup.object().shape({
      additionalEntry: yup
        .number()
        .min(0)
        .integer()
        .typeError("Slab must be a number")
        .required()
        .label("Additional Entry"),
      additionalKitchen: yup
        .number()
        .min(0)
        .integer()
        .typeError("Concrete must be a number")
        .required()
        .label("Additional Kitchen"),
      heat: yup
        .number()
        .min(0)
        .integer()
        .typeError("Reinforced Concrete must be a number")
        .required()
        .label("Heat and Electric"),
      heatAC: yup
        .number()
        .min(0)
        .integer()
        .typeError("Stone must be a number")
        .required()
        .label("Heat and Electric with AC"),
    }),
    porch: yup.array().of(
      yup.object().shape({
        type: yup.number().min(0).integer().required(),
        squareFoot: yup
          .number()
          .min(0)
          .integer()
          .typeError("Porch/Patio must be a number")
          .required()
          .label("Porch/Patio"),
      })
    ),
    radiantHeat: yup
      .number()
      .min(0)
      .integer()
      .typeError("Radiant Heat must be a number")
      .required()
      .label("Radiant Heat"),
    securitySystem: yup.boolean().required(),
  })
  .required();
