/** @jsxImportSource @emotion/react */
import { EuiTextColor } from "@elastic/eui";
import { TextColor } from "@elastic/eui/src/components/text/text_color";
import { CSSProperties, Fragment, FunctionComponent } from "react";

const WCICTextLabel: FunctionComponent<{ label: string; labelColor?: TextColor | CSSProperties["color"] }> = ({
  children,
  label,
  labelColor = "subdued",
}) => {
  return (
    <Fragment>
      <div css={{ marginBottom: 3 }}>
        <label css={{ fontSize: 12 }}>
          <EuiTextColor color={`${labelColor}`}>
            <strong>{label}</strong>
          </EuiTextColor>
        </label>
      </div>
      {children}
    </Fragment>
  );
};

export default WCICTextLabel;
