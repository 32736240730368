import { Comparators } from "@elastic/eui";

interface PageAndSortOptions {
  sortField?: string;
  sortDirection?: "asc" | "desc";
  pageIndex?: number;
  pageSize?: number;
}

const pageAndSort = <T>(items: T[], options?: PageAndSortOptions): { pageOfItems: T[]; totalItemCount: number } => {
  if (!options) {
    return {
      pageOfItems: items,
      totalItemCount: items.length,
    };
  }
  let sortedItems;
  if (options.sortField) {
    sortedItems = items
      .slice(0)
      .sort(Comparators.property(options.sortField, Comparators.default(options.sortDirection)));
  } else {
    sortedItems = items;
  }
  if (options.pageSize === 0) {
    return {
      pageOfItems: sortedItems,
      totalItemCount: sortedItems.length,
    };
  }
  let pageOfItems;

  if (options.pageIndex !== undefined && options.pageSize !== undefined) {
    const startIndex = options.pageIndex * options.pageSize;
    pageOfItems = sortedItems.slice(startIndex, Math.min(startIndex + options.pageSize, sortedItems.length));
  } else {
    pageOfItems = sortedItems;
  }
  return {
    pageOfItems,
    totalItemCount: sortedItems.length,
  };
};

export default pageAndSort;
