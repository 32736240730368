import {
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiSuperSelect,
  EuiText,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import { FormSelect } from "components/react-hook-form/SelectInput";
import { Fragment, FunctionComponent } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Invoice } from "types/apiTypes";

import BankView from "./BankView";
import CreditCardView from "./CreditCardView";
import { PaymentFormInputs } from "./PayInvoiceModal";
import PaymentAddressView from "./PaymentAddressView";

interface PaymentViewProps {
  invoice: Invoice;
  onSubmit: (data: PaymentFormInputs) => void;
  loading: boolean;
}

const PaymentView: FunctionComponent<PaymentViewProps> = ({ invoice, onSubmit, loading }) => {
  const d = new Date();
  const methods = useForm<PaymentFormInputs>({
    mode: "onTouched",
    defaultValues: {
      paymentSource: "card",
      paymentPlan: "paymentPlan0",
      fullName: "",
      cardNumber: "",
      month: String(d.getMonth() + 1),
      year: String(d.getFullYear()),
      zip: "",
      nameOnAccount: "",
      accountNumber: "",
      routingNumber: "",
      accountType: "checking",
    },
  });
  const { handleSubmit, watch } = methods;
  const paymentPlan = watch("paymentPlan");
  const paymentType = watch("paymentSource");
  return (
    <FormProvider {...methods}>
      <EuiForm component="form" onSubmit={handleSubmit(onSubmit)}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Invoice: {invoice.invoiceID}</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiFormRow fullWidth label="Payment Plan Options">
            <Controller
              name={`paymentPlan` as `${string}`}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <EuiSuperSelect
                    options={generatePaymentOptionsSelectOptions(invoice.planOptions)}
                    valueOfSelected={value}
                    onChange={onChange}
                    itemLayoutAlign="top"
                    hasDividers
                  />
                );
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label="Payment Source">
            <FormSelect
              name={`paymentSource`}
              label={"Payment Source"}
              rules={{ required: true }}
              options={[
                { value: "card", text: "Card" },
                { value: "bank", text: "Bank Account" },
                // { value: "agent", text: "Stored Agency Payment" },
              ]}
            />
          </EuiFormRow>
          {paymentType === "bank" ? <BankView /> : <CreditCardView />}
          <EuiSpacer />
          <PaymentAddressView />
        </EuiModalBody>
        <EuiModalFooter>
          <EuiTitle>
            <EuiTextColor color="success">
              ${invoice.planOptions[parseInt(paymentPlan.charAt(paymentPlan.length - 1))][0]} Due
            </EuiTextColor>
          </EuiTitle>
          <EuiButton
            onClick={handleSubmit(onSubmit)}
            isLoading={loading}
            fill
            // disabled={payable}
            color="success"
          >
            Pay Now
          </EuiButton>
        </EuiModalFooter>
      </EuiForm>
    </FormProvider>
  );
};

const generatePaymentOptionsSelectOptions = (planOptions: Invoice["planOptions"]) => {
  if (planOptions[0][0] === 0) {
    return [
      {
        value: `paymentPlan0`,
        inputDisplay: `Plan Error`,
        dropdownDisplay: (
          <Fragment>
            <strong>{`Error`}</strong>
            <EuiText size="s" color="subdued">
              <p className="euiTextColor--subdued">{`Payment Error`}</p>
            </EuiText>
          </Fragment>
        ),
      },
    ];
  } else {
    return planOptions.map((paymentPlan, index: number) => {
      if (index === 0) {
        return {
          value: `paymentPlan${index}`,
          inputDisplay: `Full Pay - $${paymentPlan[0]} Due`,
          dropdownDisplay: (
            <Fragment>
              <strong>{`Full Pay`}</strong>
              <EuiText size="s" color="subdued">
                <p className="euiTextColor--subdued">{`$${paymentPlan[0]} Due today`}</p>
              </EuiText>
            </Fragment>
          ),
        };
      } else {
        const paymentsWord = index === 1 ? "payment" : "payments";

        return {
          value: `paymentPlan${index}`,
          inputDisplay: `Payment Plan ${index + 1} - $${paymentPlan[0]} Due`,
          dropdownDisplay: (
            <Fragment>
              <strong>{`Payment Plan ${index + 1}`}</strong>
              <EuiText size="s" color="subdued">
                <p className="euiTextColor--subdued">{`$${paymentPlan[0]} Due today and ${index} ${paymentsWord} of $${paymentPlan[1]}`}</p>
              </EuiText>
            </Fragment>
          ),
        };
      }
    });
  }
};

export default PaymentView;
