import {
  CENTER_ALIGNMENT,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiEmptyPrompt,
  EuiFieldText,
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiProgress,
  EuiSpacer,
} from "@elastic/eui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppSelector } from "app/hooks";
import ErrorPage from "components/ErrorPage";
import { selectAdministrator, selectWCICEmployee } from "features/authorization/authSlice";
import { humanFileSize } from "pages/underwriting/documents/UploadedDocument";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import authAxios from "services/authAxios";
import * as yup from "yup";

interface UploadMemosType {
  name: string;
}

interface TableData {
  filename: string;
}

const UploadMemosSchema = yup.object({
  name: yup.string().max(128, "Name cannot exceed 128 characters").required().label("Name"),
});

const UploadMemos: React.FC = () => {
  const admin = useAppSelector(selectAdministrator);
  const employee = useAppSelector(selectWCICEmployee);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const openErrorModal = () => setErrorModal(true);
  const closeErrorModal = () => setErrorModal(false);
  const [tableFiles, setTableFiles] = useState<Array<TableData>>([]);

  const [uploadComplete, setUploadComplete] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => setIsModalVisible(false);

  let modal;

  if (isModalVisible) {
    if (progress < 100) {
      modal = (
        <EuiModal onClose={closeModal}>
          <EuiModalBody css={{ textAlign: CENTER_ALIGNMENT }}>
            <EuiEmptyPrompt
              icon={<EuiIcon type={"filebeatApp"} size="xl" />}
              title={
                <div>
                  <h2>Uploading Files</h2>
                  <br></br>
                  <EuiProgress
                    // label="Upload Progress"
                    valueText={true}
                    value={progress}
                    max={100}
                    color="primary"
                    size="l"
                  />
                </div>
              }
            />
          </EuiModalBody>
        </EuiModal>
      );
    } else {
      if (uploadComplete) {
        modal = (
          <EuiModal onClose={closeModal}>
            <EuiModalBody css={{ textAlign: CENTER_ALIGNMENT }}>
              <EuiEmptyPrompt
                icon={<EuiIcon type={"filebeatApp"} size="xl" />}
                title={
                  <div>
                    <h2>Completed</h2>
                    <EuiSpacer size="s" />
                    <EuiIcon type="check" color="green" size="xxl" />
                  </div>
                }
              />
              <EuiButton
                onClick={() => {
                  navigate("/library/memos");
                }}
                fill
              >
                Okay
              </EuiButton>
            </EuiModalBody>
          </EuiModal>
        );
      } else {
        modal = (
          <EuiModal onClose={closeModal}>
            <EuiModalBody css={{ textAlign: CENTER_ALIGNMENT }}>
              <EuiEmptyPrompt
                icon={<EuiIcon type={"filebeatApp"} size="xl" />}
                title={
                  <div>
                    <h2>Processing</h2>
                    <EuiSpacer size="s" />
                    <EuiLoadingSpinner size="xxl" />
                  </div>
                }
              />
            </EuiModalBody>
          </EuiModal>
        );
      }
    }
  }

  const columns: EuiBasicTableColumn<TableData>[] = [
    {
      field: "filename",
      name: "filename",
    },
    {
      field: "size",
      name: "size",
      render: (size: number) => {
        return humanFileSize(size);
      },
    },
  ];

  const methods = useForm<UploadMemosType>({
    mode: "onTouched",
    resolver: yupResolver(UploadMemosSchema),
    defaultValues: {
      name: "",
    },
  });
  const { handleSubmit, control } = methods;
  const [files, setFiles] = useState<FileList | null>(null);
  const onChange = (newFiles: FileList | null) => {
    if (newFiles !== null) {
      var temp: any = [];
      Array.from(newFiles).forEach((file: any) => {
        // console.log("name: ", file.name);
        temp.push({ filename: file.name, size: file.size });
      });
      setTableFiles(temp);
    }
    setFiles(newFiles);
  };
  const onSubmit: SubmitHandler<UploadMemosType> = (data) => {
    setIsModalVisible(true);
    setButtonLoading(true);
    const jsonDocument = {
      name: data.name,
    };

    let submitData = new FormData();
    let fileSize = 0;
    if (files !== null) {
      Array.from(files).forEach((file: any) => {
        submitData.append("files[]", file);
        fileSize += file.size;
        // submitData.append("testfile", file[0]);
      });
    }

    submitData.append("document", JSON.stringify(jsonDocument));
    // console.log(fileSize);
    authAxios
      .post("/upload_agent_memos", submitData, {
        onUploadProgress: (progressEvent) => setProgress(Math.round((progressEvent.loaded / fileSize) * 100)),
      })
      .then((res) => {
        // console.log("res: ", res);
        setButtonLoading(false);
        queryClient.invalidateQueries();
        // Set a flag here
        setUploadComplete(true);
      })
      .catch((err) => {
        setButtonLoading(false);
        setButtonDisabled(true);
        closeModal();
        openErrorModal();
        // console.log(err);
      });
  };
  if (!(admin && employee)) {
    return <ErrorPage title={"Upload Memos"} />;
  }
  const onError = (errors: any, e: any) => console.log(errors, e);
  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageHeader pageTitle="Upload Memos" restrictWidth="1200px" />
        <EuiPageContent hasBorder={false} hasShadow={false} paddingSize="none" color="transparent" borderRadius="none">
          <EuiPageContentBody restrictWidth="1200px">
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <EuiPanel hasBorder>
                <EuiFlexGroup wrap={true}>
                  <EuiFlexItem style={{ minWidth: "300px" }}>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                        return (
                          <EuiFormRow fullWidth label="Name" isInvalid={!!error} error={error?.message}>
                            <EuiFieldText onChange={onChange} defaultValue={value} onBlur={onBlur} />
                          </EuiFormRow>
                        );
                      }}
                    />

                    <EuiFormRow fullWidth label="Memos">
                      <EuiFilePicker
                        accept="image/*, .txt, .pdf, .png, .jpg, .jpeg, .gif"
                        fullWidth
                        id="1"
                        multiple
                        initialPromptText="Add Memos"
                        onChange={onChange}
                      />
                    </EuiFormRow>

                    <EuiButton
                      isLoading={buttonLoading}
                      disabled={buttonDisabled || files === null || files.length === 0}
                      type="submit"
                    >
                      Upload
                    </EuiButton>
                  </EuiFlexItem>
                  <EuiFlexItem style={{ minWidth: "300px" }}>
                    <EuiFormRow fullWidth label="Files Selected">
                      <EuiPanel hasBorder>
                        <EuiBasicTable
                          items={tableFiles}
                          itemId="uploads"
                          columns={columns}
                          tableLayout="auto"
                          noItemsMessage="No Memos Selected"
                        />
                      </EuiPanel>
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiPanel>
            </form>
            {errorModal ? <ErrorModal onClose={closeErrorModal} /> : ""}
            {modal}
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
};

export default UploadMemos;

export const ErrorModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <span style={{ paddingLeft: "15px" }}>Error</span>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiSpacer />A problem was detected with the uploaded file(s). Please refresh the page to upload another file.
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton onClick={onClose}>OK</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
