import { CommonProps, EuiButtonIcon, EuiIcon } from "@elastic/eui";
import classNames from "classnames";
import { ButtonHTMLAttributes, FunctionComponent, MouseEventHandler, ReactNode } from "react";

interface WCICTabProps extends CommonProps {
  isSelected?: boolean;
  disabled?: boolean;
  /**
   * Places content before the tab content/children.
   * Will be excluded from interactive effects.
   */
  prepend?: ReactNode;
  /**
   * Places content after the tab content/children.
   * Will be excluded from interactive effects.
   */
  append?: ReactNode;
  onCloseTab?: MouseEventHandler<HTMLButtonElement>;
  closeButton?: boolean;
  error?: boolean;
}

type WCICTabPropsForButton = WCICTabProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> & {
    onClick?: MouseEventHandler<HTMLButtonElement>;
  };

const WCICTab: FunctionComponent<WCICTabPropsForButton> = ({
  isSelected,
  children,
  className,
  disabled,
  onClick,
  onCloseTab,
  closeButton = false,
  error = false,
}) => {
  const classes = classNames("euiTab", className, {
    "euiTab-isSelected": isSelected,
    "euiTab-isDisabled": disabled,
  });
  return (
    <div role="tab" aria-selected={!!true} className={classes}>
      {error ? <EuiIcon type={"alert"} color="danger" style={{marginRight: "5px"}} /> : null}
      <button type="button" className="euiTab__content" onClick={onClick}>
        {children}
      </button>
      {closeButton ? (
        <EuiButtonIcon
          style={{ marginLeft: "6px" }}
          aria-label="Remove Form"
          display="empty"
          iconType="cross"
          onClick={onCloseTab}
        />
      ) : null}
    </div>
  );
};

export default WCICTab;
