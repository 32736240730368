import {
  EuiButton,
  EuiCallOut,
  EuiCheckbox,
  EuiEmptyPrompt,
  EuiLoadingLogo,
  EuiPage,
  EuiPageBody,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import { useAppSelector } from "app/hooks";
import ErrorPage from "components/ErrorPage";
import { selectWCICEmployee } from "features/authorization/authSlice";
import moment from "moment";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import authAxios from "services/authAxios";
import { Invoice } from "types/apiTypes";

import { WaivedLateFeeModal } from "../Policy/Invoices";
import PayInvoice from "./PayInvoice";

type PageParams = {
  invoiceID: string;
};

const getInvoiceByID = async (id: string | undefined) => {
  const { data } = await authAxios.get(`/pay-invoice/${id}`, {});
  return data;
};

function usePayInvoice(invoiceID: string | undefined) {
  return useQuery<Invoice>(["payInvoicePage", invoiceID], () => getInvoiceByID(invoiceID), {
    enabled: !!invoiceID,
  });
}

const PayInvoicePage: React.FC = () => {
  const { invoiceID } = useParams<PageParams>();
  const { data, isLoading, isError } = usePayInvoice(invoiceID);
  const employee = useAppSelector(selectWCICEmployee);
  const [waivedLateFee, setwaivedLateFee] = useState(false);
  // console.log("Invoice: ", data);
  const queryClient = useQueryClient();
  const [isWaivedLateFeeModalVisible, setWaivedLateFeeModalVisible] = useState<boolean>(false);

  if (isError) {
    return <ErrorPage title="Pay Invoice" />;
  }

  if (isLoading) {
    return (
      <EuiPage>
        <EuiPageBody restrictWidth="1300px">
          <EuiPanel hasBorder>
            <EuiEmptyPrompt icon={<EuiLoadingLogo logo="filebeatApp" size="xl" />} title={<h2>Loading</h2>} />
          </EuiPanel>
        </EuiPageBody>
      </EuiPage>
    );
  }

  return (
    <>
      <EuiPage>
        <EuiPageBody>
          <EuiPageHeader pageTitle={"Invoice: " + data?.invoiceID} restrictWidth={"1100px"} />
          <EuiPageContentBody restrictWidth="1100px">
            {data?.lateFee === true ? (
              <>
                <EuiCallOut title="This invoice is past due." color="danger" iconType="flag">
                  <p>Due date: {moment(data.dueDate).format("LL")}</p>
                </EuiCallOut>
                <EuiSpacer />
                {employee ? (
                  <EuiButton
                    onClick={() => {
                      setWaivedLateFeeModalVisible(true);
                    }}
                  >
                    Waive Late Fee
                  </EuiButton>
                ) : null}
                <EuiSpacer />
              </>
            ) : null}
            {data?.waivedLateFee === true && data?.lateFee === true ? (
              <>
                <EuiCallOut title="This invoice is past due." color="primary" iconType="flag">
                  {/* TODO how should this be handled to remove the ! */}
                  <p>Due date: {moment(data!.dueDate).format("LL")}</p>
                  <p>The late fee for this invoice has been waived.</p>
                </EuiCallOut>
                <EuiSpacer />
              </>
            ) : null}
            <PayInvoice invoice={data!} waivedLateFee={waivedLateFee} />
          </EuiPageContentBody>
        </EuiPageBody>
      </EuiPage>
      {data ? (
        <WaivedLateFeeModal
          show={isWaivedLateFeeModalVisible}
          invoiceID={data.invoiceID.toString()}
          invoiceRecordID={data.recordID.toString()}
          closeModal={() => {
            setWaivedLateFeeModalVisible(false);
          }}
          query={queryClient}
        />
      ) : null}
    </>
  );
};

export default PayInvoicePage;
