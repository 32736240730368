import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiPanel,
  EuiSpacer,
  EuiTableSortingType,
  EuiTitle,
} from "@elastic/eui";
import moment from "moment";
import { useState } from "react";
import { Cancellation } from "types/apiTypes";

import pageAndSort from "../../../services/pageAndSort";

const Cancellations: React.FC<{ cancellations: Cancellation[] }> = ({ cancellations }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof Cancellation>("effectiveDate");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  function dateFormat(date: string) {
    if (date === null) {
      return "";
    }
    if (date === undefined) {
      return "";
    }
    return moment(date).format("l");
  }

  const columns: EuiBasicTableColumn<Cancellation>[] = [
    {
      field: "cancelType",
      name: "Cancel Type",
      render: (type: string) => {
        switch (type) {
          case "C":
            return "Cancellation";
          case "C1":
            return "Non-Payment";
          case "C2":
            return "Returned Check";
          case "C3":
            return "Underwriting";
          case "CR":
            return "Conditioned Renewal";
          case "CRP":
            return "Conditioned Renewal";
          case "D":
            return "Disclosure";
          case "NR":
            return "Nonrenewal";

          default:
            return "ERROR";
        }
      },
    },
    {
      field: "processDate",
      name: "Processed Date",
      render: (date: string) => {
        return dateFormat(date);
      },
    },
    {
      field: "effectiveDate",
      name: "Effective Date",
      render: (date: string) => {
        return dateFormat(date);
      },
    },
    {
      field: "reinstatementDate",
      name: "Reinstatement Date",
      render: (date: string) => {
        return dateFormat(date);
      },
    },
  ];

  const { pageOfItems, totalItemCount } = pageAndSort(cancellations, {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
  });

  const getRowProps = (cancellation: Cancellation) => {
    const { recordID } = cancellation;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      // onClick: () => {
      //   console.log("clicked on this: ", cancellation);
      //   // navigate("/claim/" + claim.recordID);
      // },
    };
  };

  const getCellProps = (cancellation: Cancellation, column: any) => {
    const { recordID } = cancellation;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };
  const sorting: EuiTableSortingType<Cancellation> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [15, 10, 5],
    hidePerPageOptions: !showPerPageOptions,
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<Cancellation>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPanel hasBorder>
      <EuiTitle>
        <h3>Cancellations History</h3>
      </EuiTitle>
      <EuiSpacer size="m" />
      {/* {cancellations.length > 0 ? ( */}
      <EuiBasicTable
        items={pageOfItems}
        itemId="recordID"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
        pagination={pagination}
        onChange={onTableChange}
        tableLayout="auto"
        sorting={sorting}
      />
      {/* ) : (
        <EuiFlexGroup justifyContent="spaceAround">
          <EuiFlexItem grow={false}>
            <EuiTitle size="s">
              <EuiTextColor color="subdued">No Items Found</EuiTextColor>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
      )} */}
      <EuiSpacer size="s" />
    </EuiPanel>
  );
};

export default Cancellations;
