import { useQuery } from "react-query";
import { PolicyLocation } from "types/apiTypes";

import authAxios from "../../services/authAxios";

const getPolicyLocationsQuery = async (query: string) => {
  const { data } = await authAxios.post("/policy-locations", { policyID: query });
  return data;
};

function usePolicyLocationsQuery(query: string) {
  const isValidPolicyID = query.length === 5 || query.length === 8;
  return useQuery<PolicyLocation[]>(["policy_location_list", { query: query }], () => getPolicyLocationsQuery(query), {
    enabled: isValidPolicyID,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    placeholderData: [],
  });
}

export default usePolicyLocationsQuery;
