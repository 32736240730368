import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiPanel,
  EuiSpacer,
  EuiTableSortingType,
  EuiTitle,
} from "@elastic/eui";
import EuiCustomLink from "components/EuiCustomLink";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import authAxios from "services/authAxios";
import pageAndSort from "services/pageAndSort";
import { AccessLogView } from "types/apiTypes";

interface TableData {
  itemID: string;
  logTypeID: number;
  timestamp: string;
  accessLog: AccessLogView;
}

// TODO Whitespace?

const getAccessLogs = async () => {
  const { data } = await authAxios.get(`/access-logs`);
  return data;
};

function useAccessLogs() {
  const useQueryReturn = useQuery<AccessLogView[]>(["accessLogs"], () => getAccessLogs(), {
    placeholderData: [],
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 0,
  });
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

const AccessLogs = () => {
  const { data, isLoading } = useAccessLogs();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof TableData>("timestamp");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const dataTable: TableData[] = data.map((record) => {
    return {
      itemID: record.itemID,
      logTypeID: record.logTypeID,
      timestamp: record.timestamp,
      accessLog: record,
    };
  });

  const columns: EuiBasicTableColumn<TableData>[] = [
    {
      field: "itemID",
      name: "Item ID",
      render: (itemID: string, rowData: TableData) => {
        if (rowData.logTypeID === 1) {
          return <EuiCustomLink to={`/insured/${rowData.accessLog.itemRecordID}`}>{itemID}</EuiCustomLink>;
        } else if (rowData.logTypeID === 2) {
          return <EuiCustomLink to={`/policy/${rowData.accessLog.itemRecordID}`}>{itemID}</EuiCustomLink>;
        } else if (rowData.logTypeID === 3) {
          return <EuiCustomLink to={`/claim/${rowData.accessLog.itemRecordID}`}>{itemID}</EuiCustomLink>;
        }
      },
    },
    {
      field: "logTypeID",
      name: "Type",
      render: (logTypeID: number) => {
        if (logTypeID === 1) {
          return "Insured";
        } else if (logTypeID === 2) {
          return "Policy";
        } else if (logTypeID === 3) {
          return "Claim";
        }
      },
    },
    {
      field: "timestamp",
      align: "right",
      name: "Date",
      render: (date: string) => moment(date).format("l"),
    },
  ];

  const { pageOfItems, totalItemCount } = pageAndSort(dataTable, { pageIndex, pageSize, sortField, sortDirection });

  const getRowProps = (record: TableData) => {
    const { recordID } = record.accessLog;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      // onClick: () => {
      //   console.log("clicked on this: ", invoice);
      // },
    };
  };

  const getCellProps = (record: TableData, column: any) => {
    const { recordID } = record.accessLog;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };
  const sorting: EuiTableSortingType<TableData> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [15, 10, 5],
    hidePerPageOptions: !showPerPageOptions,
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<TableData>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPanel hasBorder>
      <EuiTitle>
        <h3>Recently Viewed</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiBasicTable
        items={pageOfItems}
        itemId="ID"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
        pagination={pagination}
        onChange={onTableChange}
        tableLayout="auto"
        sorting={sorting}
        loading={isLoading}
      />
    </EuiPanel>
  );
};

export default AccessLogs;
