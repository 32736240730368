import { EuiSelect, EuiSelectProps } from "@elastic/eui";
import { Controller, RegisterOptions } from "react-hook-form";

// import { FormInputProps } from "./FormInputProps";

export interface FormInputProps extends EuiSelectProps {
  name: string;
  label: string;
  rules: Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  disabled?: boolean;
}

export const FormSelect = ({ name, label, rules, disabled, ...rest }: FormInputProps) => {
  return (
    <Controller
      name={`${name}` as const}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <EuiSelect
          {...rest}
          value={value}
          disabled={disabled}
          onBlur={(event) => {
            onBlur();
            rest.onBlur?.(event);
          }}
          onChange={(e) => onChange(e)}
          inputRef={ref}
        />
      )}
    />
  );
};
