import {
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingLogo,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import ErrorPage from "components/ErrorPage";
import TransactionsTable from "components/claim/TransactionsTable";
import { useParams } from "react-router-dom";
import { ClaimActivity, ClaimTransaction } from "types/apiTypes";

import WCICLoadingData from "../../components/WCICLoadingData";
import ClaimInfo from "../../components/claim/ClaimInfo";
import ClaimsActivity from "../../components/claim/ClaimsActivity";
import PolicyInfo from "../../components/policy/PolicyInfo";
import useClaim from "../../hooks/queries/useClaim";

type PageParams = {
  claimID: string;
};

const Claim: React.FC = () => {
  const { claimID } = useParams<PageParams>();
  const { data, isLoading, isError } = useClaim(claimID);
  // console.log("claim: ", data);

  let claimsActivityAndTransactions: (ClaimTransaction | ClaimActivity)[] = [];
  if (data?.activity) {
    claimsActivityAndTransactions = [...data?.activity];
  }
  if (data?.transactions) {
    claimsActivityAndTransactions = [...claimsActivityAndTransactions, ...data?.transactions];
  }
  if (isError) {
    return <ErrorPage title="Claim" />;
  }

  if (isLoading) {
    return (
      <EuiPage>
        <EuiPageBody restrictWidth="1300px">
          <EuiPanel hasBorder>
            <EuiEmptyPrompt icon={<EuiLoadingLogo logo="filebeatApp" size="xl" />} title={<h2>Loading</h2>} />
          </EuiPanel>
        </EuiPageBody>
      </EuiPage>
    );
  }

  return (
    <EuiPage>
      <EuiPageBody>
        <WCICLoadingData isLoading={isLoading}>
          {data?.claimID ? (
            <EuiPageHeader pageTitle={"Claim: " + (data?.claimID ? data.claimID : "Error")} restrictWidth="1200px" />
          ) : null}
        </WCICLoadingData>
        <EuiPageContent hasBorder={false} hasShadow={false} paddingSize="none" color="transparent" borderRadius="none">
          <EuiPageContentBody restrictWidth="1200px">
            <EuiFlexGroup gutterSize="l" wrap>
              <WCICLoadingData isLoading={isLoading}>
                {data?.claimID ? <ClaimInfo claim={data} isLoading={isLoading} /> : null}
              </WCICLoadingData>

              <EuiFlexItem>
                {data?.claimID ? (
                  <PolicyInfo policy={data?.policy} insured={data?.insured} isLoading={isLoading} />
                ) : null}
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer />

            {data?.claimID ? <TransactionsTable transactions={data?.transactions} isLoading={isLoading} /> : null}
            {/* {data?.claimID ? <TransactionStats transactions={data?.transactions} isLoading={isLoading} /> : null} */}

            <EuiSpacer />

            {data?.claimID ? <ClaimsActivity comments={claimsActivityAndTransactions} isLoading={isLoading} /> : null}
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
};

export default Claim;
