import {
  CENTER_ALIGNMENT,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiDatePicker,
  EuiDescribedFormGroup,
  EuiEmptyPrompt,
  EuiFieldText,
  EuiFilePicker,
  EuiFormRow,
  EuiIcon,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiProgress,
  EuiSelect,
  EuiSpacer,
  EuiText,
  EuiTextArea,
} from "@elastic/eui";
import { yupResolver } from "@hookform/resolvers/yup";
import usePolicyLocationsQuery from "hooks/queries/usePolicyLocation";
import moment from "moment";
import { ErrorModal } from "pages/underwriting/documents/UploadDocuments";
import { humanFileSize } from "pages/underwriting/documents/UploadedDocument";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { PolicyLocation } from "types/apiTypes";
import * as yup from "yup";

import PolicyAutoCompleteField from "../../components/PolicyAutoCompleteField";
import useClaimsTypeEnum from "../../hooks/queries/enums/claims/useClaimTypesEnum";
import authAxios from "../../services/authAxios";

interface ReportClaimType {
  policy: string;
  locationOptions: string[];
  location: string;
  dateOfLoss: string;
  typeOfLoss: string;
  habitable: string;
  description: string;
  phone: string;
  phoneType: string;
  email: string;
  name: string;
  // file?
}

interface TableData {
  filename: string;
}

const ReportClaimSchema = yup.object({
  policy: yup.string().label("Policy").required(),
  locationOptions: yup.array(),
  location: yup
    .string()
    .label("Location")
    .when("locationOptions", (locationOptions: string[], schema: yup.StringSchema) => {
      locationOptions.push("Other");
      if (locationOptions.length) {
        return schema.oneOf(locationOptions, "Please select a location").required();
      }
      return schema;
    })
    .required(),
  dateOfLoss: yup.string().label("Date Of Loss").required(),
  typeOfLoss: yup
    .string()
    .label("Type Of Loss")
    .oneOf(["2", "3", "4", "5", "6", "7"], "Type of Loss must select from available options")
    .required(),
  habitable: yup.string().label("Habitable").oneOf(["1", "0"], "Habitable must be yes or no").required(),
  description: yup.string().label("Description").required(),
  phone: yup.string().length(10, "Phone number must be 10 digits long").label("Phone").required(),
  phoneType: yup.string().label("phone Type").required(),
  email: yup.string().label("Email Address").required(),
  name: yup.string().label("Name").max(128, "Name cannot be longer that 128 characters").required(), // TODO what requirements
});

const ReportAClaim: React.FC = () => {
  const [errorModal, setErrorModal] = useState(false);
  const openErrorModal = () => setErrorModal(true);
  const closeErrorModal = () => setErrorModal(false);
  const queryClient = useQueryClient();
  const methods = useForm<ReportClaimType>({
    mode: "onTouched",
    resolver: yupResolver(ReportClaimSchema),
    defaultValues: {
      policy: "",
      locationOptions: [],
      location: "",
      dateOfLoss: "",
      typeOfLoss: "1",
      habitable: "1",
      phone: "",
      phoneType: "1",
      email: "",
      name: "",
    },
  });
  const { handleSubmit, trigger, control } = methods;

  const policyID = methods.watch("policy");

  const { data: policyLocationData } = usePolicyLocationsQuery(policyID);
  useEffect(() => {
    const policyLocationOptions = ["--Please Choose--"];
    policyLocationData?.map((location: PolicyLocation, index: number) => {
      const locationValue = location.address1 + " " + location.city + ", " + location.state + " " + location.zip;
      return policyLocationOptions.push(locationValue);
    });
    methods.setValue("locationOptions", policyLocationOptions);
  }, [policyLocationData, methods]);

  // Create the options associated with the selected policy, Other as final option
  const policyLocationOptions = [
    {
      value: "0",
      text: "--Please Choose--",
    },
  ];
  policyLocationData?.map((location: PolicyLocation, index: number) => {
    const locationValue = location.address1 + " " + location.city + ", " + location.state + " " + location.zip;
    return policyLocationOptions.push({
      value: locationValue,
      text: locationValue,
    });
  });
  policyLocationOptions.push({
    value: "Other",
    text: "Other",
  });

  const navigate = useNavigate();

  const [files, setFiles] = useState<FileList | null>(null);
  const [tableFiles, setTableFiles] = useState<Array<TableData>>([]);

  const columns: EuiBasicTableColumn<TableData>[] = [
    {
      field: "filename",
      name: "filename",
    },
    {
      field: "size",
      name: "size",
      render: (size: number) => {
        return humanFileSize(size);
      },
    },
  ];

  const [progress, setProgress] = useState(0);
  const { data: claimTypeEnum } = useClaimsTypeEnum();

  const [uploadComplete, setUploadComplete] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isProgressModalVisible, setIsProgressModalVisible] = useState<boolean>(false);
  const closeModal = () => setIsModalVisible(false);
  const closeProgressModal = () => setIsProgressModalVisible(false);

  let modal;
  if (isProgressModalVisible) {
    if (progress < 100) {
      modal = (
        <EuiModal onClose={closeProgressModal}>
          <EuiModalBody css={{ textAlign: CENTER_ALIGNMENT }}>
            <EuiEmptyPrompt
              icon={<EuiIcon type={"filebeatApp"} size="xl" />}
              title={
                <div>
                  <h2>Uploading Files</h2>
                  <br></br>
                  <EuiProgress
                    // label="Upload Progress"
                    valueText={true}
                    value={progress}
                    max={100}
                    color="primary"
                    size="l"
                  />
                </div>
              }
            />
          </EuiModalBody>
        </EuiModal>
      );
    } else {
      if (uploadComplete) {
        modal = (
          <EuiModal onClose={closeProgressModal}>
            <EuiModalBody css={{ textAlign: CENTER_ALIGNMENT }}>
              <EuiEmptyPrompt
                icon={<EuiIcon type={"filebeatApp"} size="xl" />}
                title={
                  <div>
                    <h2>Completed</h2>
                    <EuiSpacer size="s" />
                    <EuiIcon type="check" color="green" size="xxl" />
                  </div>
                }
              />
              <EuiButton
                onClick={() => {
                  navigate("/reported_claims");
                }}
                fill
              >
                Okay
              </EuiButton>
            </EuiModalBody>
          </EuiModal>
        );
      } else {
        modal = (
          <EuiModal onClose={closeProgressModal}>
            <EuiModalBody css={{ textAlign: CENTER_ALIGNMENT }}>
              <EuiEmptyPrompt
                icon={<EuiIcon type={"filebeatApp"} size="xl" />}
                title={
                  <div>
                    <h2>Processing</h2>
                    <EuiSpacer size="s" />
                    <EuiLoadingSpinner size="xxl" />
                  </div>
                }
              />
            </EuiModalBody>
          </EuiModal>
        );
      }
    }
  }

  const onChange = (newFiles: FileList | null) => {
    if (newFiles !== null) {
      var temp: any = [];
      Array.from(newFiles).forEach((file: any) => {
        console.log("name: ", file.name);
        temp.push({ filename: file.name, size: file.size });
      });
      setTableFiles(temp);
    }
    setFiles(newFiles);
  };

  const onSubmit: SubmitHandler<ReportClaimType> = (data) => {
    if (
      policyLocationData?.find((location) => {
        const locationValue = location.address1 + " " + location.city + ", " + location.state + " " + location.zip;
        return locationValue === data.location;
      })
    ) {
      methods.setError("location", {
        type: "manual",
        message: "Please select a location",
      });
    }

    const jsonDocument = {
      policyID: data.policy,
      dateOfLoss: data.dateOfLoss,
      typeOfLoss: data.typeOfLoss,
      lossDescription: data.description,
      phoneNumber: data.phone,
      phoneNumberType: data.phoneType,
      email: data.email,
      habitable: data.habitable,
      name: data.name,
      location: data.location,
    };

    let submitData = new FormData();
    let fileSize = 0;
    if (files !== null) {
      var temp: any = [];
      Array.from(files).forEach((file: any) => {
        submitData.append("files[]", file);
        fileSize += file.size;
        temp.push({ filename: file.name, size: file.size });
      });
      setTableFiles(temp);
    }
    submitData.append("document", JSON.stringify(jsonDocument));

    authAxios
      .post("/submit_claim", submitData, {
        onUploadProgress: (progressEvent) => setProgress(Math.round((progressEvent.loaded / fileSize) * 100)),
      })
      .then((res) => {
        // console.log("result: ", res);
        queryClient.invalidateQueries();
        setUploadComplete(true);
      })
      .catch((err) => {
        closeModal();
        closeProgressModal();
        openErrorModal();
        console.log(err);
      });
  };
  const onError = (errors: any, e: any) => console.log(errors, e);
  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageHeader pageTitle="Report a Claim" restrictWidth="1200px" />
        <EuiPageContent hasBorder={false} hasShadow={false} paddingSize="none" color="transparent" borderRadius="none">
          <EuiPageContentBody restrictWidth="1200px">
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <EuiPanel hasBorder>
                <EuiDescribedFormGroup
                  title={<h3>Claim Information</h3>}
                  description={"General information for the claim"}
                >
                  <Controller
                    name="policy"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      return (
                        <EuiFormRow fullWidth label="Policy Number" isInvalid={!!error} error={error?.message}>
                          <PolicyAutoCompleteField
                            query={value}
                            onSearchChange={(e) => {
                              onChange(e.target.value);
                            }}
                            onSearchSelected={(e: any) => {
                              onChange(e.label);
                            }}
                          />
                        </EuiFormRow>
                      );
                    }}
                  />
                  <Controller
                    name="location"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <EuiFormRow fullWidth label="Location" isInvalid={!!error} error={error?.message}>
                          <EuiSelect
                            options={policyLocationOptions}
                            value={value}
                            onChange={(event) => {
                              onChange(event.target.value);
                            }}
                            onBlur={onBlur}
                          />
                        </EuiFormRow>
                      );
                    }}
                  />
                  <Controller
                    name="dateOfLoss"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <EuiFormRow fullWidth label="Date of Loss" isInvalid={!!error} error={error?.message}>
                        <EuiDatePicker
                          scrollableMonthYearDropdown
                          selected={value === "" ? undefined : moment(value)} //value = "Wed Jan 04 2023 09:30:00 GMT-0500"
                          onChange={(e) => {
                            e ? onChange(e.toISOString()) : onChange(undefined);
                          }}
                          onBlur={onBlur}
                        />
                      </EuiFormRow>
                    )}
                  />
                  <Controller
                    name="typeOfLoss"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <EuiFormRow fullWidth label="Type of Loss" isInvalid={!!error} error={error?.message}>
                          <EuiSelect
                            options={claimTypeEnum}
                            value={value}
                            onChange={(event) => onChange(event.target.value)}
                            onBlur={onBlur}
                          />
                        </EuiFormRow>
                      );
                    }}
                  />
                  <Controller
                    name="habitable"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <EuiFormRow fullWidth label="Habitable" isInvalid={!!error} error={error?.message}>
                          <EuiSelect
                            options={[
                              {
                                value: "1",
                                text: "Yes",
                              },
                              {
                                value: "0",
                                text: "No",
                              },
                            ]}
                            value={value}
                            onChange={(event) => onChange(event)}
                            onBlur={onBlur}
                          />
                        </EuiFormRow>
                      );
                    }}
                  />
                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <EuiFormRow fullWidth label="Description of Claim" isInvalid={!!error} error={error?.message}>
                          <EuiTextArea
                            placeholder="Description"
                            value={value}
                            onChange={(event) => onChange(event.target.value)}
                            onBlur={onBlur}
                          />
                        </EuiFormRow>
                      );
                    }}
                  />
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <EuiFormRow fullWidth label="Reported By" isInvalid={!!error} error={error?.message}>
                          <EuiFieldText
                            placeholder="Name"
                            fullWidth
                            value={value}
                            onChange={(event) => onChange(event.target.value)}
                            onBlur={onBlur}
                          />
                        </EuiFormRow>
                      );
                    }}
                  />
                  <Controller
                    name="phoneType"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <EuiFormRow fullWidth label="Phone Number Type" isInvalid={!!error} error={error?.message}>
                          <EuiSelect
                            options={[
                              {
                                value: "1",
                                text: "Home",
                              },
                              {
                                value: "2",
                                text: "Work",
                              },
                              {
                                value: "3",
                                text: "Mobile",
                              },
                            ]}
                            value={value}
                            onChange={(event) => onChange(event.target.value)}
                            onBlur={onBlur}
                          />
                        </EuiFormRow>
                      );
                    }}
                  />
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <EuiFormRow fullWidth label="Contact Phone Number" isInvalid={!!error} error={error?.message}>
                          <NumberFormat
                            customInput={EuiFieldText}
                            fullWidth
                            type="tel"
                            format="+1 (###) ###-####"
                            mask="_"
                            allowEmptyFormatting
                            value={value}
                            onValueChange={(values) => {
                              const { value } = values;
                              onChange(value);
                            }}
                            onBlur={onBlur}
                          />
                        </EuiFormRow>
                      );
                    }}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, ref, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <EuiFormRow fullWidth label="Contact Email Address" isInvalid={!!error} error={error?.message}>
                          <EuiFieldText
                            placeholder="example@domain.com"
                            fullWidth
                            value={value}
                            onChange={(event) => onChange(event.target.value)}
                            onBlur={onBlur}
                          />
                        </EuiFormRow>
                      );
                    }}
                  />
                  <EuiFormRow label="Supporting Documents">
                    <EuiFilePicker id="1" multiple initialPromptText="Any Claims Documents" onChange={onChange} />
                  </EuiFormRow>

                  {tableFiles.length > 0 ? (
                    <EuiFormRow fullWidth label="Files Selected">
                      <EuiPanel hasBorder>
                        <EuiBasicTable items={tableFiles} itemId="uploads" columns={columns} tableLayout="auto" />
                      </EuiPanel>
                    </EuiFormRow>
                  ) : null}

                  <EuiButton
                    onClick={async () => {
                      const output = await trigger();
                      if (output === true) {
                        setIsModalVisible(true);
                      }
                    }}
                    fill
                  >
                    Submit New Claim
                  </EuiButton>
                </EuiDescribedFormGroup>
              </EuiPanel>
              <SubmitModal
                show={isModalVisible}
                closeModal={closeModal}
                onClickYes={() => {
                  setIsProgressModalVisible(true);
                  handleSubmit(onSubmit, onError)();
                }}
              />
            </form>
            {errorModal ? <ErrorModal onClose={closeErrorModal} /> : ""}
            {modal}
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
};

export default ReportAClaim;

const SubmitModal: React.FC<{
  show: boolean;
  closeModal: () => void;
  onClickYes: () => void;
}> = ({ show, closeModal, onClickYes }) => {
  if (!show) {
    return null;
  }
  return (
    <EuiModal onClose={closeModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Fraud Disclaimer</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiText>
          Any person who knowingly, and with intent to defraud any insurance company or other person, files an
          application for insurance or statement of claim containing any materially false information, or conceals for
          the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance
          act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and
          the stated value of the claim for each such violation.
        </EuiText>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton
          type="submit"
          onClick={() => {
            onClickYes();
            closeModal();
          }}
          fill
        >
          Yes
        </EuiButton>
        <EuiButton
          onClick={() => {
            closeModal();
          }}
          fill
        >
          No
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
