import { EuiCheckbox, EuiFieldText, EuiSelect, htmlIdGenerator } from "@elastic/eui";
import { FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

type RaterFormInputProps = { name: string; input: any; isInvalid: boolean };

const RaterFormInput: FunctionComponent<RaterFormInputProps> = ({ name, input, isInvalid }) => {
  switch (input.type) {
    case "number":
      // code block
      return (
        <Controller
          name={`${name}` as const}
          // rules={rules}
          render={({ field: { onChange, ref, ...field } }) => (
            <NumberFormat
              customInput={EuiFieldText}
              fullWidth
              isInvalid={isInvalid}
              thousandSeparator={true}
              decimalScale={input.decimalScale ? input.decimalScale : 0}
              onValueChange={(target) => {
                onChange(target.value);
              }}
              inputRef={ref}
              {...field}
            />
          )}
        />
      );
    case "money":
      // code block
      return (
        <Controller
          name={`${name}` as const}
          // rules={rules}
          render={({ field: { onChange, ref, ...field } }) => (
            <NumberFormat
              customInput={EuiFieldText}
              fullWidth
              thousandSeparator={true}
              prefix={"$"}
              isInvalid={isInvalid}
              decimalScale={input.decimalScale ? input.decimalScale : 0}
              onValueChange={(target) => {
                onChange(target.value);
              }}
              inputRef={ref}
              {...field}
            />
          )}
        />
      );

    case "text":
      // code block
      return (
        <Controller
          name={`${name}` as const}
          // rules={rules}
          render={({ field: { ref, ...field } }) => (
            <EuiFieldText fullWidth inputRef={ref} {...field} isInvalid={isInvalid} />
          )}
        />
      );

    case "check":
      // code block
      return (
        <Controller
          name={`${name}` as const}
          // rules={rules}
          render={({ field: { value, onChange, ref } }) => (
            <EuiCheckbox
              id={htmlIdGenerator()()}
              label={input.label}
              checked={value}
              onChange={onChange}
              inputRef={ref}
            />
          )}
        />
      );
    case "select":
      // code block
      return (
        <Controller
          name={`${name}` as const}
          // rules={rules}
          render={({ field: { ref, ...rest } }) => (
            <EuiSelect fullWidth options={input.options} inputRef={ref} {...rest} isInvalid={isInvalid} />
          )}
        />
      );
    default:
      return (
        <Controller
          name={`${name}` as const}
          // rules={rules}
          render={({ field: { ref, ...field } }) => (
            <NumberFormat
              customInput={EuiFieldText}
              fullWidth
              prefix={"$"}
              isInvalid={isInvalid}
              thousandSeparator={true}
              decimalScale={0}
              inputRef={ref}
              {...field}
            />
          )}
        />
      );
  }
};
export default RaterFormInput;
