import { useAppSelector } from "app/hooks";
import { AxiosError } from "axios";
import { selectRatingSlice } from "features/raterSlice";
import { useQuery } from "react-query";
import { HORaterReturn } from "types/apiTypes";

import authAxios from "../../services/authAxios";

// import { HomeownersPolicyType } from "../../pages/homeowners_rater";

export const getRate = async (policyValues: any, type: any) => {
  const { data } = await authAxios.post("/rater", { ...policyValues, rateType: type });
  return data;
};

function useRater(select?: (data: HORaterReturn) => any) {
  const { currentFormValues, stale, valid, type } = useAppSelector(selectRatingSlice);
  const queryReturn = useQuery<HORaterReturn, AxiosError>(
    [`${type}Rater`, currentFormValues],
    () => getRate(currentFormValues, type),
    {
      enabled: !!currentFormValues,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      select,
    }
  );
  return { ...queryReturn, stale, valid };
}

export default useRater;
