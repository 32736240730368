import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import { useAppSelector } from "app/hooks";
import { selectAdministrator, selectWCICEmployee } from "features/authorization/authSlice";
import { useAPIURL } from "hooks/useAPIURL";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import authAxios from "services/authAxios";
import { Memo } from "types/apiTypes";

interface DataObject {
  memos: Memo[];
  total: number;
}
const getAgentMemos = async () => {
  const { data } = await authAxios.get(`/agent_memos`);
  return data;
};

function useAgentMemos() {
  const useQueryReturn = useQuery<DataObject>(["agent_memos"], () => getAgentMemos(), {
    placeholderData: { memos: [], total: 0 },
    initialData: { memos: [], total: 0 },
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
  });
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

const Memos: React.FC = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useAgentMemos();
  const admin = useAppSelector(selectAdministrator);
  const employee = useAppSelector(selectWCICEmployee);
  // console.log("admin, employee: ", admin, employee);
  console.log("data: ", data);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [, setSortField] = useState<keyof Memo>("createdAt");
  const [, setSortDirection] = useState<"asc" | "desc">("desc");

  const uploadButton =
    admin && employee
      ? [
          <EuiButton
            onClick={() => {
              navigate("/library/upload_memos");
            }}
          >
            Upload Memo
          </EuiButton>,
        ]
      : [];

  const defaultURL = useAPIURL();

  const columns: EuiBasicTableColumn<Memo>[] = [
    {
      field: "createdAt",
      name: "Date",
      width: "200px",
      render: (date: any) => {
        return moment(date).format("l");
      },
      // width: '250px'
    },
    {
      field: "name",
      name: "Name",
    },
    {
      name: "Download",
      width: "80px",
      actions: [
        {
          name: "Download",
          description: "Download File",
          type: "icon",
          icon: "download",
          onClick: (memo: Memo) => {
            window.open(`${defaultURL}/agent_memos/file/download/${memo.recordID}`, "_blank");
          },
        },
      ],
    },
  ];

  const getRowProps = (memo: Memo) => {
    return {
      onClick: () => {
        window.open(`${defaultURL}/agent_memos/file/view/${memo.recordID}`, "_blank");
      },
    };
  };

  const totalItemCount = data.total;
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [10, 20, 50, 100],
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<Memo>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPage>
      <EuiPageBody restrictWidth="1300px">
        <EuiPageHeader pageTitle="Agent Memos" restrictWidth="1200px" rightSideItems={uploadButton} />
        <EuiSpacer />

        <EuiPanel>
          <EuiBasicTable
            items={data.memos}
            loading={isLoading}
            itemId="recordID"
            columns={columns}
            rowProps={getRowProps}
            pagination={pagination}
            onChange={onTableChange}
          />
        </EuiPanel>
      </EuiPageBody>
    </EuiPage>
  );
};

export default Memos;
