import { useQuery } from "react-query";
import { Cancellation, Policy } from "types/apiTypes";

import authAxios from "../../services/authAxios";

export const getPoliciesReinstated = async () => {
  const { data } = await authAxios.get(`/reinstated-policies`);
  return data;
};

type Optional<Type, Key extends keyof Type> = Partial<Pick<Type, Key>> & Omit<Type, Key>;

export type OptionalPolicy = Optional<Policy, "agent" | "insured" | "invoices" | "claims" | "documents" | "locations">;
export type OptionalReinstated = Optional<Cancellation, "insured" | "agent">;

function usePoliciesReinstated() {
  const useQueryReturn = useQuery<OptionalReinstated[]>(["policiesReinstated"], () => getPoliciesReinstated(), {
    placeholderData: [],
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
  });
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

export default usePoliciesReinstated;
