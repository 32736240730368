/** @jsxImportSource @emotion/react */
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiTextAlign,
  EuiTextColor,
  EuiTitle,
  EuiToken,
} from "@elastic/eui";
import { Fragment, FunctionComponent, ReactNode } from "react";
import NumberFormat from "react-number-format";
import { Invoice } from "types/apiTypes";

interface PaymentOptionsGroupProps {
  installmentPlanPayoff: number;
  paymentPlanOptions: Invoice["planOptions"];
  onChange: (id: string) => void;
  selectedPlan: string;
  waivedLateFee: boolean;
}

const PaymentOptionsGroup: FunctionComponent<PaymentOptionsGroupProps> = ({
  installmentPlanPayoff,
  paymentPlanOptions,
  onChange,
  selectedPlan,
  waivedLateFee,
}) => {
  // const numberOfOptions = paymentPlanOptions.length;
  // const payoffIndex = 2;
  return (
    <>
      {paymentPlanOptions.map((plan, index) => {
        const negativePayment = plan.some((value) => value < 0);
        const isSelected = selectedPlan === index.toString();
        const paymentTotal = waivedLateFee ? plan[0] - 20 : plan[0];
        if (negativePayment) {
          return (
            <PaymentPlanOption
              key={index}
              index={index.toString()}
              isSelected={isSelected}
              onChange={onChange}
              title={plan.length === 1 ? "Full Pay" : plan.length.toString() + " Pay Plan"}
              description={"Contact accounting for additional plan options"}
              amount={0}
            />
          );
        }
        if (plan.length > 1) {
          return (
            <PaymentPlanOption
              key={index}
              index={index.toString()}
              isSelected={isSelected}
              onChange={onChange}
              title={plan.length === 1 ? "Full Pay" : plan.length.toString() + " Pay Plan"}
              description={
                <>
                  <NumberFormat
                    displayType={"text"}
                    value={paymentTotal}
                    thousandSeparator={true}
                    prefix={"$"}
                    fixedDecimalScale
                    decimalScale={2}
                    renderText={(num) => {
                      return num + " today";
                    }}
                  />
                  <EuiSpacer size="xs" />
                  <NumberFormat
                    displayType={"text"}
                    value={plan[1]}
                    thousandSeparator={true}
                    prefix={"$"}
                    fixedDecimalScale
                    decimalScale={2}
                    renderText={(num) => {
                      return index + " additional payment of " + num;
                    }}
                  />
                </>
              }
              amount={paymentTotal}
            />
          );
        }
        return (
          <PaymentPlanOption
            key={index}
            index={index.toString()}
            isSelected={isSelected}
            onChange={onChange}
            title={plan.length === 1 ? "Full Pay" : plan.length.toString() + " Pay Plan"}
            description={
              <NumberFormat
                displayType={"text"}
                value={paymentTotal}
                thousandSeparator={true}
                prefix={"$"}
                fixedDecimalScale
                decimalScale={2}
                renderText={(num) => {
                  return "One payment of " + num;
                }}
              />
            }
            amount={paymentTotal}
          />
        );
      })}
      {/*{installmentPlanPayoff > 0 ? (*/}
      {/*  <PaymentPlanOption*/}
      {/*  key={"payoff"}*/}
      {/*  index={"-1"}*/}
      {/*  isSelected={selectedPlan === "-1"}*/}
      {/*  onChange={onChange}*/}
      {/*  title={"Plan Payoff"}*/}
      {/*  description={"Pay off remaining installment invoices"}*/}
      {/*  amount={waivedLateFee ? installmentPlanPayoff - 20 : installmentPlanPayoff}*/}
      {/*/>*/}
      {/*) : null}*/}
    </>
  );
};

export default PaymentOptionsGroup;

interface PaymentPlanOptionProps {
  index: string;
  isSelected: boolean;
  onChange: (id: string) => void;
  // plan: number[];
  // waivedLateFee: boolean;
  title: string;
  description: ReactNode;
  amount: number;
}

const PaymentPlanOption = ({ index, isSelected, onChange, title, description, amount }: PaymentPlanOptionProps) => {
  // const negativePayment = plan.some((value) => value < 0);
  return (
    <Fragment key={index}>
      <input
        type="radio"
        name="planOption"
        id={index.toString()}
        checked={isSelected}
        onChange={(event) => {
          onChange(event.target.id);
        }}
        css={{
          display: "none",
          "&:not(:disabled) ~ label": {
            cursor: "pointer",
          },
        }}
      />
      <label htmlFor={index.toString()}>
        <EuiPanel hasBorder={true} color={isSelected ? "success" : "plain"}>
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem css={{ whiteSpace: "nowrap" }} grow={7}>
              <EuiFlexGroup>
                <EuiFlexItem grow={1}>
                  <EuiToken iconType={isSelected ? "check" : "empty"} size="m" shape="circle" />
                </EuiFlexItem>
                <EuiFlexItem grow={9}>
                  <EuiTitle size="s">
                    <h3>{title}</h3>
                  </EuiTitle>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiSpacer size="s" />
              {description}
            </EuiFlexItem>
            {amount < 0 ? null : (
              <EuiFlexItem style={{ minWidth: "100px", marginRight: "25px" }} grow={4}>
                <EuiTitle>
                  <EuiTextAlign textAlign="right">
                    <EuiTextColor css={{ whiteSpace: "nowrap" }} color={isSelected ? "success" : "default"}>
                      {
                        <NumberFormat
                          displayType={"text"}
                          value={amount}
                          thousandSeparator={true}
                          prefix={"$"}
                          fixedDecimalScale
                          decimalScale={2}
                          renderText={(num) => {
                            return num;
                          }}
                        />
                      }
                    </EuiTextColor>
                  </EuiTextAlign>
                </EuiTitle>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiPanel>
      </label>
      <EuiSpacer size="m" />
    </Fragment>
  );
};

// {negativePayment ? (
//   "Contact accounting for additional plan options"
// ) : plan.length > 1 ? (
// <>
//   <NumberFormat
//     displayType={"text"}
//     value={waivedLateFee ? plan[0] - 20 : plan[0]}
//     thousandSeparator={true}
//     prefix={"$"}
//     fixedDecimalScale
//     decimalScale={2}
//     renderText={(num) => {
//       return num + " today";
//     }}
//   />
//   <EuiSpacer size="xs" />
//   <NumberFormat
//     displayType={"text"}
//     value={plan[1]}
//     thousandSeparator={true}
//     prefix={"$"}
//     fixedDecimalScale
//     decimalScale={2}
//     renderText={(num) => {
//       return index + " additional payment of " + num;
//     }}
//   />
// </>
// ) : (
//   // "$" + plan[0] + " today and " + index + " payments of $" + plan[1]
// <NumberFormat
//   displayType={"text"}
//   value={waivedLateFee ? plan[0] - 20 : plan[0]}
//   thousandSeparator={true}
//   prefix={"$"}
//   fixedDecimalScale
//   decimalScale={2}
//   renderText={(num) => {
//     return "One payment of " + num;
//   }}
// />
// )}
