import { EuiLoadingContent } from "@elastic/eui";
import { LineRange } from "@elastic/eui/src/components/loading/loading_content";
import React, { Fragment, ReactNode } from "react";

const WCICLoadingData: React.FC<{ isLoading: boolean; lines?: LineRange; placeholder?: ReactNode }> = ({ children, isLoading, lines = 1, placeholder }) => {
  if (isLoading) {
    if (placeholder) {
      return <Fragment>{placeholder}</Fragment>
    } else {
      return <EuiLoadingContent lines={lines} />;
    }
  } else {
    return <Fragment>{children}</Fragment>;
  }
};

export default WCICLoadingData;