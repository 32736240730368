import { EuiProvider } from "@elastic/eui";
import { selectTheme } from "features/themeSlice";
import ToastProvider from "hooks/useToasts";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "./app/hooks";
import { asciiArt } from "./asciiArt";
import { initState, refresh } from "./features/authorization/authSlice";
import AppRoutes from "./routes/AppRoutes";

// import './App.css';

function App() {
  const init = useAppSelector(initState);
  const dispatch = useAppDispatch();
  useEffect(() => {
    asciiArt();
    dispatch(refresh());
  }, [dispatch]);
  const currentTheme = useAppSelector(selectTheme);
  // const currentTheme = "dark";
  useEffect(() => {
    setTimeout(() => {
      const theme = document.querySelector<HTMLAnchorElement>("#theme-link");
      if (theme) {
        theme.href = `/css/theme_${currentTheme}.css`;
      }
    }, 1);
  }, [currentTheme]);
  return (
    <>
      {init === false ? (
        <div>loading</div>
      ) : (
        <EuiProvider colorMode={currentTheme}>
          <ToastProvider>
            <AppRoutes />
          </ToastProvider>
        </EuiProvider>
      )}
    </>
  );
}

export default App;
