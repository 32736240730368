import ProductionReports from "pages/administration/ProductionReports";
import ProductionReportsAdmin from "pages/administration/ProductionReportsAdmin";
// import ProductionReportsAdmin from "pages/administration/ProductionReportsAdmin";
// import Claims from "pages/claims/Claims";
import ReportedClaims from "pages/claims/ReportedClaims";
import Memos from "pages/library/Memos";
import UploadMemos from "pages/library/UploadMemos";
import Profile from "pages/profile";
import PayInvoice from "pages/underwriting/PayInvoicePage/PayInvoicePage";
import TransactionPage from "pages/underwriting/PayInvoicePage/Transaction";
import Policies from "pages/underwriting/Policy/Policies";
import PolicyDocuments from "pages/underwriting/Policy/PolicyDocuments";
import UploadDocuments from "pages/underwriting/documents/UploadDocuments";
import UploadedDocument from "pages/underwriting/documents/UploadedDocument";
import UploadedDocuments from "pages/underwriting/documents/UploadedDocuments";
import RecentQuotes from "pages/underwriting/rating/RecentQuotes";
import ViewQuote from "pages/underwriting/rating/ViewQuote";
import { ReactNode } from "react";

import Contact from "../pages/Contact";
import Dashboard from "../pages/Dashboard";
import Administration from "../pages/administration/Administration";
import Claim from "../pages/claims/Claim";
import ReportAClaim from "../pages/claims/ReportAClaim";
import ReportedClaim from "../pages/claims/ReportedClaim";
import Forms from "../pages/library/Forms";
import ManualsAndGuides from "../pages/library/ManualsAndGuides";
import Marketing from "../pages/library/Marketing";
// import Memos from "../pages/library/Memos";
// import Resources from "../pages/library/Resources";
import Commissions from "../pages/reporting/Commissions";
import Insured from "../pages/underwriting/Insured";
import Policy from "../pages/underwriting/Policy";
import { HomeownersRater, LandlordsRater, RaterController } from "../pages/underwriting/rating";
import Agents from "pages/employee/Agents";
import Clusters from "pages/employee/Clusters";

type RouteConfigType = {
  path: string;
  name: string;
  component: ReactNode;
  sidebar?: boolean;
  icon?: string;
  requireAdmin?: boolean;
  requireEmployee?: boolean;
  external?: boolean;
};

type SidebarGroupType = {
  name: string;
  icon: string;
  children: RouteConfigType[];
};

export const dashboardRoutes: RouteConfigType[] = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
];

export const underwritingRoutes: RouteConfigType[] = [
  {
    path: "/policies",
    name: "Policies",
    component: Policies,
    sidebar: true,
    icon: "list",
  },
  {
    path: "/policy_documents",
    name: "Policy Documents",
    component: PolicyDocuments,
    sidebar: true,
    icon: "documents",
  },
  {
    path: "/recent_quotes",
    name: "Recent Quotes",
    component: RecentQuotes,
    sidebar: true,
    icon: "number",
  },
  {
    path: "/rating/homeowners",
    name: "Quote New Homeowners Policy",
    component: HomeownersRater,
    sidebar: true,
    icon: "home",
  },
  {
    path: "/rating/:quoteID",
    name: "Update Rate",
    component: RaterController,
    sidebar: false,
    icon: "home",
  },
  {
    path: "/quote/:quoteID",
    name: "Quote",
    component: ViewQuote,
    sidebar: false,
    icon: "home",
  },
  {
    path: "/rating/landlords",
    name: "Quote New Landlords Policy",
    component: LandlordsRater,
    sidebar: true,
    icon: "package",
  },
  {
    path: "/uploaded_documents",
    name: "Upload Documents",
    component: UploadedDocuments,
    sidebar: true,
    icon: "share",
  },
  {
    path: "/upload_documents",
    name: "New Upload",
    component: UploadDocuments,
    sidebar: false,
    icon: "share",
  },
  // KEEP
  // {
  //   path: "https://www.google.com",
  //   name: "Sharefile",
  //   component: LandlordsRater,
  //   sidebar: true,
  //   icon: "share",
  //   external: true,
  // },

  // TODO Finish later, not V1
  // {
  //   path: "/rcestimator",
  //   name: "New RC Estimator",
  //   component: RCEstimatorPage,
  //   sidebar: true,
  //   icon: "home",
  // },
  {
    path: "/policy/:policyID",
    name: "Policy",
    component: Policy,
    sidebar: false,
  },
  {
    path: "/invoice/:invoiceID",
    name: "Invoice",
    component: PayInvoice,
    sidebar: false,
  },
  {
    path: "/insured/:insuredID",
    name: "Policy",
    component: Insured,
    sidebar: false,
  },
  {
    path: "/transaction/:recordID",
    name: "Transaction",
    component: TransactionPage,
    sidebar: false,
  },
  {
    path: "/uploaded_document/:documentID",
    name: "Uploaded Document",
    component: UploadedDocument,
    sidebar: false,
  },
];

export const claimsRoutes: RouteConfigType[] = [
  {
    path: "/report_claim",
    name: "Report A Claim",
    component: ReportAClaim,
    sidebar: false,
    icon: "cloudDrizzle",
  },
  // {
  //   path: "/claims",
  //   name: "Claims",
  //   component: Claims,
  //   sidebar: false,
  //   icon: "cloudStormy",
  // },
  {
    path: "/reported_claims",
    name: "Claims Reporting",
    component: ReportedClaims,
    sidebar: true,
    icon: "cloudStormy",
  },
  {
    path: "/claim/:claimID",
    name: "Claim",
    component: Claim,
    sidebar: false,
  },
  {
    path: "/reported_claim/:reportID",
    name: "Reported Claim",
    component: ReportedClaim,
    sidebar: false,
  },
];

export const administrationRoutes: RouteConfigType[] = [
  {
    path: "/admin",
    name: "Administration",
    component: Administration,
    sidebar: true,
    icon: "controlsVertical",
    requireAdmin: true,
  },
  {
    path: "/commissions",
    name: "Commissions",
    component: Commissions,
    sidebar: true,
    icon: "visTable",
    requireAdmin: true,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    sidebar: false,
  },
  {
    path: "/production_reports",
    name: "Production Reports",
    component: ProductionReports,
    sidebar: true,
    requireAdmin: true,
    // TODO change to more appropriate icon, copied from /admin
    icon: "controlsVertical",
  },
  {
    path: "/production_reports_admin",
    name: "Production Reports Admin",
    component: ProductionReportsAdmin,
    sidebar: false,
    requireAdmin: true,
    // TODO change to more appropriate icon, copied from /admin
    icon: "controlsVertical",
  },
  {
    path: "/agents",
    name: "Agents",
    component: Agents,
    sidebar: true,
    requireAdmin: true,
    requireEmployee: true,
    // TODO change to more appropriate icon, copied from /admin
    icon: "controlsVertical",
  },
  {
    path: "/clusters",
    name: "Clusters",
    component: Clusters,
    sidebar: true,
    requireAdmin: true,
    requireEmployee: true,
    // TODO change to more appropriate icon, copied from /admin
    icon: "controlsVertical",
  },
];

export const libraryRoutes: RouteConfigType[] = [
  {
    path: "/library/forms",
    name: "Forms",
    component: Forms,
    sidebar: true,
    icon: "indexEdit",
  },
  {
    path: "/library/guides",
    name: "Manuals & Guides",
    component: ManualsAndGuides,
    sidebar: true,
    icon: "documentation",
  },
  // {
  //   path: "/library/resources",
  //   name: "Resources",
  //   component: Resources,
  //   sidebar: true,
  //   icon: "help",
  // },
  {
    path: "/library/marketing",
    name: "Marketing Flyers",
    component: Marketing,
    sidebar: true,
    icon: "training",
  },
  {
    path: "/library/memos",
    name: "Memos",
    component: Memos,
    sidebar: true,
    icon: "document",
  },
  {
    path: "/library/upload_memos",
    name: "Agency Memos Upload",
    component: UploadMemos,
    sidebar: false,
    icon: "document",
  },
];

export const contactRoutes: RouteConfigType[] = [
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    sidebar: true,
    icon: "email",
  },
];

export const sidebarRoutes: SidebarGroupType[] = [
  {
    name: "Underwriting",
    icon: "searchProfilerApp",
    children: underwritingRoutes,
  },
  {
    name: "Claims",
    icon: "pipelineApp",
    children: claimsRoutes,
  },
  {
    name: "Administration",
    icon: "managementApp",
    children: administrationRoutes,
  },
  {
    name: "Library",
    icon: "filebeatApp",
    children: libraryRoutes,
  },
  {
    name: "Contact Us",
    icon: "email",
    children: contactRoutes,
  },
];

export default dashboardRoutes;
