import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiPanel,
  EuiTableSortingType,
} from "@elastic/eui";
import { useAppSelector } from "app/hooks";
import EuiCustomLink from "components/EuiCustomLink";
import { selectClusterAgencies } from "features/authorization/authSlice";
import usePendingClaims from "hooks/queries/usePendingClaims";
import moment from "moment";
import { useState } from "react";
import pageAndSort from "services/pageAndSort";
import { Claim } from "types/apiTypes";

interface TableData {
  policyID: string;
  agentID: string;
  insuredDisplayName: string;
  claimID: string;
  dateOfLoss: string;
  reported: string;
  causeOfLoss: string;
  claim: Claim;
}

// const PendingClaims = () => {
function PendingClaims({
  pageSize,
  setPageSize,
  pageOptions,
}: {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageOptions: Array<number>;
}) {
  const { data, isLoading, isFetching, isPlaceholderData } = usePendingClaims();
  // console.log("pending claims: ", data);

  const clusterAgents = useAppSelector(selectClusterAgencies);
  const [pageIndex, setPageIndex] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof TableData>("policyID");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const dataTable: TableData[] = data.map((record) => {
    return {
      policyID: record.policyID,
      agentID: record.agentID,
      insuredDisplayName: record.insured.displayName,
      claimID: record.claimID,
      dateOfLoss: record.dateOfLoss,
      reported: record.reported,
      causeOfLoss: record.causeOfLoss.description,
      claim: record,
    };
  });
  const columns: EuiBasicTableColumn<TableData>[] = [];
  if (clusterAgents.length > 1) {
    columns.push({
      field: "agentID",
      name: "Agent ID",
    });
  }
  columns.push(
    {
      field: "policyID",
      name: "Policy Number",
      render: (policyID: string, rowData: TableData) => {
        return (
          <EuiCustomLink to={`/policy/${rowData.claim.policy.recordID}`} external={true}>
            {policyID}
          </EuiCustomLink>
        );
      },
    },
    {
      field: "insuredDisplayName",
      name: "Insured Name",
      render: (insuredDisplayName: string, rowData: TableData) => {
        return (
          <EuiCustomLink to={`/insured/${rowData.claim.insured.recordID}`} external={true}>
            {insuredDisplayName}
          </EuiCustomLink>
        );
      },
    },
    {
      field: "claimID",
      name: "Claim Number",
      render: (claimID: string, rowData: TableData) => {
        return (
          <EuiCustomLink to={`/claim/${rowData.claim.recordID}`} external={true}>
            {claimID}
          </EuiCustomLink>
        );
      },
    },
    {
      field: "dateOfLoss",
      name: "Date of Loss",
      render: (date: string) => {
        return <>{moment(date).format("l")}</>;
      },
    },
    {
      field: "reported",
      name: "Reported",
      render: (date: string) => {
        return <>{moment(date).format("l")}</>;
      },
    },
    {
      field: "causeOfLoss",
      name: "Cause of Loss",
      render: (col: string) => {
        return <>{col}</>;
      },
    }
  );

  const { pageOfItems, totalItemCount } = pageAndSort(dataTable, { pageIndex, pageSize, sortField, sortDirection });

  const getRowProps = (record: TableData) => {
    const { recordID } = record.claim;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      // onClick: () => {
      //   console.log("clicked on this: ", claim);
      // },
    };
  };

  const getCellProps = (record: TableData, column: any) => {
    const { recordID } = record.claim;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };
  const sorting: EuiTableSortingType<TableData> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: pageOptions,
    hidePerPageOptions: !showPerPageOptions,
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<TableData>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPanel hasBorder>
      {/* <EuiTitle>
            <h3>New Policies</h3>
          </EuiTitle> */}
      {/* <EuiSpacer size="m" /> */}
      <EuiBasicTable
        items={pageOfItems}
        itemId="ID"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
        pagination={pagination}
        onChange={onTableChange}
        tableLayout="auto"
        sorting={sorting}
        loading={isLoading || (isFetching && isPlaceholderData)}
      />
    </EuiPanel>
  );
}

export default PendingClaims;
