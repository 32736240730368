import { EuiHorizontalRule } from "@elastic/eui";
import useRater from "hooks/queries/useRater";

import ShowRateNumberStat from "./ShowRateNumberStat";

const ShowRate: React.FunctionComponent = () => {
  const { data, isFetching, stale, valid } = useRater();
  const isLoading = stale || !valid || isFetching || !data;

  return (
    <>
      <ShowRateNumberStat
        data={data?.table_premium}
        isLoading={isLoading}
        valid={valid}
        titleSize="s"
        description="Table Premium"
      />

      <ShowRateNumberStat
        data={data?.cov_b_premium}
        isLoading={isLoading}
        valid={valid}
        titleSize="s"
        description="Additional Coverage B"
      />

      <ShowRateNumberStat
        data={data?.cov_c_premium}
        isLoading={isLoading}
        valid={valid}
        titleSize="s"
        description="Additional Coverage C"
      />

      <ShowRateNumberStat
        data={data?.cov_d_premium}
        isLoading={isLoading}
        valid={valid}
        titleSize="s"
        description="Additional Coverage D"
      />

      <ShowRateNumberStat
        data={data?.deductible_premium}
        isLoading={isLoading}
        valid={valid}
        titleSize="s"
        description="Deductible Premium"
      />

      <ShowRateNumberStat
        data={data?.liability_premium}
        isLoading={isLoading}
        valid={valid}
        titleSize="s"
        description="Liability Premium"
      />

      <ShowRateNumberStat
        data={data?.total_form_premium}
        isLoading={isLoading}
        valid={valid}
        titleSize="s"
        description="Form Premium"
      />
      {data?.total_fire_fee ? (
        <ShowRateNumberStat
          data={data?.total_fire_fee}
          isLoading={isLoading}
          valid={valid}
          titleSize="s"
          description="NYS Fire Fee"
        />
      ) : null}

      <EuiHorizontalRule />

      <ShowRateNumberStat data={data?.total_premium} isLoading={isLoading} valid={valid} description="Total Premium" />
    </>
  );
};

export default ShowRate;
