import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiPanel, EuiSpacer, EuiText, EuiTextColor, EuiTitle } from "@elastic/eui";
import EuiCustomLink from "components/EuiCustomLink";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Employee, Insured, Policy } from "types/apiTypes";

// TODO Should this be handled by the backend?
function teamFormat(team: string) {
  if (team === "T-1") {
    return "Team 1";
  }
  if (team === "T-2") {
    return "Team 2";
  }
  if (team === "T-3") {
    return "Team 3";
  }
}

const PolicyInfo: React.FC<{ policy: Policy; insured: Insured; underwriter: Employee }> = ({
  policy,
  insured,
  underwriter,
}) => {
  function statusColor(status: string) {
    if (status === "Active") {
      return "success";
    }
    if (status === "Canceled") {
      return "danger";
    } else {
      return "warning";
    }
  }

  function nameFormat(firstName: string, lastName: string) {
    if (firstName !== null && lastName !== null) {
      return firstName + " " + lastName;
    } else if (firstName !== null && lastName === null) {
      return firstName;
    } else if (firstName === null && lastName !== null) {
      return lastName;
    }
    return "Insured Info";
  }
  return (
    <EuiPanel hasBorder>
      <EuiTitle>
        <h3>Policy Details</h3>
      </EuiTitle>

      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiSpacer size="s" />

          <label className="euiFormLabel euiFormRow__label">
            <EuiTextColor color="subdued">
              <strong>Insured Name</strong>
            </EuiTextColor>
          </label>
          <EuiText style={{ paddingLeft: "10px" }}>
            <EuiCustomLink to={`/insured/${insured.recordID}`} external={true}>
              {nameFormat(insured.firstName, insured.lastName)}
            </EuiCustomLink>
          </EuiText>
          <EuiSpacer size="s" />
          <label className="euiFormLabel euiFormRow__label">
            <EuiTextColor color="subdued">
              <strong>Insured Mailing Address</strong>
            </EuiTextColor>
          </label>
          <EuiText style={{ paddingLeft: "10px" }}>{insured.street1 ? insured.street1 : "Not Provided"}</EuiText>
          {insured.street2 ? <EuiText style={{ paddingLeft: "10px" }}>{insured.street2}</EuiText> : null}
          <EuiText style={{ paddingLeft: "10px" }}>
            {insured.city ? insured.city + ", " : "Not Provided"}
            {insured.state ? insured.state + " " : "Not Provided"}
            {insured.zip ? insured.zip : "Not Provided"}
          </EuiText>
          <EuiSpacer size="s" />
          <label className="euiFormLabel euiFormRow__label">
            <EuiTextColor color="subdued">
              <strong>Inception Date</strong>
            </EuiTextColor>
          </label>
          <EuiText style={{ paddingLeft: "10px" }}>
            {policy.inceptionDate ? moment(policy.inceptionDate).format("l") : "Not Provided"}
          </EuiText>
          <EuiSpacer size="s" />
          <label className="euiFormLabel euiFormRow__label">
            <EuiTextColor color="subdued">
              <strong>Annual Premium</strong>
            </EuiTextColor>
          </label>
          <EuiText style={{ paddingLeft: "10px" }}>
            {policy.annualPremium ? (
              <NumberFormat
                displayType={"text"}
                value={policy.annualPremium / 100}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            ) : (
              "Not Provided"
            )}
          </EuiText>
          <EuiSpacer size="s" />
          {policy.cancelDate ? (
            <>
              <label className="euiFormLabel euiFormRow__label">
                <EuiTextColor color="subdued">
                  <strong>Cancel Date</strong>
                </EuiTextColor>
              </label>
              <EuiText style={{ paddingLeft: "10px" }}>{moment(policy.cancelDate).format("l")}</EuiText>
            </>
          ) : (
            <>
              <label className="euiFormLabel euiFormRow__label">
                <EuiTextColor color="subdued">
                  <strong>Status</strong>
                </EuiTextColor>
              </label>
              <EuiText color={statusColor(policy.status)} style={{ paddingLeft: "10px" }}>
                {policy.status}
              </EuiText>
            </>
          )}
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiSpacer size="s" />

          <label className="euiFormLabel euiFormRow__label">
            <EuiTextColor color="subdued">
              <strong>Underwriter</strong>
            </EuiTextColor>
          </label>
          <EuiText style={{ paddingLeft: "10px" }}>
            {underwriter.firstName} {underwriter.lastName}
          </EuiText>
          <EuiLink style={{ paddingLeft: "10px" }} href={`mailto:` + underwriter.emailAddress}>
            {underwriter.emailAddress}
          </EuiLink>

          <EuiSpacer size="s" />
          <label className="euiFormLabel euiFormRow__label">
            <EuiTextColor color="subdued">
              <strong>Team</strong>
            </EuiTextColor>
          </label>
          <EuiText style={{ paddingLeft: "10px" }}>{teamFormat(policy.uwGroup)}</EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};

export default PolicyInfo;
