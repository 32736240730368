import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiPanel,
  EuiTableSortingType,
} from "@elastic/eui";
import { useAppSelector } from "app/hooks";
import EuiCustomLink from "components/EuiCustomLink";
import { selectClusterAgencies } from "features/authorization/authSlice";
import useReinstatedPolicies, { OptionalReinstated } from "hooks/queries/useReinstatedPolicies";
import moment from "moment";
import { useState } from "react";
import pageAndSort from "services/pageAndSort";

interface TableData {
  policyID: string;
  agentID: string;
  insuredDisplayName: string;
  cancelType: string;
  effectiveDate: string;
  reinstatementDate: string;
  cancel: OptionalReinstated;
}
function ReinstatedPolicies({
  pageSize,
  setPageSize,
  pageOptions,
}: {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageOptions: Array<number>;
}) {
  const { data, isLoading, isFetching, isPlaceholderData } = useReinstatedPolicies();
  // console.log("data: ", data);

  const clusterAgents = useAppSelector(selectClusterAgencies);
  const [pageIndex, setPageIndex] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof TableData>("effectiveDate");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const dataTable: TableData[] = data.map((record) => {
    return {
      policyID: record.policy.policyID,
      agentID: record.policy.agentID,
      insuredDisplayName: record.insured?.displayName || "",
      cancelType: record.cancelType,
      effectiveDate: record.effectiveDate,
      reinstatementDate: record.reinstatementDate,
      cancel: record,
    };
  });

  const columns: EuiBasicTableColumn<TableData>[] = [];
  if (clusterAgents.length > 1) {
    columns.push({
      field: "agentID",
      name: "Agent ID",
    });
  }

  columns.push(
    {
      field: "policyID",
      name: "Policy Number",
      render: (policyID: string, rowData: TableData) => {
        if (rowData.cancel.policy) {
          return (
            <EuiCustomLink to={`/policy/${rowData.cancel.policy.recordID}`} external={true}>
              {policyID}
            </EuiCustomLink>
          );
        }
        return null;
      },
    },
    {
      field: "insuredDisplayName",
      name: "Insured Name",
      render: (insuredDisplayName: string, rowData: TableData) => {
        if (rowData.cancel.insured) {
          return (
            <EuiCustomLink to={`/insured/${rowData.cancel.insured.recordID}`} external={true}>
              {insuredDisplayName}
            </EuiCustomLink>
          );
        }
        return { insuredDisplayName };
      },
    },
    {
      field: "cancelType",
      name: "Cancel Type",
      render: (type: string) => {
        switch (type) {
          case "C":
            return "Cancellation";
          case "C1":
            return "Non-Payment";
          case "C2":
            return "Returned Check";
          case "C3":
            return "Underwriting";
          case "CR":
            return "Conditioned Renewal";
          case "CRP":
            return "Conditioned Renewal";
          case "D":
            return "Disclosure";
          case "NR":
            return "Nonrenewal";

          default:
            return "ERROR";
        }
      },
    },
    {
      field: "effectiveDate",
      name: "Effective Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "reinstatementDate",
      name: "Reinstated Date",
      render: (date: string) => moment(date).format("l"),
    }
  );

  const { pageOfItems, totalItemCount } = pageAndSort(dataTable, { pageIndex, pageSize, sortField, sortDirection });

  const getRowProps = (record: TableData) => {
    const { recordID } = record.cancel;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      // onClick: () => {
      //   console.log("clicked on this: ", cancellation);
      //   // navigate("/policy/" + cancellation.policy.recordID); // FIXME
      // },
    };
  };

  const getCellProps = (record: TableData, column: any) => {
    const { recordID } = record.cancel;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };
  const sorting: EuiTableSortingType<TableData> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: pageOptions,
    hidePerPageOptions: !showPerPageOptions,
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<TableData>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPanel hasBorder>
      <EuiBasicTable
        items={pageOfItems}
        itemId="ID"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
        pagination={pagination}
        onChange={onTableChange}
        tableLayout="auto"
        sorting={sorting}
        loading={isLoading || (isFetching && isPlaceholderData)}
      />
    </EuiPanel>
  );
}

export default ReinstatedPolicies;
