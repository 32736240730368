import { EuiFieldNumber, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow } from "@elastic/eui";
import { FormSelect } from "components/react-hook-form/SelectInput";
import { Fragment, FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

const CreditCardView: FunctionComponent<{ errors: any }> = ({ errors }) => {
  return (
    <Fragment key="card">
      <EuiFormRow label="Cardholder Name" isInvalid={!!errors?.fullName?.message} error={errors?.fullName?.message}>
        <Controller
          name={`fullName` as `${string}`}
          render={({ field: { ref, ...rest } }) => {
            return (
              <EuiFieldText
                isInvalid={!!errors?.fullName?.message}
                placeholder="Name on the Card"
                inputRef={ref}
                {...rest}
              />
            );
          }}
        />
      </EuiFormRow>
      <EuiFormRow
        fullWidth
        label={"Card Number"}
        isInvalid={!!errors?.cardNumber?.message}
        error={errors?.cardNumber?.message}
      >
        <Controller
          name={`cardNumber` as `${string}`}
          render={({ field }) => {
            const { onChange, value, ref, ...rest } = field;
            return (
              <NumberFormat
                isInvalid={!!errors?.cardNumber?.message}
                placeholder="Card Number"
                allowLeadingZeros
                customInput={EuiFieldText}
                decimalScale={0}
                value={value}
                onValueChange={(target) => {
                  onChange(target.value);
                }}
                inputRef={ref}
                {...rest}
              />
            );
          }}
        />
      </EuiFormRow>
      <EuiFormRow>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow
              label="Expiry Month"
              fullWidth
              isInvalid={!!errors?.month?.message}
              error={errors?.month?.message}
            >
              <FormSelect
                name={`month`}
                label={"Payment Source"}
                isInvalid={!!errors?.month?.message}
                rules={{ required: true }}
                options={[
                  { value: "1", text: "1" },
                  { value: "2", text: "2" },
                  { value: "3", text: "3" },
                  { value: "4", text: "4" },
                  { value: "5", text: "5" },
                  { value: "6", text: "6" },
                  { value: "7", text: "7" },
                  { value: "8", text: "8" },
                  { value: "9", text: "9" },
                  { value: "10", text: "10" },
                  { value: "11", text: "11" },
                  { value: "12", text: "12" },
                ]}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="Expiry Year" isInvalid={!!errors?.year?.message} error={errors?.year?.message}>
              <Controller
                name={`year` as `${string}`}
                render={({ field: { ref, ...rest } }) => {
                  return <EuiFieldNumber isInvalid={!!errors?.year?.message} inputRef={ref} {...rest} />;
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow
              fullWidth
              label={"Card Code"}
              isInvalid={!!errors?.cardCode?.message}
              error={errors?.cardCode?.message}
            >
              <Controller
                name={`cardCode` as `${string}`}
                render={({ field }) => {
                  const { onChange, value, ref, ...rest } = field;
                  return (
                    <NumberFormat
                      isInvalid={!!errors?.cardCode?.message}
                      placeholder="Card Number"
                      allowLeadingZeros
                      customInput={EuiFieldText}
                      decimalScale={0}
                      value={value}
                      onValueChange={(target) => {
                        onChange(target.value);
                      }}
                      inputRef={ref}
                      {...rest}
                    />
                  );
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    </Fragment>
  );
};

export default CreditCardView;
