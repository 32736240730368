import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { VersionNote } from "types/apiTypes";

import { RootState } from "../../app/store";

// import authAxios from "../../services/authAxios";

export interface credentialsInt {
  email: string;
  password: string;
}
export interface AuthState {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  status: "idle" | "loading" | "failed";
  init: boolean;
  auth: "none" | "limited" | "full";
  auth_totp: boolean;
  auth_sms: boolean;
  agentID: string;
  agentName: string;
  administrator: boolean;
  clusterAdministrator: boolean;
  clustered: boolean;
  clusterAgencies: string[];
  impersonateID: string;
  teamID: string;
  wcicEmployee: boolean;
  newVersions: VersionNote[];
  claimsActivityLayout: "table" | "timeline";
  roleList: string[];
}

const initialState: AuthState = {
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  status: "idle",
  init: false,
  auth: "none",
  auth_totp: false,
  auth_sms: false,
  agentID: "",
  agentName: "",
  administrator: false,
  clusterAdministrator: false,
  clustered: false,
  clusterAgencies: [],
  impersonateID: "",
  teamID: "",
  wcicEmployee: false,
  newVersions: [],
  claimsActivityLayout: "table",
  roleList: [],
};

const defaultURL = process.env.REACT_APP_API_URL || "/";
const thunkAxios = axios.create({ baseURL: defaultURL });
thunkAxios.interceptors.request.use((request) => {
  request.headers["X-CSRF-TOKEN"] = Cookies.get("csrf_access_token");
  return request;
});

export const login = createAsyncThunk("auth/login", async (credentials: credentialsInt) => {
  const response = await thunkAxios.post("/auth/login", {
    email: credentials.email,
    password: credentials.password,
  });
  // The value we return becomes the `fulfilled` action payload
  // console.log("Response:", response);
  return response.data;
});

export const otp = createAsyncThunk("authunkAxiosth/otp", async (otp: { code: string; trusted: boolean }) => {
  const response = await thunkAxios.post("/otp", {
    otp: otp.code,
    trusted: otp.trusted,
  });
  // The value we return becomes the `fulfilled` action payload
  // console.log("Response:", response);
  return response.data;
});

export const refresh = createAsyncThunk("auth/refresh", async (_, { rejectWithValue }) => {
  try {
    const response = await thunkAxios.get("/auth/refresh");
    // The value we return becomes the `fulfilled` action payload
    // console.log("Response:", response);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.status);
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  const response = await thunkAxios.get("/auth/logout");
  // The value we return becomes the `fulfilled` action payload
  return response.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    localLogout: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.email = "";
      state.firstName = "";
      state.lastName = "";
      state.agentID = "";
      state.agentName = "";
      state.auth = "none";
    },
    newVersionsDismissed: (state) => {
      state.newVersions = [];
    },
    setClaimsActivityLayout: (state, action) => {
      state.claimsActivityLayout = action.payload;
    },
    setTOTPState: (state, action) => {
      state.auth_totp = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "idle";
        state.username = action.payload.user.username;
        state.email = action.payload.user.emailAddress;
        state.firstName = action.payload.user.firstName;
        state.lastName = action.payload.user.lastName;
        state.agentID = action.payload.user.agentID;
        state.agentName = action.payload.user.agentName;
        state.administrator = action.payload.user.administrator;
        state.clusterAdministrator = action.payload.user.clusterAdministrator;
        state.clustered = action.payload.user.clustered;
        state.clusterAgencies = action.payload.user.authorizedAgentIDs;
        state.impersonateID = action.payload.user.impersonateID;
        state.teamID = action.payload.user.teamID;
        state.wcicEmployee = action.payload.user.wcicEmployee;
        state.newVersions = action.payload.newVersions || [];
        state.claimsActivityLayout = action.payload.user.claimsActivityLayout;
        state.auth = action.payload.auth;
        state.auth_totp = action.payload.auth_totp;
        state.auth_sms = action.payload.auth_sms;
        state.roleList = action.payload.user.roleList;
      })
      .addCase(login.rejected, (state) => {
        state.status = "failed";
      });
    builder
      .addCase(refresh.pending, (state) => {
        state.status = "loading";
      })
      .addCase(refresh.fulfilled, (state, action) => {
        state.status = "idle";
        state.init = true;
        state.auth = action.payload.auth;
        state.auth_totp = action.payload.auth_totp;
        state.auth_sms = action.payload.auth_sms;
        state.username = action.payload.user.username;
        state.email = action.payload.user.emailAddress;
        state.firstName = action.payload.user.firstName;
        state.lastName = action.payload.user.lastName;
        state.agentID = action.payload.user.agentID;
        state.agentName = action.payload.user.agentName;
        state.administrator = action.payload.user.administrator;
        state.clusterAdministrator = action.payload.user.clusterAdministrator;
        state.clustered = action.payload.user.clustered;
        state.clusterAgencies = action.payload.user.authorizedAgentIDs;
        state.impersonateID = action.payload.user.impersonateID;
        state.teamID = action.payload.user.teamID;
        state.wcicEmployee = action.payload.user.wcicEmployee;
        state.newVersions = action.payload.newVersions || [];
        state.claimsActivityLayout = action.payload.user.claimsActivityLayout;
        state.roleList = action.payload.user.roleList;
      })
      .addCase(refresh.rejected, (state, action) => {
        // console.log(action);
        state.init = true;
        state.status = "idle";
        state.auth = "none";
      });
    builder
      .addCase(logout.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.status = "idle";
        state.auth = "none";
        state.username = "";
        state.email = "";
        state.firstName = "";
        state.lastName = "";
        state.agentID = "";
        state.agentName = "";
        state.administrator = false;
        state.clusterAdministrator = false;
        state.clustered = false;
        state.clusterAgencies = [];
        state.impersonateID = "";
        state.teamID = "";
        state.wcicEmployee = false;
        state.newVersions = [];
        state.claimsActivityLayout = "table";
        state.roleList = [];
      })
      .addCase(logout.rejected, (state) => {
        state.status = "failed";
      });
    builder
      .addCase(otp.pending, (state) => {
        state.status = "loading";
      })
      .addCase(otp.fulfilled, (state, action) => {
        state.status = "idle";
        state.auth = action.payload.auth;
        state.username = action.payload.user.username;
        state.email = action.payload.user.emailAddress;
        state.firstName = action.payload.user.firstName;
        state.lastName = action.payload.user.lastName;
        state.agentID = action.payload.user.agentID;
        state.agentName = action.payload.user.agentName;
        state.administrator = action.payload.user.administrator;
        state.clusterAdministrator = action.payload.user.clusterAdministrator;
        state.clustered = action.payload.user.clustered;
        state.clusterAgencies = action.payload.user.authorizedAgentIDs;
        state.impersonateID = action.payload.user.impersonateID;
        state.teamID = action.payload.user.teamID;
        state.wcicEmployee = action.payload.user.wcicEmployee;
        state.newVersions = action.payload.newVersions || [];
        state.claimsActivityLayout = action.payload.user.claimsActivityLayout;
        state.roleList = action.payload.user.roleList;
      })
      .addCase(otp.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { localLogout, newVersionsDismissed, setClaimsActivityLayout, setTOTPState } = authSlice.actions;

export const selectUsername = (state: RootState) => state.auth.username;
export const selectEmail = (state: RootState) => state.auth.email;
export const selectAuth = (state: RootState) => state.auth.auth;
export const selectAuthTOTP = (state: RootState) => state.auth.auth_totp;
export const selectAuthSMS = (state: RootState) => state.auth.auth_sms;
export const selectFirstName = (state: RootState) => state.auth.firstName;
export const selectLastName = (state: RootState) => state.auth.lastName;
export const selectAgencyID = (state: RootState) => state.auth.agentID;
export const selectAgencyName = (state: RootState) => state.auth.agentName;
export const selectAdministrator = (state: RootState) => state.auth.administrator;
export const selectClusterAdministrator = (state: RootState) => state.auth.clusterAdministrator;
export const selectClustered = (state: RootState) => state.auth.clustered;
export const selectClusterAgencies = (state: RootState) => state.auth.clusterAgencies;
export const selectImpersonateID = (state: RootState) => state.auth.impersonateID;
export const selectTeamID = (state: RootState) => state.auth.teamID;
export const selectWCICEmployee = (state: RootState) => state.auth.wcicEmployee;
export const selectNewVersions = (state: RootState) => state.auth.newVersions;
export const selectAuthStatus = (state: RootState) => state.auth.status;
export const selectClaimsActivityLayout = (state: RootState) => state.auth.claimsActivityLayout;
export const selectRoleList = (state: RootState) => state.auth.roleList;
export const initState = (state: RootState) => state.auth.init;

export default authSlice.reducer;
