import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiPanel,
  EuiTableSortingType,
} from "@elastic/eui";
import { useAppSelector } from "app/hooks";
import EuiCustomLink from "components/EuiCustomLink";
import { selectClusterAgencies } from "features/authorization/authSlice";
import useCancellingPolicies from "hooks/queries/usePendingCancellations";
import moment from "moment";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { Cancellation } from "types/apiTypes";

interface TableData {
  policyID: string;
  agentID: string;
  insuredDisplayName: string;
  cancelType: string;
  premiumDue: number;
  effectiveDate: string;
  cancel: Cancellation;
}

function sortTable(table: TableData[], sortField: keyof TableData, sortDirection: "asc" | "desc") {
  return table.slice(0).sort((a, b) => {
    if (sortField === "effectiveDate") {
      if (sortDirection === "asc") {
        if (a.effectiveDate + a.policyID > b.effectiveDate + b.policyID) {
          return 1;
        }
        if (a.effectiveDate + a.policyID < b.effectiveDate + b.policyID) {
          return -1;
        }
        return 0;
      } else {
        if (a.effectiveDate + a.policyID < b.effectiveDate + b.policyID) {
          return 1;
        }
        if (a.effectiveDate + a.policyID > b.effectiveDate + b.policyID) {
          return -1;
        }
        return 0;
      }
    }
    if (sortDirection === "asc") {
      if (a[sortField] > b[sortField]) {
        return 1;
      }
      if (a[sortField] < b[sortField]) {
        return -1;
      }
      return 0;
    } else {
      if (a[sortField] < b[sortField]) {
        return 1;
      }
      if (a[sortField] > b[sortField]) {
        return -1;
      }
      return 0;
    }
  });
}

function paginateTable(table: TableData[], pageIndex: number, pageSize: number) {
  const startIndex = pageIndex * pageSize;
  return table.slice(startIndex, Math.min(startIndex + pageSize, table.length));
}

function PoliciesCancelling({
  pageSize,
  setPageSize,
  pageOptions,
}: {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageOptions: Array<number>;
}) {
  const { data, isLoading, isFetching, isPlaceholderData } = useCancellingPolicies();
  console.log("Cancels: ", data);
  const clusterAgents = useAppSelector(selectClusterAgencies);
  const [pageIndex, setPageIndex] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof TableData>("effectiveDate");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const dataTable: TableData[] = data.map((record) => {
    return {
      policyID: record.policyID,
      agentID: record.agentID,
      insuredDisplayName: record.insured.displayName,
      cancelType: record.cancelType,
      premiumDue: record.premiumDue,
      effectiveDate: record.effectiveDate,
      cancel: record,
    };
  });

  const columns: EuiBasicTableColumn<TableData>[] = [];

  if (clusterAgents.length > 1) {
    columns.push({
      field: "agentID",
      name: "Agent ID",
    });
  }
  columns.push(
    {
      field: "policyID",
      name: "Policy Number",
      render: (policyID: string, rowData: TableData) => {
        return (
          <EuiCustomLink to={`/policy/${rowData.cancel.policy.recordID}`} external={true}>
            {policyID}
          </EuiCustomLink>
        );
      },
    },
    {
      field: "insuredDisplayName",
      name: "Insured Name",
      render: (insuredDisplayName: string, rowData: TableData) => {
        return (
          <EuiCustomLink to={`/insured/${rowData.cancel.insured.recordID}`} external={true}>
            {insuredDisplayName}
          </EuiCustomLink>
        );
      },
    },
    {
      field: "cancelType",
      name: "Cancel Type",
      render: (type: string) => {
        switch (type) {
          case "C":
            return "Cancellation";
          case "C1":
            return "Non-Payment";
          case "C2":
            return "Returned Check";
          case "C3":
            return "Underwriting";
          case "CR":
            return "Conditioned Renewal";
          case "CRP":
            return "Conditioned Renewal";
          case "D":
            return "Disclosure";
          case "NR":
            return "Nonrenewal";

          default:
            return "ERROR";
        }
      },
    },
    {
      field: "premiumDue",
      name: "Premium Due",
      render: (amount: number) => {
        if (amount > 0) {
          return (
            <NumberFormat
              value={amount / 100}
              displayType="text"
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              decimalSeparator="."
              prefix={"$"}
            />
          );
        } else {
          return "";
        }
      },
    },
    {
      field: "effectiveDate",
      name: "Effective Date",
      render: (date: string) => moment(date).format("l"),
    }
  );

  // const { pageOfItems, totalItemCount } = pageAndSort(dataTable, { pageIndex, pageSize, sortField, sortDirection });
  const totalItemCount = dataTable.length;
  const sortedTable = sortTable(dataTable, sortField, sortDirection);
  const pageOfItems = paginateTable(sortedTable, pageIndex, pageSize);

  const getRowProps = (record: TableData) => {
    const { recordID } = record.cancel;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      // onClick: () => {
      //   console.log("clicked on this: ", cancellation);
      //   // navigate("/policy/" + cancellation.policy.recordID); // FIXME
      // },
    };
  };

  const getCellProps = (record: TableData, column: any) => {
    const { recordID } = record.cancel;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };
  const sorting: EuiTableSortingType<TableData> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: pageOptions,
    hidePerPageOptions: !showPerPageOptions,
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<TableData>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPanel hasBorder>
      <EuiBasicTable
        items={pageOfItems}
        itemId="ID"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
        pagination={pagination}
        onChange={onTableChange}
        tableLayout="auto"
        sorting={sorting}
        loading={isLoading || (isFetching && isPlaceholderData)}
      />
    </EuiPanel>
  );
}

export default PoliciesCancelling;
