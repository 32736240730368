import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFieldPassword,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiSpacer,
} from "@elastic/eui";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { login, selectAuth, selectAuthStatus } from "../features/authorization/authSlice";

function Login({ button = <></> }) {
  const auth = useAppSelector(selectAuth);
  const authStatus = useAppSelector(selectAuthStatus);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [awaitRedirect, setAwaitRedirect] = useState(false);
  const { state }: any = useLocation();

  useEffect(() => {
    if (awaitRedirect) {
      if (auth === "limited") {
        navigate("/otp", { state: { from: state?.from || "/" } });
      }
    }
    if (auth === "full") {
      navigate(state?.from || "/");
    }
  }, [auth, awaitRedirect, navigate, state]);

  return (
    <EuiPage paddingSize="none" style={{ minHeight: "100vh" }}>
      <EuiPageBody paddingSize="none">
        <EuiPageContent id="wcic_login_panel" verticalPosition="center" horizontalPosition="center" paddingSize="l">
          <EuiEmptyPrompt
            title={<span>Agent Support Login</span>}
            body={
              <EuiForm
                component="form"
                onSubmit={(event) => {
                  event.preventDefault();
                  setAwaitRedirect(true);
                  dispatch(
                    login({
                      email: email,
                      password: password,
                    })
                  );
                }}
              >
                <EuiHorizontalRule size="full" />
                <EuiFormRow label="Email address" fullWidth isInvalid={authStatus === "failed"}>
                  <EuiFieldText
                    type="text"
                    icon="user"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                  />
                </EuiFormRow>
                <EuiSpacer size="l" />
                <EuiFormRow
                  label="Password"
                  fullWidth
                  error={"Email Address or Password Incorrect"}
                  isInvalid={authStatus === "failed"}
                >
                  <EuiFieldPassword
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="dual"
                    fullWidth
                  />
                </EuiFormRow>
                <EuiFormRow>
                  <EuiButton type="submit" fill>
                    Login
                  </EuiButton>
                </EuiFormRow>
              </EuiForm>
            }
          />
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
}

export default Login;
