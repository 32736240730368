import * as yup from "yup";

import { formsData } from "../landlord/data/formsData";

export const LPSchema = yup
  .object({
    isBusinessName: yup
      .string()
      .oneOf(["personal", "business"], (field) => `Must be business or personal name`)
      .required()
      .label("Line"),
    businessName: yup
      .string()
      .trim()
      .when("isBusinessName", (isBusinessName: string, schema: yup.StringSchema) => {
        if (isBusinessName === "business") {
          return schema.max(64, "Business name cannot exceed 64 characters").required();
        }
        return schema;
      })
      .label("Business Name"),

    firstName: yup
      .string()
      .trim()
      .when("isBusinessName", (isBusinessName: string, schema: yup.StringSchema) => {
        if (isBusinessName === "personal") {
          return schema.max(64, "First name cannot exceed 64 characters").required();
        }
        return schema;
      })
      .label("First name"),
    lastName: yup
      .string()
      .trim()
      .when("isBusinessName", (isBusinessName: string, schema: yup.StringSchema) => {
        if (isBusinessName === "personal") {
          return schema.max(64, "Last name cannot exceed 64 characters").required();
        }
        return schema;
      })
      .label("Last name"),
    streetAddress: yup.string().trim().required().label("Street address"),
    streetAddress2: yup.string().trim().label("Street address 2"),
    city: yup.string().required().label("City"),
    state: yup.string().required().min(2).max(2).label("State"),
    zip: yup.string().required().label("Zip"),
    forms: yup.array().of(
      yup.object().shape({
        name: yup.string().required(),
        fields: yup.object().when("name", (name: string, schema: yup.AnyObjectSchema) => {
          return schema.concat(formsData[name].yupShape);
        }),
      })
    ),
    liabilityForm: yup
      .string()
      .oneOf(["None", "FLOLT"], (field) => `${field.label} must be selected from the options`)
      .required()
      .label("Liability Form"),
    liability: yup
      .number()
      .when(
        ["liabilityForm", "locations"],
        // @ts-ignore
        (liabilityForm: any, locations: QuoteLocation[], schema: yup.NumberSchema) => {
          if (liabilityForm !== "None") {
            if (locations.some((item) => item.valuation === "ACV")) {
              return schema
                .oneOf([100000, 300000, 500000], (field) => `All locations must be RC for this option`)
                .required();
            } else {
              return schema
                .oneOf([100000, 300000, 500000, 1000000], (field) => `${field.label} must be selected from the options`)
                .required();
            }
          }

          return schema;
        }
      )
      .label("Liability"),
    medpay: yup
      .number()
      .when("liabilityForm", (liabilityForm: string, schema: yup.NumberSchema) => {
        if (liabilityForm !== "None") {
          return schema.oneOf([5000], (field) => `${field.label} must be selected from the options`).required();
        }
        return schema;
      })
      .label("Medpay"),
    locations: yup.array().of(
      yup.object().shape({
        streetAddress: yup.string().required().label("Street address"),
        city: yup.string().required().label("City"),
        state: yup.string().required().label("State"),
        zip: yup
          .number()
          .positive()
          .integer()
          .typeError((field) => `${field.label} is a required field`)
          .required()
          .label("Zip"),
        constructionType: yup
          .string()
          .oneOf(["F", "M"], (field) => `${field.label} must be Frame or Masonry`)
          .typeError((field) => `${field.label} must be selected from the options`)
          .required()
          .label("Construction Type"),
        protectionClass: yup
          .string()
          .typeError((field) => `${field.label} must be selected from the options`)
          .required()
          .label("Protection Class"),
        yearBuilt: yup
          .number()
          .min(1776)
          .max(2100)
          .typeError((field) => `${field.label} must be a number`)
          .required()
          .label("Year built"),
        numFamilies: yup
          .number()
          .oneOf([1, 2, 3, 4], (field) => `${field.label} must be between 1 and 4`)
          .typeError((field) => `${field.label} must be a number`)
          .required()
          .label("Number of Families"),
        valuation: yup
          .string()
          .typeError((field) => `${field.label} must be selected from the options`)
          .required()
          .label("Valuation"),
        RCpercentage: yup
          .number()
          .typeError((field) => `${field.label} must be selected from the options`)
          .label("RC Percentage"),
        perils: yup
          .string()
          .oneOf(["FL1R", "FL1RV", "FL2", "FL3"], (field) => `${field.label} must be selected from the options`)
          .typeError((field) => `${field.label} must be selected from the options`)
          .required()
          .label("Perils"),
        deductible: yup
          .number()
          .typeError((field) => `${field.label} must be selected from the options`)
          .required()
          .label("Deductible"),
        RCcovA: yup.number().label("RC Coverage A"),
        covA: yup
          .number()
          .min(20000, (field) => `${field.label} must be greater than $20,000`)
          .integer()
          .typeError((field) => `${field.label} must be a number`)
          .required()
          .label("Coverage A"),
        covB: yup
          .number()
          .min(0)
          .integer()
          .typeError((field) => `${field.label} must be a number`)
          .required()
          .label("Coverage B"),
        covC: yup
          .number()
          .min(0)
          .integer()
          .typeError((field) => `${field.label} must be a number`)
          .required()
          .label("Coverage C"),
        covD: yup
          .number()
          .min(0)
          .integer()
          .typeError((field) => `${field.label} must be a number`)
          .required()
          .label("Coverage D"),

        forms: yup.array().of(
          yup.object().shape({
            name: yup.string().required(),
            fields: yup.object().when("name", (name: string, schema: yup.AnyObjectSchema) => {
              return schema.concat(formsData[name].yupShape);
            }),
          })
        ),
      })
    ),
  })
  .required();
