import { useQuery } from "react-query";
import { Receipt } from "types/apiTypes";

import authAxios from "../../services/authAxios";

interface ReportResult {
  receipts: Receipt[];
  premium_sum: number;
  non_premium_sum: number;
  commission_sum: number;
}

const getReport = async (month: number, year: number, code: string) => {
  const { data } = await authAxios.post(`/commission-report`, { month: month, year: year, code: code });
  return data;
};

function useReport(month: number, year: number, code: string) {
  const useQueryReturn = useQuery<ReportResult>(
    ["commission_report", { month: month, year: year, code }],
    () => getReport(month, year, code),
    {
      placeholderData: { receipts: [], premium_sum: 0, non_premium_sum: 0, commission_sum: 0 },
      initialData: { receipts: [], premium_sum: 0, non_premium_sum: 0, commission_sum: 0 },
      initialDataUpdatedAt: 0,
      staleTime: 1000 * 60 * 15,
    }
  );
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

export default useReport;
