import { EuiFieldText, EuiFieldTextProps } from "@elastic/eui";
import { Controller, RegisterOptions } from "react-hook-form";

// import { FormInputProps } from "./FormInputProps";

export interface FormInputProps extends EuiFieldTextProps {
  name: string;
  label: string;
  rules: Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
}

export const FormInputText = ({ name, label, rules, ...rest }: FormInputProps) => {
  return (
    <Controller
      name={`${name}` as const}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <EuiFieldText
          {...rest}
          onChange={onChange}
          onBlur={(event) => {
            onBlur();
            rest.onBlur?.(event);
          }}
          value={value}
          inputRef={ref}
        />
      )}
    />
  );
};
