import axios from "axios";
import Cookies from "js-cookie";

import { store } from "../app/store";
import { localLogout } from "../features/authorization/authSlice";

// function getCookie(name: string) {
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop()?.split(";").shift();
// }
const defaultURL = process.env.REACT_APP_API_URL || "";
const authAxios = axios.create({ baseURL: defaultURL });

authAxios.interceptors.request.use((request) => {
  request.headers["X-CSRF-TOKEN"] = Cookies.get("csrf_access_token");
  return request;
});

authAxios.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    if (error.response.status === 401) {
      store.dispatch(localLogout());
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
);

export default authAxios;
