import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiTitle,
  htmlIdGenerator,
} from "@elastic/eui";
import { useAPIURL } from "hooks/useAPIURL";
import { Fragment, useState } from "react";

import { FormType, QuoteInput } from "../types/Rater.types";
import { FormDataType } from "./FormDataTypes";

interface TableFormDataType extends FormDataType {
  id: string;
}

const removeDuplicateForms = (formDataArray: TableFormDataType[]) => {
  return formDataArray.reduce((accumulator: TableFormDataType[], currentValue) => {
    if (!accumulator.some((tableForm) => tableForm.id === currentValue.id)) accumulator.push(currentValue);
    return accumulator;
  }, []);
};

// import { formsData } from "../homeowner/data/formsData";

export interface FormPickerProps {
  onCloseHandler: () => void;
  // append: UseFieldArrayReturn["append"];
  append: (form: FormType | FormType[]) => void;
  currentForms: string[];
  appendPolicyForm: (form: FormType | FormType[]) => void;
  formsData: Record<string, FormDataType>;
  liability: boolean;
  location: number;
  raterValues?: QuoteInput;
}

export const FormPicker = ({
  onCloseHandler,
  append,
  currentForms,
  appendPolicyForm,
  formsData,
  liability,
  location,
  raterValues,
}: FormPickerProps) => {
  const data = Object.entries(formsData).map(([key, value]): TableFormDataType => {
    return {
      id: key,
      ...value,
    };
  });
  const recData = Object.entries(formsData)
    .filter(([, value]) => {
      return value.recommended;
    })
    .map(([key, value]): TableFormDataType => {
      return {
        id: key,
        ...value,
      };
    });
  const defaultURL = useAPIURL();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [sortField, setSortField] = useState("firstName");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [selectedItems, setSelectedItems] = useState<TableFormDataType[]>([]);
  const [selectedRecItems, setSelectedRecItems] = useState<TableFormDataType[]>([]);

  const onTableChange = ({ page = {}, sort = {} }) => {
    setPageIndex(pageIndex);
    setPageSize(pageSize);
    setSortField(sortField);
    setSortDirection(sortDirection);
  };
  const onSelectionChange = (selectedItems: any) => {
    setSelectedItems(selectedItems);
  };
  const onSelectionRecChange = (selectedItems: any) => {
    setSelectedRecItems(selectedItems);
  };

  const onAddSelection = () => {
    const consolidatedForms = removeDuplicateForms([...selectedItems, ...selectedRecItems]);
    const policyForms = consolidatedForms.filter((item) => item.policyForm);
    const locationForms = consolidatedForms.filter((item) => item.policyForm !== true);
    append(
      locationForms.map((item) => {
        return {
          formID: htmlIdGenerator()(),
          name: item.id,
          fields: item.defaultInputs,
        };
      })
    );
    appendPolicyForm(
      policyForms.map((item) => {
        return {
          formID: htmlIdGenerator()(),
          name: item.id,
          fields: item.defaultInputs,
        };
      })
    );
  };

  const columns: EuiBasicTableColumn<TableFormDataType>[] = [
    {
      field: "displayFormName",
      name: "Name",
      truncateText: true,
      width: "100px",
    },
    {
      field: "formTitle",
      name: "Description",
      isExpander: true,
    },
    {
      field: "",
      name: "View",
      isExpander: true,
      width: "50px",

      render: (form: TableFormDataType) => {
        return (
          <EuiButtonIcon
            display="empty"
            iconType={"document"}
            aria-label={"View PDF"}
            color="primary"
            onClick={() => {
              window.open(`${defaultURL}/form/${form.nameEdition.name}/${form.nameEdition.edition}`, "_blank");
            }}
          />
        );
      },
    },
    {
      field: "",
      name: "Actions",
      width: "80px",
      actions: [
        {
          name: "Add",
          description: "Add this form",
          type: "icon",
          icon: "plus",
          enabled: (item: TableFormDataType) => {
            const myForm = currentForms.find((form) => {
              return form === item.id;
            });
            if (item.automated) {
              return false;
            }
            if (!!item.exclusiveForms) {
              const hasExclusiveForms = currentForms.some((form) => {
                return item.exclusiveForms!.includes(form);
              });
              if (hasExclusiveForms) {
                return false;
              }
            }
            if (item.liabilityForm) {
              if (!liability) {
                return false;
              }
            }
            if (myForm) {
              if (!item.multiAdd) {
                return false;
              }
            }
            if (item.addable && !item.addable(raterValues!, location)) {
              return false;
            }
            return true;
          },
          onClick: (event: any) => {
            if (event.policyForm) {
              appendPolicyForm({
                formID: htmlIdGenerator()(),
                name: event.id,
                fields: event.defaultInputs,
              });
            } else {
              append({
                formID: htmlIdGenerator()(),
                name: event.id,
                fields: event.defaultInputs,
              });
            }
            onCloseHandler();
          },
        },
      ],
    },
  ];
  //   const onlineUsers = store.users.filter((user) => user.online);
  const selection = {
    selectable: (item: TableFormDataType) => {
      const myForm = currentForms.find((form) => {
        return form === item.id;
      });
      if (item.automated) {
        return false;
      }
      if (!!item.exclusiveForms) {
        const hasExclusiveForms = currentForms.some((form) => {
          return item.exclusiveForms!.includes(form);
        });
        if (hasExclusiveForms) {
          return false;
        }
      }
      if (item.liabilityForm) {
        if (!liability) {
          return false;
        }
      }
      if (myForm) {
        if (!item.multiAdd) {
          return false;
        }
      }
      if (item.addable && !item.addable(raterValues!, location, selectedItems.map(item => item.id))) {
        return false;
      }
      return true;
    },
    selectableMessage: (selectable: any) => (!selectable ? "" : ""),
    onSelectionChange: onSelectionChange,
    // initialSelected: onlineUsers,
  };
  const selectionRec = {
    selectable: (item: TableFormDataType) => {
      const myForm = currentForms.find((form) => {
        return form === item.id;
      });
      if (item.automated) {
        return false;
      }
      if (!!item.exclusiveForms) {
        const hasExclusiveForms = currentForms.some((form) => {
          return item.exclusiveForms!.includes(form);
        });
        if (hasExclusiveForms) {
          return false;
        }
      }
      if (item.liabilityForm) {
        if (!liability) {
          return false;
        }
      }
      if (myForm) {
        if (!item.multiAdd) {
          return false;
        }
      }
      if (item.addable && !item.addable(raterValues!, location)) {
        return false;
      }
      return true;
    },
    selectableMessage: (selectable: any) => (!selectable ? "" : ""),
    onSelectionChange: onSelectionRecChange,
    // initialSelected: onlineUsers,
  };
  //   const onSelection = () => {
  //     if (tableRef){
  //       tableRef.current.setSelection(onlineUsers);
  //     }
  //   };
  return (
    <Fragment>
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiButton
            onClick={() => {
              onAddSelection();
              onCloseHandler();
            }}
          >
            Add Selected Forms
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem />
      </EuiFlexGroup>
      <EuiSpacer size="l" />
      {/* <EuiPanel> */}
      {recData.length > 0 ? (
        <>
          <EuiTitle>
            <h3>Frequently Used Forms</h3>
          </EuiTitle>
          <EuiSpacer size="s" />
          <EuiBasicTable
            // ref={tableRef}
            items={recData}
            itemId="id"
            columns={columns}
            isSelectable={true}
            onChange={onTableChange}
            rowHeader="Row Header"
            selection={selectionRec}
          />
          {/* </EuiPanel> */}

          <EuiSpacer size="l" />
        </>
      ) : null}

      {/* <EuiPanel> */}
      <EuiTitle>
        <h3>Other Available Forms</h3>
      </EuiTitle>
      <EuiSpacer size="s" />
      <EuiBasicTable
        // ref={tableRef}
        items={data}
        itemId="id"
        columns={columns}
        isSelectable={true}
        onChange={onTableChange}
        rowHeader="Row Header"
        selection={selection}
      />
      {/* </EuiPanel> */}
    </Fragment>
  );
};

export default FormPicker;
