import { EuiIcon, EuiSpacer, EuiTabs } from "@elastic/eui";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { HOQuoteInput, LPQuoteInput, QuoteLocation } from "../types/Rater.types";

import LocationTab from "./LocationTab";
import WCICTab from "./WCICTab";

interface MultiLocationControllerType {
  locationContent: (selectedTabId: number) => React.ReactNode;
  defaultNewLocation: QuoteLocation;
  maxLocations: number;
  onSelectedTabChange?: (newTabId: number, previousTabId: number) => void;
}

const MultiLocationController = ({
  locationContent,
  defaultNewLocation,
  maxLocations,
  onSelectedTabChange
}: MultiLocationControllerType) => {
  const { control, formState } = useFormContext<LPQuoteInput | HOQuoteInput>();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "locations", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });
  const [selectedTabId, setSelectedTabId] = useState(0);

  const fieldsLength = fields.length;

  useEffect(() => {
    if(fieldsLength <= selectedTabId){
      setSelectedTabId(0);
    }
  }, [fieldsLength, selectedTabId, setSelectedTabId])

  useEffect(() => {
    if (formState.errors.locations) {
      // do the your logic here
      // console.log("Location Errors: ", formState.errors.locations)
    }
  }, [formState]); // ✅ 

  return (
    <>
      <EuiTabs>
        {fields.map((tab, index) => {
          return (
            <LocationTab
              key={tab.id}
              index={index}
              isSelected={index === selectedTabId}
              onClick={() => {
                onSelectedTabChange?.(index, selectedTabId);
                setSelectedTabId(index);
              }}
              onCloseTab={() => {
                setSelectedTabId((currentTab) => {
                  if (currentTab < index) return currentTab;
                  if (currentTab > index) return currentTab - 1;
                  if (fields.length === 1) {
                    append(defaultNewLocation);
                    return 0;
                  }
                  if (index === 0) {
                    return 0;
                  }
                  return currentTab - 1;
                });

                remove(index);
              }}
              error={!!formState.errors?.locations?.[index]}
            />
          );
        })}

        <WCICTab
          onClick={() => {
            if (fields.length < maxLocations) {
              append(defaultNewLocation);
              onSelectedTabChange?.(fields.length, selectedTabId);
              setSelectedTabId(fields.length);
            }
          }}
        >
          <EuiIcon type="plus" size="m" />
          <span style={{ marginLeft: "6px" }}>Add Location</span>
        </WCICTab>
      </EuiTabs>
      <EuiSpacer />
      {/* <LocationTabContent key={selectedTabId} tabItem={selectedTabId} append={append} remove={remove} /> */}
      {fields.length <= selectedTabId ? null : locationContent(selectedTabId)}
    </>
  );
};

export default MultiLocationController;
