import { useQuery } from "react-query";
import { Claim, Insured, Policy, Quote } from "types/apiTypes";

import authAxios from "../../services/authAxios";

type QueryResult = Insured | Policy | Claim | Quote;

function useSitewideQuery(query: string) {
  return useQuery<QueryResult[]>(
    ["sitewide_search", query],
    async () => {
      const { data } = await authAxios.post("/search", { query: query });
      return data;
    },
    { enabled: !!query, refetchOnWindowFocus: false, staleTime: Infinity, cacheTime: Infinity }
  );
}

export default useSitewideQuery;
