import authAxios from "../../services/authAxios";
import { useQuery } from "react-query";
import { Claim } from "types/apiTypes";

const getClaimById = async (id: string | undefined) => {
  const { data } = await authAxios.get(`/claim/${id}`);
  return data;
};

function useClaim(claimRecordID: string | undefined) {
  return useQuery<Claim>(["claim", claimRecordID], () => getClaimById(claimRecordID),
  {
    enabled: !!claimRecordID,
  });
}

export default useClaim;
