import { EuiFlexGroup, EuiFlexItem, EuiPage, EuiPageBody, EuiSpacer, EuiTab, EuiTabs } from "@elastic/eui";
import { Fragment, useMemo, useState } from "react";
import { useQuery } from "react-query";
import authAxios from "services/authAxios";

import AccessLogs from "./dashboard/AccessLogs";
import DashboardWidgets from "./dashboard/DashboardWidgets";
import InvoicesComingDue from "./dashboard/InvoicesComingDue";
import NewPolicyDocuments from "./dashboard/NewPolicyDocuments";
import PendingClaims from "./dashboard/PendingClaims";
import PoliciesCancelled from "./dashboard/PoliciesCancelled";
import PoliciesCancelling from "./dashboard/PoliciesCancelling";
import PoliciesPendingIssuance from "./dashboard/PoliciesPendingIssuance";
import PoliciesReinstated from "./dashboard/PoliciesReinstated";

const getWidgetStats = async () => {
  const { data } = await authAxios.get("/widget-stats");
  return data;
};

function useWidgetStats() {
  return useQuery(["widgetStats"], () => getWidgetStats(), {
    placeholderData: [],
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
  });
}

const Dashboard: React.FC = () => {
  const { data, isLoading } = useWidgetStats();

  const [pageSize, setPageSize] = useState(() => {
    const saved = localStorage.getItem("pageSize");
    return Number(saved) || 10;
  });

  const tabs = useMemo(() => {
    const pageOptions = [10, 20, 50, 100];
    const pageSizeUpdate = (size: number) => {
      localStorage.setItem("pageSize", size.toString());
      setPageSize(size);
    };
    return [
      {
        id: "new-policy-documents",
        name: "New Policy Documents",
        content: (
          <Fragment>
            <EuiSpacer />
            <NewPolicyDocuments pageSize={pageSize} pageOptions={pageOptions} setPageSize={pageSizeUpdate} />
          </Fragment>
        ),
      },
      {
        id: "pending-claims",
        name: "Pending Claims",
        content: (
          <Fragment>
            <EuiSpacer />
            <PendingClaims pageSize={pageSize} pageOptions={pageOptions} setPageSize={pageSizeUpdate} />
          </Fragment>
        ),
      },
      {
        id: "policies-pending-issuance",
        name: "Policies Pending Issuance",
        content: (
          <Fragment>
            <EuiSpacer />
            <PoliciesPendingIssuance pageSize={pageSize} pageOptions={pageOptions} setPageSize={pageSizeUpdate} />
          </Fragment>
        ),
      },
      {
        id: "pending-cancellation",
        name: "Pending Cancellation",
        content: (
          <Fragment>
            <EuiSpacer />
            <PoliciesCancelling pageSize={pageSize} pageOptions={pageOptions} setPageSize={pageSizeUpdate} />
          </Fragment>
        ),
      },
      {
        id: "policies-reinstated-last-30",
        name: "Policies Reinstated",
        content: (
          <Fragment>
            <EuiSpacer />
            <PoliciesReinstated pageSize={pageSize} pageOptions={pageOptions} setPageSize={pageSizeUpdate} />
          </Fragment>
        ),
      },
      {
        id: "policies-cancelled-last-30",
        name: "Policies Canceled",
        content: (
          <Fragment>
            <EuiSpacer />
            <PoliciesCancelled pageSize={pageSize} pageOptions={pageOptions} setPageSize={pageSizeUpdate} />
          </Fragment>
        ),
      },
    ];
  }, [pageSize, setPageSize]);

  const [selectedTabId, setSelectedTabId] = useState(() => {
    const saved = localStorage.getItem("tab");
    return saved || "new-policy-documents";
  });

  const selectedTabContent = useMemo(() => {
    return tabs.find((obj) => obj.id === selectedTabId)?.content;
  }, [selectedTabId, tabs]);

  const onSelectedTabChanged = (id: string) => {
    setSelectedTabId(id);
    localStorage.setItem("tab", id);
  };

  const renderTabs = () => {
    return tabs.map((tab, index) => (
      <EuiTab key={index} onClick={() => onSelectedTabChanged(tab.id)} isSelected={tab.id === selectedTabId}>
        {tab.name}
      </EuiTab>
    ));
  };

  return (
    <EuiPage>
      <EuiPageBody restrictWidth="1300px">
        <EuiSpacer />
        <DashboardWidgets widgetData={data} isLoading={isLoading} onSelect={onSelectedTabChanged} />
        <EuiSpacer />
        <EuiTabs>{renderTabs()}</EuiTabs>
        {selectedTabContent}
        <EuiSpacer />
        <EuiFlexGroup>
          <EuiFlexItem grow={1}>
            <AccessLogs />
          </EuiFlexItem>
          <EuiFlexItem grow={2}>
            <InvoicesComingDue />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPageBody>
    </EuiPage>
  );
};

export default Dashboard;
