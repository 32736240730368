import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiPanel,
  EuiSpacer,
  EuiTableSortingType,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import moment from "moment";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { useNavigate } from "react-router-dom";
import { Policy } from "types/apiTypes";

import pageAndSort from "../../../services/pageAndSort";

const Policies: React.FC<{ policies: Policy[] }> = ({ policies }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof Policy>("inceptionDate");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const navigate = useNavigate();

  const columns: EuiBasicTableColumn<Policy>[] = [
    {
      field: "policyID",
      name: "Policy ID",
    },
    {
      field: "policyTypeDisplay",
      name: "Type",
      render: (policyType: string) => {
        switch (policyType) {
          case "Renewal":
            return <EuiTextColor color="success">{policyType}</EuiTextColor>;
          case "Cancellation":
            return <EuiTextColor color="danger">{policyType}</EuiTextColor>;
          case "Installment":
            return <EuiTextColor color="warning">{policyType}</EuiTextColor>;
          case "New Policy":
            return <EuiTextColor color="success">{policyType}</EuiTextColor>;
          case "Endorsement":
            return <EuiTextColor color="success">{policyType}</EuiTextColor>;

          default:
            return <EuiTextColor color="default">{policyType}</EuiTextColor>;
        }
      },
      //   width: "10%"
    },
    {
      field: "inceptionDate",
      name: "Inception Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "expirationDate",
      name: "Expiration Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "annualPremium",
      name: "Annual Premium",
      render: (annualPremium: number) => {
        return (
          <NumberFormat
            displayType={"text"}
            style={{ paddingLeft: "10px" }}
            value={annualPremium / 100}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        );
      },
    },
  ];

  const { pageOfItems, totalItemCount } = pageAndSort(policies, {pageIndex, pageSize, sortField, sortDirection});

  const getRowProps = (policy: Policy) => {
    const { recordID } = policy;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      onClick: () => {
        navigate("/policy/" + policy.recordID);
      },
    };
  };

  const getCellProps = (policy: Policy, column: any) => {
    const { recordID } = policy;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };
  const sorting: EuiTableSortingType<Policy> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [15, 10, 5],
    hidePerPageOptions: !showPerPageOptions,
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<Policy>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPanel hasBorder>
      <EuiTitle>
        <h3>Policies</h3>
      </EuiTitle>
      <EuiSpacer size="m" />
      <EuiBasicTable
        items={pageOfItems}
        itemId="record_id"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
        pagination={pagination}
        onChange={onTableChange}
        tableLayout="auto"
        sorting={sorting}
      />
    </EuiPanel>
  );
};

export default Policies;
