import {
  EuiAvatar,
  EuiBadge,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiHeader,
  EuiHeaderLogo,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiNotificationEvent,
  EuiPanel,
  EuiPopover,
  EuiPortal,
  EuiShowFor,
  EuiSpacer,
  EuiText,
  EuiTitle,
  htmlIdGenerator,
} from "@elastic/eui";
// Uncomment to use in consuming apps or CodeSandbox
// import theme from '@elastic/eui/dist/eui_theme_light.json';
// import { htmlIdGenerator } from "@elastic/eui/lib/services";
import { toggleTheme } from "features/themeSlice";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import authAxios from "services/authAxios";
import { VersionNote } from "types/apiTypes";

import logo from "../WebLogo NB.svg";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  logout,
  newVersionsDismissed,
  selectFirstName,
  selectImpersonateID,
  selectLastName,
  selectNewVersions,
} from "../features/authorization/authSlice";
import EuiCustomLink from "./EuiCustomLink";
import PolicyholderSearch from "./headerSearch";
import Sidebar from "./sidebar";

const getVersionNews = async () => {
  const { data } = await authAxios.get(`/version-news`);
  return data;
};

function useVersionNews() {
  const useQueryReturn = useQuery<VersionNote[]>(["news"], () => getVersionNews(), {
    placeholderData: [],
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
  });
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

function FullHeader({ children }: any) {
  /**
   * Header Alerts
   */
  const dispatch = useAppDispatch();
  const firstName = useAppSelector(selectFirstName);
  const lastName = useAppSelector(selectLastName);
  const impersonate = useAppSelector(selectImpersonateID);
  const newVersions = useAppSelector(selectNewVersions);
  const [isAlertFlyoutVisible, setIsAlertFlyoutVisible] = useState(false);
  const { data } = useVersionNews();

  const events = data.map((version) => {
    return {
      id: htmlIdGenerator()(),
      type: "News",
      iconType: version.icon,
      iconAriaLabel: "Elastic",
      time: moment(version.releaseDate).format("LL"),
      badgeColor: "accent",
      title: version.title,
      messages: [version.description],
      isRead: false,
    };
  });

  const headerAlerts = (
    <EuiPortal>
      <EuiFlyout
        onClose={() => setIsAlertFlyoutVisible(false)}
        size="s"
        id="guideHeaderAlertExample"
        aria-labelledby="guideHeaderAlertExampleTitle"
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="s">
            <h2 id="guideHeaderAlertExampleTitle">What's New</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiPanel paddingSize="none" hasShadow={true} style={{ maxWidth: "540px" }}>
            {events.map((event) => (
              <EuiNotificationEvent
                key={event.id}
                id={event.id}
                type={event.type}
                badgeColor={event.badgeColor}
                iconType={event.iconType}
                iconAriaLabel={event.iconAriaLabel}
                time={event.time}
                title={event.title}
                isRead={event.isRead}
                messages={event.messages}
                onClickPrimaryAction={() => {}}
                onClickTitle={event.id !== "news" ? () => {} : undefined}
              />
            ))}
          </EuiPanel>
        </EuiFlyoutBody>
      </EuiFlyout>
    </EuiPortal>
  );

  /**
   * User Menu
   */

  const HeaderUserMenu = () => {
    const id = htmlIdGenerator()();
    const [isOpen, setIsOpen] = useState(false);
    // const dispatch = useAppDispatch();

    const onMenuButtonClick = () => {
      setIsOpen(!isOpen);
    };

    const closeMenu = () => {
      setIsOpen(false);
    };

    const button = (
      <EuiHeaderSectionItemButton
        aria-controls={id}
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-label="Account menu"
        onClick={onMenuButtonClick}
      >
        <EuiShowFor sizes={["l", "xl"]}>
          <EuiBadge iconType="user" color={impersonate === null ? "primary" : "warning"} style={{ cursor: "pointer" }}>
            <span style={{ cursor: "pointer" }}>{`${firstName} ${lastName}`}</span>
          </EuiBadge>
        </EuiShowFor>
        <EuiShowFor sizes={["xs", "s", "m"]}>
          <EuiAvatar name={`${firstName} ${lastName}`} size="s" />
        </EuiShowFor>
      </EuiHeaderSectionItemButton>
    );

    return (
      <EuiPopover
        id={id}
        repositionOnScroll
        button={button}
        isOpen={isOpen}
        anchorPosition="downRight"
        closePopover={closeMenu}
        panelPaddingSize="none"
      >
        <div style={{ width: 320 }}>
          <EuiFlexGroup gutterSize="m" className="euiHeaderProfile" responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiAvatar name={`${firstName} ${lastName}`} size="xl" />
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText>
                <p>{`${firstName} ${lastName}`}</p>
              </EuiText>

              <EuiSpacer size="m" />

              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false}>
                      <EuiCustomLink to="/profile">Edit Profile</EuiCustomLink>
                    </EuiFlexItem>

                    <EuiFlexItem grow={false}>
                      <EuiLink
                        onClick={(event: any) => {
                          event.preventDefault();
                          dispatch(logout());
                        }}
                      >
                        Log out
                      </EuiLink>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </EuiPopover>
    );
  };
  /**
   * Sitewide search
   */

  //   const search = (
  //     <EuiFieldSearch
  //     style={{minWidth: '50vw'}}
  //       fullWidth
  //       placeholder="Something really really really really big"
  //     />
  //   );

  return (
    <>
      <EuiHeader
        theme="dark"
        position="fixed"
        sections={[
          {
            items: [
              <Sidebar />,
              <EuiHeaderLogo iconType={logo} href="">
                <EuiShowFor sizes={["m", "l", "xl"]}>Wayne Cooperative</EuiShowFor>
                <EuiShowFor sizes={["xs", "s"]}>WCIC</EuiShowFor>
              </EuiHeaderLogo>,
              <EuiShowFor sizes={["l", "xl"]}>
                <EuiBadge>Agency Support</EuiBadge>
              </EuiShowFor>,
              <EuiShowFor sizes={["xs", "s", "m"]}>
                <EuiAvatar type="space" name="Agency Support" size="s" />
              </EuiShowFor>,
            ],
            borders: "none",
          },
          {
            items: [
              <EuiShowFor sizes={["m", "l", "xl"]}>
                <PolicyholderSearch />
              </EuiShowFor>,
            ],
            borders: "none",
          },
          {
            items: [
              <EuiShowFor sizes={["xs", "s"]}>
                <PolicyholderSearch />
              </EuiShowFor>,
              <EuiHeaderSectionItemButton
                notification={newVersions.length}
                aria-label="Notifications: Updates available"
                onClick={() => {
                  setIsAlertFlyoutVisible(!isAlertFlyoutVisible);
                }}
              >
                <EuiIcon type="cheer" size="m" />
              </EuiHeaderSectionItemButton>,
              <EuiHeaderSectionItemButton aria-label="Theme toggle" onClick={() => dispatch(toggleTheme())}>
                <EuiIcon type="invert" size="l" />
              </EuiHeaderSectionItemButton>,
              <HeaderUserMenu />,
            ],
            borders: "none",
          },
        ]}
      />
      {children}
      {newVersions.length > 0 ? (
        <NewVersionModal
          versions={newVersions}
          onClose={() => {
            dispatch(newVersionsDismissed());
          }}
        />
      ) : null}
      {isAlertFlyoutVisible ? headerAlerts : null}
    </>
  );
}

interface NewVersionModalProps {
  versions: VersionNote[];
  onClose: () => void;
}

const NewVersionModal = ({ onClose, versions }: NewVersionModalProps) => {
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>What's New</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiSpacer />
        <EuiFlexGroup direction="column" gutterSize="l">
          {versions.map((version) => {
            return (
              <EuiFlexItem key={version.recordID}>
                <EuiCard
                  layout="horizontal"
                  icon={<EuiIcon size="xl" type={version.icon} />}
                  title={version.title}
                  display="subdued"
                  description={version.description}
                  betaBadgeProps={{
                    label: moment(version.releaseDate).format("LL"),
                  }}
                />
              </EuiFlexItem>
            );
          })}
        </EuiFlexGroup>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton onClick={onClose}>Continue</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default FullHeader;
