import { useMutation } from "react-query";

import authAxios from "../../../services/authAxios";

const useChangeEmail = () => {
  // const queryClient = useQueryClient();

  return useMutation((newEmail: string) => authAxios.post("/change_email_address", { email: newEmail}), {
    // onSuccess: () => {
    //   // ✅ refetch the comments list for our blog post
    //   queryClient.invalidateQueries(["posts", id, "comments"]);
    // },
  });
};

export default useChangeEmail;