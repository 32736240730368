import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiPanel,
  EuiTableSortingType,
} from "@elastic/eui";
import { useAppSelector } from "app/hooks";
import EuiCustomLink from "components/EuiCustomLink";
import { selectClusterAgencies } from "features/authorization/authSlice";
import useCancelledPolicies from "hooks/queries/useCancelledPolicies";
import moment from "moment";
import { useState } from "react";
import pageAndSort from "services/pageAndSort";

interface TableData {
  recordID: number;
  policyID: string;
  agentID: string;
  insuredDisplayName: string;
  insuredRecordID: number;
  cancelDate: string;
  cancelReason: string;
}

function PoliciesCancelled({
  pageSize,
  setPageSize,
  pageOptions,
}: {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageOptions: Array<number>;
}) {
  // const options = [
  //   { value: 1, text: "Yesterday" },
  //   { value: 7, text: "1 Week" },
  //   { value: 14, text: "2 Weeks" },
  //   { value: 30, text: "30 Days" },
  //   { value: 90, text: "90 Days" },
  // ];

  // const onChange = (e: any) => {
  //   setSelectedRange(e.target.value);
  // };

  // const [selectedRange, setSelectedRange] = useState(options[1].value);

  const { data, isLoading, isFetching, isPlaceholderData } = useCancelledPolicies();
  // console.log("Policies: ", data);
  
  const clusterAgents = useAppSelector(selectClusterAgencies);
  const [pageIndex, setPageIndex] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof TableData>("cancelDate");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const dataTable: TableData[] = data.map((record) => {
    return {
      recordID: record.recordID,
      policyID: record.policyID,
      agentID: record.agentID,
      insuredDisplayName: record.insured?.displayName || "",
      insuredRecordID: record.insured?.recordID || 0,
      cancelDate: record.cancelDate,
      cancelReason: record.cancelReason,
    };
  });
  const columns: EuiBasicTableColumn<TableData>[] = [];

  if (clusterAgents.length > 1) {
    columns.push({
      field: "agentID",
      name: "Agent ID",
    });
  }
  columns.push(
    {
      field: "policyID",
      name: "Policy Number",
      render: (policyID: string, rowData: TableData) => {
        return (
          <EuiCustomLink to={`/policy/${rowData.recordID}`} external={true}>
            {policyID}
          </EuiCustomLink>
        );
      },
    },
    {
      field: "insuredDisplayName",
      name: "Insured Name",
      render: (insuredDisplayName: string, rowData: TableData) => {
        return (
          <EuiCustomLink to={`/insured/${rowData.insuredRecordID}`} external={true}>
            {insuredDisplayName}
          </EuiCustomLink>
        );
      },
    },
    {
      field: "cancelReason",
      name: "Reason",
    },
    {
      field: "cancelDate",
      name: "Cancel Date",
      render: (date: string) => moment(date).format("l"),
    }
  );

  const { pageOfItems, totalItemCount } = pageAndSort(dataTable, { pageIndex, pageSize, sortField, sortDirection });

  const getRowProps = (record: TableData) => {
    const { recordID } = record;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      // onClick: () => {
      //   console.log("clicked on this: ", cancellation);
      //   // navigate("/policy/" + cancellation.policy.recordID); // FIXME
      // },
    };
  };

  const getCellProps = (record: TableData, column: any) => {
    const { recordID } = record;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };
  const sorting: EuiTableSortingType<TableData> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: pageOptions,
    hidePerPageOptions: !showPerPageOptions,
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<TableData>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPanel hasBorder>
      {/* <EuiFormRow label="Select a Range">
        <EuiSelect
          id={"rangeID"}
          options={options}
          value={selectedRange}
          onChange={(e) => onChange(e)}
          aria-label="Use aria labels when no actual label is in use"
        />
      </EuiFormRow>
      <EuiSpacer /> */}
      <EuiBasicTable
        items={pageOfItems}
        itemId="ID"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
        pagination={pagination}
        onChange={onTableChange}
        tableLayout="auto"
        sorting={sorting}
        loading={isLoading || (isFetching && isPlaceholderData)}
      />
    </EuiPanel>
  );
}

export default PoliciesCancelled;
