import { useQuery } from "react-query";
import { Quote } from "types/apiTypes";

import authAxios from "../../services/authAxios";

interface RecentQuotesDataObject {
  quotes: Quote[];
  totalQuotes: number;
}

const getQuotes = async (
  start: string,
  end: string,
  page: number,
  pageSize: number,
  sortField: string,
  sortDirection: string
) => {
  const { data } = await authAxios.post(`/recent-quotes`, {
    start: start,
    end: end,
    page: page,
    pageSize: pageSize,
    sortField: sortField,
    sortDirection: sortDirection,
  });
  return data;
};

function useRecentQuotes(
  start: string,
  end: string,
  page: number,
  pageSize: number,
  sortField: string,
  sortDirection: string
) {
  const useQueryReturn = useQuery<RecentQuotesDataObject>(
    ["recent_quotes", start, end, page, pageSize, sortField, sortDirection],
    () => getQuotes(start, end, page, pageSize, sortField, sortDirection),
    {
      placeholderData: {
        quotes: [],
        totalQuotes: 0,
      },
      initialData: {
        quotes: [],
        totalQuotes: 0,
      },
      initialDataUpdatedAt: 0,
      staleTime: 1000 * 60 * 15,
      keepPreviousData: true,
    }
  );
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

export default useRecentQuotes;
