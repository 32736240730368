import {
  EuiAvatar,
  EuiFieldSearch,
  EuiHighlight,
  EuiIcon,
  EuiInputPopover,
  EuiSelectable,
  EuiSelectableTemplateSitewideMetaData,
  EuiSelectableTemplateSitewideOption,
  euiSelectableTemplateSitewideRenderOptions, // htmlIdGenerator,
} from "@elastic/eui";
import { ChangeEventHandler, ReactNode, useState } from "react";
import { useDebounce } from "use-debounce";

import usePolicyQuery from "../hooks/queries/usePolicyQuery";

type PolicyAutoCompleteFieldProps = {
  query: string;
  onSearchChange: ChangeEventHandler<HTMLInputElement>;
  onSearchSelected: (text: string) => void;
};

const PolicyAutoCompleteField: React.FC<PolicyAutoCompleteFieldProps> = ({
  query,
  onSearchChange,
  onSearchSelected,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const [debouncedQuery] = useDebounce(query, 400);
  let enableQuery = false;
  if (debouncedQuery.length > 2) {
    enableQuery = true;
  }
  const { data } = usePolicyQuery(debouncedQuery, enableQuery);
  const toggleIsPopoverOpen = (shouldBeOpen = !isPopoverOpen) => {
    setIsPopoverOpen(shouldBeOpen);
  };

  let resultSet: EuiSelectableTemplateSitewideOption[] = [];
  if (data) {
    data.forEach((element) => {
      resultSet.push({
        label: `${element.policyID}`,
        key: `policy*${element.recordID}`,
        icon: {
          type: "home",
        },
        meta: [
          // {
          //   text: "Policy Type",
          //   type: "deployment",
          // },
          {
            text: element.policyTypeDisplay,
            type: "deployment",
          },
          // {
          //   text: "Address",
          //   type: "deployment",
          // },
          {
            text: `${element.locations[0]?.address1 || ""}, ${element.locations[0]?.city || ""}, ${
              element.locations[0]?.state || ""
            }`,
            type: "application",
          },
        ],
      });
    });
  }
  const formattedOptions = euiSelectableTemplateSitewideFormatOptions(resultSet);
  // console.log(formattedOptions);
  // let resultSet: any = [];
  // if (data) {
  //   data.forEach((element: any) => {
  //     resultSet.push({
  //       // label: "Bryan Rice",
  //       label: `${element.firstName} ${element.lastName}, ${element.city}`,
  //       title: "Something",
  //       className: "euiSelectableTemplateSitewide__listItem",
  //       key: htmlIdGenerator()(),
  //       prepend: <EuiIcon color="subdued" size="l" type="user" />,
  //       icon: {
  //         type: "home",
  //       },
  //       meta: element,
  //     });
  //   });
  // }
  const input = (
    <EuiFieldSearch
      fullWidth
      onBlur={() => {
        setSelectedOption(false);
      }}
      onFocus={() => {
        if (!selectedOption) {
          toggleIsPopoverOpen(true);
        }
      }}
      onKeyDown={(event) => {
        if (event.key !== "Tab") {
          toggleIsPopoverOpen(true);
        }
      }}
      value={query}
      onChange={onSearchChange}
    />
  );

  const onChange = (updatedOptions: any[]) => {
    const clickedItem = updatedOptions.find((option) => option.checked === "on");
    if (!clickedItem) return;
    onSearchSelected(clickedItem);
    setSelectedOption(true);
    setIsPopoverOpen(false);
  };

  return (
    <EuiInputPopover
      fullWidth
      input={input}
      isOpen={isPopoverOpen}
      closePopover={() => {
        toggleIsPopoverOpen(false);
      }}
    >
      <EuiSelectable
        aria-label="Basic example"
        className="euiSelectableTemplateSitewide__list"
        options={formattedOptions}
        onChange={onChange}
        singleSelection="always"
        renderOption={euiSelectableTemplateSitewideRenderOptions}
        listProps={{
          rowHeight: 68,
          showIcons: false,
        }}
      >
        {(list) => list}
      </EuiSelectable>
    </EuiInputPopover>
    // <Fragment>{input}</Fragment>

    // <AddressAutoCompleteField query={myField} onSearchChange={(value) => dispatch(updatePropertyAddress(value))} />
  );
};
export default PolicyAutoCompleteField;

export const euiSelectableTemplateSitewideFormatOptions = (options: EuiSelectableTemplateSitewideOption[]) => {
  return options.map((item: EuiSelectableTemplateSitewideOption) => {
    let title = item.label;
    if (item.meta && item.meta.length) {
      title += ` •${renderOptionMeta(item.meta, "", true)}`;
    }

    return {
      key: item.label,
      title,
      ...item,
      className: "euiSelectableTemplateSitewide__listItem",
      prepend: item.icon ? <EuiIcon color="subdued" size="l" {...item.icon} /> : item.prepend,
      append: item.avatar ? <EuiAvatar type="space" size="s" {...item.avatar} /> : item.append,
    };
  });
};

function renderOptionMeta(
  meta?: EuiSelectableTemplateSitewideMetaData[],
  searchValue: string = "",
  stringsOnly: boolean = false
): ReactNode {
  if (!meta || meta.length < 1) return;
  const metas: ReactNode = meta.map((meta: EuiSelectableTemplateSitewideMetaData) => {
    const { text, highlightSearchString, className, ...rest } = meta;
    if (stringsOnly) {
      return ` ${text}`;
    }

    // Start with the base and custom classes
    let metaClasses = "euiSelectableTemplateSitewide__optionMeta";

    // If they provided a type, create the class and append
    if (meta.type) {
      metaClasses = `euiSelectableTemplateSitewide__optionMeta--${meta.type}`;
    }

    return (
      <EuiHighlight search={highlightSearchString ? searchValue : ""} className={metaClasses} key={text} {...rest}>
        {text}
      </EuiHighlight>
    );
  });

  return stringsOnly ? metas : <span className="euiSelectableTemplateSitewide__optionMetasList">{metas}</span>;
}
