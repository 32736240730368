import { Invoice } from "types/apiTypes";

interface PaymentAmountInterface {
  paymentPlan: string;
  //   planOptions: number;
  waivedLateFee: boolean;
  invoice: Invoice;
}

export default function PaymentAmount({ paymentPlan, waivedLateFee, invoice }: PaymentAmountInterface) {
  let payment = 0;
  if (paymentPlan === "-1") {
    payment = invoice.installmentPlanPayoff;
  } else {
    payment = invoice.planOptions[parseInt(paymentPlan, 10)][0];
  }
  if (waivedLateFee) {
    return payment - 20;
  }
  return payment;
}
