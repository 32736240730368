import { EuiDescribedFormGroup, EuiFormRow } from "@elastic/eui";
import { Fragment, FunctionComponent } from "react";
import { get, useFormState, useWatch } from "react-hook-form";

import { FormInputsType } from "./FormDataTypes";
import RaterFormInput from "./RaterFormInput";

type RaterFormInputsProps = { formPrefix: string; inputs: FormInputsType[]; description: string };

const RaterFormInputs: FunctionComponent<RaterFormInputsProps> = ({ formPrefix, inputs, description }) => {
  const formInputValues = useWatch({ name: `${formPrefix}.fields` as any });
  const { errors } = useFormState(`${formPrefix}.fields` as any);
  function calculateVisibleOptions(inputs: FormInputsType[]) {
    const returnArray: FormInputsType[] = [];
    if (!inputs) return returnArray;
    inputs.forEach((input: any) => {
      if (!input.requires) {
        returnArray.push(input);
      } else {
        if (Array.isArray(input.requires)) {
          if (
            input.requires.every((item: any) => {
              if (Array.isArray(item)) {
                return item.some((orItem: any) => {
                  return formInputValues[orItem.option] === orItem.value;
                });
              }
              return formInputValues[item.option] === item.value;
            })
          ) {
            returnArray.push(input);
          }
        } else {
          if (formInputValues[input.requires.option] === input.requires.value) {
            returnArray.push(input);
          }
        }
      }
    });
    return returnArray;
  }
  const selectedInputs = calculateVisibleOptions(inputs);
  return (
    <EuiDescribedFormGroup
      fullWidth
      title={<span></span>}
      description={<Fragment>{description}</Fragment>}
      // fieldFlexItemProps={{ style: { flexGrow: 2 } }}
    >
      {selectedInputs.map((input, index) => {
        const inputType: string = input.type;
        const inputError = get(errors, `${formPrefix}.fields.${input.name}`);
        if (inputType === "check") {
          return (
            <EuiFormRow fullWidth key={input.name} isInvalid={!!inputError && true} error={inputError?.message && true}>
              <RaterFormInput
                input={input}
                name={`${formPrefix}.fields.${input.name}`}
                isInvalid={!!inputError && true}
              />
            </EuiFormRow>
          );
        }
        return (
          <EuiFormRow
            fullWidth
            label={input.label}
            key={input.name}
            isInvalid={!!inputError && true}
            error={inputError?.message}
          >
            <RaterFormInput
              input={input}
              name={`${formPrefix}.fields.${input.name}`}
              isInvalid={!!inputError && true}
            />
          </EuiFormRow>
        );
      })}
    </EuiDescribedFormGroup>
  );
};

export default RaterFormInputs;
