import {
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiSpacer,
  EuiTextColor,
} from "@elastic/eui";
import { useAPIURL } from "hooks/useAPIURL";

import construction from "../../img/marketing/construction.jpg";
import credit from "../../img/marketing/credit-card.jpg";
import flood from "../../img/marketing/flood.jpg";
import home from "../../img/marketing/home.jpg";
import kitchen from "../../img/marketing/kitchen.jpg";
import vacuum from "../../img/marketing/vacuum.jpg";

const Marketing: React.FC = () => {
  const defaultURL = useAPIURL();
  const data = [
    {
      id: 1,
      title: "Inland Flood",
      description: (
        <>
          <EuiTextColor color="subdued">WCIC-1100 (ed. 04/16)</EuiTextColor>
          <br />
          <EuiTextColor color="subdued">WCIC-1200 (ed. 04/16)</EuiTextColor>
        </>
      ),
      photo: flood,
      link: "202203_WCIC1100.pdf",
    },
    {
      id: 2,
      title: "Added Water Damages",
      description: (
        <>
          <EuiTextColor color="subdued">ML-72A (ed. 05/13)</EuiTextColor>
          <br />
          <EuiTextColor color="subdued">FL-72A (ed. 05/13)</EuiTextColor>
        </>
      ),
      photo: vacuum,
      link: "202203_MLFL72A.pdf",
    },

    {
      id: 3,
      title: "Equipment Breakdown",
      description: (
        <>
          <EuiTextColor color="subdued">ML-346BG (ed. 09/13)</EuiTextColor>
          <br />
          <EuiTextColor color="subdued">FL-345 (ed. 12/17)</EuiTextColor>
          <br />
          <EuiTextColor color="subdued">ML-345 (ed. 12/17)</EuiTextColor>
        </>
      ),
      photo: kitchen,
      link: "BR013_EquipmentBreakdown_All.pdf",
    },
    {
      id: 4,
      title: "Theft of Building Materials",
      description: (
        <>
          <EuiTextColor color="subdued">ML-17 (ed. 06/99)</EuiTextColor>
        </>
      ),
      photo: home,
      link: "202203_ML17.pdf",
    },
    {
      id: 5,
      title: "Identity Theft & Fraud Recovery",
      description: (
        <>
          <EuiTextColor color="subdued">WCIC-1001 (ed. 09/11)</EuiTextColor>
        </>
      ),
      photo: credit,
      link: "202203_IDFR.pdf",
    },
    {
      id: 6,
      title: "Underground Utility Line",
      description: (
        <>
          <EuiTextColor color="subdued">ML-342 (ed. 07/2014)</EuiTextColor>
          <br />
          <EuiTextColor color="subdued">FL-342 (ed. 04/16)</EuiTextColor>
        </>
      ),
      photo: construction,
      link: "202203_MLFL342.pdf",
    },
  ];

  return (
    <>
      <EuiPage>
        <EuiPageBody restrictWidth="1000px">
          <EuiPageHeader iconType="visBarVerticalStacked" pageTitle="Marketing" />

          <EuiFlexGroup wrap>
            {data.map((element) => {
              return (
                <EuiFlexItem grow={false} key={element.id} style={{ width: "300px", maxWidth: "500px" }}>
                  <EuiSpacer size="m" />
                  <EuiCard
                    textAlign="left"
                    image={
                      <EuiImage
                        alt="add a element.alt description..."
                        src={element.photo}
                        // src={someSrc}
                      />
                    }
                    // image="https://source.unsplash.com/400x200/?Nature"
                    title={element.title}
                    description={element.description}
                    footer={
                      <EuiFlexGroup justifyContent="flexEnd">
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            onClick={() => {
                              window.open(`${defaultURL}/marketing/${element.link}`, "_blank");
                            }}
                          >
                            More info
                          </EuiButton>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    }
                  />
                </EuiFlexItem>
              );
            })}
          </EuiFlexGroup>
        </EuiPageBody>
      </EuiPage>
    </>
  );
};

export default Marketing;
