import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiCard,
  EuiDescribedFormGroup,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiSelect,
  EuiSelectOption,
  EuiSpacer,
  EuiSwitch,
  EuiTitle,
  htmlIdGenerator,
} from "@elastic/eui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Fragment, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useFieldArray, useForm, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";

import { FormInputText } from "../../../../components/react-hook-form/FormInput";
import authAxios from "../../../../services/authAxios";
import { RCschema } from "../schemas/RCEstimator.schema";
import { RCInputs } from "./RCEstimator.types";

export const defaultValues: RCInputs = {
  name: "",
  streetAddress: "",
  zip: undefined,
  effectiveDate: "",
  constructionYear: undefined,
  squareFootage: 0,
  constructionClass: 0,
  stories: 1,
  roofType: 1,
  laborType: 2,
  exteriorFinish: {
    siding: 0,
    stucco: 0,
    brickVeneer: 0,
    concrete: 0,
    brickConcrete: 0,
    stoneVeneer: 0,
  },
  basement: {
    slab: 0,
    concrete: 0,
    reinforcedConcrete: 0,
    partiallyFinished: 0,
    recRoom: 0,
    finished: 0,
  },
  multiFamily: {
    additionalEntry: 0,
    additionalKitchen: 0,
    heat: 0,
    heatAC: 0,
  },
  attic: 0,
  fullBath: 1, // check with Bryan on this logic
  halfBath: 0,
  openBreezeway: 0,
  closedBreezeway: 0,
  carport1Bay: 0,
  carport2Bay: 0,
  garage1: 0,
  garage2: 0,
  garage3: 0,
  garageFinish: 0,
  centralAir: false,
  centralVac: false,
  deck: 0,
  dormersDecorative: 0,
  fireplaceMasonry: 0,
  fireplaceOther: 0,
  generator: false,
  hotWaterHeat: false,
  hotTub: 0,
  kitchenUpgrade: 1,
  mudrooms: 0,
  laundry: 0,
  radiantHeat: 0,
  securitySystem: false,
  porch: [],
  dormers: [],
};
const RCEstimator = ({ onFinishEstimator, initialData }: any) => {
  const [isRCEModalOpen, setIsRCEModalOpen] = useState(false);
  const openRCEInfoModal = () => setIsRCEModalOpen(true);
  const closeRCEInfoModal = () => setIsRCEModalOpen(false);
  const {
    formState: { errors },
    handleSubmit,
    control,
    trigger,
    getValues,
    setValue,
  } = useFormContext<RCInputs>();

  if (initialData !== undefined) {
    setValue("name", initialData.insuredName);
    setValue("streetAddress", initialData.streetAddress);
    setValue("zip", initialData.zip);
    setValue("constructionYear", initialData.constructionYear);
    setValue("effectiveDate", initialData.effectiveDate);
  }

  const {
    fields: porchItems,
    append: appendPorch,
    remove: removePorch,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "porch", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });
  const {
    fields: dormerItems,
    append: appendDormer,
    remove: removeDormer,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "dormers", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const onSubmit: SubmitHandler<RCInputs> = (data) => {
    authAxios.post("/rce", data).then((res) => {
      if (onFinishEstimator) {
        onFinishEstimator(data, res?.data);
      }
    });
  };

  return (
    <form id="rcestimator" onSubmit={handleSubmit(onSubmit)}>
      <EuiPanel hasBorder>
        <EuiDescribedFormGroup title={<h3>Property Basics</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow fullWidth label="Insured Name" isInvalid={errors.name && true} error={errors.name?.message}>
            <FormInputText
              name={`name`}
              label={"Insured Name"}
              rules={{ required: true }}
              isInvalid={errors.name && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Street Address"
            isInvalid={errors.streetAddress && true}
            error={errors.streetAddress?.message}
          >
            <FormInputText
              name={`streetAddress`}
              label={"Street Address"}
              rules={{ required: true }}
              isInvalid={errors.streetAddress && true}
            />
          </EuiFormRow>

          <EuiFormRow fullWidth label="Zip" isInvalid={errors.zip && true} error={errors.zip?.message}>
            <NumberInput name={`zip`} thousandSeparator={false} isInvalid={errors.zip && true} />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Construction Year"
            isInvalid={errors.constructionYear && true}
            error={errors.constructionYear?.message}
          >
            <NumberInput
              name={`constructionYear`}
              thousandSeparator={false}
              isInvalid={errors.constructionYear && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Square Footage"
            isInvalid={errors.squareFootage && true}
            error={errors.squareFootage?.message}
          >
            <NumberInput name={`squareFootage`} isInvalid={errors.squareFootage && true} />
          </EuiFormRow>
          <Controller
            name={`constructionClass`}
            render={({ field }) => {
              const { onChange, onBlur, value, ref, ...rest } = field;
              return (
                <EuiFormRow
                  isInvalid={errors.constructionClass && true}
                  error={errors.constructionClass?.message}
                  fullWidth
                  label="Class of Replacement"
                  labelAppend={
                    <EuiButtonIcon
                      iconType="iInCircle"
                      aria-label={"Replacement class descriptions"}
                      onClick={openRCEInfoModal}
                    />
                  }
                >
                  <EuiSelect
                    fullWidth
                    onBlur={onBlur}
                    options={[
                      { value: 0, text: "Select a class" },
                      { value: 1, text: "Economy" },
                      { value: 2, text: "Standard" },
                      { value: 3, text: "Custom" },
                      { value: 4, text: "Estate" },
                    ]}
                    value={value}
                    onChange={(e) => {
                      const v = parseInt(e.target.value, 10);
                      onChange(v);
                      if (v === 1) {
                        setValue("fullBath", 1);
                        setValue("halfBath", 0);
                        setValue("centralAir", false);
                      }
                      // Standard
                      if (v === 2) {
                        setValue("fullBath", 1);
                        setValue("halfBath", 1);
                        setValue("centralAir", false);
                      }
                      // Custom
                      if (v === 3) {
                        setValue("fullBath", 2);
                        setValue("halfBath", 1);
                        setValue("centralAir", true);
                      }
                      // Estate
                      if (v === 4) {
                        setValue("fullBath", 3);
                        setValue("halfBath", 0);
                        setValue("centralAir", true);
                      }
                    }}
                    {...rest}
                    inputRef={ref}
                  />
                </EuiFormRow>
              );
            }}
          />
          <EuiFormRow fullWidth label="Number of Stories">
            <SelectInput
              name={`stories`}
              options={[
                { value: 1, text: "1" },
                { value: 2, text: "1.5" },
                { value: 3, text: "2" },
                { value: 4, text: "2.5" },
                { value: 5, text: "Bi-level" },
                { value: 6, text: "Tri-level" },
              ]}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label="Roof Material">
            <SelectInput
              name={`roofType`}
              options={[
                { value: 1, text: "Shingle" },
                { value: 2, text: "Clay Tile" },
                { value: 3, text: "Wood Shake" },
                { value: 4, text: "Metal" },
                { value: 5, text: "Slate" },
                { value: 6, text: "Architectural Shingles" },
              ]}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label="Labor">
            <SelectInput
              name={`laborType`}
              options={[
                { value: 1, text: "Unionized Labor" },
                { value: 2, text: "Non-Unionized Labor" },
              ]}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
      </EuiPanel>
      <EuiSpacer />
      <EuiPanel hasBorder>
        <EuiDescribedFormGroup
          title={<h3>Exterior Finish</h3>}
          description="Finishes applied to the exterior of the house.  This is entered as a percentage of the total house."
          className="wcicRaterDescribedFormGroup"
        >
          <EuiFormRow
            fullWidth
            label="Wood, vinyl or aluminum siding"
            display="columnCompressed"
            isInvalid={errors.exteriorFinish?.siding && true}
            error={errors.exteriorFinish?.siding?.message}
          >
            <NumberInput
              name={`exteriorFinish.siding`}
              thousandSeparator={false}
              onBlurCustom={() => {
                trigger("exteriorFinish");
              }}
              append="%"
              isInvalid={errors.exteriorFinish?.siding && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Stucco on stud frame"
            display="columnCompressed"
            isInvalid={errors.exteriorFinish?.stucco && true}
            error={errors.exteriorFinish?.stucco?.message}
          >
            <NumberInput
              name={`exteriorFinish.stucco`}
              thousandSeparator={false}
              onBlurCustom={() => {
                trigger("exteriorFinish");
              }}
              append="%"
              isInvalid={errors.exteriorFinish?.stucco && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Brick veneer on stud frame"
            display="columnCompressed"
            isInvalid={errors.exteriorFinish?.brickVeneer && true}
            error={errors.exteriorFinish?.brickVeneer?.message}
          >
            <NumberInput
              name={`exteriorFinish.brickVeneer`}
              thousandSeparator={false}
              onBlurCustom={() => {
                trigger("exteriorFinish");
              }}
              append="%"
              isInvalid={errors.exteriorFinish?.brickVeneer && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Concrete block"
            display="columnCompressed"
            isInvalid={errors.exteriorFinish?.concrete && true}
            error={errors.exteriorFinish?.concrete?.message}
          >
            <NumberInput
              name={`exteriorFinish.concrete`}
              thousandSeparator={false}
              onBlurCustom={() => {
                trigger("exteriorFinish");
              }}
              append="%"
              isInvalid={errors.exteriorFinish?.concrete && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Brick with concrete block back"
            display="columnCompressed"
            isInvalid={errors.exteriorFinish?.brickConcrete && true}
            error={errors.exteriorFinish?.brickConcrete?.message}
          >
            <NumberInput
              name={`exteriorFinish.brickConcrete`}
              thousandSeparator={false}
              onBlurCustom={() => {
                trigger("exteriorFinish");
              }}
              append="%"
              isInvalid={errors.exteriorFinish?.brickConcrete && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Stone veneer on stud frame"
            display="columnCompressed"
            isInvalid={errors.exteriorFinish?.stoneVeneer && true}
            error={errors.exteriorFinish?.stoneVeneer?.message}
          >
            <NumberInput
              name={`exteriorFinish.stoneVeneer`}
              thousandSeparator={false}
              onBlurCustom={() => {
                trigger("exteriorFinish");
              }}
              append="%"
              isInvalid={errors.exteriorFinish?.stoneVeneer && true}
            />
          </EuiFormRow>

          <EuiFormRow
            fullWidth
            label="Total"
            display="columnCompressed"
            isInvalid={errors.exteriorFinish && true}
            error={"Exterior finish must add to 100"}
          >
            <NumberFormat
              customInput={EuiFieldText}
              thousandSeparator={false}
              disabled
              value={
                getValues("exteriorFinish.siding") +
                getValues("exteriorFinish.stucco") +
                getValues("exteriorFinish.brickVeneer") +
                getValues("exteriorFinish.concrete") +
                getValues("exteriorFinish.brickConcrete") +
                getValues("exteriorFinish.stoneVeneer")
              }
              append="%"
              isInvalid={errors.exteriorFinish && true}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
      </EuiPanel>
      <EuiSpacer />
      <EuiPanel hasBorder>
        <EuiTitle>
          <h2>Basement</h2>
        </EuiTitle>
        <EuiSpacer />
        <EuiDescribedFormGroup title={<h3>Type of basement</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow
            fullWidth
            label="No Basement - Slab"
            isInvalid={errors.basement?.slab && true}
            error={errors.basement?.slab?.message}
          >
            <NumberInput
              name={`basement.slab`}
              thousandSeparator={true}
              append="Sq Ft"
              isInvalid={errors.basement?.slab && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Concrete Block"
            isInvalid={errors.basement?.concrete && true}
            error={errors.basement?.concrete?.message}
          >
            <NumberInput
              name={`basement.concrete`}
              thousandSeparator={true}
              append="Sq Ft"
              isInvalid={errors.basement?.concrete && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Reinforced Concrete"
            isInvalid={errors.basement?.reinforcedConcrete && true}
            error={errors.basement?.reinforcedConcrete?.message}
          >
            <NumberInput
              name={`basement.reinforcedConcrete`}
              thousandSeparator={true}
              append="Sq Ft"
              isInvalid={errors.basement?.reinforcedConcrete && true}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup
          title={<h3>Additional Finishings</h3>}
          description=""
          className="wcicRaterDescribedFormGroup"
        >
          <EuiFormRow
            fullWidth
            label="Partially Finished"
            isInvalid={errors.basement?.partiallyFinished && true}
            error={errors.basement?.partiallyFinished?.message}
          >
            <NumberInput
              name={`basement.partiallyFinished`}
              thousandSeparator={true}
              append="Sq Ft"
              isInvalid={errors.basement?.partiallyFinished && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Recreation Room"
            isInvalid={errors.basement?.recRoom && true}
            error={errors.basement?.recRoom?.message}
          >
            <NumberInput
              name={`basement.recRoom`}
              thousandSeparator={true}
              append="Sq Ft"
              isInvalid={errors.basement?.recRoom && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Finished"
            isInvalid={errors.basement?.finished && true}
            error={errors.basement?.finished?.message}
          >
            <NumberInput
              name={`basement.finished`}
              thousandSeparator={true}
              append="Sq Ft"
              isInvalid={errors.basement?.finished && true}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
      </EuiPanel>
      <EuiSpacer />
      <EuiPanel hasBorder>
        <EuiTitle>
          <h2>Additional Features</h2>
        </EuiTitle>
        <EuiSpacer />

        <EuiDescribedFormGroup title={<h3>Attic</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow
            fullWidth
            label="Attic Finished Full"
            display="columnCompressed"
            isInvalid={errors.attic && true}
            error={errors.attic?.message}
          >
            <NumberInput name={`attic`} thousandSeparator={true} append="Sq Ft" isInvalid={errors.attic && true} />
          </EuiFormRow>
        </EuiDescribedFormGroup>

        <EuiDescribedFormGroup title={<h3>Bathrooms</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow
            fullWidth
            label="Full Bath"
            display="columnCompressed"
            isInvalid={errors.fullBath && true}
            error={errors.fullBath?.message}
          >
            <NumberInput
              name={`fullBath`}
              thousandSeparator={true}
              append="Count"
              isInvalid={errors.fullBath && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Half Bath"
            display="columnCompressed"
            isInvalid={errors.halfBath && true}
            error={errors.halfBath?.message}
          >
            <NumberInput
              name={`halfBath`}
              thousandSeparator={true}
              append="Count"
              isInvalid={errors.halfBath && true}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>Breezeway</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow
            fullWidth
            label="Open"
            display="columnCompressed"
            isInvalid={errors.openBreezeway && true}
            error={errors.openBreezeway?.message}
          >
            <NumberInput
              name={`openBreezeway`}
              thousandSeparator={true}
              append="Sq Ft"
              isInvalid={errors.openBreezeway && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Enclosed"
            display="columnCompressed"
            isInvalid={errors.closedBreezeway && true}
            error={errors.closedBreezeway?.message}
          >
            <NumberInput
              name={`closedBreezeway`}
              thousandSeparator={true}
              append="Sq Ft"
              isInvalid={errors.closedBreezeway && true}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>Carport</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow
            fullWidth
            label="1 Car"
            display="columnCompressed"
            isInvalid={errors.carport1Bay && true}
            error={errors.carport1Bay?.message}
          >
            <NumberInput
              name={`carport1Bay`}
              thousandSeparator={true}
              append="Count"
              isInvalid={errors.carport1Bay && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="2 Car"
            display="columnCompressed"
            isInvalid={errors.carport2Bay && true}
            error={errors.carport2Bay?.message}
          >
            <NumberInput
              name={`carport2Bay`}
              thousandSeparator={true}
              append="Count"
              isInvalid={errors.carport2Bay && true}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup
          title={<h3>Garage - Attached</h3>}
          description=""
          className="wcicRaterDescribedFormGroup"
        >
          <EuiFormRow
            fullWidth
            label="1 Car"
            display="columnCompressed"
            isInvalid={errors.garage1 && true}
            error={errors.garage1?.message}
          >
            <NumberInput name={`garage1`} thousandSeparator={true} append="Sq Ft" isInvalid={errors.garage1 && true} />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="2 Car"
            display="columnCompressed"
            isInvalid={errors.garage2 && true}
            error={errors.garage2?.message}
          >
            <NumberInput name={`garage2`} thousandSeparator={true} append="Sq Ft" isInvalid={errors.garage2 && true} />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="3 Car"
            display="columnCompressed"
            isInvalid={errors.garage3 && true}
            error={errors.garage3?.message}
          >
            <NumberInput name={`garage3`} thousandSeparator={true} append="Sq Ft" isInvalid={errors.garage3 && true} />
          </EuiFormRow>
          <EuiFormRow fullWidth label="Finish" display="columnCompressed">
            <SelectInput
              name={`garageFinish`}
              options={[
                { value: 1, text: "Same as House" },
                { value: 2, text: "Brick veneer stud frame" },
                { value: 3, text: "Stucco on stud frame" },
                { value: 4, text: "Concrete Block" },
                { value: 5, text: "Brick with concrete block back" },
              ]}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup
          title={<h3>Central Air Conditioning</h3>}
          description=""
          className="wcicRaterDescribedFormGroup"
        >
          <EuiFormRow fullWidth label="Central Air" display="columnCompressedSwitch">
            <SwitchInput name={`centralAir`} label="" />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>Central Vacuum</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow fullWidth label="Central Vac" display="columnCompressedSwitch">
            <SwitchInput name={`centralVac`} label="" />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>Decks</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow
            fullWidth
            label="Wooden Decks"
            display="columnCompressed"
            isInvalid={errors.deck && true}
            error={errors.deck?.message}
          >
            <NumberInput name={`deck`} thousandSeparator={true} append="Sq Ft" isInvalid={errors.deck && true} />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup
          title={<h3>Porches and Patios</h3>}
          description=""
          className="wcicRaterDescribedFormGroup"
        >
          {porchItems.map((porch, index) => {
            return (
              <EuiFlexGroup key={porch.id}>
                <EuiFlexItem>
                  <EuiFormRow label="Type">
                    <SelectInput
                      name={`porch.${index}.type`}
                      options={[
                        { value: 1, text: "Open" },
                        { value: 2, text: "Enclosed" },
                      ]}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label="Size"
                    isInvalid={errors.porch?.[index]?.squareFoot && true}
                    error={errors.porch?.[index]?.squareFoot?.message}
                  >
                    <NumberInput
                      name={`porch.${index}.squareFoot`}
                      thousandSeparator={true}
                      append="Sq Ft"
                      isInvalid={errors.porch?.[index]?.squareFoot && true}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFormRow hasEmptyLabelSpace>
                    <EuiButtonIcon
                      iconType={"trash"}
                      aria-label={"Remove porch"}
                      color="danger"
                      onClick={() => removePorch(index)}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            );
          })}
          <EuiFormRow hasEmptyLabelSpace>
            <EuiButtonEmpty
              iconType={"plus"}
              aria-label={"Add porch"}
              onClick={() => appendPorch({ type: 1, squareFoot: 0 })}
            >
              Add Porch or Patio
            </EuiButtonEmpty>
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup
          title={<h3>Decorative Dormers</h3>}
          description=""
          className="wcicRaterDescribedFormGroup"
        >
          <EuiFormRow
            fullWidth
            label="Decorative Dormers"
            display="columnCompressed"
            isInvalid={errors.dormersDecorative && true}
            error={errors.dormersDecorative?.message}
          >
            <NumberInput
              name={`dormersDecorative`}
              thousandSeparator={true}
              append="Count"
              isInvalid={errors.dormersDecorative && true}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>Full Use Dormers</h3>} description="" className="wcicRaterDescribedFormGroup">
          {dormerItems.map((dormer, index) => {
            return (
              <Fragment key={dormer.id}>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow label="Type">
                      <SelectInput
                        name={`dormers.${index}.type`}
                        options={[
                          { value: 1, text: "Shed" },
                          { value: 2, text: "Gable" },
                        ]}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow
                      label="Size"
                      isInvalid={errors.dormers?.[index]?.squareFoot && true}
                      error={errors.dormers?.[index]?.squareFoot?.message}
                    >
                      <NumberInput
                        name={`dormers.${index}.squareFoot`}
                        thousandSeparator={true}
                        append="Sq Ft"
                        isInvalid={errors.dormers?.[index]?.squareFoot && true}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiFormRow hasEmptyLabelSpace>
                      <EuiButtonIcon
                        iconType={"trash"}
                        aria-label={"Remove dormer"}
                        color="danger"
                        onClick={() => removeDormer(index)}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer size="s" />
              </Fragment>
            );
          })}
          <EuiFormRow hasEmptyLabelSpace>
            {/* <EuiButtonIcon
              iconType={"plus"}
              aria-label={"Add dormer"}
              onClick={() => appendDormer({ type: 1, squareFoot: 0 })}
            /> */}
            <EuiButtonEmpty
              iconType={"plus"}
              aria-label={"Add dormer"}
              onClick={() => appendDormer({ type: 1, squareFoot: 0 })}
            >
              Dormer
            </EuiButtonEmpty>
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>Fireplaces</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow
            fullWidth
            label="Masonry"
            display="columnCompressed"
            isInvalid={errors.fireplaceMasonry && true}
            error={errors.fireplaceMasonry?.message}
          >
            <NumberInput
              name={`fireplaceMasonry`}
              thousandSeparator={true}
              append="Count"
              isInvalid={errors.fireplaceMasonry && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Other"
            display="columnCompressed"
            isInvalid={errors.fireplaceOther && true}
            error={errors.fireplaceOther?.message}
          >
            <NumberInput
              name={`fireplaceOther`}
              thousandSeparator={true}
              append="Count"
              isInvalid={errors.fireplaceOther && true}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup
          title={<h3>Whole House Generator</h3>}
          description=""
          className="wcicRaterDescribedFormGroup"
        >
          <EuiFormRow fullWidth label="Whole House Generator" display="columnCompressedSwitch">
            <SwitchInput name={`generator`} label="" />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>Hot Water Heat</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow fullWidth label="Hot Water Heat" display="columnCompressedSwitch">
            <SwitchInput name={`hotWaterHeat`} label="" />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>Hot Tub</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow
            fullWidth
            label="Hot Tub"
            display="columnCompressed"
            isInvalid={errors.hotTub && true}
            error={errors.hotTub?.message}
          >
            <NumberInput name={`hotTub`} thousandSeparator={true} append="Count" isInvalid={errors.hotTub && true} />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>Kitchen Upgrade</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow fullWidth label="Kitchen Upgrade" display="columnCompressedSwitch">
            <SelectInput
              name={`kitchenUpgrade`}
              options={[
                { value: 1, text: "None" },
                { value: 2, text: "Minor" },
                { value: 3, text: "Average" },
                { value: 4, text: "Major" },
              ]}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>

        <EuiDescribedFormGroup title={<h3>Mudroom</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow
            fullWidth
            label="Mudroom"
            display="columnCompressed"
            isInvalid={errors.mudrooms && true}
            error={errors.mudrooms?.message}
          >
            <NumberInput
              name={`mudrooms`}
              thousandSeparator={true}
              append="Sq Ft"
              isInvalid={errors.mudrooms && true}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>Laundry</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow
            fullWidth
            label="Laundry"
            display="columnCompressed"
            isInvalid={errors.laundry && true}
            error={errors.laundry?.message}
          >
            <NumberInput name={`laundry`} thousandSeparator={true} append="Sq Ft" isInvalid={errors.laundry && true} />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>Radiant Heat</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow
            fullWidth
            label="Radiant Heat"
            display="columnCompressed"
            isInvalid={errors.radiantHeat && true}
            error={errors.radiantHeat?.message}
          >
            <NumberInput
              name={`radiantHeat`}
              thousandSeparator={true}
              append="Sq Ft"
              isInvalid={errors.radiantHeat && true}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>Security System</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow fullWidth label="Security System" display="columnCompressedSwitch">
            <SwitchInput name={`securitySystem`} label="" />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiDescribedFormGroup title={<h3>Multi-Residence</h3>} description="" className="wcicRaterDescribedFormGroup">
          <EuiFormRow
            fullWidth
            label="Additional Entries"
            display="columnCompressed"
            isInvalid={errors.multiFamily?.additionalEntry && true}
            error={errors.multiFamily?.additionalEntry?.message}
          >
            <NumberInput
              name={`multiFamily.additionalEntry`}
              thousandSeparator={true}
              append="Count"
              isInvalid={errors.multiFamily?.additionalEntry && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Additional Kitchens"
            display="columnCompressed"
            isInvalid={errors.multiFamily?.additionalKitchen && true}
            error={errors.multiFamily?.additionalKitchen?.message}
          >
            <NumberInput
              name={`multiFamily.additionalKitchen`}
              thousandSeparator={true}
              append="Count"
              isInvalid={errors.multiFamily?.additionalKitchen && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Heat and Electric"
            display="columnCompressed"
            isInvalid={errors.multiFamily?.heat && true}
            error={errors.multiFamily?.heat?.message}
          >
            <NumberInput
              name={`multiFamily.heat`}
              thousandSeparator={true}
              append="Count"
              isInvalid={errors.multiFamily?.heat && true}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label="Heat and Electric with AC"
            display="columnCompressed"
            isInvalid={errors.multiFamily?.heatAC && true}
            error={errors.multiFamily?.heatAC?.message}
          >
            <NumberInput
              name={`multiFamily.heatAC`}
              thousandSeparator={true}
              append="Count"
              isInvalid={errors.multiFamily?.heatAC && true}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
      </EuiPanel>
      {isRCEModalOpen ? <ReplacementCostInfoModal onClose={closeRCEInfoModal} /> : ""}
    </form>
  );
};

export default RCEstimator;

const NumberInput = ({
  name,
  decimalScale = 0,
  thousandSeparator = true,
  append,
  isInvalid = false,
  onBlurCustom = () => {},
}: {
  name: string;
  decimalScale?: number;
  thousandSeparator?: boolean;
  append?: string;
  isInvalid?: boolean;
  onBlurCustom?: () => void;
}) => {
  return (
    <Controller
      name={`${name}` as const}
      // rules={rules}
      render={({ field }) => {
        const { onChange, value, ref, onBlur, ...rest } = field;
        return (
          <NumberFormat
            customInput={EuiFieldText}
            fullWidth
            thousandSeparator={thousandSeparator}
            decimalScale={decimalScale}
            append={append}
            value={value}
            onValueChange={(target) => {
              if (target.value === "") {
                onChange(null);
              } else {
                onChange(parseInt(target.value, 10));
              }
            }}
            onBlur={(event: any) => {
              if (event.target.value === "") {
                onChange(0);
              }
              onBlur();
              onBlurCustom();
            }}
            {...rest}
            inputRef={ref}
            isInvalid={isInvalid}
          />
        );
      }}
    />
  );
};

const SelectInput = ({ name, options }: { name: string; options: EuiSelectOption[] }) => {
  return (
    <Controller
      name={`${name}` as const}
      // rules={rules}
      render={({ field }) => {
        const { onChange, value, ref, ...rest } = field;
        return (
          <EuiSelect
            fullWidth
            options={options}
            value={value}
            onChange={(e) => {
              onChange(parseInt(e.target.value, 10));
            }}
            {...rest}
            inputRef={ref}
          />
        );
      }}
    />
  );
};

const SwitchInput = ({ name, label }: { name: string; label: string }) => {
  return (
    <Controller
      name={`${name}` as const}
      // rules={rules}
      render={({ field: { value, onChange } }) => (
        <EuiSwitch
          id={htmlIdGenerator()()}
          label={label}
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
      )}
    />
  );
};

export const RCEstimatorFormProvider = ({ defaultInputValues, children }: any) => {
  const methods = useForm<RCInputs>({
    mode: "onTouched",
    resolver: yupResolver(RCschema),
    defaultValues: defaultInputValues ? defaultInputValues : defaultValues,
  });
  return <FormProvider {...methods}>{children}</FormProvider>;
};

const ReplacementCostInfoModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Replacement Classes</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiSpacer />
        <EuiFlexGroup direction="column" gutterSize="l">
          <EuiFlexItem key="economy">
            <EuiCard
              layout="horizontal"
              icon={<EuiIcon size="xl" type="documents" />}
              title={"Economy"}
              display="subdued"
              description="This class is constructed to meet minimum code requirements and to
                  provide adequate accommodations. Simple construction with minimal amenities. Few, if any,
                  upgrades. Residence typically includes kitchen, living room, 1 full bath and 2 bedrooms. Hot
                  air heat. No central air."
              // betaBadgeProps={{
              //   label: "A label",
              // }}
            />
          </EuiFlexItem>
          <EuiFlexItem key="standard">
            <EuiCard
              layout="horizontal"
              icon={<EuiIcon size="xl" type="documents" />}
              title={"Standard"}
              display="subdued"
              description="This class is constructed to meet code requirements and to provide
              comfortable accommodations. There are usually several features that make the residence
              attractive from the exterior and the interior finishes may include some design enhancements.
              Standard construction with some amenities. Better quality finishes. Residence typically
              includes kitchen, living room, 1 1/2 baths, 3 bedrooms and dining ell. Hot air heat. No
              central air."
              // betaBadgeProps={{
              //   label: "A label",
              // }}
            />
          </EuiFlexItem>
          <EuiFlexItem key="custom">
            <EuiCard
              layout="horizontal"
              icon={<EuiIcon size="xl" type="documents" />}
              title={"Custom"}
              display="subdued"
              description="This class of residence is constructed to exceed code requirements
              and to provide attractive and comfortable accommodations. There are usually several special
              features that make the residence attractive from the exterior and the interior finishes are of
              high quality. Upscale amenities. Curb appeal. Architectural design. Residence typically
              includes kitchen, living room, 2 1/2 baths, 4 bedrooms, dining room, and family room. Hot air
              heat with central air."
              // betaBadgeProps={{
              //   label: "A label",
              // }}
            />
          </EuiFlexItem>
          <EuiFlexItem key="estate">
            <EuiCard
              layout="horizontal"
              icon={<EuiIcon size="xl" type="documents" />}
              title={"Estate"}
              display="subdued"
              description="This class of residence is constructed to meet the individual
              requirements of the design architect and/or owner. They normally exceed code requirements and
              provide unique, attractive and comfortable accommodations. There are usually many design
              features that make the residence attractive from the exterior and clearly give it a special
              identity. The interior finishes are superior. Lavish amenities. Great curb appeal. Residence
              typically includes kitchen, living room, 3 baths, 4 bedrooms, dining room, family room,
              solarium and great room. Hot air heat with central air."
              // betaBadgeProps={{
              //   label: "A label",
              // }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton onClick={onClose}>Continue</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
