import {
  Criteria,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPage,
  EuiPageBody,
  EuiPanel,
  EuiSelect,
  EuiSpacer,
  EuiTableSortingType,
  EuiText,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppSelector } from "app/hooks";
import { selectAgencyID, selectClusterAdministrator, selectClusterAgencies } from "features/authorization/authSlice";
import useReport from "hooks/queries/useReport";
import { useAPIURL } from "hooks/useAPIURL";
import moment from "moment";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import pageAndSort from "services/pageAndSort";
import { Receipt } from "types/apiTypes";
import * as yup from "yup";

function simpleNumber(num: number) {
  return (
    <NumberFormat
      value={num / 100}
      displayType="text"
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={true}
      prefix={"$"}
      renderText={(num) => {
        return <>{num}</>;
      }}
    />
  );
}

interface CommissionReportType {
  month: number;
  year: number;
  code: string;
}

const CommissionReportSchema = yup.object({
  month: yup.number().required().label("Month"),
  year: yup.number().required().label("Year"),
  code: yup.string().required().label("Agent Code"),
});

const Commissions = () => {
  const defaultURL = useAPIURL();
  const date = new Date();
  const agentID = useAppSelector(selectAgencyID);
  const clusterAdmin = useAppSelector(selectClusterAdministrator);
  const clusterAgents = useAppSelector(selectClusterAgencies);

  const [query, setQuery] = useState<CommissionReportType>({
    month: date.getMonth(), // Months are 0-11 so default is "last month" according to monthOptions
    year: date.getFullYear(),
    code: agentID,
  });
  const { data, isLoading, isFetching, isPlaceholderData } = useReport(query.month, query.year, query.code);
  // console.log("Reporting Data: ", data);

  const yearOptions = [
    { value: date.getFullYear(), text: String(date.getFullYear()) },
    { value: date.getFullYear() - 1, text: String(date.getFullYear() - 1) },
    { value: date.getFullYear() - 2, text: String(date.getFullYear() - 2) },
  ];
  const monthOptions = [
    { value: 1, text: "January" },
    { value: 2, text: "February" },
    { value: 3, text: "March" },
    { value: 4, text: "April" },
    { value: 5, text: "May" },
    { value: 6, text: "June" },
    { value: 7, text: "July" },
    { value: 8, text: "August" },
    { value: 9, text: "September" },
    { value: 10, text: "October" },
    { value: 11, text: "November" },
    { value: 12, text: "December" },
  ];

  const methods = useForm<CommissionReportType>({
    mode: "onTouched",
    resolver: yupResolver(CommissionReportSchema),
    defaultValues: {
      month: date.getMonth(), // Date is 0-11 for month values
      year: date.getFullYear(),
      code: agentID,
    },
  });
  const { handleSubmit, control } = methods;

  const onSubmit: SubmitHandler<CommissionReportType> = (data) => {
    // console.log("Handle submit", data);
    setQuery({ month: data.month, year: data.year, code: data.code });
  };

  const [sortField, setSortField] = useState<keyof Receipt>("receiptDate");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const columns: EuiBasicTableColumn<Receipt>[] = [
    // {
    //   field: "agentID",
    //   name: "Agent ID",

    // },
    {
      field: "policyID",
      name: "Policy ID",
      footer: () => {
        return (
          <EuiText size="m">
            <strong>Totals:</strong>
          </EuiText>
        );
      },
    },
    {
      field: "name",
      name: "Name",
    },
    {
      field: "policyInceptionDate",
      name: "Inception Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "invoiceID",
      name: "Invoice ID",
    },
    {
      field: "invoiceType",
      name: "Type",
      render: (invoiceType: string) => {
        switch (invoiceType) {
          case "R":
            return <EuiTextColor color="success">Renewal</EuiTextColor>;
          case "C":
            return <EuiTextColor color="danger">Cancellation</EuiTextColor>;
          case "D":
            return <EuiTextColor color="danger">Earned Premium</EuiTextColor>;
          case "I":
            return <EuiTextColor color="warning">Installment</EuiTextColor>;
          case "N":
            return <EuiTextColor color="success">New Policy</EuiTextColor>;
          case "E":
            return <EuiTextColor color="success">Endorsement</EuiTextColor>;
          case "W":
            return <EuiTextColor color="danger">Write Off</EuiTextColor>;

          default:
            return <EuiTextColor color="default">{invoiceType}</EuiTextColor>;
        }
      },
    },
    {
      field: "receiptDate",
      name: "Receipt Date",
      render: (date: string) => moment(date).format("l"),
    },

    {
      field: "premium",
      dataType: "number",
      name: "Premium",
      render: (amount: number) => simpleNumber(amount),
      footer: () => {
        return (
          <EuiText size="m">
            <strong>{simpleNumber(data.premium_sum)}</strong>
          </EuiText>
        );
      },
    },
    {
      field: "commission",
      dataType: "number",
      name: "Commission",
      render: (amount: number) => simpleNumber(amount),
      footer: () => {
        return (
          <EuiText size="m">
            <strong>{simpleNumber(data.commission_sum)}</strong>
          </EuiText>
        );
      },
    },
  ];
  const { pageOfItems } = pageAndSort(data.receipts, { sortField, sortDirection });

  const getRowProps = (receipt: Receipt) => {
    const { recordID } = receipt;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      // onClick: () => {
      //   console.log("clicked on this: ", receipt);
      // },
    };
  };

  const getCellProps = (receipt: Receipt, column: any) => {
    const { recordID } = receipt;
    const { field } = column;
    return {
      className: "wcicTableCellPreWrap",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };

  const sorting: EuiTableSortingType<Receipt> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
  };

  const onTableChange = ({ sort }: Criteria<Receipt>) => {
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
  };

  const onError = (errors: any, e: any) => console.log(errors, e);

  return (
    <EuiPage>
      <EuiPageBody restrictWidth="1300px">
        <EuiTitle>
          <h3>Commission Report</h3>
        </EuiTitle>
        <EuiSpacer size="m" />
        <form onSubmit={handleSubmit(onSubmit, onError)} id={"commissionReportForm"}>
          <EuiFlexGroup>
            <EuiFlexItem>
              <Controller
                name="month"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  return (
                    <EuiFormRow fullWidth label="Month" isInvalid={!!error} error={error?.message}>
                      <EuiSelect
                        options={monthOptions}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setQuery({ month: parseInt(e.target.value, 10), year: query.year, code: query.code });
                        }}
                        onBlur={onBlur}
                        aria-label="month dropdown"
                      />
                    </EuiFormRow>
                  );
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <Controller
                name="year"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  return (
                    <EuiFormRow fullWidth label="Year" isInvalid={!!error} error={error?.message}>
                      <EuiSelect
                        options={yearOptions}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setQuery({ month: query.month, year: parseInt(e.target.value, 10), code: query.code });
                        }}
                        onBlur={onBlur}
                        aria-label="year dropdown"
                      />
                    </EuiFormRow>
                  );
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <Controller
                name="code"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  return (
                    <EuiFormRow fullWidth label="Agent Code" isInvalid={!!error} error={error?.message}>
                      <EuiSelect
                        options={clusterAgents.map((agentID) => ({ value: agentID, text: agentID }))}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setQuery({ month: query.month, year: query.year, code: e.target.value });
                        }}
                        onBlur={onBlur}
                        disabled={!clusterAdmin}
                        aria-label="year dropdown"
                      />
                    </EuiFormRow>
                  );
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </form>

        <EuiSpacer />

        <EuiFlexGroup>
          <EuiFlexItem grow={8}></EuiFlexItem>
          <EuiFlexItem>
            <EuiButton
              isDisabled={query.month >= date.getMonth() + 1 && query.year === date.getFullYear()}
              onClick={() => {
                // TODO What does this need to look like in production
                window.open(`${defaultURL}/commission-pdf/${query.code}/${query.month}/${query.year}`);
              }}
              download
            >
              Generate PDF
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButton
              isDisabled={query.month >= date.getMonth() + 1 && query.year === date.getFullYear()}
              onClick={() => {
                // TODO What does this need to look like in production
                window.open(`${defaultURL}/commission-csv/${query.code}/${query.month}/${query.year}`);
              }}
              download
            >
              Generate CSV
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButton
              isDisabled={query.month >= date.getMonth() + 1 && query.year === date.getFullYear()}
              onClick={() => {
                // TODO What does this need to look like in production
                window.open(`${defaultURL}/commission-excel/${query.code}/${query.month}/${query.year}`);
              }}
              download
            >
              Generate Excel
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="m" />
        <EuiPanel hasBorder>
          <EuiBasicTable
            items={pageOfItems}
            itemId="ID"
            columns={columns}
            rowProps={getRowProps}
            cellProps={getCellProps}
            onChange={onTableChange}
            tableLayout="auto"
            sorting={sorting}
            loading={isLoading || (isFetching && isPlaceholderData)}
          />
        </EuiPanel>
      </EuiPageBody>
    </EuiPage>
  );
};

export default Commissions;
