import { useQuery } from "react-query";
import { AgentUser } from "types/apiTypes";

import authAxios from "../../services/authAxios";

function useAgentUsers() {
  return useQuery<AgentUser[]>(
    ["users"],
    async () => {
      const { data } = await authAxios.get("/users");
      return data;
    },
    { placeholderData: [] }
  );
}

export default useAgentUsers;
