import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiPage,
  EuiPageBody,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
  EuiTableSortingType,
  EuiText,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import WCICLoadingData from "components/WCICLoadingData";
import { useAPIURL } from "hooks/useAPIURL";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import authAxios from "services/authAxios";
import pageAndSort from "services/pageAndSort";
// FIXME do we need to update File to ClaimReportedFile and UploadedDocumentFile?
import { File, UploadDocument } from "types/apiTypes";

type PageParams = {
  documentID: string;
};

interface TableData {
  recordID: number;
  fileSize: number;
  fileName: string;
  fileExtension: string;

  file: File;
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
}

const getUploadedDocumentById = async (id: string | undefined) => {
  const { data } = await authAxios.get(`/uploaded_document/${id}`);
  return data;
};

function useUploadedDocument(uploadedDocumentRecordId: string | undefined) {
  return useQuery<UploadDocument>(
    ["uploaded_document", uploadedDocumentRecordId],
    () => getUploadedDocumentById(uploadedDocumentRecordId),
    {
      staleTime: 1000 * 60 * 15,
      cacheTime: 1000 * 60 * 15,
      enabled: !!uploadedDocumentRecordId,
    }
  );
}

const UploadedDocument: React.FC = () => {
  const defaultURL = useAPIURL();
  const { documentID } = useParams<PageParams>();
  const { data, isLoading } = useUploadedDocument(documentID);
  console.log("Uploaded Doc: ", data);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof TableData>("fileSize");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  let dataTable: TableData[] = [];
  try {
    dataTable = data!.files.map((record) => {
      return {
        recordID: record.recordID,
        fileSize: record.size,
        fileName: record.name,
        fileExtension: record.extension,
        file: record,
      };
    });
  } catch {
    dataTable = [];
  }
  // console.log("dataTable: ", dataTable);

  const columns: EuiBasicTableColumn<TableData>[] = [
    {
      field: "fileName",
      name: "Name",
      render: (fileName: string, rowData: TableData) => (
        <span>
          <EuiIcon
            // type={"accessibility"}
            type={extensionIcon(rowData.fileExtension)}
            size="m"
            style={{ verticalAlign: "text-top", marginRight: "10px" }}
          />{" "}
          {fileName}
        </span>
      ),
    },
    {
      field: "fileSize",
      name: "Size",
      width: "80px",

      render: (size: number) => {
        return humanFileSize(size);
      },
    },
    {
      name: "Download",
      width: "80px",
      actions: [
        {
          name: "Download",
          description: "Download File",
          type: "icon",
          icon: "download",
          onClick: (record: TableData) => {
            let status = "";
            if (record.file.scanned === false) {
              status = "pending";
            }
            if (record.file.scanned === true && record.file.quarantine === true) {
              status = "error";
            }
            if (record.file.scanned === true && record.file.quarantine === false) {
              status = "success";
            }
            if (status === "success") {
              window.open(`${defaultURL}/uploaded_documents/file/view/${record.recordID}`, "_blank");
            }
          },
        },
      ],
    },
  ];

  const { pageOfItems, totalItemCount } = pageAndSort(dataTable, { pageIndex, pageSize, sortField, sortDirection });

  const getRowProps = (record: TableData) => {
    const { recordID } = record.file;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      onClick: () => {
        let status = "";
        if (record.file.scanned === false) {
          status = "pending";
        }
        if (record.file.scanned === true && record.file.quarantine === true) {
          status = "error";
        }
        if (record.file.scanned === true && record.file.quarantine === false) {
          status = "success";
        }
        if (status === "success") {
          window.open(`${defaultURL}/uploaded_documents/file/download/${record.recordID}`, "_blank");
        }
      },
    };
  };

  const getCellProps = (record: TableData, column: any) => {
    const { recordID } = record.file;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };

  const sorting: EuiTableSortingType<TableData> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
  };

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [15, 10, 5],
    hidePerPageOptions: !showPerPageOptions,
  };

  const onTableChange = ({ page, sort }: CriteriaWithPagination<TableData>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageHeader pageTitle="Uploaded Document Details" restrictWidth="1200px" />
        <EuiPageContentBody restrictWidth="1200px">
          <EuiFlexGroup gutterSize={"l"}>
            <EuiFlexItem>
              <EuiPanel hasBorder>
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Uploaded By</strong>
                  </EuiTextColor>
                </label>
                <WCICLoadingData isLoading={isLoading}>
                  <EuiText style={{ paddingLeft: "10px" }}>
                    {data?.agent_user ? data.agent_user.firstName + " " + data.agent_user.lastName : "Not Provided"}
                  </EuiText>
                  <EuiText style={{ paddingLeft: "10px" }}>
                    {data?.agent_user ? (
                      <EuiLink href={`mailto:` + data?.agent_user.emailAddress}>
                        {data?.agent_user.emailAddress}
                      </EuiLink>
                    ) : (
                      "Not Provided"
                    )}
                  </EuiText>
                </WCICLoadingData>
                <EuiSpacer size="xs" />
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Agent</strong>
                  </EuiTextColor>
                </label>
                <WCICLoadingData isLoading={isLoading}>
                  <EuiText style={{ paddingLeft: "10px" }}>
                    {data?.agent ? data.agent.agentID + " : " + data.agent.displayName : "Not Provided"}
                  </EuiText>
                </WCICLoadingData>
                <EuiSpacer size="xs" />
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Uploaded On</strong>
                  </EuiTextColor>
                </label>
                <WCICLoadingData isLoading={isLoading}>
                  <EuiText style={{ paddingLeft: "10px" }}>
                    {data?.timestamp ? moment(data.timestamp).format("ll") : "Not Provided"}
                  </EuiText>
                </WCICLoadingData>
              </EuiPanel>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiPanel hasBorder>
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Underwriter</strong>
                  </EuiTextColor>
                </label>
                <WCICLoadingData isLoading={isLoading}>
                  <EuiText style={{ paddingLeft: "10px" }}>
                    {data?.agent.underwriting_team
                      ? data?.agent.underwriting_team.underwriter.firstName +
                        " " +
                        data?.agent.underwriting_team.underwriter.lastName
                      : "Not Provided"}
                  </EuiText>
                  <EuiText style={{ paddingLeft: "10px" }}>
                    {data?.agent_user ? (
                      <EuiLink href={`mailto:` + data?.agent.underwriting_team.underwriter.emailAddress}>
                        {data?.agent.underwriting_team.underwriter.emailAddress}
                      </EuiLink>
                    ) : (
                      "Not Provided"
                    )}
                  </EuiText>
                </WCICLoadingData>

                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Team</strong>
                  </EuiTextColor>
                </label>
                <WCICLoadingData isLoading={isLoading}>
                  <EuiText style={{ paddingLeft: "10px" }}>
                    {data?.agent.underwriting_team ? data?.agent.underwriting_team.displayName : "Not Provided"}
                  </EuiText>
                </WCICLoadingData>
                <EuiSpacer size="xs" />
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer />
          <EuiPanel hasBorder>
            <label className="euiFormLabel euiFormRow__label">
              <EuiTextColor color="subdued">
                <strong>Type</strong>
              </EuiTextColor>
            </label>
            <WCICLoadingData isLoading={isLoading}>
              <EuiText style={{ paddingLeft: "10px" }}>{data?.type ? data?.type : "Not Provided"}</EuiText>
            </WCICLoadingData>
            <EuiSpacer size="s" />
            <label className="euiFormLabel euiFormRow__label">
              <EuiTextColor color="subdued">
                <strong>Description</strong>
              </EuiTextColor>
            </label>
            <WCICLoadingData isLoading={isLoading}>
              <EuiText style={{ paddingLeft: "10px" }}>{data?.comments ? data?.comments : "Not Provided"}</EuiText>
            </WCICLoadingData>
          </EuiPanel>
          <EuiSpacer />
          <EuiPanel hasBorder>
            <EuiFlexGroup>
              <EuiFlexItem grow={7}>
                <EuiTitle size="s">
                  <h2 id="flyoutLargeTitle">Files</h2>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton
                  disabled={!data?.files.every((file) => {
                    return file.scanned === true && file.quarantine === false;
                  })}
                  onClick={() => {
                    window.open(`${defaultURL}/uploaded_document/${documentID}/zip`, "_blank");
                  }}
                >
                  Download All
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiBasicTable
              items={pageOfItems}
              itemId="fileID"
              columns={columns}
              rowProps={getRowProps}
              cellProps={getCellProps}
              pagination={pagination}
              onChange={onTableChange}
              sorting={sorting}
              loading={isLoading}
            />
          </EuiPanel>
        </EuiPageContentBody>
      </EuiPageBody>
    </EuiPage>
  );
};

export default UploadedDocument;

export function extensionIcon(fileExtension: string) {
  const fileTypes: Record<string, string> = {
    png: "image",
    jpg: "image",
    jpeg: "image",
    bmp: "image",
    svg: "image",
    xlsx: "tableDensityNormal",
    xls: "tableDensityNormal",
  };
  if (fileExtension in fileTypes) {
    return fileTypes[fileExtension];
  }
  return "document";
}
