import { useMutation } from "react-query";

import authAxios from "../../../services/authAxios";

const useVerifyTOTP = () => {
  // const queryClient = useQueryClient();

  return useMutation((totpCode: string) => authAxios.post("/totp-verify", { otp: totpCode }), {
    // onSuccess: () => {
    //   // ✅ refetch the comments list for our blog post
    //   queryClient.invalidateQueries(["posts", id, "comments"]);
    // },
  });
};

export default useVerifyTOTP;
