import { useQuery } from "react-query";

import authAxios from "../../services/authAxios";

function useRCEstimate(rcData: any, enabled: boolean) {
  return useQuery(
    ["rce", rcData],
    async () => {
      const { data } = await authAxios.post("/rce", rcData);
      return data;
    },
    {
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

export default useRCEstimate;
