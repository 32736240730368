import {
  Criteria,
  EuiBadge,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiLink,
  EuiPage,
  EuiPageBody,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
  EuiTableSortingType,
} from "@elastic/eui";
import { EditableTableText } from "components/EditableTableText";
import { useToast } from "hooks/useToasts";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import authAxios from "services/authAxios";
import pageAndSort from "services/pageAndSort";
import { Agent, AgentCluster } from "types/apiTypes";

import { NewClusterModal } from "./NewClusterModal";

type AgentDataInColumn = Pick<Agent, "agentID" | "displayName">;

const getClusters = async () => {
  const { data } = await authAxios.get(`/clusters`);
  return data;
};

function useClusters() {
  const useQueryReturn = useQuery<AgentCluster[]>(["clusters"], () => getClusters(), {
    placeholderData: [],
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
  });
  return {
    ...useQueryReturn,
    data: useQueryReturn.data!,
  };
}

const Clusters: React.FC = () => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const setClusterName = useMutation({
    mutationFn: (clusterNameData: { recordID: number; name: string }) => {
      return authAxios.post("/change-cluster-name", {
        recordID: clusterNameData.recordID,
        name: clusterNameData.name,
      });
    },
    onMutate: async (updateClusterName) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ["clusters"] });
      // Snapshot the previous value
      const previouspassKeys = queryClient.getQueryData(["clusters"]);
      // Optimistically update to the new value
      queryClient.setQueryData(["clusters"], (old: AgentCluster[] | undefined) => {
        if (old === undefined) {
          return [];
        }
        const updatedCluster = {
          ...old.find((item) => {
            return item.recordID === updateClusterName.recordID;
          })!,
          name: updateClusterName.name,
        };
        return [
          ...old.filter((item: any) => {
            return item.recordID !== updateClusterName.recordID;
          }),
          updatedCluster,
        ];
      });
      // Return a context object with the snapshotted value
      return { previouspassKeys };
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["clusters"] });
    },
  });

  const setAgentFromCluster = useMutation({
    mutationFn: (agentClusterData: {
      agentID: string;
      newClusterID: string;
      currentClusterID: string;
      clusterName?: string;
    }) => {
      return authAxios.post("/agent-cluster", {
        agentID: agentClusterData.agentID,
        clusterID: agentClusterData.newClusterID,
      });
    },
    onMutate: async (updateAgentCluster) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ["clusters"] });
      // Snapshot the previous value
      const previouspassKeys = queryClient.getQueryData(["clusters"]);
      // Optimistically update to the new value
      queryClient.setQueryData(["clusters"], (old: AgentCluster[] | undefined) => {
        if (old === undefined) {
          return [];
        }
        return old.map((cluster) => {
          return {
            ...cluster,
            agents: cluster.agents.filter((agent) => {
              return agent.agentID !== updateAgentCluster.agentID;
            }),
          };
        });
      });

      if (updateAgentCluster.newClusterID === "None") {
        addToast({
          title: "You have removed " + updateAgentCluster.agentID + " from " + updateAgentCluster.clusterName,
          color: "success",
          text: (
            <>
              <EuiLink
                onClick={() => {
                  setAgentFromCluster.mutate({
                    agentID: updateAgentCluster.agentID,
                    newClusterID: updateAgentCluster.currentClusterID,
                    currentClusterID: "",
                  });
                }}
              >
                Undo
              </EuiLink>
            </>
          ),
        });
      }
      // Return a context object with the snapshotted value
      return { previouspassKeys };
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["agents"] });
      queryClient.invalidateQueries({ queryKey: ["clusters"] });
    },
  });

  const [sortField, setSortField] = useState<keyof AgentCluster>("recordID");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const { data, isLoading } = useClusters();
  // console.log("Cluster Data: ", data);

  const columns: EuiBasicTableColumn<AgentCluster>[] = [
    { field: "recordID", name: "Record ID", width: "100px" },
    {
      field: "name",
      name: "Name",
      width: "300px",
      render: (name: string, cluster: AgentCluster) => {
        return (
          <EditableTableText
            text={name}
            setNewText={(newText) => {
              setClusterName.mutate({
                recordID: cluster.recordID,
                name: newText,
              });
            }}
          />
        );
      },
    },
    {
      field: "agents",
      name: "Agents In Cluster",

      render: (agents: AgentDataInColumn[], cluster) => {
        return (
          <>
            {agents.map((agent: AgentDataInColumn) => {
              // return <span key={agent.agentID}>{agent.agentID} , </span>;

              return (
                <EuiBadge
                  key={agent.agentID}
                  iconType="cross"
                  iconSide="right"
                  color="warning"
                  iconOnClick={() => {
                    setAgentFromCluster.mutate({
                      agentID: agent.agentID,
                      clusterName: cluster.name,
                      currentClusterID: cluster.recordID.toString(),
                      newClusterID: "None",
                    });
                  }}
                  iconOnClickAriaLabel="Aria label applied to icon button"
                >
                  {agent.agentID.split("*")[1]}
                </EuiBadge>
              );
            })}
          </>
        );
      },
    },
  ];

  const { pageOfItems } = pageAndSort(data, {
    sortField,
    sortDirection,
  });

  const getRowProps = (record: AgentCluster) => {
    const { recordID } = record;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
    };
  };

  const getCellProps = (record: AgentCluster, column: any) => {
    const { recordID } = record;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };

  const sorting: EuiTableSortingType<AgentCluster> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
  };

  const onTableChange = ({ page, sort }: Criteria<AgentCluster>) => {
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
  };

  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageHeader pageTitle="Cluster Management" restrictWidth="1300px" />
        <EuiPageContentBody restrictWidth="1300px">
          <NewClusterModal />
          <EuiSpacer />
          <EuiPanel hasBorder>
            <EuiBasicTable
              items={pageOfItems}
              itemId="ID"
              columns={columns}
              rowProps={getRowProps}
              cellProps={getCellProps}
              onChange={onTableChange}
              tableLayout="auto"
              sorting={sorting}
              loading={isLoading}
            />
          </EuiPanel>
        </EuiPageContentBody>
      </EuiPageBody>
    </EuiPage>
  );
};

export default Clusters;
