import { EuiButtonIcon, EuiFieldText } from "@elastic/eui";
import { useState } from "react";

interface EditableTableTextProps {
  text: string;
  setNewText: (newText: string) => void;
}

export const EditableTableText = ({ text, setNewText }: EditableTableTextProps) => {
  const [editedText, setEditedText] = useState<string | undefined>();
  if (editedText !== undefined) {
    return (
      <>
        <EuiFieldText
          value={editedText}
          autoFocus
          onChange={(event) => {
            setEditedText(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setNewText(editedText);
              setEditedText(undefined);
            }
          }}
        />{" "}
        <EuiButtonIcon
          aria-label="Save Name Edit"
          iconType="check"
          color="success"
          onClick={() => {
            setNewText(editedText);
            setEditedText(undefined);
          }}
        />
        <EuiButtonIcon
          aria-label="Cancel Name Edit"
          iconType="cross"
          color="danger"
          onClick={() => {
            setEditedText(undefined);
          }}
        />
      </>
    );
  }
  return (
    <>
      {text}{" "}
      <EuiButtonIcon
        aria-label="Edit Name"
        style={{ marginLeft: "10px" }}
        iconType="pencil"
        onClick={() => {
          setEditedText(text);
        }}
      />
    </>
  );
};
