import authAxios from "../../services/authAxios";
import { useQuery } from "react-query";
import { Quote } from "types/apiTypes";

const getQuoteById = async (id: string | undefined) => {
  const { data } = await authAxios.get(`/quote/${id}`);
  return data;
};

function useQuote(quoteID: string | undefined) {
  return useQuery<Quote>(["quote", quoteID], () => getQuoteById(quoteID), {
    enabled: !!quoteID,
  });
}

export default useQuote;
