import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiSpacer,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import { useAppSelector } from "app/hooks";
import { selectWCICEmployee } from "features/authorization/authSlice";
import { useAPIURL } from "hooks/useAPIURL";
import moment from "moment";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { QueryClient, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import authAxios from "services/authAxios";
// import { disableInProduction } from "services/runInProduction";
import { Invoice } from "types/apiTypes";

import PayInvoiceModal from "../PayInvoiceModal/PayInvoiceModal";

interface InvoiceModal {
  invoiceID: string;
  invoiceRecordID: string;
}

function sortTable(table: Invoice[]) {
  return table.sort((a, b) => {
    // Unpaid is below paid, sort by date if paid status is equal
    if (a.hasBalance && b.hasBalance) {
      // sort by due date
      if (a.dueDate > b.dueDate) {
        return 1;
      } else {
        return -1;
      }
    }
    if (!a.hasBalance && !b.hasBalance) {
      // sort by invoice date
      if (a.date < b.date) {
        return 1;
      } else {
        return -1;
      }
    }
    //Sort hasBalace above not hasBalance
    if (a.hasBalance) {
      return -1;
    }
    return 1;
  });
}

function paginateTable<T>(table: T[], pageIndex: number, pageSize: number) {
  const startIndex = pageIndex * pageSize;
  return table.slice(startIndex, Math.min(startIndex + pageSize, table.length));
}

const Invoices: React.FC<{ invoices: Invoice[] }> = ({ invoices, children }) => {
  // console.log("invoices: ", invoices);
  const queryClient = useQueryClient();
  const employee = useAppSelector(selectWCICEmployee);
  const defaultURL = useAPIURL();
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showPerPageOptions] = useState(true);
  const [invoiceModalID, setInvoiceModalID] = useState<Invoice | undefined>(undefined);
  const [invoice, setInvoice] = useState<InvoiceModal>({ invoiceID: "", invoiceRecordID: "" });
  const [isWaivedLateFeeModalVisible, setWaivedLateFeeModalVisible] = useState<boolean>(false);

  // TODO fix this any
  const actions: any = [
    {
      name: "Pay",
      description: "Pay invoice balance",
      type: "icon",
      icon: "payment",
      onClick: (invoice: Invoice) => {
        navigate(`/invoice/${invoice.recordID}`);
      },
      enabled: (item: Invoice) => {
        if (item.type === "I") {
          return item.balance !== 0 && item.pending !== true && item.newestPayable === true;
        }
        return item.balance !== 0 && item.pending !== true;
      },
      available: (item: Invoice) => {
        return item.balance !== 0 && item.pending !== true;
      },
    },
    {
      name: "PDF",
      description: "View PDF",
      type: "icon",
      icon: "copy",
      onClick: (item: Invoice) => {
        window.open(`${defaultURL}/invoice-pdf/${item.recordID}`, "_blank");
      },
    },
  ];

  if (employee) {
    actions.push({
      name: "Late Fee",
      description: "Waive Late Fee",
      type: "icon",
      icon: "cut",
      onClick: (invoice: Invoice) => {
        setInvoice({ invoiceID: invoice.invoiceID.toString(), invoiceRecordID: invoice.recordID.toString() });
        setWaivedLateFeeModalVisible(true);
      },
      available: (invoice: Invoice) => {
        if (invoice.waivedLateFee === true) {
          return false;
        }
        if (invoice.balance !== 0 && invoice.pending !== true) {
          return true;
        }

        return false;
      },
    });
  }

  const columns: EuiBasicTableColumn<Invoice>[] = [
    {
      field: "invoiceID",
      name: "Invoice ID",
    },
    {
      field: "type",
      name: "Type",
      render: (invoiceType: string) => {
        switch (invoiceType) {
          case "R":
            return <EuiTextColor color="success">Renewal</EuiTextColor>;
          case "C":
            return <EuiTextColor color="danger">Cancellation</EuiTextColor>;
          case "D":
            return <EuiTextColor color="danger">Earned Premium</EuiTextColor>;
          case "I":
            return <EuiTextColor color="warning">Installment</EuiTextColor>;
          case "N":
            return <EuiTextColor color="success">New Policy</EuiTextColor>;
          case "E":
            return <EuiTextColor color="success">Endorsement</EuiTextColor>;
          case "W":
            return <EuiTextColor color="success">Write Off</EuiTextColor>;

          default:
            return <EuiTextColor color="default">{invoiceType}</EuiTextColor>;
        }
      },
      //   width: "10%"
    },
    {
      field: "date",
      name: "Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "dueDate",
      name: "Due Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "balance",
      name: "Balance",
      align: "right",
      render: (balance: number, record: Invoice) => {
        if (record.pending) {
          return "Pending";
        }
        return (
          <NumberFormat
            displayType={"text"}
            style={{ paddingLeft: "10px" }}
            value={balance / 100} // TODO check this?
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        );
      },
    },
    {
      name: "Actions",
      actions: actions,
    },
  ];

  // const { _, totalItemCount } = pageAndSort(invoices, { pageIndex, pageSize, sortField, sortDirection });
  const totalItemCount = invoices.length;
  const sortedTable = sortTable(invoices);
  const pageOfItems = paginateTable(sortedTable, pageIndex, pageSize);

  const getRowProps = (invoice: Invoice) => {
    const { recordID } = invoice;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      // onClick: () => {
      //   console.log("clicked on this: ", invoice);
      //   // setInvoiceModalID(invoice);
      // },
    };
  };

  const getCellProps = (invoice: Invoice, column: any) => {
    const { recordID } = invoice;
    const { field } = column;
    return {
      className: "wcicTableCellPreWrap",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
      // style: {"white-space": "pre"}
    };
  };

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [15, 10, 5],
    hidePerPageOptions: !showPerPageOptions,
  };
  const onTableChange = ({ page }: CriteriaWithPagination<Invoice>) => {
    const { index: pageIndex, size: pageSize } = page;
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPanel hasBorder>
      <EuiTitle>
        <h3>Invoices</h3>
      </EuiTitle>
      <EuiSpacer size="m" />
      <EuiBasicTable
        items={pageOfItems}
        itemId="recordID"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
        pagination={pagination}
        onChange={onTableChange}
        tableLayout="auto"
      />
      {invoiceModalID ? (
        <PayInvoiceModal
          invoice={invoiceModalID}
          onClose={() => {
            setInvoiceModalID(undefined);
          }}
        />
      ) : null}
      <WaivedLateFeeModal
        show={isWaivedLateFeeModalVisible}
        invoiceID={invoice.invoiceID}
        invoiceRecordID={invoice.invoiceRecordID}
        closeModal={() => {
          setWaivedLateFeeModalVisible(false);
        }}
        query={queryClient}
      />
    </EuiPanel>
  );
};

export default Invoices;

export const WaivedLateFeeModal: React.FC<{
  show: boolean;
  invoiceID: string;
  invoiceRecordID: string;
  query: QueryClient;
  closeModal: () => void;
}> = ({ show, invoiceID, invoiceRecordID, query, closeModal }) => {
  if (!show) {
    return null;
  }

  return (
    <EuiModal onClose={closeModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Waive late fee for invoice: {invoiceID}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody></EuiModalBody>

      <EuiModalFooter>
        <EuiButton
          color="primary"
          onClick={() => {
            closeModal();
            authAxios.post("/waive_late_fee", { invoiceRecordID: invoiceRecordID, status: true });
            query.invalidateQueries();
          }}
          fill
        >
          Waive Fee
        </EuiButton>
        <EuiButton onClick={closeModal}>Cancel</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
