import {
  EuiButton,
  EuiFlexGrid,
  EuiFlexItem,
  EuiLink,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiPopover,
  EuiSpacer,
  EuiText,
  EuiTitle,
  htmlIdGenerator,
} from "@elastic/eui";
import { useAPIURL } from "hooks/useAPIURL";
import { useState } from "react";

const ManualsAndGuides: React.FC = () => {
  const [popoverID, setPopoverID] = useState<number | boolean>(false);
  const defaultURL = useAPIURL();

  const data = [
    {
      id: 1,
      title: "General Underwriting Guide",
      description:
        "The Underwriting Guide contains current procedures and policies with regard to business acceptable to Wayne Cooperative.",
      links: [
        {
          title: "UW Guidelines",
          link: "202403_UW Guidelines.pdf",
        },
        {
          title: "Directory",
          link: "202403_UW Directory.pdf",
        },
      ],
    },
    {
      id: 2,
      title: "Accounting Guide",
      description:
        "The Accounting Guide contains current procedures and policies regarding the Company's accounting practices.",
      links: [
        {
          title: "Accounting Guide",
          link: "Acct_Guide_202310.pdf",
        },
      ],
    },
    {
      id: 9,
      title: "Homeowners Policy (2014)",
      description:
        "The Homeowners Policy Program manual contains rules, classifications, rates and premiums for writing property and liability insurance when the dwelling insured contains no more than four families and is used exclusively for residential purposes.",
      links: [
        {
          title: "Application",
          link: "080_201005F_Homeowner_App.pdf",
        },
        {
          title: "Rates and Rules",
          link: "HO_RateMan.pdf",
        },

      ],
    },
    {
      id: 3,
      title: "Homeowners Policy (2024)",
      description:
        "The Homeowners Policy Program manual contains rules, classifications, rates and premiums for writing property and liability insurance when the dwelling insured contains no more than four families and is used exclusively for residential purposes.",
      links: [
        {
          title: "Application",
          link: "80_NY_202202_AdobeFillable.pdf",
        },
        {
          title: "Rates and Rules",
          link: "202409_HO Rate Manual V2.pdf",
        },
        {
          title: "Guidelines",
          link: "HO_UGuide.pdf",
        },
      ],
    },

    {
      id: 4,
      title: "Landlords Package",
      description:
        "	The Landlords Policy information manual contains rules, classifications, rates and premiums for writing property and liability insurance when the dwelling insured contains no more than 4 families and is used exclusively for rental purposes.",
      links: [
        // {
        //   title: "Online Quote",
        // },
        {
          title: "Application",
          link: "084F_Dwelling Fire App_022012.PDF",
        },
        {
          title: "Rates and Rules",
          link: "LP_RateMan.pdf",
        },
      ],
    },

    {
      id: 5,
      title: "Mobile Homeowners Policy",
      description:
        "The Mobile Homeowners Policy Program manual contains rules, classifications, rates and premiums for writing property and liability insurance to 1 and 2 family owner-occupied mobile homes and Tenant occupied mobile homes (ML-4).",
      links: [
        {
          title: "Application",
          link: "085_200209_MobileHome_App.pdf",
        },
        {
          title: "Rates and Rules",
          link: "MO_RateMan.pdf",
        },
      ],
    },
    {
      id: 6,
      title: "Farmowners Policy",
      description:
        "The WCIC Farmowners Program provides property and liability coverage's specifically tailored to the business needs of a farmer. This manual contains the rules, rates and forms available to tailor the FO policy to the individual farmer's needs.",
      links: [
        {
          title: "Application",
          link: "WCIC_FO_Application.pdf",
        },
        {
          title: "Rates and Rules",
          link: "FO_RateMan.pdf",
        },
      ],
    },
    {
      id: 7,
      title: "Dwelling Farm Fire & Personal Liability",
      description:
        "The Dwelling/Farm Fire & Liability Program manual contains rules, classifications, rates and premiums for writing monoline property and liability insurance when the risk does not qualify for another package policy. Liability coverage CANNOT be written on a standalone basis. Special underwriting rules may apply.",
      links: [
        {
          title: "Application",
          link: "084F_Dwelling Fire App_022012.PDF",
        },
        // {
        //   title: "Rates and Rules ed. 01/82",
        //   link: "FI_RateMan.pdf",
        // },
        {
          title: "Rates and Rules ed. 02/2016",
          link: "FI_RateMan_02_2016.pdf",
        },
      ],
    },
    {
      id: 8,
      title: "Inland Marine Liability",
      description:
        "The Inland Marine contains the rates for scheduling livestock, farm machinery and available classes of personal property.",
      links: [
        // {
        //   title: "Rates and Rules ed. 03/86",
        //   link: "IM_RATES_201306.pdf",
        // },
        {
          title: "Rates and Rules ed. 02/2016",
          link: "IM_RateMan_02_2016.pdf",
        },
        {
          title: "Rates and Rules ed. 07/2023",
          link: "IM_RateMan.pdf",
        },
      ],
    },
    {
      id: 10,
      title: "Inland Flood Coverage",
      description:
        "The inland flood manual contains rates and rules for adding inland flood coverage to Homeowner, Farmowner or Landlord policies.",
      links: [
        {
          title: "Rates and Rules",
          link: "202409_Inland Flood Rate Manual.pdf",
        },
      ],
    },
  ];

  const rc_estimator = [
    {
      id: 1,
      title: "2024 RC Estimator",
      link: "WCIC_2024_RC_Estimator.pdf",
    },
    {
      id: 2,
      title: "2024 RC Estimator Instuctions",
      link: "WCIC_2024_RC_Estim_Instruct.pdf",
    },
  ];

  const questionnaires = [
    {
      id: 1,
      title: "Solid Fuel Questionnaire",
      link: "073F_Solid Fuel Questionnaire_072009.PDF",
    },
    {
      id: 2,
      title: "Seasonal LP Questionnaire",
      link: "Seasonal_Landlord_Questionnaire_Form.pdf",
    },
    {
      id: 3,
      title: "Owner-Occupied Dog Questionnaire",
      link: "Owner_Occupied_Dog_Questionnaire_2022.pdf",
    },
    {
      id: 4,
      title: "Tenant-Occupied Dog Questionnaire",
      link: "Tenant_Occupied_Dog_Questionnaire_2022.pdf",
    },
  ];

  return (
    <>
      <EuiPage>
        <EuiPageBody restrictWidth="1000px">
          <EuiPageHeader iconType="documentation" pageTitle="Manuals and Guides" />
          <EuiSpacer size="m" />
          <EuiPageContent
            hasBorder={false}
            hasShadow={false}
            paddingSize="none"
            color="transparent"
            borderRadius="none"
          >
            <EuiPageContentBody restrictWidth="1200px">
              <EuiFlexGrid columns={2}>
                {data.map((element) => {
                  return (
                    <EuiFlexItem key={element.id}>
                      <EuiPanel hasBorder>
                        <EuiTitle size="s">
                          <h3>{element.title}</h3>
                        </EuiTitle>
                        <EuiPopover
                          button={
                            <EuiLink
                              onMouseEnter={() => {
                                setPopoverID(element.id);
                              }}
                              onMouseLeave={() => {
                                setPopoverID(false);
                              }}
                            >
                              Details
                            </EuiLink>
                          }
                          isOpen={popoverID === element.id}
                          anchorPosition="leftUp"
                          closePopover={() => {
                            setPopoverID(false);
                          }}
                          initialFocus={false}
                          ownFocus={false}
                        >
                          <div style={{ width: "300px" }}>
                            <EuiText size="s">
                              <p>{element.description}</p>
                            </EuiText>
                          </div>
                        </EuiPopover>
                        <EuiSpacer />
                        <span>
                          {element.links.map((link) => {
                            return (
                              <EuiButton
                                key={htmlIdGenerator()()}
                                style={{ margin: "10px" }}
                                onClick={() => {
                                  window.open(`${defaultURL}/manuals/${link.link}`, "_blank");
                                }}
                              >
                                {link.title}
                              </EuiButton>
                            );
                          })}
                        </span>
                      </EuiPanel>
                    </EuiFlexItem>
                  );
                })}
              </EuiFlexGrid>

              <EuiSpacer size="xxl" />
              <EuiPageHeader iconType="questionInCircle" pageTitle="Questionnaires" />
              <EuiSpacer size="m" />
              <EuiPanel hasBorder>
                <EuiFlexGrid columns={2}>
                  {questionnaires.map((element) => {
                    return (
                      <EuiFlexItem key={element.id}>
                        <EuiButton
                          key={htmlIdGenerator()()}
                          style={{ margin: "10px" }}
                          onClick={() => {
                            window.open(`${defaultURL}/manuals/${element.link}`, "_blank");
                          }}
                        >
                          {element.title}
                        </EuiButton>
                      </EuiFlexItem>
                    );
                  })}
                </EuiFlexGrid>
              </EuiPanel>
              <EuiSpacer size="xxl" />
              <EuiPageHeader iconType="tag" pageTitle="Replacement Cost Estimator" />
              <EuiSpacer size="m" />
              <EuiPanel hasBorder>
                <EuiFlexGrid columns={2}>
                  {rc_estimator.map((element) => {
                    return (
                      <EuiFlexItem key={element.id}>
                        <EuiButton
                          key={htmlIdGenerator()()}
                          style={{ margin: "10px" }}
                          onClick={() => {
                            window.open(`${defaultURL}/manuals/${element.link}`, "_blank");
                          }}
                        >
                          {element.title}
                        </EuiButton>
                      </EuiFlexItem>
                    );
                  })}
                </EuiFlexGrid>
              </EuiPanel>
            </EuiPageContentBody>
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    </>
  );
};

export default ManualsAndGuides;
