import { EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow } from "@elastic/eui";
import { FormSelect } from "components/react-hook-form/SelectInput";
import { Fragment, FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

const PaymentAddressView: FunctionComponent<{ errors: any }> = ({ errors }) => {
  return (
    <Fragment key="address">
      <EuiFormRow>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="First Name" isInvalid={!!errors.firstName?.message} error={errors?.firstName?.message}>
              <Controller
                name={`firstName` as `${string}`}
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <EuiFieldText
                      isInvalid={!!errors?.firstName?.message}
                      placeholder="First Name"
                      inputRef={ref}
                      {...rest}
                    />
                  );
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="Last Name" isInvalid={!!errors.lastName?.message} error={errors?.lastName?.message}>
              <Controller
                name={`lastName` as `${string}`}
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <EuiFieldText
                      isInvalid={!!errors?.lastName?.message}
                      placeholder="Last Name"
                      inputRef={ref}
                      {...rest}
                    />
                  );
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiFormRow label="Address" isInvalid={!!errors.address?.message} error={errors?.address?.message}>
        <Controller
          name={`address` as `${string}`}
          render={({ field: { ref, ...rest } }) => {
            return (
              <EuiFieldText isInvalid={!!errors?.address?.message} placeholder="Address" inputRef={ref} {...rest} />
            );
          }}
        />
      </EuiFormRow>

      <EuiFormRow>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="City" isInvalid={!!errors.city?.message} error={errors?.city?.message}>
              <Controller
                name={`city` as `${string}`}
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <EuiFieldText isInvalid={!!errors?.city?.message} placeholder="City" inputRef={ref} {...rest} />
                  );
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="State" fullWidth isInvalid={!!errors.state?.message} error={errors?.state?.message}>
              <FormSelect
                name={`state`}
                label={"State"}
                isInvalid={!!errors?.state?.message}
                defaultValue="NY"
                rules={{ required: true }}
                options={[
                  { value: "AL", text: "AL" },
                  { value: "AK", text: "AK" },
                  { value: "AZ", text: "AZ" },
                  { value: "AR", text: "AR" },
                  { value: "CA", text: "CA" },
                  { value: "CO", text: "CO" },
                  { value: "CT", text: "CT" },
                  { value: "DE", text: "DE" },
                  { value: "DC", text: "DC" },
                  { value: "FL", text: "FL" },
                  { value: "GA", text: "GA" },
                  { value: "HI", text: "HI" },
                  { value: "ID", text: "ID" },
                  { value: "IL", text: "IL" },
                  { value: "IN", text: "IN" },
                  { value: "IA", text: "IA" },
                  { value: "KS", text: "KS" },
                  { value: "KY", text: "KY" },
                  { value: "LA", text: "LA" },
                  { value: "ME", text: "ME" },
                  { value: "MD", text: "MD" },
                  { value: "MA", text: "MA" },
                  { value: "MI", text: "MI" },
                  { value: "MN", text: "MN" },
                  { value: "MS", text: "MS" },
                  { value: "MO", text: "MO" },
                  { value: "MT", text: "MT" },
                  { value: "NE", text: "NE" },
                  { value: "NV", text: "NV" },
                  { value: "NH", text: "NH" },
                  { value: "NJ", text: "NJ" },
                  { value: "NM", text: "NM" },
                  { value: "NY", text: "NY" },
                  { value: "NC", text: "NC" },
                  { value: "ND", text: "ND" },
                  { value: "OH", text: "OH" },
                  { value: "OK", text: "OK" },
                  { value: "OR", text: "OR" },
                  { value: "PA", text: "PA" },
                  { value: "RI", text: "RI" },
                  { value: "SC", text: "SC" },
                  { value: "SD", text: "SD" },
                  { value: "TN", text: "TN" },
                  { value: "TX", text: "TX" },
                  { value: "UT", text: "UT" },
                  { value: "VT", text: "VT" },
                  { value: "VA", text: "VA" },
                  { value: "WA", text: "WA" },
                  { value: "WV", text: "WV" },
                  { value: "WI", text: "WI" },
                  { value: "WY", text: "WY" },
                ]}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="Zip" isInvalid={!!errors.zip?.message} error={errors?.zip?.message}>
              <Controller
                name={`zip` as `${string}`}
                render={({ field }) => {
                  const { onChange, value, ref, ...rest } = field;
                  return (
                    <NumberFormat
                      placeholder="Zip"
                      customInput={EuiFieldText}
                      decimalScale={0}
                      value={value}
                      isInvalid={!!errors?.zip?.message}
                      format="#####"
                      onValueChange={(target) => {
                        onChange(target.value);
                      }}
                      inputRef={ref}
                      {...rest}
                    />
                  );
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    </Fragment>
  );
};

export default PaymentAddressView;
