import * as yup from "yup";

import { formsData } from "../homeowner/data/formsData";

export const HOSchema = yup
  .object({
    isBusinessName: yup
      .string()
      .oneOf(["personal", "business"], (field) => `Must be business or personal name`)
      .required()
      .label("Line"),
    businessName: yup
      .string()
      .trim()
      .when("isBusinessName", (isBusinessName: string, schema: yup.StringSchema) => {
        if (isBusinessName === "business") {
          return schema.max(64, "Business name cannot exceed 64 characters").required();
        }
        return schema;
      })
      .label("Business Name"),

    firstName: yup
      .string()
      .trim()
      .when("isBusinessName", (isBusinessName: string, schema: yup.StringSchema) => {
        if (isBusinessName === "personal") {
          return schema.max(64, "First name cannot exceed 64 characters").required();
        }
        return schema;
      })
      .label("First name"),
    lastName: yup
      .string()
      .trim()
      .when("isBusinessName", (isBusinessName: string, schema: yup.StringSchema) => {
        if (isBusinessName === "personal") {
          return schema.max(64, "Last name cannot exceed 64 characters").required();
        }
        return schema;
      })
      .label("Last name"),
    streetAddress: yup.string().trim().required().label("Street address"),
    streetAddress2: yup.string().trim().label("Street address"),
    city: yup.string().required().label("City"),
    state: yup.string().required().label("State"),
    zip: yup.string().required().label("Zip"),
    forms: yup.array().of(
      yup.object().shape({
        name: yup.string().required(),
        fields: yup.object().when("name", (name: string, schema: yup.AnyObjectSchema) => {
          return schema.concat(formsData[name].yupShape);
        }),
      })
    ),
    locations: yup.array().of(
      yup.object().shape({
        streetAddress: yup.string().required().label("Street address"),
        city: yup.string().required().label("City"),
        state: yup.string().required().label("State"),
        zip: yup
          .number()
          .positive()
          .integer()
          .typeError((field) => `${field.label} is a required field`)
          .required()
          .label("Zip"),
        constructionType: yup
          .string()
          .oneOf(["F", "M"], (field) => `${field.label} must be Frame or Masonry`)
          .typeError((field) => `${field.label} must be selected from the options`)
          .required()
          .label("Construction Type"),
        protectionClass: yup
          .string()
          .oneOf(["A", "B", "C", "D"], (field) => `${field.label} must be A, B, C or D`)
          .typeError((field) => `${field.label} must be selected from the options`)
          .required()
          .label("Protection Class"),
        yearBuilt: yup
          .number()
          .min(1776)
          .max(2100)
          .typeError((field) => `${field.label} must be a number`)
          .required()
          .label("Year built"),
        numFamilies: yup
          .number()
          .oneOf([1, 2, 3, 4], (field) => `${field.label} must be between 1 and 4`)
          .typeError((field) => `${field.label} must be a number`)
          .required()
          .label("Number of Families"),
        valuation: yup
          .string()
          .when("perils", (perils: string, schema: yup.StringSchema) => {
            if (perils === "ML5") {
              return schema.oneOf(["RC"], (field) => `${field.label} must be RC`);
            } else {
              return schema.oneOf(["RC", "ACV", "IACV"], (field) => `${field.label} must be RC, ACV or IACV`);
            }
          })
          .typeError((field) => `${field.label} must be selected from the options`)
          .required()
          .label("Valuation"),
        RCpercentage: yup
          .number()
          .when("valuation", (valuation: string, schema: yup.NumberSchema) => {
            if (valuation === "IACV") {
              return schema.oneOf([60], (field) => `${field.label} must be 60% with Index ACV`);
            } else {
              return schema.oneOf([100, 95, 92, 90], (field) => `must be 100%, 95%, 92% or 90% with RC`);
            }
          })
          .typeError((field) => `${field.label} must be selected from the options`)
          .label("RC Percentage"),
        perils: yup
          .string()
          .oneOf(["ML1", "ML2", "ML3", "ML5"], (field) => `${field.label} must be ML-1, ML-2, ML-3 or ML-5`)
          .typeError((field) => `${field.label} must be selected from the options`)
          .required()
          .label("Perils"),
        deductible: yup
          .number()
          .typeError((field) => `${field.label} must be selected from the options`)
          .required()
          .label("Deductible"),
        RCcovA: yup.number().label("RC Coverage A"), //TODO does this need more? default of 0
        covA: yup
          .number()
          .min(50000, (field) => `${field.label} must be greater than $50,000`)
          .integer()
          .typeError((field) => `${field.label} must be a number`)
          .required()
          .label("Coverage A"),
        covB: yup
          .number()
          .min(0)
          .integer()
          .typeError((field) => `${field.label} must be a number`)
          .required()
          .label("Coverage B"),
        covC: yup
          .number()
          .min(0)
          .integer()
          .typeError((field) => `${field.label} must be a number`)
          .required()
          .label("Coverage C"),
        covD: yup
          .number()
          .min(0)
          .integer()
          .typeError((field) => `${field.label} must be a number`)
          .required()
          .label("Coverage D"),
        liabilityForm: yup
          .string()
          .oneOf(["None", "ML9", "ML10"], (field) => `${field.label} must be selected from the options`)
          .required()
          .label("Liability Form"),
        liability: yup
          .number()
          .when("liabilityForm", (liabilityForm: string, schema: yup.NumberSchema) => {
            if (liabilityForm !== "None") {
              return schema
                .oneOf(
                  [0, 100000, 300000, 500000, 1000000],
                  (field) => `${field.label} must be selected from the options`
                ) // Does this need 0 as a value?
                .required();
            }
            return schema;
          })
          .label("Liability"),
        medpay: yup
          .number()
          .when("liabilityForm", (liabilityForm: string, schema: yup.NumberSchema) => {
            if (liabilityForm !== "None") {
              return schema.oneOf([5000], (field) => `${field.label} must be selected from the options`).required();
            }
            return schema;
          })
          .label("Medpay"),

        forms: yup.array().of(
          yup.object().shape({
            name: yup.string().required(),
            fields: yup.object().when("name", (name: string, schema: yup.AnyObjectSchema) => {
              return schema.concat(formsData[name].yupShape);
            }),
          })
        ),
      })
    ),
  })
  .required();
