import {
  EuiButton,
  EuiCollapsibleNav,
  EuiCollapsibleNavGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiListGroup,
  EuiListGroupProps,
  EuiPinnableListGroupItemProps,
  EuiSpacer,
} from "@elastic/eui";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../app/hooks";
import {
  selectAdministrator,
  selectAgencyID,
  selectAgencyName,
  selectClusterAdministrator,
  selectClusterAgencies, selectWCICEmployee,
} from "../features/authorization/authSlice";
import { sidebarRoutes } from "../routes";

function Sidebar() {
  const [navIsOpen, setNavIsOpen] = useState<boolean>(JSON.parse(String(localStorage.getItem("navIsDocked"))) || false);
  const isModifiedEvent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
  const agencyID = useAppSelector(selectAgencyID);
  const agencyName = useAppSelector(selectAgencyName);
  const agencyAdmin = useAppSelector(selectAdministrator);
  const clusterAdmin = useAppSelector(selectClusterAdministrator);
  const clusterAgents = useAppSelector(selectClusterAgencies);
  const employee = useAppSelector(selectWCICEmployee);
  const admin = agencyAdmin || clusterAdmin;

  const isLeftClickEvent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => event.button === 0;
  const navigate = useNavigate();

  // const NavLinks: EuiPinnableListGroupItemProps[] = [
  //   { label: "Dashboard", href: "/" },
  //   { label: "Login", href: "/login" },
  // ];

  // const NavClickLinks: EuiPinnableListGroupItemProps[] = NavLinks.map((link) => {
  //   return {
  //     ...link,
  //     onClick: (event) => {
  //       if (event.defaultPrevented) {
  //         return;
  //       }

  //       // If target prop is set (e.g. to "_blank"), let browser handle link.
  //       if ((event.target as Element).getAttribute("target")) {
  //         return;
  //       }

  //       if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
  //         return;
  //       }

  //       // Prevent regular link behavior, which causes a browser refresh.
  //       event.preventDefault();

  //       // Push the route to the history.
  //       if (link.href !== undefined) {
  //         navigate(link.href);
  //       }
  //     },
  //   };
  // });
  const deploymentsList: EuiListGroupProps["listItems"] = clusterAgents.map((agent) => {
    return {
      label: "Agency " + agent.replace("AGT*", ""),
      iconType: "users",
      size: "s",
    };
  });

  const TopNavLinks: EuiPinnableListGroupItemProps[] = [
    {
      label: "Home",
      href: "/",
      iconType: "home",
      iconProps: { size: "l" },
      isActive: false,
      onClick: (event) => {
        if (event.defaultPrevented) {
          return;
        }

        // If target prop is set (e.g. to "_blank"), let browser handle link.
        if ((event.target as Element).getAttribute("target")) {
          return;
        }

        if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
          return;
        }

        // Prevent regular link behavior, which causes a browser refresh.
        event.preventDefault();

        // Push the route to the history.
        navigate("/");
      },
    },
  ];

  const manageButton = () => {
    if (admin) {
      return (
        <EuiButton
          color="ghost"
          fullWidth
          onClick={() => {
            navigate("/admin");
          }}
        >
          Manage Agencies
        </EuiButton>
      );
    }
    return null;
  };

  return (
    <EuiCollapsibleNav
      id="guideHeaderCollapsibleNavExample"
      aria-label="Main navigation"
      isOpen={navIsOpen}
      isDocked={true}
      button={
        <EuiHeaderSectionItemButton aria-label="Toggle main navigation" onClick={() => setNavIsOpen(!navIsOpen)}>
          <EuiIcon type={"menu"} size="m" aria-hidden="true" />
        </EuiHeaderSectionItemButton>
      }
      onClose={() => setNavIsOpen(false)}
    >
      <EuiCollapsibleNavGroup
        title={
          <span>
            <small style={{ fontWeight: "normal" }}>Agency {agencyID.replace("AGT*", "")}</small> <br />
            <strong>{agencyName}</strong>
          </span>
        }
        iconType="flag"
        iconSize="xl"
        isCollapsible={true}
        initialIsOpen={false}
        background="dark"
      >
        <div role="group" className="kibanaNavDeployment__content">
          <EuiListGroup listItems={deploymentsList} flush />
          <EuiSpacer size="s" />
          {manageButton()}
        </div>
      </EuiCollapsibleNavGroup>
      <EuiCollapsibleNavGroup background="light">
        <EuiListGroup listItems={TopNavLinks} maxWidth="none" color="text" gutterSize="none" size="s" />
      </EuiCollapsibleNavGroup>
      <EuiFlexItem className="eui-yScroll">
        {sidebarRoutes
          .filter((sidebarGroup) => {
            return sidebarGroup.children.some(({ requireAdmin = false, requireEmployee = false, sidebar }) => {
              if (sidebar === false) {
                return false;
              }
              if (requireAdmin) {
                if (!admin) {
                  return false
                }
              }
              if (requireEmployee) {
                if (!employee) {
                  return false
                }
              }
              return true;
            });
          })
          .map(({ name, icon, children }) => {
            const SidebarLinks: EuiPinnableListGroupItemProps[] = children
              .filter(({ sidebar }) => sidebar)
              .filter(({ requireAdmin = false, requireEmployee = false }) => {
                if (requireAdmin) {
                  if (!admin) {
                    return false
                  }
                }
                if (requireEmployee) {
                  if (!employee) {
                    return false
                  }
                }
                return true;
              })
              .map(({ name, path, icon }) => {
                return {
                  label: name,
                  href: path,
                  iconType: icon,
                  onClick: (event) => {
                    if (event.defaultPrevented) {
                      return;
                    }

                    // If target prop is set (e.g. to "_blank"), let browser handle link.
                    if ((event.target as Element).getAttribute("target")) {
                      return;
                    }

                    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
                      return;
                    }

                    // Prevent regular link behavior, which causes a browser refresh.
                    event.preventDefault();

                    // Push the route to the history.
                    if (path !== undefined) {
                      navigate(path);
                    }
                  },
                };
              });
            return (
              <EuiCollapsibleNavGroup key={name} title={name} iconType={icon} isCollapsible={true} initialIsOpen={true}>
                <EuiListGroup listItems={SidebarLinks} maxWidth="none" color="subdued" gutterSize="none" size="s" />
              </EuiCollapsibleNavGroup>
            );
          })}

        {/* <EuiFlexItem className="eui-yScroll"></EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiShowFor sizes={["l", "xl"]}>
            <EuiCollapsibleNavGroup>
              <EuiListGroupItem
                size="xs"
                color="subdued"
                label={`${navIsDocked ? "Undock" : "Dock"} navigation`}
                onClick={() => {
                  setNavIsDocked(!navIsDocked);
                  localStorage.setItem("navIsDocked", JSON.stringify(!navIsDocked));
                }}
                iconType={navIsDocked ? "lock" : "lockOpen"}
              />
            </EuiCollapsibleNavGroup>
          </EuiShowFor>
        </EuiFlexItem> */}
      </EuiFlexItem>
    </EuiCollapsibleNav>
  );
}

export default Sidebar;
