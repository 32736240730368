import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPage,
  EuiPageBody,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSelect,
  EuiSpacer,
  EuiTitle,
} from "@elastic/eui";
import { yupResolver } from "@hookform/resolvers/yup";
// import { useAppSelector } from "app/hooks";
import ErrorPage from "components/ErrorPage";
// import { selectAdministrator, selectWCICEmployee } from "features/authorization/authSlice";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import authAxios from "services/authAxios";
import { ProductionReportsData } from "types/apiTypes";
import * as yup from "yup";

interface PRResults {
  result: [];
  duplicates: [];
  errors: [];
}
interface ProductionReportsForm {
  year: string;
  quarter: string;
}

const ProductionReportSchema = yup
  .object({
    year: yup.string().required().label("Year"),
    quarter: yup.string().oneOf(["Q1", "Q2", "Q3", "Q4"]).required().label("Quarter"),
  })
  .required();

const ProductionReportsAdmin: React.FC = () => {
  const getProductionReportsAdmin = async (year: string, quarter: string) => {
    const { data } = await authAxios.get(`/admin-production-reports`, {
      params: { year: year, quarter: quarter },
    });
    return data;
  };
  function useProductionReportsAdmin(year: string, quarter: string) {
    const useQueryReturn = useQuery<PRResults>(
      ["production_reports_results", year, quarter],
      () => getProductionReportsAdmin(year, quarter),
      {
        placeholderData: { result: [], duplicates: [], errors: [] },
        initialData: { result: [], duplicates: [], errors: [] },
        initialDataUpdatedAt: 0,
        staleTime: 1000 * 60 * 15,
        keepPreviousData: true,
        enabled: year !== "" || quarter !== "",
      }
    );
    return {
      ...useQueryReturn,
      data: useQueryReturn.data!,
    };
  }

  const uploadProductionReports = useMutation(
    () => {
      return authAxios.get(`/admin-upload-production-reports`, {
        params: { year: year, quarter: quarter },
      });
    },
    {
      onSuccess: (data) => {
        console.log("duplicates: ", data.data.duplicates);
        setTotal(data.data.total);
        setIsUploadCompletedModalVisible(true);
      },
      onError: (error: any) => {
        console.log("error: ", error);
      },
    }
  );

  // const admin = useAppSelector(selectAdministrator);
  // const employee = useAppSelector(selectWCICEmployee);
  // if (!admin && !employee) {
  //   return <ErrorPage title="Production Reports Admin" />;
  // }

  const [isScanModalVisible, setIsScanModalVisible] = useState<boolean>(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState<boolean>(false);
  const [isUploadCompletedModalVisible, setIsUploadCompletedModalVisible] = useState<boolean>(false);
  const [total, setTotal] = useState("");
  const today = new Date();
  const quarterNum = Math.floor((today.getMonth() + 3) / 3);
  const q = quarterNum > 3 ? "Q1" : "Q" + (quarterNum - 1);
  const methods = useForm<ProductionReportsForm>({
    mode: "onTouched",
    resolver: yupResolver(ProductionReportSchema),
    defaultValues: {
      year: today.getFullYear().toString(),
      quarter: q,
    },
  });

  const defaultQuarter = { year: "", quarter: "" };

  const { handleSubmit, control, getValues } = methods;
  const [{ year, quarter }, setForm] = useState(defaultQuarter);
  const onError = (errors: any, e: any) => console.log(errors, e);

  const onProductionReportSubmit: SubmitHandler<ProductionReportsForm> = (data) => {
    // console.log("form data: ", data);
    setForm({ year: data.year, quarter: data.quarter });
    // console.log("year, quarter: ", year, quarter);
  };

  const { data, isLoading, isError } = useProductionReportsAdmin(year, quarter);
  // console.log("data: ", data);
  if (isError) {
    return <ErrorPage title="Production Reports Admin" />;
  }

  const columns: EuiBasicTableColumn<ProductionReportsData>[] = [
    {
      field: "filename",
      name: "filename",
    },
    {
      field: "year",
      name: "year",
    },
    {
      field: "quarter",
      name: "quarter",
    },
    {
      field: "agentID",
      name: "Agent ID",
    },
  ];
  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageHeader pageTitle="Production Reports Admin" restrictWidth="1300px" />
        <EuiPageContentBody restrictWidth="1300px">
          <EuiPanel hasBorder>
            <form onSubmit={handleSubmit(onProductionReportSubmit, onError)}>
              <Controller
                name="year"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  return (
                    <EuiFormRow fullWidth label="Year" isInvalid={!!error} error={error?.message}>
                      <EuiSelect
                        onBlur={onBlur}
                        options={[
                          {
                            value: today.getFullYear().toString(),
                            text: today.getFullYear().toString(),
                          },
                          {
                            value: (today.getFullYear() - 1).toString(),
                            text: (today.getFullYear() - 1).toString(),
                          },
                          {
                            value: (today.getFullYear() - 2).toString(),
                            text: (today.getFullYear() - 2).toString(),
                          },
                          {
                            value: (today.getFullYear() - 3).toString(),
                            text: (today.getFullYear() - 3).toString(),
                          },
                          {
                            value: (today.getFullYear() - 4).toString(),
                            text: (today.getFullYear() - 4).toString(),
                          },
                        ]}
                        value={value}
                        onChange={onChange}
                      />
                    </EuiFormRow>
                  );
                }}
              />
              <Controller
                name="quarter"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  return (
                    <EuiFormRow fullWidth label="Quarter" isInvalid={!!error} error={error?.message}>
                      <EuiSelect
                        onBlur={onBlur}
                        options={[
                          {
                            value: "Q1",
                            text: "Q1",
                          },
                          {
                            value: "Q2",
                            text: "Q2",
                          },
                          {
                            value: "Q3",
                            text: "Q3",
                          },
                          {
                            value: "Q4",
                            text: "Q4",
                          },
                        ]}
                        value={value}
                        onChange={onChange}
                      />
                    </EuiFormRow>
                  );
                }}
              />
              <EuiSpacer />
              <EuiButton
                // type="submit"
                onClick={() => {
                  // TODO Confirmation modal
                  setIsScanModalVisible(true);
                }}
              >
                Scan
              </EuiButton>
              <EuiSpacer />
              <EuiButton
                onClick={() => {
                  setIsUploadModalVisible(true);
                }}
              >
                Upload
              </EuiButton>
              <SubmitModal
                show={isScanModalVisible}
                title={"Scan"}
                closeModal={() => {
                  setIsScanModalVisible(false);
                }}
                onClickYes={() => {
                  setForm({ year: getValues("year"), quarter: getValues("quarter") });
                  setIsScanModalVisible(false);
                }}
              />

              <SubmitModal
                show={isUploadModalVisible}
                title={"Upload"}
                closeModal={() => {
                  setIsUploadModalVisible(false);
                }}
                onClickYes={() => {
                  uploadProductionReports.mutate();
                  setIsUploadModalVisible(false);
                }}
              />
            </form>

            <EuiSpacer />
            <EuiBasicTable
              items={data.result}
              itemId="uploads"
              columns={columns}
              tableLayout="auto"
              loading={isLoading}
              noItemsMessage="No Production Reports Found"
            />
          </EuiPanel>
          <EuiSpacer />
          <EuiPanel hasBorder>
            <EuiTitle>
              <h1>Errors Table</h1>
            </EuiTitle>
            <EuiBasicTable
              items={data.errors}
              itemId="uploads"
              columns={columns}
              tableLayout="auto"
              loading={isLoading}
              noItemsMessage="No Errors Found"
            />
          </EuiPanel>
          <UploadConfirmationModal
            show={isUploadCompletedModalVisible}
            total={total}
            closeModal={() => {
              setIsUploadCompletedModalVisible(false);
            }}
          />
        </EuiPageContentBody>
      </EuiPageBody>
    </EuiPage>
  );
};

export default ProductionReportsAdmin;

const SubmitModal: React.FC<{
  show: boolean;
  title: string;
  closeModal: () => void;
  onClickYes: () => void;
}> = ({ show, title, closeModal, onClickYes }) => {
  if (!show) {
    return null;
  }
  return (
    <EuiModal onClose={closeModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Are you sure you want to {title} Production Reports?</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody></EuiModalBody>

      <EuiModalFooter>
        <EuiButton
          type="submit"
          onClick={() => {
            onClickYes();
            closeModal();
          }}
          fill
        >
          Yes
        </EuiButton>
        <EuiButton
          onClick={() => {
            closeModal();
          }}
          fill
        >
          No
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

const UploadConfirmationModal: React.FC<{
  show: boolean;
  total: string;
  closeModal: () => void;
}> = ({ show, total, closeModal }) => {
  if (!show) {
    return null;
  }
  return (
    <EuiModal onClose={closeModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Production Reports Upload Completed</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>{total} production reports were uploaded successfully.</EuiModalBody>

      <EuiModalFooter>
        <EuiButton
          onClick={() => {
            closeModal();
          }}
          fill
        >
          Okay
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
