import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFlexGrid,
  EuiFlexItem,
  EuiPage,
  EuiPageBody,
  EuiPanel,
  EuiSpacer,
  EuiStat,
  EuiTableSortingType,
} from "@elastic/eui";
import { useAppSelector } from "app/hooks";
import ErrorPage from "components/ErrorPage";
import EuiCustomLink from "components/EuiCustomLink";
import { selectClusterAgencies } from "features/authorization/authSlice";
import moment from "moment";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { useQuery } from "react-query";
import authAxios from "services/authAxios";
import { Policy } from "types/apiTypes";

interface DataObject {
  policies: Policy[];
  totalPremium: number;
  totalPolicies: number;
}
interface WidgetData {
  totalHOActivePolicies: number;
  totalHOAnnualPremium: number;
  totalLPActivePolicies: number;
  totalLPAnnualPremium: number;
  totalFIActivePolicies: number;
  totalFIAnnualPremium: number;
  totalMOActivePolicies: number;
  totalMOAnnualPremium: number;
  totalCPActivePolicies: number;
  totalCPAnnualPremium: number;
  totalAnnualPremium: number;
  totalActivePolicies: number;
}

const getWidgetData = async () => {
  const { data } = await authAxios.get(`/counts_and_premiums_by_line`);
  return data;
};
function useWidgetData() {
  const useQueryReturn = useQuery<WidgetData>(["widget_data"], () => getWidgetData(), {
    placeholderData: {
      totalHOActivePolicies: 0,
      totalHOAnnualPremium: 0,
      totalLPActivePolicies: 0,
      totalLPAnnualPremium: 0,
      totalFIActivePolicies: 0,
      totalFIAnnualPremium: 0,
      totalMOActivePolicies: 0,
      totalMOAnnualPremium: 0,
      totalCPActivePolicies: 0,
      totalCPAnnualPremium: 0,
      totalAnnualPremium: 0,
      totalActivePolicies: 0,
    },
    initialData: {
      totalHOActivePolicies: 0,
      totalHOAnnualPremium: 0,
      totalLPActivePolicies: 0,
      totalLPAnnualPremium: 0,
      totalFIActivePolicies: 0,
      totalFIAnnualPremium: 0,
      totalMOActivePolicies: 0,
      totalMOAnnualPremium: 0,
      totalCPActivePolicies: 0,
      totalCPAnnualPremium: 0,
      totalAnnualPremium: 0,
      totalActivePolicies: 0,
    },
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
    keepPreviousData: true,
  });
  return {
    ...useQueryReturn,
    data: useQueryReturn.data!,
  };
}

const getPolicies = async (page: number, pageSize: number, sortField: string, sortDirection: string) => {
  const { data } = await authAxios.get(`/policies`, {
    params: { pageSize: pageSize, pageIndex: page, sortField, sortDirection },
  });
  return data;
};

function usePolicies(page: number, pageSize: number, sortField: string, sortDirection: string) {
  const useQueryReturn = useQuery<DataObject>(
    ["policies", page, pageSize, sortField, sortDirection],
    () => getPolicies(page, pageSize, sortField, sortDirection),
    {
      placeholderData: { policies: [], totalPolicies: 0, totalPremium: 0 },
      initialData: { policies: [], totalPolicies: 0, totalPremium: 0 },
      initialDataUpdatedAt: 0,
      staleTime: 1000 * 60 * 15,
      keepPreviousData: true,
    }
  );
  return {
    ...useQueryReturn,
    data: useQueryReturn.data!,
  };
}

const Policies: React.FC = () => {
  const clusterAgents = useAppSelector(selectClusterAgencies);
  // console.log("clusterAgents: ", clusterAgents);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sortField, setSortField] = useState<keyof Policy>("policyID");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  // const { data: TotalActivePolicies, isLoading: isLoadingTotalActivePolicies } = useTotalActivePolicies();
  const { data: WidgetData, isLoading: isLoadingWidgetData } = useWidgetData();
  const { data, isLoading, isError } = usePolicies(pageIndex, pageSize, sortField, sortDirection);
  // console.log("Data: ", data);

  if (isError) {
    return <ErrorPage title="Policies" />;
  }
  const columns: EuiBasicTableColumn<Policy>[] = [];

  if (clusterAgents.length > 1) {
    columns.push({
      field: "agentID",
      name: "Agent ID",
    });
  }

  columns.push(
    {
      field: "policyID",
      name: "Policy Number",
      render: (policyID: string, rowData: Policy) => {
        return (
          <EuiCustomLink to={`/policy/${rowData.recordID}`} external={true}>
            {policyID}
          </EuiCustomLink>
        );
      },
    },
    {
      field: "insured.displayName",
      name: "Insured Name",
      render: (insuredDisplayName: string, rowData: Policy) => {
        if (rowData.insured) {
          return (
            <EuiCustomLink to={`/insured/${rowData.insured.recordID}`} external={true}>
              {insuredDisplayName}
            </EuiCustomLink>
          );
        } else {
          return { insuredDisplayName };
        }
      },
    },
    {
      field: "policyType",
      name: "Policy Type",
    },
    {
      field: "inceptionDate",
      name: "Inception Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "expirationDate",
      name: "Expiration Date",
      render: (date: string) => moment(date).format("l"),
    }
  );

  const totalItemCount = data.totalPolicies;

  const getRowProps = (record: Policy) => {
    const { recordID } = record;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      // onClick: () => {
      //   console.log("clicked on this: ", document);
      // },
    };
  };

  const getCellProps = (record: Policy, column: any) => {
    const { recordID } = record;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };
  const sorting: EuiTableSortingType<Policy> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [10, 20, 50, 100],
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<Policy>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPage>
      <EuiPageBody restrictWidth="1300px">
        {/* totals */}
        <EuiFlexGrid columns={2}>
          <EuiFlexItem>
            <EuiPanel hasBorder>
              <EuiStat
                title={
                  <NumberFormat value={WidgetData.totalActivePolicies} displayType="text" thousandSeparator={true} />
                }
                description="Total Active Policies"
                textAlign="right"
                titleColor="success"
                isLoading={isLoadingWidgetData}
              />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel hasBorder>
              <EuiStat
                title={
                  <NumberFormat
                    value={WidgetData.totalAnnualPremium / 100}
                    displayType="text"
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={0}
                    prefix={"$"}
                  />
                }
                description="Total Annual Premium"
                textAlign="right"
                titleColor="success"
                isLoading={isLoadingWidgetData}
              />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGrid>
        <EuiSpacer />

        <EuiPanel hasBorder>
          <EuiBasicTable
            items={data.policies}
            itemId="ID"
            columns={columns}
            rowProps={getRowProps}
            cellProps={getCellProps}
            pagination={pagination}
            onChange={onTableChange}
            tableLayout="auto"
            sorting={sorting}
            loading={isLoading}
          />
        </EuiPanel>
      </EuiPageBody>
    </EuiPage>
  );
};

export default Policies;
