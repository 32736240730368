import {
  EuiCallOut,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingLogo,
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import ErrorPage from "components/ErrorPage";
import EuiCustomLink from "components/EuiCustomLink";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import { Cancellation } from "types/apiTypes";

import usePolicy from "../../hooks/queries/usePolicy";
import AgentInfo from "./Policy/AgentInfo";
import Cancellations from "./Policy/Cancellations";
import ClaimsHistory from "./Policy/ClaimsHistory";
import Documents from "./Policy/Documents";
import Invoices from "./Policy/Invoices";
import Locations from "./Policy/Locations";
import PaymentHistory from "./Policy/PaymentHistory";
import PolicyInfo from "./Policy/PolicyInfo";

type PageParams = {
  policyID: string;
};

const Policy: React.FC = () => {
  const { policyID } = useParams<PageParams>();
  const { data, isLoading, isError } = usePolicy(policyID, {
    agent: true,
    insured: true,
    invoices: true,
    claims: true,
    documents: true,
    cancellations: true,
    receipts: true,
    quotes: true,
  });
  // console.log("Policy:", data);
  // console.log("Invoices:", data?.invoices);


  if (isError) {
    return <ErrorPage title="Policy" />;
  }

  if (isLoading || data === undefined) {
    return (
      <EuiPage>
        <EuiPageBody restrictWidth="1300px">
          <EuiPanel hasBorder>
            <EuiEmptyPrompt icon={<EuiLoadingLogo logo="filebeatApp" size="xl" />} title={<h2>Loading</h2>} />
          </EuiPanel>
        </EuiPageBody>
      </EuiPage>
    );
  }

  return (
    <EuiPage>
      <EuiPageBody restrictWidth="1300px">
        <EuiPageHeader iconType="home" pageTitle={data?.policyID} />
        <CancellationCallout cancels={data.cancels || []} cancelDate={data.cancelDate} />

        <EuiFlexGroup wrap>
          <EuiFlexItem grow={2}>
            <PolicyInfo policy={data!} insured={data?.insured!} underwriter={data?.underwriter!} />
          </EuiFlexItem>
          <EuiFlexItem>
            <AgentInfo agent={data?.agent!} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer />
        <Locations locations={data?.locations!} />
        {/* <EuiSpacer /> */}
        {/* <Quotes quotes={data?.quotes!} /> */}
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiSpacer />
            <Invoices invoices={data?.invoices!} />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiSpacer />
            <PaymentHistory receipts={data?.receipts!} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiSpacer />
            <Documents documents={data?.documents!} />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiSpacer />
            <Cancellations cancellations={data?.cancels!} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer />
        <ClaimsHistory claims={data?.claims!} />
        <EuiSpacer />
      </EuiPageBody>
    </EuiPage>
  );
};

export default Policy;

const cancelReasons: Record<string, string> = {
  C: "Cancellation",
  C1: "Non-Payment",
  C2: "Returned Check",
  C3: "Underwriting",
  CR: "Conditioned Renewal",
  CRP: "Conditioned Renewal",
  D: "Disclosure",
  NR: "Nonrenewal",
};

interface CancellationCalloutProps {
  cancels: Cancellation[];
  cancelDate: string;
}

function CancellationCallout({ cancels, cancelDate }: CancellationCalloutProps) {
  // console.log("cancel data: ", cancels);
  if (cancelDate) {
    return (
      <>
        <EuiCallOut title="Policy Canceled" color="danger" iconType="flag"></EuiCallOut>
        <EuiSpacer />
      </>
    );
  }
  if (cancels.length === 0) {
    return null;
  }
  const currentCancels = cancels.filter((cancel) => {
    if (cancel.cancelType === "CRP" || cancel.cancelType === "CR" || cancel.cancelType === "D") {
      return false;
    }
    if (cancel.reinstatementDate === null) {
      return true;
    }
    return false;
  });
  if (currentCancels.length === 0) {
    return null;
  }

  return (
    <>
      <EuiCallOut title="Pending Cancellation" color="warning" iconType="flag">
        {currentCancels.map((item) => {
          if (item.premiumDue) {
            return (
              <NumberFormat
                key={item.recordID}
                displayType="text"
                value={item.premiumDue / 100}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
                fixedDecimalScale={true}
                renderText={(text) => {
                  return (
                    <p>
                      In Cancellation for {cancelReasons[item.cancelType] || "Unknown"}
                      {", " + text + " premium due. "}
                      {item.invoiceRecordID === null ? null : (
                        <EuiCustomLink to={`/invoice/${item.invoiceRecordID}`}>Pay Now</EuiCustomLink>
                      )}
                    </p>
                  );
                }}
              />
            );
          }
          return <p key={item.recordID}>In Cancellation for {cancelReasons[item.cancelType] || "Unknown"}</p>;
        })}
      </EuiCallOut>
      <EuiSpacer />
    </>
  );
}
