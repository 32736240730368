import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@elastic/eui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import authAxios from "services/authAxios";
import * as yup from "yup";

interface NewClusterType {
  name: string;
}

const NewClusterSchema = yup
  .object({
    name: yup.string().max(128, "Name cannot be more than 128 characters").required().label("Cluster Name"),
  })
  .required();

export const NewClusterModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const newCluster = useMutation({
    mutationFn: (newCluster: { name: string }) => {
      return authAxios.post("/new-cluster", {
        name: newCluster.name,
      });
    },
  });

  const methods = useForm<NewClusterType>({
    mode: "onTouched",
    resolver: yupResolver(NewClusterSchema),
    defaultValues: {
      name: "",
    },
  });
  const { handleSubmit, control, reset } = methods;

  const onSubmitNewCluster: SubmitHandler<NewClusterType> = (data) => {
    newCluster.mutate(data, {
      // newCluster.mutate(data.name, {
      onSuccess: (data) => {
        // update table here
        reset();
        queryClient.invalidateQueries();
        setIsModalVisible(false);
      },
      onError: (error) => {
        console.log("Error onSubmit:", error);
      },
    });
  };
  const onNewClusterError = (errors: any, e: any) => console.log(errors, e);

  return (
    <>
      <EuiButton
        onClick={() => {
          setIsModalVisible(true);
        }}
      >
        New Cluster
      </EuiButton>
      {isModalVisible ? (
        <EuiModal
          onClose={() => {
            setIsModalVisible(false);
          }}
          initialFocus="[name=popswitch]"
        >
          <form onSubmit={handleSubmit(onSubmitNewCluster, onNewClusterError)} id="NewClusterForm">
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                <h1>New Cluster</h1>
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  return (
                    <EuiFormRow fullWidth label="Cluster Name" isInvalid={!!error} error={error?.message}>
                      <EuiFieldText
                        onChange={onChange}
                        value={value}
                        onBlur={onBlur}
                        isInvalid={!!error}
                        placeholder="Cluster Name"
                        autoFocus
                      />
                    </EuiFormRow>
                  );
                }}
              />
            </EuiModalBody>

            <EuiModalFooter>
              <EuiButtonEmpty
                onClick={() => {
                  setIsModalVisible(false);
                }}
              >
                Cancel
              </EuiButtonEmpty>
              <EuiButton type="submit">Create Cluster</EuiButton>
            </EuiModalFooter>
          </form>
        </EuiModal>
      ) : null}
    </>
  );
};
