import { useQuery } from "react-query";

import authAxios from "../../services/authAxios";

function useAgentEmail() {
  return useQuery<string>(
    ["agent_email"],
    async () => {
      const { data } = await authAxios.get("/agent_email");
      return data;
    },
    {
      placeholderData: "",
    }
  );
}

export default useAgentEmail;
