import { EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow } from "@elastic/eui";
import { FormSelect } from "components/react-hook-form/SelectInput";
import { Fragment, FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

const PaymentAddressView: FunctionComponent = () => {
  return (
    <Fragment key="address">
      <EuiFormRow label="Address">
        <Controller
          name={`address` as `${string}`}
          render={({ field: { ref, ...rest } }) => {
            return <EuiFieldText placeholder="Address" {...rest} />;
          }}
        />
      </EuiFormRow>

      <EuiFormRow>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="City">
              <Controller
                name={`city` as `${string}`}
                render={({ field: { ref, ...rest } }) => {
                  return <EuiFieldText placeholder="City" {...rest} />;
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="State" fullWidth>
              <FormSelect
                name={`state`}
                label={"State"}
                defaultValue="NY"
                rules={{ required: true }}
                options={[
                  { value: "AL", text: "AL" },
                  { value: "AK", text: "AK" },
                  { value: "AZ", text: "AZ" },
                  { value: "AR", text: "AR" },
                  { value: "CA", text: "CA" },
                  { value: "CO", text: "CO" },
                  { value: "CT", text: "CT" },
                  { value: "DE", text: "DE" },
                  { value: "DC", text: "DC" },
                  { value: "FL", text: "FL" },
                  { value: "GA", text: "GA" },
                  { value: "HI", text: "HI" },
                  { value: "ID", text: "ID" },
                  { value: "IL", text: "IL" },
                  { value: "IN", text: "IN" },
                  { value: "IA", text: "IA" },
                  { value: "KS", text: "KS" },
                  { value: "KY", text: "KY" },
                  { value: "LA", text: "LA" },
                  { value: "ME", text: "ME" },
                  { value: "MD", text: "MD" },
                  { value: "MA", text: "MA" },
                  { value: "MI", text: "MI" },
                  { value: "MN", text: "MN" },
                  { value: "MS", text: "MS" },
                  { value: "MO", text: "MO" },
                  { value: "MT", text: "MT" },
                  { value: "NE", text: "NE" },
                  { value: "NV", text: "NV" },
                  { value: "NH", text: "NH" },
                  { value: "NJ", text: "NJ" },
                  { value: "NM", text: "NM" },
                  { value: "NY", text: "NY" },
                  { value: "NC", text: "NC" },
                  { value: "ND", text: "ND" },
                  { value: "OH", text: "OH" },
                  { value: "OK", text: "OK" },
                  { value: "OR", text: "OR" },
                  { value: "PA", text: "PA" },
                  { value: "RI", text: "RI" },
                  { value: "SC", text: "SC" },
                  { value: "SD", text: "SD" },
                  { value: "TN", text: "TN" },
                  { value: "TX", text: "TX" },
                  { value: "UT", text: "UT" },
                  { value: "VT", text: "VT" },
                  { value: "VA", text: "VA" },
                  { value: "WA", text: "WA" },
                  { value: "WV", text: "WV" },
                  { value: "WI", text: "WI" },
                  { value: "WY", text: "WY" },
                ]}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="Zip">
              <Controller
                name={`zip` as `${string}`}
                render={({ field }) => {
                  const { onChange, value, ...rest } = field;
                  return (
                    <NumberFormat
                      placeholder="Zip"
                      customInput={EuiFieldText}
                      decimalScale={0}
                      value={value}
                      format="#####"
                      onValueChange={(target) => {
                        onChange(target.value);
                      }}
                      {...rest}
                    />
                  );
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    </Fragment>
  );
};

export default PaymentAddressView;
