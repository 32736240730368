import { useQuery } from "react-query";
import { Policy } from "types/apiTypes";

import authAxios from "../../services/authAxios";

export const getPoliciesCancelled = async () => {
  const { data } = await authAxios.get(`/cancelled-policies`);
  return data;
};

function usePoliciesCancelled() {
  const useQueryReturn = useQuery<Policy[]>(["policiesCancelled"], () => getPoliciesCancelled(), {
    placeholderData: [],
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
  });
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

export default usePoliciesCancelled;
