import { useQuery } from "react-query";
import { PolicyDocument } from "types/apiTypes";

import authAxios from "../../services/authAxios";

export const getNewDocuments = async () => {
  const { data } = await authAxios.get(`/new-documents`);
  return data;
};

function useNewDocuments() {
  const useQueryReturn = useQuery<PolicyDocument[]>(["newDocuments"], () => getNewDocuments(), {
    placeholderData: [],
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
  });
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

export default useNewDocuments;
