import {
  EuiBadge,
  EuiButton,
  EuiButtonEmpty,
  EuiConfirmModal,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPage,
  EuiPageBody,
  EuiPanel,
  EuiSpacer,
  EuiTitle,
} from "@elastic/eui";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import useChangeEmail from "hooks/queries/mutations/useChangeEmail";
import useChangePassword from "hooks/queries/mutations/usePasswordMutation";
import useVerifyTOTP from "hooks/queries/mutations/useVerifyTOTP";
import useTOTPEnrollURI from "hooks/queries/useTOTPEnrollURI";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import authAxios from "services/authAxios";
import * as yup from "yup";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  refresh,
  selectAdministrator,
  selectAuthTOTP,
  selectEmail,
  selectImpersonateID, // selectUsername,
  selectWCICEmployee,
  setTOTPState,
} from "../features/authorization/authSlice";
import { useToast } from "../hooks/useToasts";

interface ChangeAgentCodeType {
  code: string;
}
interface ChangeEmailType {
  newEmail: string;
  confirmEmail: string;
}

interface ChangePasswordType {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
interface EnableOTPType {
  OTP: string;
}

const EnableOTPSchema = yup
  .object({
    OTP: yup
      .string()
      .required()
      .label("Code")
      .test("len", "Code is 6 or 8 digits", (val) => val!.length === 6 || val!.length === 8),
  })
  .required();
const ChangeAgentCodeSchema = yup
  .object({
    code: yup
      .string()
      .required()
      .label("Agent Code")
      .test("len", "Agent Code is 3 digits", (val) => val!.length === 3),
  })
  .required();

const ChangeEmailSchema = yup
  .object({
    newEmail: yup.string().email().required().label("New Email Address"),
    confirmEmail: yup
      .string()
      .email()
      .required()
      .oneOf([yup.ref("newEmail"), null], "Must match new email")
      .label("Confirm Email Address"),
  })
  .required();

const ChangePasswordSchema = yup
  .object({
    currentPassword: yup.string().required().label("Current Password"),
    newPassword: yup
      .string()
      .min(8, "Password must be at least 8 characters long")
      .matches(/[^a-zA-Z\d]/, "Password requires at least 1 special character")
      .matches(/^(?=.*[a-z])/, "Password requires at least 1 lowercase letter")
      .matches(/^(?=.*[A-Z])/, "Password requires at least 1 uppercase letter")
      .required()
      .label("New Password"),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("newPassword"), null], "Must match new password")
      .label("Confirm Password"),
  })
  .required();

const Profile = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const email = useAppSelector(selectEmail);
  // const username = useAppSelector(selectUsername);
  const impersonate = useAppSelector(selectImpersonateID);
  const employee = useAppSelector(selectWCICEmployee);
  const admin = useAppSelector(selectAdministrator);
  const totpEnabled = useAppSelector(selectAuthTOTP);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const closeEmailModal = () => setIsEmailModalVisible(false);
  const showEmailModal = () => setIsEmailModalVisible(true);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const closePasswordModal = () => setIsPasswordModalVisible(false);
  const showPasswordModal = () => setIsPasswordModalVisible(true);
  const [isOTPModalVisible, setIsOTPModalVisible] = useState(false);
  const closeOTPModal = () => setIsOTPModalVisible(false);
  const showOTPModal = () => setIsOTPModalVisible(true);
  const [isAgentCodeModalVisible, setIsAgentCodeModalVisible] = useState(false);
  const closeAgentCodeModal = () => setIsAgentCodeModalVisible(false);
  const showAgentCodeModal = () => setIsAgentCodeModalVisible(true);
  const [confirmUntrustModalVisible, setConfirmUntrustModalVisible] = useState(false);
  const [trustModalLoading, setTrustModalLoading] = useState(false);
  const [totpDisableModalVisible, setTOTPDisableModalVisible] = useState(false);
  const [totpDisableModalLoading, setTOTPDisableModalLoading] = useState(false);
  const { addToast } = useToast();
  const navigate = useNavigate();

  const disableImpersonation = useMutation(() => {
    return authAxios.post("/disable_impersonation");
  });
  const runDataUpdater = useMutation(() => {
    return authAxios.post("/run_updater");
  });

  let untrustModal;

  if (confirmUntrustModalVisible) {
    untrustModal = (
      <EuiConfirmModal
        style={{ width: 600 }}
        title="Untrust all devices?"
        onCancel={() => setConfirmUntrustModalVisible(false)}
        onConfirm={() => {
          setTrustModalLoading(true);
          authAxios.get("/untrust-devices").then(() => {
            setTrustModalLoading(false);
            setConfirmUntrustModalVisible(false);
            addToast({
              title: "All devices untrusted",
              color: "success",
              text: <p>All trusted devices have been removed for your account.</p>,
            });
          });
        }}
        cancelButtonText="Cancel"
        confirmButtonText="Untrust Devices"
        defaultFocusedButton="confirm"
        buttonColor="danger"
        isLoading={trustModalLoading}
      >
        <p>All trusted devices will be removed from your account.</p>
      </EuiConfirmModal>
    );
  }

  let totpDisableModal;

  if (totpDisableModalVisible) {
    totpDisableModal = (
      <EuiConfirmModal
        style={{ width: 600 }}
        title="Disable Authenticator App?"
        onCancel={() => setTOTPDisableModalVisible(false)}
        onConfirm={() => {
          setTOTPDisableModalLoading(true);
          authAxios.delete("/totp-disable").then(() => {
            dispatch(setTOTPState(false));
            setTOTPDisableModalLoading(false);
            setTOTPDisableModalVisible(false);
            addToast({
              title: "Authenticator App Removed",
              color: "success",
              text: <p>Time Based Authenticator App removed.</p>,
            });
          });
        }}
        cancelButtonText="Cancel"
        confirmButtonText="Remove"
        defaultFocusedButton="confirm"
        buttonColor="danger"
        isLoading={totpDisableModalLoading}
      >
        <p>This will remove the Time Based Authenticator App from your account.</p>
      </EuiConfirmModal>
    );
  }
  return (
    <EuiPage>
      <EuiPageBody restrictWidth="1300px">
        <EuiTitle>
          <h1>Settings</h1>
        </EuiTitle>
        <EuiSpacer />

        <EuiFlexGroup direction="column">
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiPanel hasBorder>
                  Email Address: {email}
                  <EuiSpacer />
                  <EuiButton style={{ width: "180px" }} onClick={showEmailModal}>
                    Change Email Address
                  </EuiButton>
                  <EuiSpacer />
                  <EuiButton style={{ width: "180px" }} onClick={showPasswordModal}>
                    Change Password
                  </EuiButton>
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiPanel hasBorder>
                  Multifactor
                  <EuiSpacer />
                  {totpEnabled ? (
                    <EuiButton onClick={() => setTOTPDisableModalVisible(true)}>Disable Authenticator App</EuiButton>
                  ) : (
                    <EuiButton onClick={showOTPModal}>Enable Authenticator App</EuiButton>
                  )}
                  <EuiSpacer />
                  <EuiButton
                    style={{ width: "180px" }}
                    onClick={() => {
                      setConfirmUntrustModalVisible(true);
                    }}
                  >
                    Untrust All Devices
                  </EuiButton>
                </EuiPanel>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>

          {employee ? (
            <EuiFlexItem>
              <EuiTitle>
                <h2>WCIC Employees</h2>
              </EuiTitle>
              <EuiSpacer />
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiPanel hasBorder>
                    Impersonating:{" "}
                    {impersonate === null ? (
                      <EuiBadge color="primary">Employee</EuiBadge>
                    ) : (
                      <EuiBadge color="warning">{impersonate}</EuiBadge>
                    )}
                    <EuiSpacer />
                    <EuiButton style={{ width: "180px" }} onClick={showAgentCodeModal}>
                      Change Agency Code
                    </EuiButton>
                    <EuiSpacer />
                    <EuiButton
                      style={{ width: "180px" }}
                      isDisabled={impersonate === null ? true : false}
                      onClick={() => {
                        disableImpersonation.mutate(undefined, {
                          onSuccess: (data) => {
                            dispatch(refresh());
                            queryClient.invalidateQueries();
                          },
                        });
                      }}
                    >
                      Turn off Impersonation
                    </EuiButton>
                  </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiPanel hasBorder>
                    Scheduled Tasks
                    <EuiSpacer />
                    <EuiButton
                      style={{ width: "180px" }}
                      isDisabled={admin ? false : true}
                      onClick={() => {
                        runDataUpdater.mutate();
                      }}
                    >
                      Run Data Updater
                    </EuiButton>
                  </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiPanel hasBorder>
                    Production Reports
                    <EuiSpacer />
                    <EuiButton
                      style={{ width: "180px" }}
                      isDisabled={admin ? false : true}
                      onClick={() => {
                        navigate("/production_reports_admin");
                      }}
                    >
                      Admin Control
                    </EuiButton>
                  </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiPanel hasBorder>
                    Test Email
                    <EuiSpacer />
                    <EuiButton
                      style={{ width: "180px" }}
                      isDisabled={admin ? false : true}
                      onClick={() => {
                        authAxios.post(`/send_test_email`);
                      }}
                    >
                      Send To Brandon
                    </EuiButton>
                  </EuiPanel>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          ) : (
            <EuiFlexItem></EuiFlexItem>
          )}
        </EuiFlexGroup>
        {untrustModal}
        {totpDisableModal}
        {isOTPModalVisible ? <EnableTOTPModal onClose={closeOTPModal} /> : null}
        {isEmailModalVisible ? <ChangeEmailModal onClose={closeEmailModal} /> : null}
        {isPasswordModalVisible ? <ChangePasswordModal onClose={closePasswordModal} /> : null}
        {isAgentCodeModalVisible ? <ChangeAgentCodeModal onClose={closeAgentCodeModal} /> : null}
      </EuiPageBody>
    </EuiPage>
  );
};

export default Profile;

interface ChangeEmailModalProps {
  onClose: () => void;
}

const ChangeEmailModal = ({ onClose }: ChangeEmailModalProps) => {
  const changeEmail = useChangeEmail();
  const { addToast } = useToast();
  const methods = useForm<ChangeEmailType>({
    mode: "onTouched",
    resolver: yupResolver(ChangeEmailSchema),
    defaultValues: {
      newEmail: "",
      confirmEmail: "",
    },
  });
  const { handleSubmit, control, reset } = methods;
  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const onSubmit: SubmitHandler<ChangeEmailType> = (data) => {
    changeEmail.mutate(data.newEmail, {
      onSuccess: (data) => {
        addToast({
          title: "Email Changed Successfully",
          color: "success",
          text: <p>Thanks!</p>,
        });
        reset();
        onClose();
      },
      onError: (error) => {},
    });
  };
  const onEmailError = (errors: any, e: any) => console.log(errors, e);
  return (
    <EuiModal onClose={onClose} initialFocus="[name=popswitch]">
      <form onSubmit={handleSubmit(onSubmit, onEmailError)} id={"changeEmailForm"}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Change Email Address</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <Controller
            name="newEmail"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <EuiFormRow fullWidth label="New Email" isInvalid={!!error} error={error?.message}>
                  <EuiFieldText
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    isInvalid={!!error}
                    placeholder="New Email"
                    autoFocus
                  />
                </EuiFormRow>
              );
            }}
          />
          <EuiSpacer />
          <Controller
            name="confirmEmail"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <EuiFormRow fullWidth label="Confirm Email" isInvalid={!!error} error={error?.message}>
                  <EuiFieldText
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    isInvalid={!!error}
                    placeholder="Confirm Email"
                  />
                </EuiFormRow>
              );
            }}
          />
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
          <EuiButton type="submit">Change Email</EuiButton>
        </EuiModalFooter>
      </form>
    </EuiModal>
  );
};

interface EnableTOTPModalProps {
  onClose: () => void;
}

const EnableTOTPModal = ({ onClose }: EnableTOTPModalProps) => {
  const { addToast } = useToast();
  const { data } = useTOTPEnrollURI();
  const mutation = useVerifyTOTP();
  const dispatch = useAppDispatch();
  const methods = useForm<EnableOTPType>({
    mode: "onTouched",
    resolver: yupResolver(EnableOTPSchema),
  });
  const { handleSubmit, control, setError } = methods;

  const onOTPSubmit = (data: EnableOTPType) => {
    mutation.mutate(data.OTP, {
      onSuccess: () => {
        dispatch(setTOTPState(true));
        addToast({
          title: "Authenticator Enabled",
          color: "success",
          text: <p>Authenticator app successfully enabled.</p>,
        });
        onClose();
      },
      onError: () => {
        setError("OTP", {
          type: "manual",
          message: "Code not valid",
        });
      },
    });
  };
  const onOTPError = (errors: any, e: any) => console.log(errors, e);
  return (
    <EuiModal onClose={onClose} initialFocus="[name=popswitch]">
      <form onSubmit={handleSubmit(onOTPSubmit, onOTPError)} id={"changePasswordForm"}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Enable OTP</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          {data ? <QRCodeSVG value={data} /> : <EuiLoadingSpinner size="xxl" />}
          <EuiSpacer />
          <Controller
            name="OTP"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <EuiFormRow fullWidth label="Confirm One Time Password" isInvalid={!!error} error={error?.message}>
                  <EuiFieldText
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    isInvalid={!!error}
                    autoComplete="password"
                    placeholder="One Time Password"
                    autoFocus
                  />
                </EuiFormRow>
              );
            }}
          />
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
          <EuiButton type="submit">Enable OTP</EuiButton>
        </EuiModalFooter>
      </form>
    </EuiModal>
  );
};

interface ChangePasswordModalProps {
  onClose: () => void;
}

const ChangePasswordModal = ({ onClose }: ChangePasswordModalProps) => {
  const changePassword = useChangePassword();
  const { addToast } = useToast();
  const methods = useForm<ChangePasswordType>({
    mode: "onTouched",
    resolver: yupResolver(ChangePasswordSchema),
  });
  const { handleSubmit, control, reset, setError } = methods;
  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);
  const onPasswordSubmit: SubmitHandler<ChangePasswordType> = (data) => {
    changePassword.mutate(data, {
      onSuccess: (data) => {
        addToast({
          title: "Password Changed Successfully",
          color: "success",
          text: <p>Thanks!</p>,
        });
        reset();
        onClose();
      },
      onError: (error) => {
        if (error instanceof Error) {
          const axiosError = error as AxiosError;
          setError(axiosError.response?.data.input, {
            type: "manual",
            message: axiosError.response?.data.message,
          });
        }
      },
    });
  };

  const onPasswordError = (errors: any, e: any) => console.log(errors, e);
  return (
    <EuiModal onClose={onClose} initialFocus="[name=popswitch]">
      <form onSubmit={handleSubmit(onPasswordSubmit, onPasswordError)} id={"changePasswordForm"}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Change Password</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <Controller
            name="currentPassword"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <EuiFormRow fullWidth label="Current Password" isInvalid={!!error} error={error?.message}>
                  <EuiFieldText
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    type="password"
                    isInvalid={!!error}
                    placeholder="Current Password"
                    autoFocus
                  />
                </EuiFormRow>
              );
            }}
          />
          <EuiSpacer />
          <Controller
            name="newPassword"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <EuiFormRow fullWidth label="New Password" isInvalid={!!error} error={error?.message}>
                  <EuiFieldText
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    type="password"
                    isInvalid={!!error}
                    placeholder="New Password"
                  />
                </EuiFormRow>
              );
            }}
          />
          <EuiSpacer />
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <EuiFormRow fullWidth label="Confirm Password" isInvalid={!!error} error={error?.message}>
                  <EuiFieldText
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    type="password"
                    isInvalid={!!error}
                    placeholder="Confirm Password"
                  />
                </EuiFormRow>
              );
            }}
          />
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
          <EuiButton type="submit">Change Password</EuiButton>
        </EuiModalFooter>
      </form>
    </EuiModal>
  );
};
interface ChangeAgentCodeModalProps {
  onClose: () => void;
}

const ChangeAgentCodeModal = ({ onClose }: ChangeAgentCodeModalProps) => {
  // const useAgentCode = useMutation((code: ChangeAgentCodeType) => {
  //   console.log(code);
  //   return authAxios.post("/change_agent_code", code);
  // });
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const useAgentCode = () => {
    return useMutation(({ code }: { code: string }) =>
      authAxios.post("/change_agent_code", {
        code: `AGT*${code}`,
      })
    );
  };
  const changeAgentCode = useAgentCode();
  const { addToast } = useToast();
  const methods = useForm<ChangeAgentCodeType>({
    mode: "onTouched",
    resolver: yupResolver(ChangeAgentCodeSchema),
  });
  const { handleSubmit, control, reset, setError } = methods;
  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);
  const onAgentCodeSubmit: SubmitHandler<ChangeAgentCodeType> = (data) => {
    changeAgentCode.mutate(data, {
      onSuccess: (data) => {
        addToast({
          title: "Agent Code Changed Successfully",
          color: "success",
          text: <p>Thanks!</p>,
        });
        reset();
        dispatch(refresh());
        queryClient.invalidateQueries();
        onClose();
      },
      onError: (error) => {
        if (error instanceof Error) {
          const axiosError = error as AxiosError;
          setError(axiosError.response?.data.input, {
            type: "manual",
            message: axiosError.response?.data.message,
          });
        }
      },
    });
  };

  const onAgentCodeError = (errors: any, e: any) => console.log(errors, e);
  return (
    <EuiModal onClose={onClose} initialFocus="[name=popswitch]">
      <form onSubmit={handleSubmit(onAgentCodeSubmit, onAgentCodeError)} id={"changeAgentCodeForm"}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Change Agent Code</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <Controller
            name="code"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <EuiFormRow fullWidth label="Agent Code" isInvalid={!!error} error={error?.message}>
                  <EuiFieldText
                    prepend={"AGT"}
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    type="text"
                    isInvalid={!!error}
                    placeholder="Agent ID"
                    autoFocus
                  />
                </EuiFormRow>
              );
            }}
          />
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
          <EuiButton type="submit">Change Agent Code</EuiButton>
        </EuiModalFooter>
      </form>
    </EuiModal>
  );
};
