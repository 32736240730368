import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiTableSortingType,
} from "@elastic/eui";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import authAxios from "services/authAxios";
import pageAndSort from "services/pageAndSort";
import { ReportedClaim } from "types/apiTypes";
import * as yup from "yup";

interface QueryType {
  name: string;
  policyID: string;
  location: string;
}

const QueryTypeSchema = yup.object({
  name: yup.string().label("Name").max(128, "Name cannot be longer than 128 characters"),
  policyID: yup.string().label("Name").max(8, "Policy number cannot exceed 8 characters"),
  location: yup.string().label("Name").max(128, "Address cannot be longer than 128 characters"),
});

interface TableData {
  recordID: number;
  policyID: string;
  description: string;
  dateOfLoss: string;
  timestamp: string;

  reportedClaim: ReportedClaim;
}

// query contains all data get by backend
const getReportedClaims = async (query: QueryType) => {
  const { data } = await authAxios.post(`/posted-claims`, {
    name: query.name,
    policyID: query.policyID,
    location: query.location,
  });
  return data;
};

function useReportedClaims(query: QueryType) {
  const useQueryReturn = useQuery<ReportedClaim[]>(["reportedClaims", query], () => getReportedClaims(query), {
    placeholderData: [],
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 0,
  });
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

const ReportedClaims: React.FC = () => {
  const navigate = useNavigate();
  const methods = useForm<QueryType>({
    mode: "onTouched",
    resolver: yupResolver(QueryTypeSchema),
    defaultValues: {
      name: "",
      policyID: "",
      location: "",
    },
  });
  const { getValues } = methods;

  const [reportedQuery] = useState<QueryType>(getValues);

  // const onSubmit: SubmitHandler<QueryType> = (data) => {
  //   setReportedQuery(data);
  // };
  const { data, isLoading } = useReportedClaims(reportedQuery);
  // console.log("Data: ", data);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof TableData>("timestamp");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const dataTable: TableData[] = data.map((record) => {
    return {
      recordID: record.recordID,
      policyID: record.policyID,
      name: record.name,
      location: record.location,
      description: record.description,
      dateOfLoss: record.dateOfLoss,
      timestamp: record.timestamp,
      reportedClaim: record,
    };
  });

  const columns: EuiBasicTableColumn<TableData>[] = [
    {
      field: "policyID",
      name: "Policy ID",
      width: "100px",
    },
    {
      field: "name",
      name: "Reported By",
      width: "150px",
    },
    {
      field: "location",
      name: "Location",
      width: "240px",
    },
    {
      field: "description",
      name: "Description",
      width: "240px",
      truncateText: true,
      render: (description: string) => {
        return description;
      },
    },
    {
      field: "dateOfLoss",
      align: "right",
      width: "90px",
      name: "Date of Loss",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "timestamp",
      align: "right",
      width: "90px",
      name: "Reported",
      render: (date: string) => moment(date).format("l"),
    },
    {
      name: "Actions",
      width: "80px",
      actions: [
        {
          name: "View",
          description: "View Reported Claim",
          type: "icon",
          icon: "copy",
          onClick: (document: TableData) => {
            navigate(`/reported_claim/${document.recordID}`);
          },
        },
      ],
    },
  ];

  const { pageOfItems, totalItemCount } = pageAndSort(dataTable, { pageIndex, pageSize, sortField, sortDirection });

  const getRowProps = (record: TableData) => {
    const { recordID } = record.reportedClaim;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
    };
  };

  const getCellProps = (record: TableData, column: any) => {
    const { recordID } = record.reportedClaim;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };

  const sorting: EuiTableSortingType<TableData> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
  };

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [15, 10, 5],
    hidePerPageOptions: !showPerPageOptions,
  };

  const onTableChange = ({ page, sort }: CriteriaWithPagination<TableData>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  // const onError = (errors: any, e: any) => console.log(errors, e);
  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageHeader
          pageTitle="Reported Claims"
          restrictWidth="1200px"
          rightSideItems={[
            <EuiButton
              onClick={() => {
                navigate("/report_claim");
              }}
            >
              Report New Claim
            </EuiButton>,
          ]}
        />
        <EuiPageContent hasBorder={false} hasShadow={false} paddingSize="none" color="transparent" borderRadius="none">
          <EuiPageContentBody restrictWidth="1200px">
            <EuiPanel hasBorder>
              <EuiBasicTable
                items={pageOfItems}
                itemId="ID"
                columns={columns}
                rowProps={getRowProps}
                cellProps={getCellProps}
                pagination={pagination}
                onChange={onTableChange}
                sorting={sorting}
                loading={isLoading}
              />
            </EuiPanel>
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
};

export default ReportedClaims;
