import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel, EuiSpacer, EuiStat } from "@elastic/eui";

const DashboardWidgets: React.FC<{ widgetData: any; isLoading: boolean; onSelect: (id: string) => void }> = ({
  widgetData,
  isLoading,
  onSelect,
}) => {
  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel
            onClick={() => {
              onSelect("new-policy-documents");
            }}
          >
            <EuiStat
              title={typeof widgetData.newDocuments === "number" ? widgetData.newDocuments : "NA"}
              description="New Policy Documents"
              textAlign="right"
              titleColor="success"
              isLoading={isLoading}
            >
              <EuiIcon type="documents" color="success" />
            </EuiStat>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel
            onClick={() => {
              onSelect("pending-claims");
            }}
          >
            <EuiStat
              title={typeof widgetData.pendingClaims === "number" ? widgetData.pendingClaims : "NA"}
              description="Pending Claims"
              titleColor="success"
              textAlign="right"
              isLoading={isLoading}
            >
              <EuiIcon type="cloudDrizzle" color="success" />
            </EuiStat>
          </EuiPanel>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiPanel
            onClick={() => {
              onSelect("policies-pending-issuance");
            }}
          >
            <EuiStat
              title={typeof widgetData.pendingIssuance === "number" ? widgetData.pendingIssuance : "NA"}
              description="Policies Pending Issuance"
              titleColor="success"
              textAlign="right"
              isLoading={isLoading}
            >
              <EuiIcon type="clock" color="success" />
            </EuiStat>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />

      <EuiFlexGroup>
        {/* Keep last 3 together */}
        <EuiFlexItem>
          <EuiPanel
            onClick={() => {
              onSelect("pending-cancellation");
            }}
          >
            <EuiStat
              title={typeof widgetData.pendingCancellation === "number" ? widgetData.pendingCancellation : "NA"}
              description="Pending Cancellation"
              titleColor="success"
              textAlign="right"
              isLoading={isLoading}
            >
              <EuiIcon type="flag" color="success" />
            </EuiStat>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel
            onClick={() => {
              onSelect("policies-reinstated-last-30");
            }}
          >
            <EuiStat
              title={typeof widgetData.reinstatedLast30 === "number" ? widgetData.reinstatedLast30 : "NA"}
              description="Policies Reinstated within last 30 Days"
              titleColor="success"
              textAlign="right"
              isLoading={isLoading}
            >
              <EuiIcon type="refresh" color="success" />
            </EuiStat>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel
            onClick={() => {
              onSelect("policies-cancelled-last-30");
            }}
          >
            <EuiStat
              title={typeof widgetData.canelledLast30 === "number" ? widgetData.canelledLast30 : "NA"}
              description="Policies Canceled within last 30 Days"
              titleColor="success"
              textAlign="right"
              isLoading={isLoading}
            >
              <EuiIcon type="alert" color="success" />
            </EuiStat>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default DashboardWidgets;
