import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiPanel, EuiSpacer, EuiText, EuiTitle } from "@elastic/eui";
// import NumberFormat from "react-number-format";
// import WCICLoadingData from "../WCICLoadingData";
import { WCICTextLabel } from "components";
import moment from "moment";
import { Claim, Insured } from "types/apiTypes";

const ClaimInfo: React.FC<{ claim: Claim; isLoading: boolean }> = ({ claim, isLoading }) => {
  function nameFormat(firstName: string, lastName: string) {
    const keyString = "claimant-name";
    let result = "";
    if (firstName !== null && lastName !== null) {
      result = firstName + " " + lastName;
    } else if (firstName !== null && lastName === null) {
      result = firstName;
    } else if (firstName === null && lastName !== null) {
      result = lastName;
    }
    // else neither exist, this shouldn't happen
    else {
      result = "Insured Info";
    }
    return (
      <EuiText key={keyString} style={{ paddingLeft: "10px" }}>
        {result}
      </EuiText>
    );
  }

  function addressFormat(insured: Insured) {
    if (insured.street2 === null) {
      return (
        <>
          <WCICTextLabel label="Address">
            {/* <EuiText style={{ paddingLeft: "10px" }}>Address</EuiText> */}
          </WCICTextLabel>
          <EuiText style={{ paddingLeft: "10px" }}>{insured.street1 ? insured.street1 : "Not Provided"}</EuiText>
          <EuiText style={{ paddingLeft: "10px" }}>
            {insured.city ? insured.city + ", " : "Not Provided"}
            {insured.state ? insured.state + " " : "Not Provided"}
            {insured.zip ? insured.zip : "Not Provided"}
          </EuiText>
          <EuiSpacer size="s" />
        </>
      );
    }

    return (
      <>
        <WCICTextLabel label="Address"></WCICTextLabel>
        <EuiText style={{ paddingLeft: "10px" }}>{insured.street1 ? insured.street1 : "Not Provided"}</EuiText>
        <EuiText style={{ paddingLeft: "10px" }}>{insured.street2 ? insured.street2 : <></>}</EuiText>
        <EuiText style={{ paddingLeft: "10px" }}>
          {insured.city ? insured.city + ", " : "Not Provided"}
          {insured.state ? insured.state + " " : "Not Provided"}
          {insured.zip ? insured.zip : "Not Provided"}
        </EuiText>
      </>
    );
  }

  const data = claim;
  return (
    <EuiFlexItem grow={2}>
      <EuiPanel hasBorder>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="s">
              <h2>Claim Details</h2>
            </EuiTitle>

            <EuiSpacer size="s" />
            <WCICTextLabel label="Cause of Loss">
              <EuiText style={{ paddingLeft: "10px" }}>
                {data?.causeOfLoss ? data.causeOfLoss.description : "Not Provided"}
              </EuiText>
            </WCICTextLabel>

            <EuiSpacer size="s" />
            <WCICTextLabel label="Description">
              <EuiText style={{ paddingLeft: "10px" }}>{data?.description ? data.description : "Not Provided"}</EuiText>
            </WCICTextLabel>

            <EuiSpacer size="s" />
            <WCICTextLabel label="Reported">
              <EuiText style={{ paddingLeft: "10px" }}>
                {data?.reported ? moment(data.reported).format("l") : "Not Provided"}
              </EuiText>
            </WCICTextLabel>

            <EuiSpacer size="s" />
            <WCICTextLabel label="Date of Loss">
              <EuiText style={{ paddingLeft: "10px" }}>
                {data?.dateOfLoss ? moment(data.dateOfLoss).format("l") : "Not Provided"}
              </EuiText>
            </WCICTextLabel>

            <EuiSpacer size="s" />
            <WCICTextLabel label="Closed">
              <EuiText style={{ paddingLeft: "10px" }}>
                {data?.closed ? (
                  <EuiText color="danger">Closed on {moment(data.closed).format("l")}</EuiText>
                ) : (
                  <EuiText color="success">Active</EuiText>
                )}
              </EuiText>
            </WCICTextLabel>
            <EuiSpacer size="s" />
            <WCICTextLabel label="Adjustor">
              <EuiText style={{ paddingLeft: "10px" }}>
                {data?.adjustor !== null ? data?.adjustor.displayName : "Not Provided"}
              </EuiText>
              {data?.adjustor !== null ? (
                <EuiLink style={{ paddingLeft: "10px" }} href={`mailto:` + data?.adjustor.emailAddress}>
                  {data?.adjustor.emailAddress}
                </EuiLink>
              ) : (
                <></>
              )}
            </WCICTextLabel>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiTitle size="s">
              <h3>Claimant Info</h3>
            </EuiTitle>
            <EuiSpacer size="s" />
            <WCICTextLabel label="Claimant Name">
              <EuiText style={{ paddingLeft: "10px" }}>
                {nameFormat(data?.claimant?.firstName, data?.claimant?.lastName)}
              </EuiText>
            </WCICTextLabel>

            <EuiSpacer size="s" />
            {addressFormat(data?.claimant)}
            <EuiSpacer size="s" />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </EuiFlexItem>
  );
};

export default ClaimInfo;
