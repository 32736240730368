import axios from "axios";
import { useQuery } from "react-query";
import { SearchData } from "types/apiTypes";

const getAddressAutoComplete = async (query: string) => {
  const { data } = await axios.get("https://us-autocomplete-pro.api.smartystreets.com/lookup", {
    params: { key: "105549226754346602", search: query, prefer_geolocation: "CITY", prefered_states: "NY" },
  });
  return data.suggestions;
};

function useAddressAutoComplete(query: string, enableQuery: boolean) {
  return useQuery<SearchData[]>(["address_autocomplete", { query: query }], () => getAddressAutoComplete(query), {
    enabled: enableQuery,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    placeholderData: [],
  });
}

export default useAddressAutoComplete;
