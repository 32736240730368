import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiPage,
  EuiPageBody,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiTableSortingType,
} from "@elastic/eui";
import { useAPIURL } from "hooks/useAPIURL";
import { useState } from "react";
import { useQuery } from "react-query";
import pageAndSort from "services/pageAndSort";

import authAxios from "../../services/authAxios";

interface DataObject {
  productionReports: ProductionReportTable[];
}

interface ProductionReportTable {
  recordID: number;
  groupID: number;
  agentID: string;
  year: string;
  quarter: string;
  productionGroup?: { groupName: string };
}

function useProductionReports() {
  const useQueryReturn = useQuery<DataObject>(
    ["production_reports"],
    async () => {
      const { data } = await authAxios.get(`/production_reports`);
      return data;
    },
    {
      placeholderData: { productionReports: [] },
      initialData: { productionReports: [] },
      initialDataUpdatedAt: 0,
      staleTime: 1000 * 60 * 15,
      keepPreviousData: true,
    }
  );
  return {
    ...useQueryReturn,
    data: useQueryReturn.data!,
  };
}

const ProductionReports: React.FC = () => {
  const defaultURL = useAPIURL();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof ProductionReportTable>("agentID");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const { data, isLoading } = useProductionReports();
  // console.log("data: ", data);
  const columns: EuiBasicTableColumn<ProductionReportTable>[] = [
    {
      field: "",
      name: "Agent ID/Group Name",
      render: (record: ProductionReportTable) => {
        // console.log("record: ", record);
        if (record.agentID === null) {
          return record.productionGroup?.groupName;
        }
        if (record.groupID === null) {
          return record.agentID;
        } else return "error";
      },
    },
    {
      field: "year",
      name: "Year",
    },
    {
      field: "quarter",
      name: "Quarter",
    },
    {
      name: "Download",
      width: "80px",
      actions: [
        {
          name: "Download",
          description: "Download File",
          type: "icon",
          icon: "download",
          onClick: (record: ProductionReportTable) => {
            window.open(`${defaultURL}/production_reports/file/download/${record.recordID}`, "_blank");
          },
        },
      ],
    },
  ];

  const { pageOfItems, totalItemCount } = pageAndSort(data.productionReports, {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
  });
  const getRowProps = (record: ProductionReportTable) => {
    return {
      onClick: () => {
        window.open(`${defaultURL}/production_reports/file/view/${record.recordID}`, "_blank");
      },
    };
  };

  const sorting: EuiTableSortingType<ProductionReportTable> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
  };

  const onTableChange = ({ page, sort }: CriteriaWithPagination<ProductionReportTable>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [10, 20, 50, 100],
    hidePerPageOptions: !showPerPageOptions,
  };
  return (
    <>
      <EuiPage>
        <EuiPageBody>
          <EuiPageHeader pageTitle="Production Reports" restrictWidth="1300px" />
          <EuiPageContentBody restrictWidth="1300px">
            <EuiPanel hasBorder>
              <EuiBasicTable
                items={pageOfItems}
                itemID="reports"
                columns={columns}
                rowProps={getRowProps}
                pagination={pagination}
                onChange={onTableChange}
                tableLayout="auto"
                sorting={sorting}
                loading={isLoading}
              />
            </EuiPanel>
          </EuiPageContentBody>
        </EuiPageBody>
      </EuiPage>
    </>
  );
};

export default ProductionReports;
