import { EuiPage, EuiPageBody, EuiSpacer, EuiTitle } from "@elastic/eui";

import FormsTable from "./FormsTable";

const Forms: React.FC = () => {
  return (
    <EuiPage>
      <EuiPageBody restrictWidth="1300px">
        <EuiTitle>
          <h3>URB Forms</h3>
        </EuiTitle>
        <EuiSpacer size="m" />
        <FormsTable />
      </EuiPageBody>
    </EuiPage>
  );
};

export default Forms;
