import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiPage,
  EuiPageBody,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
  EuiTableSortingType,
  EuiText,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import EuiCustomLink from "components/EuiCustomLink";
import { useAPIURL } from "hooks/useAPIURL";
import moment from "moment";
import { extensionIcon, humanFileSize } from "pages/underwriting/documents/UploadedDocument";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import pageAndSort from "services/pageAndSort";
import { File } from "types/apiTypes";

import WCICLoadingData from "../../components/WCICLoadingData";
import useClaimsTypeEnum from "../../hooks/queries/enums/claims/useClaimTypesEnum";
import useReportedClaim from "../../hooks/queries/useReportedClaim";

type PageParams = {
  reportID: string;
};

interface TableData {
  recordID: number;
  fileSize: number;
  fileName: string;
  fileExtension: string;

  file: File;
}

function habitable(situation: number) {
  if (situation === 0) {
    return "No";
  } else if (situation === 1) {
    return "Yes";
  } else {
    return "Not Provided";
  }
}

const ReportedClaim: React.FC = () => {
  const defaultURL = useAPIURL();
  const { reportID } = useParams<PageParams>();
  const { data, isLoading } = useReportedClaim(reportID);
  // console.log("Reported Claim: ", data);
  const { data: claimTypeEnum, isLoading: claimTypeisLoading } = useClaimsTypeEnum();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof TableData>("fileSize");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  let dataTable: TableData[] = [];
  try {
    dataTable = data!.files.map((record) => {
      return {
        recordID: record.recordID,
        fileSize: record.size,
        fileName: record.name,
        fileExtension: record.extension,
        file: record,
      };
    });
  } catch {
    dataTable = [];
  }
  // console.log("dataTable: ", dataTable);

  const columns: EuiBasicTableColumn<TableData>[] = [
    {
      field: "fileName",
      name: "Name",
      render: (fileName: string, rowData: TableData) => (
        <span>
          <EuiIcon
            // type={"accessibility"}
            type={extensionIcon(rowData.fileExtension)}
            size="m"
            style={{ verticalAlign: "text-top", marginRight: "10px" }}
          />{" "}
          {fileName}
        </span>
      ),
    },
    {
      field: "fileSize",
      name: "Size",
      width: "80px",

      render: (size: number) => {
        return humanFileSize(size);
      },
    },
    {
      name: "Download",
      width: "80px",
      actions: [
        {
          name: "Download",
          description: "Download File",
          type: "icon",
          icon: "download",
          onClick: (record: TableData) => {
            window.open(`${defaultURL}/reported_claim/file/view/${record.recordID}`, "_blank");
          },
        },
      ],
    },
  ];

  const { pageOfItems, totalItemCount } = pageAndSort(dataTable, { pageIndex, pageSize, sortField, sortDirection });

  const getRowProps = (record: TableData) => {
    const { recordID } = record.file;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      onClick: () => {
        window.open(`${defaultURL}/reported_claim/file/download/${record.recordID}`, "_blank");
      },
    };
  };

  const getCellProps = (record: TableData, column: any) => {
    const { recordID } = record.file;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };

  const sorting: EuiTableSortingType<TableData> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
  };

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [15, 10, 5],
    hidePerPageOptions: !showPerPageOptions,
  };

  const onTableChange = ({ page, sort }: CriteriaWithPagination<TableData>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageHeader pageTitle="Reported Claim" restrictWidth="1200px" />
        <EuiPageContentBody restrictWidth="1200px">
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiPanel className="wcicReportedClaimDetailsPanel" hasBorder>
                <EuiTitle size="s">
                  <h2 id="flyoutLargeTitle">Claim Details</h2>
                </EuiTitle>
                <EuiSpacer size="s" />
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Policy ID</strong>
                  </EuiTextColor>
                </label>
                <WCICLoadingData isLoading={isLoading}>
                  <EuiSpacer size="xs" />
                  <EuiCustomLink style={{ paddingLeft: "10px" }} to={`/policy/${data?.policy.recordID}`}>
                    {data?.policyID ? data?.policyID : "Not Provided"}
                  </EuiCustomLink>
                </WCICLoadingData>
                <EuiSpacer size="xs" />
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Date of Loss</strong>
                  </EuiTextColor>
                </label>
                <WCICLoadingData isLoading={isLoading}>
                  <EuiText style={{ paddingLeft: "10px" }}>
                    {data?.dateOfLoss ? moment(data.dateOfLoss).format("ll") : "Not Provided"}
                  </EuiText>
                </WCICLoadingData>
                <EuiSpacer size="xs" />
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Loss Type</strong>
                  </EuiTextColor>
                </label>
                <WCICLoadingData isLoading={isLoading || claimTypeisLoading}>
                  <EuiText style={{ paddingLeft: "10px" }}>
                    {data?.claimType
                      ? claimTypeEnum?.find((claimType) => claimType.value === data.claimType)?.text
                      : "Not Provided"}
                  </EuiText>
                </WCICLoadingData>
                <EuiSpacer size="xs" />
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Habitable</strong>
                  </EuiTextColor>
                </label>
                <WCICLoadingData isLoading={isLoading || claimTypeisLoading}>
                  <EuiText style={{ paddingLeft: "10px" }}>
                    {data?.situation ? habitable(data.situation) : "Not Provided"}
                  </EuiText>
                </WCICLoadingData>
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel hasBorder>
                <EuiTitle size="s">
                  <h2>Location Info</h2>
                </EuiTitle>
                <EuiSpacer size="s" />
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Address</strong>
                  </EuiTextColor>
                </label>
                <EuiText style={{ paddingLeft: "10px" }}>{data?.location ? data.location : "Not Provided"}</EuiText>
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel className="wcicReportedClaimDetailsPanel" hasBorder>
                <EuiTitle size="xs">
                  <h2 id="flyoutLargeTitle">Reported By</h2>
                </EuiTitle>
                <EuiSpacer size="xs" />
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Name</strong>
                  </EuiTextColor>
                </label>
                <WCICLoadingData isLoading={isLoading}>
                  <EuiText style={{ paddingLeft: "10px" }}>{data?.name ? data.name : "Not Provided"}</EuiText>
                </WCICLoadingData>
                <EuiSpacer size="xs" />
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Email Address</strong>
                  </EuiTextColor>
                </label>
                <WCICLoadingData isLoading={isLoading}>
                  {data?.emailAddress ? (
                    <>
                      <EuiSpacer size="xs" />
                      <EuiLink style={{ paddingLeft: "10px" }} href={`mailto:` + data?.emailAddress}>
                        {data?.emailAddress}
                      </EuiLink>
                    </>
                  ) : (
                    "Not Provided"
                  )}
                </WCICLoadingData>
                <EuiSpacer size="xs" />
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Phone Number</strong>
                  </EuiTextColor>
                </label>
                <WCICLoadingData isLoading={isLoading}>
                  <EuiText style={{ paddingLeft: "10px" }}>
                    {data?.phoneNumber ? (
                      <NumberFormat value={data.phoneNumber} displayType={"text"} format="(###) ###-####" />
                    ) : (
                      "Not Provided"
                    )}
                  </EuiText>
                </WCICLoadingData>
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer />
          <EuiPanel hasBorder>
            <EuiTitle size="s">
              <h2 id="flyoutLargeTitle">Description</h2>
            </EuiTitle>
            <WCICLoadingData isLoading={isLoading} lines={3}>
              <EuiText>{data?.description ? data.description : "None"}</EuiText>
            </WCICLoadingData>
          </EuiPanel>
          <EuiSpacer />
          <EuiPanel hasBorder>
            <EuiFlexGroup>
              <EuiFlexItem grow={7}>
                <EuiTitle size="s">
                  <h2 id="flyoutLargeTitle">Files</h2>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton
                  disabled={pageOfItems.length <= 0}
                  onClick={() => {
                    window.open(`${defaultURL}/reported_claim_document/${reportID}/zip`, "_blank");
                  }}
                >
                  Download All
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiBasicTable
              items={pageOfItems}
              itemId="fileID"
              columns={columns}
              rowProps={getRowProps}
              cellProps={getCellProps}
              pagination={pagination}
              onChange={onTableChange}
              sorting={sorting}
              loading={isLoading}
            />
          </EuiPanel>
        </EuiPageContentBody>
      </EuiPageBody>
    </EuiPage>
  );
};

export default ReportedClaim;
