import { EuiModal } from "@elastic/eui";
import { FunctionComponent, useReducer } from "react";
import { DispatchDataResponse, useAcceptJs } from "react-acceptjs";
import { useMutation } from "react-query";
import authAxios from "services/authAxios";
import { Invoice } from "types/apiTypes";

import PaymentView from "./PaymentView";
import ReceiptView from "./ReceiptView";

export interface TokenPaymentData extends Partial<DispatchDataResponse> {
  invoiceRecordID: string;
  paymentPlan: string;
  paymentSource: string;
  amount: number;
}

interface PaymentCreditType {
  paymentSource: "card";
  paymentPlan: string;
  fullName: string;
  cardNumber: string;
  month: string;
  year: string;
  cardCode: string;
  accountNumber?: string;
  routingNumber?: string;
  nameOnAccount?: string;
  accountType?: "checking" | "savings" | "businessChecking";
  address: string;
  city: string;
  state: string;
  zip: string;
}

interface PaymentBankType {
  paymentSource: "bank";
  paymentPlan: string;
  fullName: string;
  accountNumber: string;
  routingNumber: string;
  nameOnAccount: string;
  accountType: "checking" | "savings" | "businessChecking";
  cardNumber?: string;
  month?: string;
  year?: string;
  cardCode?: string;
  address: string;
  city: string;
  state: string;
  zip: string;
}

interface PaymentAgentType {
  paymentSource: "agent";
  paymentPlan: string;
  fullName?: string;
  accountNumber?: string;
  routingNumber?: string;
  nameOnAccount?: string;
  accountType?: "checking" | "savings" | "businessChecking";
  cardNumber?: string;
  month?: string;
  year?: string;
  cardCode?: string;
  zip?: string;
}

export type PaymentFormInputs = PaymentCreditType | PaymentBankType | PaymentAgentType;

interface PayInvoiceModalProps {
  invoice: Invoice;
  onClose: () => void;
}

const authData = {
  apiLoginID: "3fB8HDe9nec",
  clientKey: "23zcFGuKH7eqBe68p6Hkt9MHrJ6M52zzLsA6B6kbMde4kjwqF2fBg58Tzc66LRVu",
};

enum PaymentActionType {
  RESET = "RESET",
  TOKENIZING = "TOKENIZING",
  CHARGING = "CHARGING",
  CHARGED = "CHARGED",
  ERROR = "ERROR",
}

interface PaymentAction {
  type: PaymentActionType;
  payload?: string;
}

interface PaymentState {
  entry: boolean;
  loading: boolean;
  tokenized: boolean;
  charged: boolean;
  error: boolean;
  errorMessage?: string;
}

function payInvoiceReducer(state: PaymentState, action: PaymentAction) {
  switch (action.type) {
    case PaymentActionType.RESET:
      return {
        ...state,
        entry: true,
        loading: false,
        tokenized: false,
        charged: false,
        error: false,
        errorMessage: "",
      };
    case PaymentActionType.TOKENIZING:
      return {
        ...state,
        loading: true,
        tokenizing: true,
      };
    case PaymentActionType.CHARGING:
      return {
        ...state,
        loading: true,
        tokenizing: false,
        charging: true,
      };
    case PaymentActionType.CHARGED:
      return {
        ...state,
        entry: false,
        loading: false,
        charging: false,
        charged: true,
      };
    case PaymentActionType.ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
}

const PayInvoiceModal: FunctionComponent<PayInvoiceModalProps> = ({ invoice, onClose }) => {
  const [state, dispatch] = useReducer(payInvoiceReducer, {
    entry: true,
    loading: false,
    tokenized: false,
    charged: false,
    error: false,
    errorMessage: "",
  });

  const payInvoiceMutation = useMutation((paymentData: TokenPaymentData) => {
    return authAxios.post("/pay_invoice", paymentData);
  });

  const { dispatchData } = useAcceptJs({ authData });

  const onSubmit = (data: PaymentFormInputs) => {
    dispatch({ type: PaymentActionType.TOKENIZING });
    dispatchData({
      cardData: {
        cardNumber: data.cardNumber || "",
        month: data.month || "",
        year: data.year || "",
        cardCode: data.cardCode || "",
      },
    })
      .then((opaqueData: any) => {
        dispatch({ type: PaymentActionType.CHARGING });
        payInvoiceMutation.mutate(
          {
            ...opaqueData,
            paymentPlan: data.paymentPlan.charAt(data.paymentPlan.length - 1),
            invoiceRecordID: invoice.recordID,
            paymentSource: data.paymentSource,
            amount: invoice.planOptions[parseInt(data.paymentPlan.charAt(data.paymentPlan.length - 1))][0],
            zip: data.zip || "",
          },
          {
            onSuccess: (data: any) => {
              dispatch({ type: PaymentActionType.CHARGED });
              console.log(data);
            },
          }
        );
      })
      .catch((error: any) => {
        console.log("🚀 ~ file: PayInvoiceModal.tsx ~ line 125 ~ onSubmit ~ error", error);
      });
  };

  return (
    <EuiModal onClose={onClose}>
      {state.entry ? (
        <PaymentView invoice={invoice} onSubmit={onSubmit} loading={state.loading} />
      ) : (
        <ReceiptView invoice={invoice} />
      )}
    </EuiModal>
  );
};

export default PayInvoiceModal;
