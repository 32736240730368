import { EuiEmptyPrompt, EuiLoadingLogo, EuiPage, EuiPageBody, EuiPanel } from "@elastic/eui";
import ErrorPage from "components/ErrorPage";
import useQuote from "hooks/queries/useQuote";
import { useParams } from "react-router-dom";

import HomeownersViewQuote from "./homeowner/HomeownersViewQuote";
import LandlordsViewQuote from "./landlord/LandlordsViewQuote";

type PageParams = {
  quoteID: string;
};
const ViewQuote = () => {
  const { quoteID } = useParams<PageParams>();
  const { data, isLoading, isError } = useQuote(quoteID);
  // console.log("Quote Data: ", data);
  if (isError) {
    return <ErrorPage title="Quote" />;
  }

  if (isLoading) {
    return (
      <EuiPage>
        <EuiPageBody restrictWidth="1300px">
          <EuiPanel hasBorder>
            <EuiEmptyPrompt icon={<EuiLoadingLogo logo="filebeatApp" size="xl" />} title={<h2>Loading</h2>} />
          </EuiPanel>
        </EuiPageBody>
      </EuiPage>
    );
  }

  function LoadQuote(type: string){
    if (type === "HO"){
      return <HomeownersViewQuote quote={data!} />
    }
    if (type === "LP"){
      return <LandlordsViewQuote quote={data!} />
    }
  }

  return (
    <EuiPage>
      <EuiPageBody restrictWidth="1300px">{LoadQuote(data?.rateType!)}</EuiPageBody>
    </EuiPage>
  );
};

export default ViewQuote;
