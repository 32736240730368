import { useQuery } from "react-query";
import { TypeOfLoss } from "types/apiTypes";
import authAxios from "../../../../services/authAxios";

function useClaimsTypeEnum() {
  return useQuery<TypeOfLoss[]>(
    "claimsTypeEnum",
    async () => {
      const { data } = await authAxios.get("/type_of_loss_list");
      return data;
    },
    { refetchOnWindowFocus: false, staleTime: Infinity, cacheTime: Infinity }
  );
}

export default useClaimsTypeEnum;
