import { EuiButtonIcon, EuiPopover, EuiPopoverFooter, EuiPopoverTitle, EuiText } from "@elastic/eui";
import { ReactNode, useState } from "react";

const CoveragePopover = ({
  children,
  title,
  description,
}: {
  children: ReactNode;
  title: string;
  description: string;
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const onButtonClick = () => setIsPopoverOpen((isValuationPopoverOpen) => !isValuationPopoverOpen);
  const closePopover = () => setIsPopoverOpen(false);
  return (
    <EuiPopover
      button={<EuiButtonIcon aria-label={description} iconType="iInCircle" onClick={onButtonClick} />}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      anchorPosition="upCenter"
    >
      <EuiPopoverTitle>{title}</EuiPopoverTitle>
      <div style={{ width: "300px" }}>
        <EuiText size="s">{children}</EuiText>
      </div>
      <EuiPopoverFooter>
      </EuiPopoverFooter>
    </EuiPopover>
  );
};

export default CoveragePopover;
