import { useQuery } from "react-query";
import { Claim } from "types/apiTypes";

import authAxios from "../../services/authAxios";

export const getPendingClaims = async () => {
  const { data } = await authAxios.get(`/pending-claims`);
  return data;
};

function usePendingClaims() {
  const useQueryReturn = useQuery<Claim[]>(["pendingClaims"], () => getPendingClaims(), {
    placeholderData: [],
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
  });
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

export default usePendingClaims;
