import { useQuery } from "react-query";
import { Insured } from "types/apiTypes";

import authAxios from "../../services/authAxios";



function useInsured(insuredRecordID: string | undefined) {
  return useQuery<Insured>(
    ["insured", insuredRecordID],
    async () => {
      const { data } = await authAxios.get(`/insured/${insuredRecordID}`);
      return data;
    },
    {
      enabled: !!insuredRecordID,
    }
  );
}

export default useInsured;
