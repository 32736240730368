import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiPanel,
  EuiTableSortingType,
} from "@elastic/eui";
import { useAppSelector } from "app/hooks";
import EuiCustomLink from "components/EuiCustomLink";
import { selectClusterAgencies } from "features/authorization/authSlice";
import useNewDocuments from "hooks/queries/useNewDocuments";
import { useAPIURL } from "hooks/useAPIURL";
import moment from "moment";
import { useState } from "react";
import pageAndSort from "services/pageAndSort";
import { PolicyDocument } from "types/apiTypes";

interface TableData {
  policyID: string;
  insuredDisplayName: string;
  agentID: string;
  docType: string;
  docDatetime: string;
  policyEffectiveDate: string;
  policyInceptionDate: string;
  policyDocument: PolicyDocument;
}

function NewPolicyDocuments({
  pageSize,
  setPageSize,
  pageOptions,
}: {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageOptions: Array<number>;
}) {
  const { data, isLoading, isFetching, isPlaceholderData } = useNewDocuments();
  const clusterAgents = useAppSelector(selectClusterAgencies);
  const [pageIndex, setPageIndex] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof TableData>("docDatetime");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const defaultURL = useAPIURL();

  const dataTable: TableData[] = data.map((record) => {
    return {
      policyID: record.policy.policyID,
      insuredDisplayName: record.policy.insured?.displayName || "",
      agentID: record.agentID,
      docType: record.docType,
      docDatetime: record.docDatetime,
      policyEffectiveDate: record.policyEffectiveDate,
      policyInceptionDate: record.policyInceptionDate,
      policyDocument: record,
    };
  });

  const columns: EuiBasicTableColumn<TableData>[] = [];

  if (clusterAgents.length > 1) {
    columns.push({
      field: "agentID",
      name: "Agent ID",
    });
  }
  columns.push(
    {
      field: "policyID",
      name: "Policy Number",
      render: (policyID: string, rowData: TableData) => {
        return (
          <EuiCustomLink to={`/policy/${rowData.policyDocument.policy.recordID}`} external={true}>
            {policyID}
          </EuiCustomLink>
        );
      },
    },
    {
      field: "insuredDisplayName",
      name: "Insured Name",
      render: (insuredDisplayName: string, rowData: TableData) => {
        if (rowData.policyDocument.policy.insured) {
          return (
            <EuiCustomLink to={`/insured/${rowData.policyDocument.policy.insured?.recordID}`} external={true}>
              {insuredDisplayName}
            </EuiCustomLink>
          );
        }
        return { insuredDisplayName };
      },
    },
    {
      field: "docType",
      name: "Document Type",
      truncateText: true,
      render: (type: string) => {
        switch (type) {
          case "DEC":
            return "Declarations Page";
          default:
            return type;
        }
      },
    },
    {
      field: "docDatetime",
      name: "Document Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "policyEffectiveDate",
      name: "Effective Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "policyInceptionDate",
      name: "Inception Date",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "",
      name: "View",
      actions: [
        {
          name: "View",
          description: "View this document",
          type: "icon",
          icon: "copy",
          onClick: (rowData: TableData) => {
            window.open(`${defaultURL}/document/${rowData.policyDocument.recordID}`, "_blank");
          },
        },
      ],
    }
  );

  const { pageOfItems, totalItemCount } = pageAndSort(dataTable, { pageIndex, pageSize, sortField, sortDirection });

  const getRowProps = (document: TableData) => {
    const { recordID } = document.policyDocument;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      // onClick: () => {
      //   console.log("clicked on this: ", document);
      // },
    };
  };

  const getCellProps = (document: TableData, column: any) => {
    const { recordID } = document.policyDocument;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };
  const sorting: EuiTableSortingType<TableData> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: pageOptions,
    hidePerPageOptions: !showPerPageOptions,
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<TableData>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPanel hasBorder>
      <EuiBasicTable
        items={pageOfItems}
        itemId="ID"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
        pagination={pagination}
        onChange={onTableChange}
        sorting={sorting}
        loading={isLoading || (isFetching && isPlaceholderData)}
      />
    </EuiPanel>
  );
}

export default NewPolicyDocuments;
