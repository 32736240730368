import { EuiGlobalToastList, EuiGlobalToastListToast } from "@elastic/eui";
import React, { ReactNode, useContext, useState } from "react";

interface ToastContextValue {
  addToast: (toast: Omit<EuiGlobalToastListToast, "id">) => void;
  removeToast: (removedToast: EuiGlobalToastListToast) => void;
  removeAllToasts: () => void;
}

const defaultValues = {
  addToast: () => {},
  removeToast: () => {},
  removeAllToasts: () => {},
}

const ToastContext = React.createContext<ToastContextValue>(defaultValues);

let toastId = 1;

const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<EuiGlobalToastListToast[]>([]);

  const addToast = (toast: Omit<EuiGlobalToastListToast, "id">) => {
    const toaster = {
      id: `toast${toastId++}`,
      ...toast,
    };
    setToasts(toasts.concat(toaster));
  };

  const removeToast = (removedToast: EuiGlobalToastListToast) => {
    setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
  };

  const removeAllToasts = () => {
    setToasts([]);
  };

  return (
    <ToastContext.Provider
      value={{
        addToast,
        removeToast,
        removeAllToasts,
      }}
    >
      <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} />
      {children}
    </ToastContext.Provider>
  );
};

const useToast = () => {
  const toastHelpers = useContext(ToastContext);

  return toastHelpers;
};

export { ToastContext, useToast };
export default ToastProvider;
