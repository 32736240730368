import { EuiStat, EuiStatProps } from "@elastic/eui";
import NumberFormat from "react-number-format";

interface ShowRateNumberStatProps {
  data: number | undefined;
  isLoading: EuiStatProps["isLoading"];
  valid: boolean;
  description: string;
  titleSize?: EuiStatProps["titleSize"];
}

const ShowRateNumberStat = ({ data, isLoading, valid, description, titleSize  }: ShowRateNumberStatProps) => {
  return (
    <NumberFormat
      value={data}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"}
      renderText={(value) => {
        return (
          <EuiStat
            title={value ? value : "$0"}
            titleColor={valid ? "success" : "danger"}
            description={description}
            textAlign="right"
            isLoading={isLoading}
            titleSize={titleSize}
          />
        );
      }}
    />
  );
};

export default ShowRateNumberStat;
