import {
  EuiBetaBadge,
  EuiButton,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { WCICTextLabel } from "components";
import { useAPIURL } from "hooks/useAPIURL";
import moment from "moment";
import NumberFormat from "react-number-format";
import { useNavigate } from "react-router-dom";
import { HOForm, Quote } from "types/apiTypes";

import ShowRateNumberStat from "../components/ShowRateNumberStat";
import { HOQuoteInput } from "../types/Rater.types";
import { formsData } from "./data/formsData";

type HomeownersViewQuoteProps = {
  quote: Quote;
};

function valuationFormat(valuation: string) {
  switch (valuation) {
    case "RC":
      return "Replacement Cost Estimate";
    case "ACV":
      return "Actual Cash Value";
  }
}

function perilsFormat(peril: string) {
  switch (peril) {
    case "ML1":
      return "ML-1";

    case "ML2":
      return "ML-2";

    case "ML3":
      return "ML-3";

    case "ML4":
      return "ML-4";

    default:
      break;
  }
}

function simpleNumber(num: string) {
  return (
    <NumberFormat
      value={num}
      displayType="text"
      thousandSeparator={true}
      prefix={"$"}
      renderText={(num) => {
        return <>{num}</>;
      }}
    />
  );
}

const HomeownersViewQuote = ({ quote }: HomeownersViewQuoteProps) => {
  const navigate = useNavigate();
  const quoteDetails = quote.details as HOQuoteInput;
  const processedForms = quoteDetails.locations[0].forms.map((form) => {
    return { ...form, rate: quote.rate!.form_premium[form.formID] };
  });
  const defaultURL = useAPIURL();

  return (
    <>
      <EuiPageHeader iconType="home" pageTitle={`Homeowners Quote ${quote.recordID}`} />
      <EuiFlexGroup wrap>
        <EuiFlexItem grow={2}>
          <EuiPanel>
            <EuiTitle>
              <h3>Applicant</h3>
            </EuiTitle>
            <WCICTextLabel label="Name">
              <EuiText style={{ paddingLeft: "10px" }}>{`${quoteDetails.firstName} ${quoteDetails.lastName}`}</EuiText>
            </WCICTextLabel>

            <WCICTextLabel label="Mailing Address">
              <EuiText style={{ paddingLeft: "10px" }}>{`${quoteDetails.streetAddress}`}</EuiText>
              <EuiText style={{ paddingLeft: "10px" }}>{`${quote.city}, ${quote.state} ${quote.zip}`}</EuiText>
            </WCICTextLabel>
          </EuiPanel>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiPanel>
            <WCICTextLabel label="Rate">
              <EuiText style={{ paddingLeft: "10px" }}>{simpleNumber(`${quote.totalPremium}`)}</EuiText>
            </WCICTextLabel>
            <WCICTextLabel label="Quote Created">
              <EuiText style={{ paddingLeft: "10px" }}>{moment(`${quote.timestamp}`).format("lll")}</EuiText>
            </WCICTextLabel>
            <EuiSpacer />
            <EuiButton
              onClick={() => {
                window.open(`${defaultURL}/print-quote/${quote.recordID}`, "_blank");
              }}
            >
              Print
            </EuiButton>
            <EuiSpacer />
            <EuiButton onClick={() => navigate(`/rating/${quote.recordID}`)}>Edit</EuiButton>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiPanel>
        <EuiTitle>
          <h3>Coverages</h3>
        </EuiTitle>

        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
            <WCICTextLabel label="Location">
              <EuiText style={{ paddingLeft: "10px" }}>{`${quoteDetails.streetAddress}`}</EuiText>
              <EuiText style={{ paddingLeft: "10px" }}>{`${quote.city}, ${quote.state} ${quote.zip}`}</EuiText>
            </WCICTextLabel>
          </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <WCICTextLabel label="Property Coverages">
              <EuiText style={{ paddingLeft: "10px" }}>
                Perils: {perilsFormat(`${quoteDetails.locations[0].perils}`)}
              </EuiText>
              {/* Should have a conditional to show percentage if applicable... what kind of format? */}
              <EuiText style={{ paddingLeft: "10px" }}>
                Valuation: {valuationFormat(`${quoteDetails.locations[0].valuation}`)}
              </EuiText>
              <EuiText style={{ paddingLeft: "10px" }}>
                Dwelling Limit (Coverage A): {simpleNumber(`${quoteDetails.locations[0].covA}`)}
              </EuiText>
              <EuiText style={{ paddingLeft: "10px" }}>
                Related Structures (Coverage B): {simpleNumber(`${quoteDetails.locations[0].covB}`)}
              </EuiText>
              <EuiText style={{ paddingLeft: "10px" }}>
                Contents (Coverage C): {simpleNumber(`${quoteDetails.locations[0].covC}`)}
              </EuiText>
              <EuiText style={{ paddingLeft: "10px" }}>
                Loss of Rents (Coverage D): {simpleNumber(`${quoteDetails.locations[0].covD}`)}
              </EuiText>
            </WCICTextLabel>
          </EuiFlexItem>
          <EuiFlexItem grow={2}>
            <WCICTextLabel label="Liability Coverage">
              <EuiText style={{ paddingLeft: "10px" }}>
                Liability Form: {`${quoteDetails.locations[0].liabilityForm}`}
              </EuiText>
              <EuiText style={{ paddingLeft: "10px" }}>
                Liability: {simpleNumber(`${quoteDetails.locations[0].liability}`)}
              </EuiText>
              <EuiText style={{ paddingLeft: "10px" }}>
                Medpay: {simpleNumber(`${quoteDetails.locations[0].medpay}`)}
              </EuiText>
            </WCICTextLabel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
      <EuiSpacer />
      <EuiPanel>
        <EuiTitle>
          <h3>Additional Forms</h3>
        </EuiTitle>
        <EuiSpacer />
        <EuiFlexGroup direction="column">
          {processedForms.map((form) => (
            <QuoteFormView key={form.formID} form={form} />
          ))}
        </EuiFlexGroup>
      </EuiPanel>
    </>
  );
};

export default HomeownersViewQuote;

interface HOFromWithRate extends HOForm {
  rate: number;
}

interface QuoteFormViewProps {
  form: HOFromWithRate;
}

const QuoteFormView = ({ form }: QuoteFormViewProps) => {
  const formData = formsData[form.name];
  const fieldKeys = Object.keys(form.fields || {});
  const listItems = fieldKeys.map((fieldName) => {
    const fieldInfo = formData.inputs.find((field) => field.name === fieldName);
    if (fieldInfo?.type === "number") {
      return {
        title: fieldInfo.label,
        description: form.fields[fieldName],
      };
    }
    if (fieldInfo?.type === "money") {
      return {
        title: fieldInfo.label,
        description: simpleNumber(form.fields[fieldName]),
      };
    }
    if (fieldInfo?.type === "select") {
      const selectionText = fieldInfo?.options?.find((option) => option.value === form.fields[fieldName]);
      return {
        title: fieldInfo.label,
        description: selectionText?.text || "",
      };
    }

    return {
      title: "Default",
      description: "Return",
    };
  });

  return (
    <EuiFlexItem>
      <EuiPanel className="wcicPolicyForm wcicPolicyForm--hasBetaBadge" paddingSize="l" color="subdued">
        <EuiFlexGroup gutterSize="l" direction="row">
          <EuiFlexItem grow={false} style={{ marginRight: "0px" }}>
            <EuiIcon size="xl" type={"reportingApp"} style={{ marginRight: "16px" }} />
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiTitle size="s">
              <span>{formData.formTitle}</span>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiDescriptionList listItems={listItems} textStyle={"reverse"} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="column" alignItems="flexEnd">
              <EuiFlexItem grow={false}>
                <ShowRateNumberStat data={form.rate} isLoading={false} valid={true} description="Premium" />
                {/* <PremiumStat formID={formID} /> */}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
        <span className="wcicPolicyForm__betaBadgeWrapper">
          {formData.policyForm ? (
            <EuiBetaBadge
              label={"label"}
              className="wcicPolicyForm__betaBadge wcicPolicyForm__betaBadge--blue"
              tooltipContent={<span>This form applies to all locations.</span>}
              color="subdued"
            />
          ) : (
            <EuiBetaBadge label={formData.displayFormName} className="wcicPolicyForm__betaBadge " />
          )}
        </span>
      </EuiPanel>
    </EuiFlexItem>
  );
};
