/** @jsxImportSource @emotion/react */
import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiLoadingLogo,
  EuiPage,
  EuiPageBody,
  EuiPageContentBody,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import ErrorPage from "components/ErrorPage";
import { useAPIURL } from "hooks/useAPIURL";
import NumberFormat from "react-number-format";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import authAxios from "services/authAxios";
import { Transaction } from "types/apiTypes";

type PageParams = {
  recordID: string;
};

const getTransactionByID = async (id: string | undefined) => {
  const { data } = await authAxios.get(`/tranasaction/${id}`, {});
  return data;
};

function useTranasaction(recordID: string | undefined) {
  return useQuery<Transaction>(["tranasactionPage", recordID], () => getTransactionByID(recordID), {
    enabled: !!recordID,
  });
}

function renderPaymentMethod(data: Transaction) {
  if (data.transactionJSON.payment.bank_account?.account_number) {
    return "Bank account ending in " + String(data.transactionJSON.payment.bank_account?.account_number).slice(-4);
  }
  if (data.transactionJSON.payment.credit_card?.card_number) {
    return "Credit card ending in " + String(data.transactionJSON.payment.credit_card?.card_number).slice(-4);
  }
}
function renderPaymentType(data: Transaction) {
  if (data.transactionJSON.payment.bank_account?.account_number) {
    return "Bank";
  }
  if (data.transactionJSON.payment.credit_card?.card_number) {
    return "Card";
  }
}

const TransactionPage: React.FC = () => {
  const navigate = useNavigate();
  const defaultURL = useAPIURL();
  const { recordID } = useParams<PageParams>();
  const { data, isLoading, isError } = useTranasaction(recordID);
  // console.log("data: ", data);

  if (isError) {
    return <ErrorPage title="Transaction" />;
  }

  if (isLoading) {
    return (
      <EuiPage>
        <EuiPageBody restrictWidth="1300px">
          <EuiPanel hasBorder>
            <EuiEmptyPrompt icon={<EuiLoadingLogo logo="filebeatApp" size="xl" />} title={<h2>Loading</h2>} />
          </EuiPanel>
        </EuiPageBody>
      </EuiPage>
    );
  }

  return (
    <>
      <EuiPage>
        <EuiPageBody>
          <EuiSpacer />
          <EuiPageContentBody restrictWidth="700px">
            <EuiPanel hasBorder css={{ textAlign: "center" }}>
              <EuiSpacer />
              <EuiIcon type="checkInCircleFilled" color="green" size="xxl" />
              <EuiSpacer />
              <EuiTitle size="l" children={<h3>Thank you for your payment!</h3>} />

              <EuiHorizontalRule margin="m" size="half" />

              <EuiText>
                <strong>Policy ID: </strong>
                {data?.line_items[0].policyNumber}
              </EuiText>
              <EuiText>
                <strong>Invoice ID:</strong> {data?.line_items[0].invoiceNumber}
              </EuiText>
              <EuiHorizontalRule margin="m" size="half" />

              <EuiText>
                <strong>Payment Type:</strong> {renderPaymentType(data!)}
              </EuiText>
              <EuiText>{renderPaymentMethod(data!)}</EuiText>
              <EuiHorizontalRule margin="m" size="half" />
              <EuiText textAlign="center">{data?.transactionJSON.line_items[0].description}</EuiText>
              <EuiText textAlign="center">Receipt sent to: {data?.transactionJSON.customer.email}</EuiText>
              <EuiSpacer />
              <EuiTitle size="l">
                <EuiTextColor color={"green"}>
                  <NumberFormat
                    displayType={"text"}
                    value={data?.line_items[0].unitPrice}
                    thousandSeparator={true}
                    prefix={"$"}
                    fixedDecimalScale
                    decimalScale={2}
                    renderText={(num) => {
                      return num;
                    }}
                  />
                </EuiTextColor>
              </EuiTitle>
              <EuiSpacer />
            </EuiPanel>
            <EuiSpacer />

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiButton
                  fill
                  onClick={() => {
                    navigate(`/policy/${data?.policy_record_id}`);
                  }}
                >
                  Return to Policy
                  {/* Policy {data?.line_items[0].policyNumber} */}
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton
                  fill
                  onClick={() => {
                    window.open(`${defaultURL}/email_receipt_pdf/${data?.recordID}`, "_blank");
                  }}
                >
                  Print Receipt
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPageContentBody>
        </EuiPageBody>
      </EuiPage>
    </>
  );
};

export default TransactionPage;
