import { createEntityAdapter, createSlice, EntityId } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { formsData } from "../pages/underwriting/rating/homeowner/data/formsData";

export interface HORaterState {
  firstName: string;
  lastName: string;
  insuredAddress: string;
  insuredZip: string;
  propertyAddress: string;
  propertyZip: string;
  constructionType: string;
  constructionYear: string;
  protectionClass: string;
  numberFamilies: string;
  valuation: string;
  perils: string;
  coverageA: string;
  coverageB: string;
  coverageC: string;
  coverageD: string;
  deductible: string;
  liability: number;
  medpay: number;
  forms: any;
}

// const initialState: HORaterState = {
//   firstName: "",
//   lastName: "",
//   insuredAddress: "",
//   insuredZip: "",
//   propertyAddress: "",
//   propertyZip: "",
//   constructionType: "1",
//   constructionYear: "",
//   protectionClass: "1",
//   numberFamilies: "1",
//   valuation: "1",
//   perils: "1",
//   coverageA: "100000",
//   coverageB: "10000",
//   coverageC: "50000",
//   coverageD: "20000",
//   deductible: "250",
//   liability: 100000,
//   medpay: 0,
//   forms: {},
// };

type Form = { formID: string; formName: string; inputs: any; inputOptions?: any };

const formsAdapter = createEntityAdapter<Form>({
  selectId: (form) => form.formID,
});

function calculateVisibleOptions(formName: string, inputs: any) {
  if (!formsData[formName].inputs) return undefined;
  const returnArray: any = [];
  formsData[formName].inputs.forEach((input) => {
    if (!input.requires) {
      returnArray.push(input);
    } else {
      if (Array.isArray(input.requires)) {
        if (
          input.requires.every((item: any) => {
            if (Array.isArray(item)) {
              return item.some((orItem: any) => {
                return inputs[orItem.option] === orItem.value;
              });
            }
            return inputs[item.option] === item.value;
          })
        ) {
          returnArray.push(input);
        }
      } else {
        if (inputs[input.requires.option] === input.requires.value) {
          returnArray.push(input);
        }
      }
    }
  });
  return returnArray;
}

export const homeownerRaterSlice = createSlice({
  name: "homeownerRater",
  initialState: {
    firstName: "Bryan",
    lastName: "Rice",
    insuredAddress: "5762 Oatfield Dr",
    insuredZip: "14424",
    propertyAddress: "576",
    propertyZip: "14425",
    constructionType: "1",
    constructionYear: "1993",
    protectionClass: "1",
    numberFamilies: "1",
    valuation: "1",
    perils: "3",
    coverageA: "100000",
    coverageB: "10000",
    coverageC: "50000",
    coverageD: "20000",
    deductible: "500",
    liability: 100000,
    medpay: 0,
    forms: formsAdapter.getInitialState(),
    formNames: {},
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addForm: (state, action) => {
      const defaultInputs = formsData[action.payload.formName].defaultInputs;
      // const inputOptions = calculateVisibleOptions(action.payload.formName, defaultInputs);
      formsAdapter.addOne(state.forms, {
        formID: action.payload.formID,
        formName: action.payload.formName,
        inputs: defaultInputs,
      });
      state.formNames = { ...state.formNames, [action.payload.formID]: action.payload.formName };
    },
    addManyForms: (state, action) => {
      formsAdapter.addMany(
        state.forms,
        action.payload.map((item: any) => {
          const defaultInputs = formsData[item.formName].defaultInputs;
          return { formID: item.formID, formName: item.formName, inputs: defaultInputs };
        })
      );
      action.payload.forEach((item: any) => (state.formNames = { ...state.formNames, [item.formID]: item.formName }));
    },
    removeForm: (state, action) => {
      const id: any = action.payload;
      formsAdapter.removeOne(state.forms, action);
      // formsAdapter.addOne(state.forms, action);
      delete (state.formNames as any)[id];
      // state.formNames = { ...state.formNames, [action.payload.formID]: [action.payload.formName] };
    },
    updateForm: (state, action) => {
      formsAdapter.updateOne(state.forms, action);
    },
    updateFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    updateLastName: (state, action) => {
      state.lastName = action.payload;
    },
    updateInsuredAddress: (state, action) => {
      state.insuredAddress = action.payload;
    },
    updateInsuredZip: (state, action) => {
      state.insuredZip = action.payload;
    },
    updateLiability: (state, action) => {
      state.liability = action.payload;
    },
    updateMedpay: (state, action) => {
      state.medpay = action.payload;
    },
    updateValuation: (state, action) => {
      state.valuation = action.payload;
    },
    updatePerils: (state, action) => {
      state.perils = action.payload;
    },
    updateCoverageA: (state, action) => {
      state.coverageA = action.payload;
    },
    updateCoverageB: (state, action) => {
      state.coverageB = action.payload;
    },
    updateCoverageC: (state, action) => {
      state.coverageC = action.payload;
    },
    updateCoverageD: (state, action) => {
      state.coverageD = action.payload;
    },
    updateDeductible: (state, action) => {
      state.deductible = action.payload;
    },
    updatePropertyAddress: (state, action) => {
      state.propertyAddress = action.payload;
    },
    updatePropertyZip: (state, action) => {
      state.propertyZip = action.payload;
    },
    updateConstructionType: (state, action) => {
      state.constructionType = action.payload;
    },
    updateConstructionYear: (state, action) => {
      state.constructionYear = action.payload;
    },
    updateProtectionClass: (state, action) => {
      state.protectionClass = action.payload;
    },
    updateNumberFamilies: (state, action) => {
      state.numberFamilies = action.payload;
    },
    updateFormInput: (state, action) => {
      (state.forms.entities as any)[action.payload.id].inputs = {
        ...(state.forms.entities as any)[action.payload.id].inputs,
        ...action.payload.changes,
      };
    },
  },
});

export const {
  updateFirstName,
  updateLastName,
  updateInsuredAddress,
  updateInsuredZip,
  updateLiability,
  updateMedpay,
  updateValuation,
  updatePerils,
  updateCoverageA,
  updateCoverageB,
  updateCoverageC,
  updateCoverageD,
  updateDeductible,
  updatePropertyAddress,
  updatePropertyZip,
  updateConstructionYear,
  updateConstructionType,
  updateNumberFamilies,
  updateProtectionClass,
  addForm,
  updateForm,
  updateFormInput,
  removeForm,
  addManyForms,
} = homeownerRaterSlice.actions;

export const selectFirstName = (state: RootState) => state.homeownerRater.firstName;
export const selectLastName = (state: RootState) => state.homeownerRater.lastName;
export const selectInsuredAddress = (state: RootState) => state.homeownerRater.insuredAddress;
export const selectInsuredZip = (state: RootState) => state.homeownerRater.insuredZip;
export const selectLiability = (state: RootState) => state.homeownerRater.liability;
export const selectMedpay = (state: RootState) => state.homeownerRater.medpay;
export const selectValuation = (state: RootState) => state.homeownerRater.valuation;
export const selectPerils = (state: RootState) => state.homeownerRater.perils;
export const selectCoverageA = (state: RootState) => state.homeownerRater.coverageA;
export const selectCoverageB = (state: RootState) => state.homeownerRater.coverageB;
export const selectCoverageC = (state: RootState) => state.homeownerRater.coverageC;
export const selectCoverageD = (state: RootState) => state.homeownerRater.coverageD;
export const selectDeductible = (state: RootState) => state.homeownerRater.deductible;
export const selectPropertyAddress = (state: RootState) => state.homeownerRater.propertyAddress;
export const selectPropertyZip = (state: RootState) => state.homeownerRater.propertyZip;
export const selectConstructionType = (state: RootState) => state.homeownerRater.constructionType;
export const selectConstructionYear = (state: RootState) => state.homeownerRater.constructionYear;
export const selectProtectionClass = (state: RootState) => state.homeownerRater.protectionClass;
export const selectNumberFamilies = (state: RootState) => state.homeownerRater.numberFamilies;
export const selectRate = (state: RootState) => {
  const formsArray = state.homeownerRater.forms.ids.map((id: any) => state.homeownerRater.forms.entities[id]);
  return {
    firstName: state.homeownerRater.firstName,
    lastName: state.homeownerRater.lastName,
    insuredAddress: state.homeownerRater.insuredAddress,
    insuredZip: state.homeownerRater.insuredZip,
    propertyAddress: state.homeownerRater.propertyAddress,
    propertyZip: state.homeownerRater.propertyZip,
    constructionType: parseInt(state.homeownerRater.constructionType, 10),
    constructionYear: parseInt(state.homeownerRater.constructionYear, 10),
    protectionClass: parseInt(state.homeownerRater.protectionClass, 10),
    numberFamilies: parseInt(state.homeownerRater.numberFamilies, 10),
    valuation: parseInt(state.homeownerRater.valuation, 10),
    perils: parseInt(state.homeownerRater.perils, 10),
    coverageA: parseInt(state.homeownerRater.coverageA, 10),
    coverageB: parseInt(state.homeownerRater.coverageB, 10),
    coverageC: parseInt(state.homeownerRater.coverageC, 10),
    coverageD: parseInt(state.homeownerRater.coverageD, 10),
    deductible: parseInt(state.homeownerRater.deductible, 10),
    liability: state.homeownerRater.liability,
    medpay: state.homeownerRater.medpay,
    forms: formsArray,
  };
};

export const formSelectors = formsAdapter.getSelectors<RootState>((state) => state.homeownerRater.forms);

export const selectFormNames = (state: RootState) => state.homeownerRater.formNames;
export const selectFormOptions = (state: RootState, formID: EntityId) => {
  const myForm = formSelectors.selectById(state, formID);
  if (!myForm) return null;
  // const defaultInputs = formsData[myForm.formName].defaultInputs;
  return calculateVisibleOptions(myForm.formName, myForm.inputs);
};

export default homeownerRaterSlice.reducer;
