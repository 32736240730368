export function asciiArt() {
  console.log(`                                                                           
                 \`-/////////:-------------:::::::::::::::::://///////////////////-.                 
               \`:++++++++++/-------::::::::::::::://////////////////+++++++++++++++:\`               
               -+++++++++++/--------:::::::::::::://////////////////++++++++++++++++-               
               :++++++++++++:-------:::::::::::::://////////////////++++++++++++++++:               
               \`+++++++++++++:------:::::::::::::://////////////////++++++++++++++++\`               
                \`/++++++++++++:---------::::::::::::://////////////////+++++++++++/\`                
                 \`/++++++++++++/\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`.:----::::/+++/\`                 
                   /++++++++++++/\`                                 ------------//                   
                    /++++++++++++/\`                              \`-------------:                    
         \`\`\`\`\`\`\`\`\`\`\`\`:+++++++++////.\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`\`:-------------\`\`\`\`\`\`\`\`\`\`\`\`         
      .:++++++///:----:/+++/////////--------------::::::::::::::::::::::://////////////////+:.      
    \`/++++++++++/-------/////////////-------------::::::::::::::::::::://///////////////++++++/\`    
    -++++++++++//--------/////////////:-----------::::::::::::::::::::://///////////////+++++++-    
    -++++++///////--------/////////////:----------::::::::::::::::::::://///////////////+++++++-    
    \`/++//////////:--------:////////////:---------::::::::::::::::::::://///////////////++++++/\`    
      :////////////:--------:////////////:-------------:::::::::::::::::::://///////////+++++/\`     
       ://///////////.\`\`\`\`\`\`\`://///////////.\`\`\`\`\`\`\`\`\`\`\`\`.:-------------\`\`\`\`\`\`\`.:-------::/++:       
        -/////////////.       :////////:::::\`          \`--------------       \`------------/-        
         -/////////////.       -///::::::::::.        .:-------------       .:-------------         
          ./////////////.       ./::::::::::::.      .:------------.       .:------------.          
           .//////////::/-       .:::::::::::::.    .::----------:.       .:------------\`           
            \`///////:::::::       \`/::::::::::::-  -:::::-------:\`       -:::---------:\`            
             \`:/::::::::::::\`      \`:::::::::::::--::::::::::--:\`       -::::::::----:\`             
              \`::::::::::::::\`      \`:::::::::---::::::::::::::\`      \`-::::::::::::-\`              
                ::::::::::::::\`       ::::------::::::::::::::       \`:::::::::::::-                
                 -:::::::::::::.       -:------:::::::::::::-       .:::::::::::::-                 
                  .:::::::::::::.       .-----:::::::::::::-       .:::::::::::::.                  
                   .:::::::::::::.       .---:::::::::::::-       .:::::::::::::.                   
                    .:::::::::::::-       .::::::::::::::.       -:::::::::::::.                    
                     \`:::::::::::-:-      -/::::::::::::--      -/::::::::::::\`                     
                      \`::::::--------    -///::::::::::----    -//:::::::::::\`                      
                       \`::------------  :////////:::::------  ://////:::::::\`                       
                         :-------------:///////////::--------:///////////::                         
                          ------------:////////////:--------:////////////:                          
                           ----------/////////////:--------/////////////-                           
                            .:-----://///////////-.:-----://///////////-                            
                             .:::://////////////.  .:::://////////////.                             
                              ./++++///////////-    .//++////////////.                              
                               \`/++++++///////.      \`/+++++////////\`                               
                                \`/++++++++++/\`        \`/++++++++///\`                                
                                 \`:/++++++//\`          \`:+++++++/:\`                                 
                                   \`-://:-\`              .-:::/-\`                                   
`);
}
