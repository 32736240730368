import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiDatePicker,
  EuiDatePickerRange,
  EuiFormRow,
  EuiPage,
  EuiPageBody,
  EuiPanel,
  EuiSpacer,
  EuiTableSortingType,
  EuiTitle,
} from "@elastic/eui";
import useRecentQuotes from "hooks/queries/useRecentQuotes";
import { useAPIURL } from "hooks/useAPIURL";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import pageAndSort from "services/pageAndSort";
import { Quote } from "types/apiTypes";

const RecentQuotes = () => {
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(() => {
    const saved = localStorage.getItem("start");
    return saved || moment().format("YYYY-MM-DD");
  });
  const [endDate, setEndDate] = useState(() => {
    const saved = localStorage.getItem("end");
    return saved || moment().format("YYYY-MM-DD");
  });

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof Quote>("timestamp");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const defaultURL = useAPIURL();

  const { data, isLoading } = useRecentQuotes(startDate, endDate, pageIndex, pageSize, sortField, sortDirection);
  // console.log("Recent Quote Data: ", data);

  const columns: EuiBasicTableColumn<Quote>[] = [
    {
      field: "displayName",
      name: "Name",
    },

    {
      field: "address",
      name: "Address",
    },
    {
      field: "city",
      name: "City",
    },
    {
      field: "rateType",
      name: "Rate Type",
      render: (type: string) => {
        switch (type) {
          case "LP":
            return "Landlord";

          case "HO":
            return "Homeowner";

          default:
            return "";
        }
      },
    },
    {
      field: "comments",
      name: "Comments",
    },
    {
      field: "timestamp",
      name: "Quote Created",
      render: (date: string) => moment(date).format("l"),
    },
    {
      field: "",
      name: "Actions",
      actions: [
        {
          name: "View RCE",
          description: "View this RCE",
          type: "icon",
          icon: "copy",
          enabled: (quote: any) => {
            if (quote.rateType === "HO") {
              return quote.details.locations[0].valuation === "RC" || quote.details.locations[0].valuation === "IACV";
            }
            if (quote.rateType === "LP") {
              return quote.details.locations.some((element: any) => {
                return element.valuation === "RC";
              });
            }
            return false;
          },
          onClick: (quote: Quote) => {
            window.open(`${defaultURL}/print-rce/${quote.recordID}`, "_blank");
          },
        },
        {
          name: "View Quote",
          description: "View this quote",
          type: "icon",
          icon: "copy",
          onClick: (quote: Quote) => {
            window.open(`${defaultURL}/print-quote/${quote.recordID}`, "_blank");
          },
        },
        {
          name: "Edit",
          description: "Edit this quote",
          type: "icon",
          icon: "pencil",
          onClick: (quote: Quote) => {
            navigate(`/rating/${quote.recordID}`);
          },
        },
      ],
    },
  ];

  // const { pageOfItems, totalItemCount } = pageAndSort(data, { sortField, sortDirection, pageIndex, pageSize });

  const totalItemCount = data.totalQuotes;

  const getRowProps = (quote: Quote) => {
    const { recordID } = quote;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      // onClick: () => {
      //   console.log("clicked on this: ", quote);
      // },
    };
  };

  const getCellProps = (quote: Quote, column: any) => {
    const { recordID } = quote;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };
  const sorting: EuiTableSortingType<Quote> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [10, 20, 50, 100],
    hidePerPageOptions: !showPerPageOptions,
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<Quote>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPage>
      <EuiPageBody restrictWidth="1300px">
        <EuiSpacer />
        <EuiPanel hasBorder>
          <EuiTitle>
            <h3>Recent Quotes</h3>
          </EuiTitle>
          <EuiSpacer />
          <EuiFormRow label="Select a Range">
            <EuiDatePickerRange
              startDateControl={
                <EuiDatePicker
                  selected={moment(startDate, "YYYY-MM-DD")}
                  onChange={(date: Moment) => {
                    setStartDate(date.format("YYYY-MM-DD"));
                    localStorage.setItem("start", date.format("YYYY-MM-DD"));
                  }}
                  startDate={moment(startDate, "YYYY-MM-DD")}
                  endDate={moment(endDate, "YYYY-MM-DD")}
                  isInvalid={startDate > endDate}
                  aria-label="Start date"
                />
              }
              endDateControl={
                <EuiDatePicker
                  selected={moment(endDate, "YYYY-MM-DD")}
                  onChange={(date: Moment) => {
                    setEndDate(date.format("YYYY-MM-DD"));
                    localStorage.setItem("end", date.format("YYYY-MM-DD"));
                  }}
                  startDate={moment(startDate, "YYYY-MM-DD")}
                  endDate={moment(endDate, "YYYY-MM-DD")}
                  isInvalid={startDate > endDate}
                  aria-label="End date"
                />
              }
            />
          </EuiFormRow>
          <EuiSpacer />
          <EuiBasicTable
            items={data.quotes}
            itemId="ID"
            columns={columns}
            rowProps={getRowProps}
            cellProps={getCellProps}
            pagination={pagination}
            onChange={onTableChange}
            tableLayout="auto"
            sorting={sorting}
            loading={isLoading}
          />
        </EuiPanel>
      </EuiPageBody>
    </EuiPage>
  );
};

export default RecentQuotes;
