import { EuiButtonIcon, EuiSelect, EuiSelectOption } from "@elastic/eui";
import { useState } from "react";

interface EditableTableSelectProps {
  options: EuiSelectOption[];
  value: string;
  setNewOption: (newValue: string) => void;
}

export const EditableTableSelect = ({ options, value, setNewOption }: EditableTableSelectProps) => {
  const [editedValue, setEditedValue] = useState<string | undefined>();
  const text = options.find((item) => {return item.value === value}) || {value, text:"Item Not Found"};
  if (editedValue !== undefined) {
    return (
      <>
        <EuiSelect
          value={editedValue}
          options={options}
          autoFocus
          onChange={(event) => {
            setEditedValue(event.target.value);
          }}
        />{" "}
        <EuiButtonIcon
          aria-label="Save Name Edit"
          iconType="check"
          color="success"
          onClick={() => {
            setNewOption(editedValue);
            setEditedValue(undefined);
          }}
        />
        <EuiButtonIcon
          aria-label="Cancel Name Edit"
          iconType="cross"
          color="danger"
          onClick={() => {
            setEditedValue(undefined);
          }}
        />
      </>
    );
  }
  return (
    <>
      {text.text}{" "}
      <EuiButtonIcon
        aria-label="Edit Name"
        style={{ marginLeft: "10px" }}
        iconType="pencil"
        onClick={() => {
          setEditedValue(value);
        }}
      />
    </>
  );
};
