import { useQuery } from "react-query";
import { Form } from "types/apiTypes";

import authAxios from "../../services/authAxios";

const getForms = async (type: string) => {
  const { data } = await authAxios.get(`/forms`, { params: { type: type } });
  return data;
};

function useForms(type: string) {
  const useQueryReturn = useQuery<Form[]>(["urb_forms", type], () => getForms(type), {
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
  });
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

export default useForms;
