import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiButton,
  EuiButtonEmpty,
  EuiConfirmModal,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiHealth,
  EuiHorizontalRule,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPage,
  EuiPageBody,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSelect,
  EuiSelectOption,
  EuiSpacer,
  EuiSwitch,
  EuiTableSortingType,
  EuiText,
  EuiTextAlign,
  EuiTitle,
  EuiToolTip,
  htmlIdGenerator,
  useGeneratedHtmlId,
} from "@elastic/eui";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { useToast } from "hooks/useToasts";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import pageAndSort from "services/pageAndSort";
import { AgentUser } from "types/apiTypes";
import * as yup from "yup";

import { useAppSelector } from "../../app/hooks";
import {
  selectAgencyID,
  selectClusterAdministrator,
  selectClusterAgencies,
} from "../../features/authorization/authSlice";
import useAgentEmail from "../../hooks/queries/useAgentEmail";
import useAgentUsers from "../../hooks/queries/useAgentUsers";
import authAxios from "../../services/authAxios";

interface TableData {
  recordID: number;
  emailAddress: string;
  agentID: string;
  firstName: string;
  lastName: string;
  lastLogin: string;
  enabled: boolean;
  administrator: boolean;
  clusterAdministrator: boolean;
}

type FormInputs = {
  // username: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  agent: string;
  admin: boolean;
  clusterAdmin: boolean;
  emailNotifications: boolean;
};
interface UserEnabledToggle {
  userID: AgentUser["recordID"];
  toggle: boolean;
}

interface EditUserProps {
  user: AgentUser | undefined;
  edit: boolean;
}

const Administration: React.FC = () => {
  const [enableUser, setEnableUser] = useState<AgentUser | undefined>();
  const [forgotPasswordUser, setForgotPasswordUser] = useState<AgentUser | undefined>();
  const [editUser, setEditUser] = useState<EditUserProps | undefined>();
  const [isAgentEmailNotificationModalVisible, setAgentEmailNotificationModalVisible] = useState(false);
  const closeAgentEmailModal = () => setAgentEmailNotificationModalVisible(false);
  const openAgentEmailModal = () => setAgentEmailNotificationModalVisible(true);

  const [isRemoveAgentEmailNotificationVisible, setRemoveAgentEmailNotificationVisible] = useState(false);
  const closeRemoveAgentEmailModal = () => setRemoveAgentEmailNotificationVisible(false);
  const openRemoveAgentEmailModal = () => setRemoveAgentEmailNotificationVisible(true);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof TableData>("lastLogin");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const { data, isLoading } = useAgentUsers();
  const { data: emailData } = useAgentEmail();

  var dataTable: Array<TableData> = [];
  if (data) {
    dataTable = data.map((record) => {
      return {
        recordID: record.recordID,
        emailAddress: record.emailAddress,
        agentID: record.agentID,
        firstName: record.firstName,
        lastName: record.lastName,
        lastLogin: record.lastLogin,
        enabled: record.enabled,
        administrator: record.administrator,
        clusterAdministrator: record.clusterAdministrator,
        roleList: record.roleList,
      };
    });
  }

  const actions = [
    {
      name: "Forgot Password",
      description: "Send a forgot password link",
      icon: "lock",
      color: "success",
      type: "icon",
      onClick: (user: AgentUser) => {
        setForgotPasswordUser(user);
      },
      isPrimary: true,
      "data-test-subj": "action-password-reset",
    },
    {
      name: "Disable",
      description: "Disable this user",
      icon: "pause",
      color: "danger",
      type: "icon",
      onClick: (record: AgentUser) => {
        setEnableUser(record);
      },
      available: (record: AgentUser) => {
        return record.enabled;
      },
      isPrimary: true,
      "data-test-subj": "action-delete",
    },
    {
      name: "Enable",
      description: "Enable this user",
      icon: "play",
      color: "success",
      type: "icon",
      onClick: (record: AgentUser) => {
        setEnableUser(record);
      },
      available: (record: AgentUser) => {
        return !record.enabled;
      },
      isPrimary: true,
      "data-test-subj": "action-delete",
    },
    {
      name: "Edit",
      isPrimary: true,
      // available: ({ enabled }:{enabled: any}) => !enabled,
      description: "Edit this user",
      icon: "pencil",
      type: "icon",
      onClick: (record: AgentUser) => {
        setEditUser({ user: record, edit: true });
      },
      "data-test-subj": "action-edit",
    },
  ];
  const columns = [
    // {
    //   field: "username",
    //   name: "Username",
    // },
    {
      field: "",
      name: "Email",
      render: (data: any) => {
        if (data.clusterAdministrator) {
          return (
            <>
              <EuiToolTip content="Cluster Administrator">
                <EuiIcon type={"starFilled"} size="m" />
              </EuiToolTip>{" "}
              {data.emailAddress}
            </>
          );
        }
        if (data.administrator) {
          return (
            <>
              <EuiToolTip content="Administrator">
                <EuiIcon type={"starEmpty"} size="m" />
              </EuiToolTip>{" "}
              {data.emailAddress}
            </>
          );
        }
        return (
          <>
            <EuiIcon type={"empty"} size="m" /> {data.emailAddress}
          </>
        );
      },
    },
    {
      field: "firstName",
      name: "First Name",
      sortable: true,
      "data-test-subj": "firstNameCell",
    },
    {
      field: "lastName",
      name: "Last Name",
      truncateText: true,
    },
    {
      field: "agentID",
      name: "Primary Agency",
    },
    {
      field: "lastLogin",
      name: "Last Login",
      truncateText: true,
      render: (time: string) => {
        if (time === undefined) {
          return "Never";
        }
        return moment(moment.utc(time).toDate()).local().format("lll");
      },
    },

    {
      field: "enabled",
      name: "Enabled",
      dataType: "boolean",
      render: (enabled: AgentUser["enabled"]) => {
        const color = enabled ? "success" : "danger";
        const label = enabled ? "Enabled" : "Disabled";
        return <EuiHealth color={color}>{label}</EuiHealth>;
      },
    },
    {
      name: "Actions",
      actions,
    },
  ];

  const { pageOfItems, totalItemCount } = pageAndSort(dataTable, { pageIndex, pageSize, sortField, sortDirection });

  const getRowProps = (record: TableData) => {
    const { recordID } = record;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
    };
  };

  const getCellProps = (record: TableData, column: any) => {
    const { recordID } = record;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };

  const sorting: EuiTableSortingType<TableData> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [10, 20, 50, 100],
    hidePerPageOptions: !showPerPageOptions,
  };

  const onTableChange = ({ page, sort }: CriteriaWithPagination<TableData>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <>
      <EuiPage>
        <EuiPageBody>
          <EuiPageHeader pageTitle="Administration" restrictWidth="1300px" />
          <EuiPageContentBody restrictWidth="1300px">
            <EuiFlexGroup direction="column">
              <EuiFlexItem>
                <EuiPanel>
                  <EuiTitle>
                    <h3>Daily Transaction Report</h3>
                  </EuiTitle>
                  <EuiSpacer size="s" />
                  <EuiText color="subdued" size="s">
                    Send the daily transaction report to a general agency email address that is not associated with a
                    username. To send the daily transaction report to a specific user, please edit the user's
                    permissions in the 'Users' table below.
                  </EuiText>
                  <EuiHorizontalRule margin="xs" />
                  <EuiFlexGroup alignItems="center">
                    <EuiFlexItem grow={1}>
                      <EuiTitle size="xs">
                        <EuiTextAlign textAlign="left">Email</EuiTextAlign>
                      </EuiTitle>
                    </EuiFlexItem>
                    <EuiFlexItem grow={9}>
                      <EuiTextAlign textAlign="left">{emailData !== null ? emailData : null}</EuiTextAlign>
                    </EuiFlexItem>
                    {emailData !== null ? (
                      <EuiFlexItem grow={false}>
                        <EuiButtonEmpty color="danger" flush="right" type="button" onClick={openRemoveAgentEmailModal}>
                          Remove Email
                        </EuiButtonEmpty>
                      </EuiFlexItem>
                    ) : null}

                    <EuiFlexItem grow={false}>
                      <EuiButtonEmpty flush="right" type="button" onClick={openAgentEmailModal}>
                        Update Email
                      </EuiButtonEmpty>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiPanel>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiTitle>
                        <h2>Users</h2>
                      </EuiTitle>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton
                        type="button"
                        onClick={() => {
                          setEditUser({ user: undefined, edit: false });
                        }}
                      >
                        Add User
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiHorizontalRule />
                  <EuiBasicTable
                    items={pageOfItems}
                    rowHeader="users"
                    columns={columns as any} // keep as any? actions array?
                    hasActions={true}
                    rowProps={getRowProps}
                    cellProps={getCellProps}
                    pagination={pagination}
                    onChange={onTableChange}
                    tableLayout="auto"
                    sorting={sorting}
                    loading={isLoading}
                  />
                </EuiPanel>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPageContentBody>
        </EuiPageBody>
      </EuiPage>
      <ToggleEnabledModal
        user={enableUser}
        clear={() => {
          setEnableUser(undefined);
        }}
      />
      <ForgotPasswordModal
        user={forgotPasswordUser}
        clear={() => {
          setForgotPasswordUser(undefined);
        }}
      />
      <EditUserModal
        editUserProps={editUser}
        clear={() => {
          setEditUser(undefined);
        }}
      />
      {isAgentEmailNotificationModalVisible ? (
        <AgentEmailNotificationModal email={emailData || ""} onClose={closeAgentEmailModal} />
      ) : null}
      {isRemoveAgentEmailNotificationVisible ? (
        <DisableAgentEmailNotificationModal onClose={closeRemoveAgentEmailModal} />
      ) : null}
    </>
  );
};

export default Administration;

const SwitchInput = ({ name, label }: { name: string; label: string }) => {
  return (
    <Controller
      name={`${name}` as const}
      // rules={rules}
      render={({ field: { value, onChange } }) => (
        <EuiSwitch
          id={htmlIdGenerator()()}
          label={label}
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
      )}
    />
  );
};

const SelectInput = ({ name, options }: { name: string; options: EuiSelectOption[] }) => {
  return (
    <Controller
      name={`${name}` as const}
      // rules={rules}
      render={({ field }) => {
        const { ref, ...rest } = field;
        return <EuiSelect fullWidth options={options} {...rest} />;
      }}
    />
  );
};

interface AgentEmailNotificationType {
  email: string;
}

const AgentEmailNotificationSchema = yup.object({
  email: yup.string().email().required().label("Email Address"),
});
interface AgentEmailNotificationModalProps {
  email: string;
  onClose: () => void;
}
const useAgentEmailNotifications = () => {
  return useMutation((email: string) => authAxios.post("/adjust_agent_email_notifications", { email: email }));
};
const AgentEmailNotificationModal = ({ onClose, email }: AgentEmailNotificationModalProps) => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const mutation = useAgentEmailNotifications();

  const methods = useForm<AgentEmailNotificationType>({
    mode: "onTouched",
    resolver: yupResolver(AgentEmailNotificationSchema),
    defaultValues: { email: email },
  });
  const { handleSubmit, control } = methods;

  const onAgentEmailNotificationSubmit = (data: AgentEmailNotificationType) => {
    mutation.mutate(data.email, {
      onSuccess: (res) => {
        console.log("res: ", res);
        queryClient.invalidateQueries("agent_email");
        addToast({
          title: "Setup Complete",
          color: "success",
        });
        onClose();
      },
    });
  };
  const onAgentEmailNotificationError = (errors: any, e: any) => console.log(errors, e);
  return (
    <EuiModal onClose={onClose} initialFocus="[name=popswitch]">
      <form
        onSubmit={handleSubmit(onAgentEmailNotificationSubmit, onAgentEmailNotificationError)}
        id={"AgentEmailNotificationForm"}
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Daily Transaction Report Setup</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiFormRow>
            <EuiTextAlign>
              Send the daily transaction report to a general agency email address that is not associated with a
              username. <br />
              <br />
              <b>Only one email address per agent code.</b>
            </EuiTextAlign>
          </EuiFormRow>

          <EuiSpacer />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <EuiFormRow fullWidth label="Email Address" isInvalid={!!error} error={error?.message}>
                  <EuiFieldText
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    isInvalid={!!error}
                    placeholder="Email Address"
                    autoFocus
                  />
                </EuiFormRow>
              );
            }}
          />
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
          <EuiButton type="submit">Submit</EuiButton>
        </EuiModalFooter>
      </form>
    </EuiModal>
  );
};
interface DisableAgentEmailNotificationType {
  status: boolean;
}

interface DisableAgentEmailNotificationModalProps {
  onClose: () => void;
}
const useDisableAgentEmailNotifications = () => {
  return useMutation((status: boolean) => authAxios.post("/adjust_agent_email_notifications", { disable: status }));
};
const DisableAgentEmailNotificationModal = ({ onClose }: DisableAgentEmailNotificationModalProps) => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const mutation = useDisableAgentEmailNotifications();

  const methods = useForm<DisableAgentEmailNotificationType>({
    mode: "onTouched",
    defaultValues: { status: true },
  });
  const { handleSubmit } = methods;

  const onDisableAgentEmailNotificationSubmit = (data: DisableAgentEmailNotificationType) => {
    mutation.mutate(data.status, {
      onSuccess: (res) => {
        console.log("res: ", res);
        queryClient.invalidateQueries("agent_email");
        addToast({
          title: "Notifications Off",
          color: "success",
        });
        onClose();
      },
    });
  };
  const onDisableAgentEmailNotificationError = (errors: any, e: any) => console.log(errors, e);
  return (
    <EuiModal onClose={onClose} initialFocus="[name=popswitch]">
      <form
        onSubmit={handleSubmit(onDisableAgentEmailNotificationSubmit, onDisableAgentEmailNotificationError)}
        id={"DisableAgentEmailNotificationForm"}
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Disable Daily Transaction Report Setup</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiFormRow>
            <EuiTextAlign>Turn off the daily transaction report for the agency email?</EuiTextAlign>
          </EuiFormRow>
          <EuiSpacer />
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
          <EuiButton type="submit">Turn Off</EuiButton>
        </EuiModalFooter>
      </form>
    </EuiModal>
  );
};

const EditUserModal = ({ editUserProps, clear }: { editUserProps: EditUserProps | undefined; clear: () => void }) => {
  const queryClient = useQueryClient();
  const agentID = useAppSelector(selectAgencyID);
  const modalFormId = useGeneratedHtmlId({ prefix: "modalForm" });
  const clusterAdmin = useAppSelector(selectClusterAdministrator);
  const clusterAgents = useAppSelector(selectClusterAgencies);
  // const roleList = useAppSelector(selectRoleList);
  const newUserMutation = useMutation((newUser: FormInputs) => {
    return authAxios.post("/user", newUser);
  });
  const editUserMutation = useMutation(({ editUser, userID }: { editUser: FormInputs; userID: number }) => {
    return authAxios.post(`/user/update/${userID}`, editUser);
  });
  const { addToast } = useToast();

  const methods = useForm<FormInputs>({
    mode: "onTouched",
    defaultValues: {
      // username: "",
      firstName: "",
      lastName: "",
      emailAddress: "",
      agent: agentID,
      admin: false,
      clusterAdmin: false,
      emailNotifications: false,
    },
  });

  useEffect(() => {
    if (editUserProps !== undefined && editUserProps.user !== undefined) {
      methods.reset({
        // username: editUserProps.user.username,
        firstName: editUserProps.user.firstName,
        lastName: editUserProps.user.lastName,
        emailAddress: editUserProps.user.emailAddress,
        agent: editUserProps.user.agentID,
        admin: editUserProps.user.administrator,
        clusterAdmin: editUserProps.user.clusterAdministrator,
        emailNotifications: editUserProps.user.roleList.some((item) => item === "Email Notification"),
      });
    } else {
      if (editUserProps !== undefined) {
        methods.reset({
          // username: "",
          firstName: "",
          lastName: "",
          emailAddress: "",
          agent: agentID,
          admin: false,
          clusterAdmin: false,
          emailNotifications: false,
        });
      }
    }
  }, [editUserProps, methods, agentID]);

  const { handleSubmit, reset, control } = methods;
  const onSubmit = (data: FormInputs) => {
    if (editUserProps && editUserProps.user && editUserProps.edit) {
      editUserMutation.mutate(
        { editUser: data, userID: editUserProps.user.recordID },
        {
          onSuccess: () => {
            // I will fire second!
            queryClient.invalidateQueries("users");
            clear();
            reset();
            addToast({
              title: "Agent Successfully Updated",
              color: "success",
              text: <p>Thanks!</p>,
            });
          },
          onError: () => {
            // I will fire second!
            addToast({
              title: "An error occurred during the update",
              color: "danger",
              text: <p>Please try again later!</p>,
            });
          },
          onSettled: (data, error, variables, context) => {
            // I will fire second!
          },
        }
      );
    } else {
      newUserMutation.mutate(data, {
        onSuccess: (data, variables, context) => {
          // I will fire second!
          queryClient.invalidateQueries("users");
          clear();
          reset();
          addToast({
            title: "Agent Successfully Added",
            color: "success",
            text: <p>Thanks!</p>,
          });
        },
        onError: (error, variables, context) => {
          // I will fire second!
          const axiosError = error as AxiosError;
          methods.setError("emailAddress", {
            type: "manual",
            message: axiosError.response?.data.message,
          });
          addToast({
            title: "An error occurred while adding the agent",
            color: "danger",
            text: <p>Please try again later!</p>,
          });
        },
        onSettled: (data, error, variables, context) => {
          // I will fire second!
        },
      });
    }
  };

  const onError = (errors: any, e: any) => console.log(errors, e);
  if (editUserProps !== undefined) {
    return (
      <EuiModal onClose={() => clear()} initialFocus="[name=emailAddress]">
        <EuiModalHeader>
          <EuiModalHeaderTitle>{editUserProps.edit ? <h1>Update user</h1> : <h1>Add new user</h1>}</EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <FormProvider {...methods}>
            <EuiForm id={modalFormId} component="form" onSubmit={handleSubmit(onSubmit, onError)}>
              <Controller
                name="emailAddress"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  return (
                    <EuiFormRow fullWidth label="Email Address" isInvalid={!!error} error={error?.message}>
                      <EuiFieldText
                        placeholder="Email Address"
                        fullWidth
                        value={value}
                        onChange={(event) => onChange(event.target.value)}
                        onBlur={onBlur}
                      />
                    </EuiFormRow>
                  );
                }}
              />
              <Controller
                name="firstName"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  return (
                    <EuiFormRow fullWidth label="First Name" isInvalid={!!error} error={error?.message}>
                      <EuiFieldText
                        placeholder="First Name"
                        fullWidth
                        value={value}
                        onChange={(event) => onChange(event.target.value)}
                        onBlur={onBlur}
                      />
                    </EuiFormRow>
                  );
                }}
              />
              <Controller
                name="lastName"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  return (
                    <EuiFormRow fullWidth label="Last Name" isInvalid={!!error} error={error?.message}>
                      <EuiFieldText
                        placeholder="Last Name"
                        fullWidth
                        value={value}
                        onChange={(event) => onChange(event.target.value)}
                        onBlur={onBlur}
                      />
                    </EuiFormRow>
                  );
                }}
              />
              {clusterAdmin ? (
                <EuiFormRow label="Agency">
                  <SelectInput
                    name={`agent`}
                    options={clusterAgents.map((agent: string) => {
                      return {
                        value: agent,
                        text: agent,
                      };
                    })}
                  />
                </EuiFormRow>
              ) : null}

              <EuiFormRow>
                <SwitchInput name={`admin`} label="Agency Administrator" />
              </EuiFormRow>
              {clusterAdmin ? (
                <EuiFormRow>
                  <SwitchInput name={`clusterAdmin`} label="Cluster Administrator" />
                </EuiFormRow>
              ) : null}
              <EuiFormRow>
                <SwitchInput name={`emailNotifications`} label="Daily Transaction Report" />
              </EuiFormRow>
            </EuiForm>
          </FormProvider>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={clear}>Cancel</EuiButtonEmpty>

          <EuiButton type="submit" form={modalFormId} fill>
            Save
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }
  return null;
};

const ForgotPasswordModal = ({ user, clear }: { user: AgentUser | undefined; clear: () => void }) => {
  const forgotPasswordMutation = useMutation(({ id }: { id: number }) =>
    authAxios.post("/forgot_password", {
      id: id,
    })
  );
  const { addToast } = useToast();

  if (user !== undefined) {
    return (
      <EuiConfirmModal
        title="Password Reset"
        onCancel={() => {
          clear();
        }}
        isLoading={forgotPasswordMutation.isLoading}
        onConfirm={() => {
          forgotPasswordMutation.mutate(
            { id: user.recordID },
            {
              onSuccess: (data) => {
                addToast({
                  title: "Forgot password email sent",
                  color: "success",
                  text: <p>Thanks!</p>,
                });
                clear();
              },
              onError: (error) => {
                if (error instanceof Error) {
                  addToast({
                    title: "Something went wrong",
                    color: "danger",
                  });
                  clear();
                }
              },
            }
          );
          // setDisableUserID("");
        }}
        cancelButtonText="No"
        confirmButtonText={`Yes`}
        buttonColor={"primary"}
        defaultFocusedButton="cancel"
      >
        {`Do you want to send a password reset to ${user.emailAddress}?`}
      </EuiConfirmModal>
    );
  }
  return null;
};

const ToggleEnabledModal = ({ user, clear }: { user: AgentUser | undefined; clear: () => void }) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const userEnabledMutation = useMutation((enabledToggle: UserEnabledToggle) => {
    return authAxios.post("/user/enabled", enabledToggle);
  });
  if (user !== undefined) {
    const enable = !user.enabled;
    const buttonColor = enable ? "success" : "danger";
    return (
      <EuiConfirmModal
        title={`Do you want to ${enable ? "enable" : "disable"} this account?`}
        onCancel={() => {
          clear();
        }}
        onConfirm={() => {
          userEnabledMutation.mutate(
            { userID: user.recordID, toggle: !user.enabled },
            {
              onSuccess: (data, variables, context) => {
                // I will fire second!
                queryClient.invalidateQueries("users");
                clear();
              },
              onError: (error, variables, context) => {
                // I will fire second!
                const axiosError = error as AxiosError;

                addToast({
                  title: "Error",
                  color: "danger",
                  text: <p>{axiosError.response?.data.message}</p>,
                });
              },
              onSettled: (data, error, variables, context) => {
                // I will fire second!
              },
            }
          );
          // setDisableUserID("");
        }}
        cancelButtonText="No"
        confirmButtonText={`Yes, ${enable ? "enable" : "disable"}`}
        buttonColor={buttonColor}
        defaultFocusedButton="cancel"
      >
        <p>
          You&rsquo;re about to {enable ? "enable" : "disable"} {user.emailAddress}.
        </p>
        <p>Are you sure you want to do this?</p>
      </EuiConfirmModal>
    );
  }
  return null;
};
