import { EuiEmptyPrompt, EuiPage, EuiPageBody, EuiPageTemplate } from "@elastic/eui";
import React from "react";

const ErrorPage: React.FC<{ title: string }> = ({ title }) => {
  return (
    <EuiPage>
      <EuiPageBody restrictWidth="1300px">
        <EuiPageTemplate template="centeredBody" pageContentProps={{ paddingSize: "none" }}>
          <EuiEmptyPrompt
            iconType="lock"
            title={<h2>An error has occurred</h2>}
            body={
              <p>Please check the {title} you are looking up or contact your admin.</p>
            }
          />
        </EuiPageTemplate>
      </EuiPageBody>
    </EuiPage>
  );
};

export default ErrorPage;
