import { EuiSuggestProps, EuiSuggestionProps } from "@elastic/eui";
import useAddressAutoComplete from "hooks/queries/useAddressAutoComplete";
import { SearchData } from "types/apiTypes";
import { useDebounce } from "use-debounce";

import { WCICSuggest } from "./WCICSuggest";

// interface AddressAutoCompleteProps extends EuiSuggestProps {
//   query: string;
//   onQueryChange: (target: string) => void;
//   onItemClick: (item: WCICSuggestionProps) => void;
//   onBlur?: () => void;
// }

type AddressAutoCompleteProps = Partial<Omit<EuiSuggestProps, "onItemClick">> & {
  query: string;
  onQueryChange: (target: string) => void;
  onItemClick: (item: WCICSuggestionProps) => void;
};

interface WCICSuggestionProps extends EuiSuggestionProps {
  data: SearchData;
}

const AddressAutoComplete = ({ query, onQueryChange, onItemClick, onBlur, ...rest }: AddressAutoCompleteProps) => {
  const [debouncedQuery] = useDebounce(query, 400);
  let enableQuery = false;
  if (debouncedQuery.length > 3) {
    // console.log("Searching for: ", debouncedQuery);
    enableQuery = true;
  }
  const { data } = useAddressAutoComplete(debouncedQuery, enableQuery);

  // console.log("QueryData: ", data);

  const suggestions: WCICSuggestionProps[] =
    data?.map((item) => {
      return {
        type: { iconType: "home", color: "tint4" },
        label: `${item.street_line}, ${item.city}, ${item.state}, ${item.zipcode}`,
        data: item,
        // description: "This is the description",
      };
    }) || [];
  return (
    <WCICSuggest
      aria-label="Address Autocomplete"
      status={"unchanged"}
      value={query}
      onBlur={onBlur}
      onChange={onQueryChange}
      onItemClick={(item) => onItemClick(item as WCICSuggestionProps)}
      suggestions={suggestions}
      // placeholder={""}
      {...rest}
    />
  );
};

export default AddressAutoComplete;
