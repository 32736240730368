import authAxios from "../../services/authAxios";
import { useQuery } from "react-query";
import { ReportedClaim } from "types/apiTypes";

const getReportedClaimById = async (id: string | undefined) => {
  const { data } = await authAxios.get(`/reported_claim/${id}`);
  return data;
};

function useReportedClaim(claimRecordId: string | undefined) {
  return useQuery<ReportedClaim>(["reported_claim", claimRecordId], () => getReportedClaimById(claimRecordId), {
    staleTime: 1000 * 60 * 15,
    cacheTime: 1000 * 60 * 15,
    enabled: !!claimRecordId
  });
}

export default useReportedClaim;
