import { useQuery } from "react-query";
import { Invoice, Policy, UsePolicyOptions } from "types/apiTypes";

import authAxios from "../../services/authAxios";

const getPolicyById = async (id: string | undefined, options: UsePolicyOptions) => {
  const { data } = await authAxios.get(`/policy/${id}`, { params: options });
  if (data.invoices) {
    const installments: Invoice[] = data.invoices
      .filter((invoice: Invoice) => invoice.type === "I")
      .filter((invoice: Invoice) => invoice.balance !== 0 && invoice.pending !== true);
    // console.log("installments: ", installments);

    const sortedInstallments: Invoice[] = installments?.sort(function (a, b) {
      return Date.parse(a.dueDate) - Date.parse(b.dueDate);
    });
    // console.log("sortedInstallments: ", sortedInstallments);
    sortedInstallments?.map((invoice: Invoice) => {
      return (invoice.newestPayable = false);
    });
    if (sortedInstallments.length > 0) {
      sortedInstallments[0].newestPayable = true;
      // console.log("newest: ", sortedInstallments[0]);
    }
  }
  return data;
};

function usePolicy(policyRecordID: string | undefined, options: UsePolicyOptions = {}) {
  return useQuery<Policy>(["policy", policyRecordID], () => getPolicyById(policyRecordID, options), {
    // staleTime: 1000 * 60 * 15,
    // cacheTime: 1000 * 60 * 15,
    enabled: !!policyRecordID,
  });
}

export default usePolicy;
