import {
  EuiBadge,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiSelectableMessage,
  EuiSelectableTemplateSitewide,
  EuiSelectableTemplateSitewideOption,
  cascadingMenuKeys,
} from "@elastic/eui";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import useSitewideQuery from "../hooks/queries/useSitewideQuery";

function PolicyholderSearch() {
  // const [isLoading, setIsLoading] = useState(false);
  // const [result, setResult] = useState<any>([]);
  const [query, setQuery] = useState("");
  const [searchRef, setSearchRef] = useState<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const blurEvent = new FocusEvent("blur");
  const [debouncedQuery] = useDebounce(query, 200);
  const { data, isLoading } = useSitewideQuery(debouncedQuery);
  // console.log("QueryResult:", data);
  const setFieldValue = (field: HTMLInputElement, value: string) => {
    const nativeInputValue = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, "value");
    const nativeInputValueSetter = nativeInputValue ? nativeInputValue.set : undefined;
    if (nativeInputValueSetter) {
      nativeInputValueSetter.call(field, value);
    }
    field.dispatchEvent(new Event("change"));
  };

  const clearField = (field: HTMLInputElement) => setFieldValue(field, "");

  const onChange = (updatedOptions: EuiSelectableTemplateSitewideOption[]) => {
    const clickedItem = updatedOptions.find((option) => option.checked === "on");
    if (!clickedItem) return;
    const selectedID = clickedItem.key;
    switch (selectedID?.split("*")[0]) {
      case "policy":
        navigate("/policy/" + selectedID?.split("*")[1]);
        break;
      case "insured":
        navigate("/insured/" + selectedID?.split("*")[1]);
        break;
      case "claim":
        navigate("/claim/" + selectedID?.split("*")[1]);
        break;
      case "quote":
        navigate("/quote/" + selectedID?.split("*")[1]);
        break;
    }

    if (searchRef) {
      searchRef.dispatchEvent(
        new KeyboardEvent("keydown", {
          key: cascadingMenuKeys.ESCAPE,
          bubbles: true,
        })
      );
      clearField(searchRef);
      searchRef.dispatchEvent(blurEvent);
      searchRef.dispatchEvent(new InputEvent("change"));
    }
    (document.activeElement as HTMLElement).blur();
  };

  let resultSet: EuiSelectableTemplateSitewideOption[] = [];
  data?.forEach((element) => {
    if (element.type === "quote") {
      resultSet.push({
        label: `${element.applicantFirstName} ${element.applicantLastName}`,
        key: `quote*${element.recordID}`,
        icon: {
          type: "pageSelect",
        },
        meta: [
          {
            text: "Quote",
            type: "article",
          },
          {
            text: `${element.comments}`,
          },
        ],
      });
    }
    if (element.type === "insured") {
      resultSet.push({
        label: `${element.displayName}`,
        key: `insured*${element.recordID}`,
        icon: {
          type: "user",
        },
        meta: [
          {
            text: "Insured",
            type: "platform",
          },
          {
            text: `${element.city}, ${element.state}`,
          },
        ],
      });
    }
    if (element.type === "policy") {
      let text = "No Location";
      if (element.locations[0]?.address1 && element.locations[0]?.city && element.locations[0]?.state) {
        text = `${element.locations[0]?.address1 || ""}, ${element.locations[0]?.city || ""}, ${
          element.locations[0]?.state || ""
        }`;
      }

      resultSet.push({
        label: `${element.policyID}`,
        key: `policy*${element.recordID}`,
        icon: {
          type: "home",
        },
        meta: [
          // {
          //   text: "Policy Type",
          //   type: "deployment",
          // },
          {
            text: element.policyTypeDisplay || "Policy Pending Issuance",
            type: "deployment",
          },
          // {
          //   text: "Address",
          //   type: "deployment",
          // },
          {
            text: text,
            type: "application",
          },
        ],
      });
    }
    if (element.type === "claim") {
      resultSet.push({
        label: `${element.claimID}`,
        key: `claim*${element.recordID}`,
        icon: {
          type: "cloudDrizzle",
        },
        meta: [
          {
            text: "Claim",
            type: "case",
          },
          {
            text: element.policyID,
            type: "application",
          },
          {
            text: moment(element.dateOfLoss).format("l"),
            type: "application",
          },
        ],
      });
    }
  });

  return (
    <EuiSelectableTemplateSitewide
      options={resultSet}
      isPreFiltered={true}
      isLoading={isLoading}
      onChange={onChange}
      searchProps={{
        style: { minWidth: "40vw" },
        placeholder: "Search Policies, Claims, Insureds",
        inputRef: setSearchRef,
        compressed: true,
        onChange: (e) => {
          setQuery(e);
        },
      }}
      popoverButton={
        <EuiHeaderSectionItemButton aria-label="Sitewide search">
          <EuiIcon type="search" size="m" />
        </EuiHeaderSectionItemButton>
      }
      popoverButtonBreakpoints={["xs", "s"]}
      popoverFooter={
        <div>
          <EuiBadge color="success" onClick={() => {}} onClickAriaLabel="Example of onClick event for the button">
            Policies
          </EuiBadge>
          <EuiBadge color="danger">Claims</EuiBadge>
          <EuiBadge color="warning">Insureds</EuiBadge>
          <EuiBadge color="accent">Quotes</EuiBadge>
        </div>
      }
      popoverProps={{
        repositionOnScroll: true, // Necessary when placing search in a fixed component
      }}
      emptyMessage={
        <EuiSelectableMessage style={{ minHeight: 300 }}>
          <p>Search for Policies, Claims and Insureds</p>
        </EuiSelectableMessage>
      }
    />
  );
}

export default PolicyholderSearch;
