import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingLogo,
  EuiPanel,
  EuiSpacer,
  EuiTableSortingType,
  EuiText,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import moment from "moment";
import { useState } from "react";
import NumberFormat from "react-number-format";
import pageAndSort from "services/pageAndSort";
import { ClaimTransaction } from "types/apiTypes";

const TransactionsTable: React.FC<{ transactions: ClaimTransaction[]; isLoading: boolean }> = ({
  transactions,
  isLoading,
}) => {
  const total = transactions.reduce((a, v) => (a = a + v.amount), 0);
  const lossPayments = transactions
    .filter((transaction) => transaction.code.includes("1"))
    .reduce((a, v) => (a = a + v.amount), 0);
  const adjustmentlExpense = transactions
    .filter((transaction) => transaction.code.includes("3"))
    .reduce((a, v) => (a = a + v.amount), 0);
  const appraisalExpense = transactions
    .filter((transaction) => transaction.code.includes("8"))
    .reduce((a, v) => (a = a + v.amount), 0);
  const salvage = transactions
    .filter((transaction) => transaction.code.includes("4"))
    .reduce((a, v) => (a = a + v.amount), 0);

  const TransactionStatsEmptyComponent = () => {
    return (
      <EuiPanel hasBorder={true}>
        <EuiTitle size="s">
          <h3>Payments</h3>
        </EuiTitle>
        <EuiSpacer size="s" />

        <EuiFlexGroup justifyContent="spaceAround">
          <EuiFlexItem grow={false}>
            <EuiTitle size="l">
              <EuiTextColor color="subdued">No Payments</EuiTextColor>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size="l" />
      </EuiPanel>
    );
  };

  const TransactionStatsComponent: React.FC<{ transactions: ClaimTransaction[] }> = ({ transactions }) => {
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [showPerPageOptions] = useState(true);
    const [sortField, setSortField] = useState<keyof ClaimTransaction>("recordID");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

    const columns: EuiBasicTableColumn<ClaimTransaction>[] = [
      {
        field: "transactionID",
        name: "Transaction Number",
      },
      {
        field: "checkID",
        name: "Check Number",
      },
      {
        field: "date",
        name: "Date",
        render: (date: string) => moment(date).format("l"),
      },
      {
        field: "description",
        name: "Description",
      },
      {
        field: "code",
        name: "Type",
        render: (code: string) => {
          switch (code) {
            case "1":
              return "Loss Payment";
            case "2":
              return "Legal Expense";
            case "3":
              return "Adjustment Expense";
            case "4":
              return "Salvage and Subrogation Loss Recovery";
            case "5":
              return "Salvage and Subrogation LAE Recovery";
            case "6":
              return "Reinsurance Loss Recovery";
            case "7":
              return "Reinsurance LAE Recovery";
            case "8":
              return "Appraisal Expense";
            case "9":
              return "Loss Reserves";
            case "10":
              return "Reinsured Loss Recoverable";
            case "11":
              return "LAE Reserves";
            case "12":
              return "Reinsured Loss Recoverable";
            case "13":
              return "Premium Income Account";
            case "14":
              return "Non Premium Billables";
            case "15":
              return "Non Premium Billables";
            default:
              return "ERROR";
          }
        },
      },
      {
        field: "amount",
        name: "Amount",
        render: (amount: number) => {
          return (
            <NumberFormat
              value={amount / 100}
              displayType="text"
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              decimalSeparator="."
              prefix={"$"}
              renderText={(num) => {
                return <>{num}</>;
              }}
            />
          );
        },
      },
    ];

    const { pageOfItems, totalItemCount } = pageAndSort(transactions, {
      pageIndex,
      pageSize,
      sortField,
      sortDirection,
    });

    const getRowProps = (transaction: ClaimTransaction) => {
      const { recordID } = transaction;
      return {
        "data-test-subj": `row=${recordID}`,
        className: "customRowClass",
        onClick: () => {
          // console.log("clicked on this: ", transaction);
        },
      };
    };

    const getCellProps = (transaction: ClaimTransaction, column: any) => {
      const { recordID } = transaction;
      const { field } = column;
      return {
        className: "customCellClass",
        "data-test-subj": `cell-${recordID}-${field}`,
        textOnly: true,
      };
    };

    const sorting: EuiTableSortingType<ClaimTransaction> = {
      sort: {
        field: sortField,
        direction: sortDirection,
      },
      enableAllColumns: true,
    };

    const pagination = {
      pageIndex,
      pageSize,
      totalItemCount,
      pageSizeOptions: [15, 10, 5],
      hidePerPageOptions: !showPerPageOptions,
    };

    const onTableChange = ({ page, sort }: CriteriaWithPagination<ClaimTransaction>) => {
      const { index: pageIndex, size: pageSize } = page;
      if (sort) {
        const { field: sortField, direction: sortDirection } = sort;
        setSortField(sortField);
        setSortDirection(sortDirection);
      }
      setPageIndex(pageIndex);
      setPageSize(pageSize);
    };

    return (
      <>
        <EuiPanel hasBorder>
          <EuiTitle>
            <h3>
              <NumberFormat
                value={total / 100}
                displayType="text"
                thousandSeparator={true}
                fixedDecimalScale={true}
                decimalScale={2}
                decimalSeparator="."
                prefix={"$"}
                renderText={(num) => {
                  return "Total Payments: " + num;
                }}
              />
            </h3>
          </EuiTitle>
          <EuiSpacer size="m" />
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiPanel hasBorder color="subdued">
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Loss Payment</strong>
                  </EuiTextColor>
                </label>
                <EuiText style={{ paddingLeft: "10px" }}>
                  <NumberFormat
                    value={lossPayments / 100}
                    displayType="text"
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    decimalSeparator="."
                    prefix={"$"}
                  />
                </EuiText>
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel hasBorder color="subdued">
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Loss Adjusting Expenses</strong>
                  </EuiTextColor>
                </label>
                <EuiText style={{ paddingLeft: "10px" }}>
                  <NumberFormat
                    value={adjustmentlExpense / 100}
                    displayType="text"
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    decimalSeparator="."
                    prefix={"$"}
                  />
                </EuiText>
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel hasBorder color="subdued">
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Appraisal Expense</strong>
                  </EuiTextColor>
                </label>
                <EuiText style={{ paddingLeft: "10px" }}>
                  <NumberFormat
                    value={appraisalExpense / 100}
                    displayType="text"
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    decimalSeparator="."
                    prefix={"$"}
                  />
                </EuiText>
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel hasBorder color="subdued">
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Salvage & Subrogation Recoveries</strong>
                  </EuiTextColor>
                </label>
                <EuiText style={{ paddingLeft: "10px" }}>
                  <NumberFormat
                    value={salvage / 100}
                    displayType="text"
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    decimalSeparator="."
                    prefix={"$"}
                  />
                </EuiText>
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>

        <EuiSpacer />
        <EuiPanel hasBorder={true}>
          <EuiTitle>
            <h3>Payments</h3>
          </EuiTitle>
          <EuiSpacer size="s" />

          <EuiBasicTable
            style={{ padding: "0 1% 0 1%" }}
            items={pageOfItems}
            itemId="ID"
            columns={columns}
            rowProps={getRowProps}
            cellProps={getCellProps}
            pagination={pagination}
            onChange={onTableChange}
            tableLayout="auto"
            sorting={sorting}
          />
        </EuiPanel>
      </>
    );
  };

  if (isLoading) {
    return <EuiEmptyPrompt icon={<EuiLoadingLogo logo="filebeatApp" size="xl" />} title={<h2>Loading</h2>} />;
  }

  if (transactions.length === 0) {
    return <TransactionStatsEmptyComponent />;
  }
  return <TransactionStatsComponent transactions={transactions} />;
};

export default TransactionsTable;
