import {
  CENTER_ALIGNMENT,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiEmptyPrompt,
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiProgress,
  EuiSelect,
  EuiSpacer,
  EuiTextArea,
} from "@elastic/eui";
import { yupResolver } from "@hookform/resolvers/yup";
// import QuoteAutoCompleteField from "components/QuoteAutoCompleteField";
import { useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import authAxios from "services/authAxios";
import * as yup from "yup";

import { humanFileSize } from "./UploadedDocument";

interface UploadDocumentsType {
  type: string;
  comments: string;
  quoteName: string;
  quoteID: string;
}

const UploadDocumentSchema = yup.object({
  type: yup
    .string()
    .oneOf(["Application", "Photo", "Endorsement", "Policy Cancellation", "Estimator", "Questionnaire", "Other"])
    .required()
    .label("Type"),
});

// const getAgentRecentQuotes = async () => {
//   const { data } = await authAxios.get(`/get-recent-quotes`);
//   return data;
// };

// function useAgentRecentQuotes() {
//   return useQuery<Quote[]>(["agent_recent_quotes"], () => getAgentRecentQuotes(), {
//     placeholderData: [],
//     initialData: [],
//     staleTime: 1000 * 60 * 15,
//     cacheTime: 1000 * 60 * 15,
//   });
// }

const UploadDocuments: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const openErrorModal = () => setErrorModal(true);
  const closeErrorModal = () => setErrorModal(false);
  // const [tableFiles, setTableFiles] = useState<Array<TableData>>([]);
  // const [recentQuoteSelect, setRecentQuoteSelect] = useState(false);
  const filePickerRef = useRef<EuiFilePicker>(null);

  // const { data, isLoading, refetch } = useAgentRecentQuotes();
  // console.log("data, isLoading: ", data, isLoading);

  // let quoteOptions: any = [];

  // if (data !== undefined) {
  //   quoteOptions = data.map((quote: Quote) => {
  //     return {
  //       value: quote.recordID,
  //       inputDisplay: quote.address,
  //       dropdownDisplay: (
  //         <Fragment>
  //           <strong>
  //             {quote.address} {quote.city}, {quote.state}
  //           </strong>
  //           <EuiText size="s" color="subdued">
  //             <p>
  //               {quote.applicantFirstName} {quote.applicantLastName}
  //             </p>
  //           </EuiText>
  //         </Fragment>
  //       ),
  //     };
  //   });
  // }
  // console.log("quoteOptions: ", quoteOptions);

  const [uploadComplete, setUploadComplete] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => setIsModalVisible(false);

  let modal;

  if (isModalVisible) {
    if (progress < 100) {
      modal = (
        <EuiModal onClose={closeModal}>
          <EuiModalBody css={{ textAlign: CENTER_ALIGNMENT }}>
            <EuiEmptyPrompt
              icon={<EuiIcon type={"filebeatApp"} size="xl" />}
              title={
                <div>
                  <h2>Uploading Files</h2>
                  <br></br>
                  <EuiProgress
                    // label="Upload Progress"
                    valueText={true}
                    value={progress}
                    max={100}
                    color="primary"
                    size="l"
                  />
                </div>
              }
            />
          </EuiModalBody>
        </EuiModal>
      );
    } else {
      if (uploadComplete) {
        modal = (
          <EuiModal onClose={closeModal}>
            <EuiModalBody css={{ textAlign: CENTER_ALIGNMENT }}>
              <EuiEmptyPrompt
                icon={<EuiIcon type={"filebeatApp"} size="xl" />}
                title={
                  <div>
                    <h2>Completed</h2>
                    <EuiSpacer size="s" />
                    <EuiIcon type="check" color="green" size="xxl" />
                  </div>
                }
              />
              <EuiButton
                onClick={() => {
                  navigate("/uploaded_documents");
                }}
                fill
              >
                Okay
              </EuiButton>
            </EuiModalBody>
          </EuiModal>
        );
      } else {
        modal = (
          <EuiModal onClose={closeModal}>
            <EuiModalBody css={{ textAlign: CENTER_ALIGNMENT }}>
              <EuiEmptyPrompt
                icon={<EuiIcon type={"filebeatApp"} size="xl" />}
                title={
                  <div>
                    <h2>Processing</h2>
                    <EuiSpacer size="s" />
                    <EuiLoadingSpinner size="xxl" />
                  </div>
                }
              />
            </EuiModalBody>
          </EuiModal>
        );
      }
    }
  }

  const columns: EuiBasicTableColumn<File>[] = [
    {
      field: "name",
      name: "filename",
    },
    {
      field: "size",
      name: "size",
      render: (size: number) => {
        return humanFileSize(size);
      },
    },
    {
      name: "remove",
      width: "80px",
      actions: [
        {
          name: "remove",
          description: "Remove File",
          type: "icon",
          icon: "trash",
          onClick: (record) => {
            setFiles((currentFiles) => {
              return currentFiles.filter((file) => file.name !== record.name);
            });
          },
        },
      ],
    },
  ];

  const methods = useForm<UploadDocumentsType>({
    mode: "onTouched",
    resolver: yupResolver(UploadDocumentSchema),
    defaultValues: {
      type: "0",
      comments: "",
      quoteName: "",
      quoteID: "",
    },
  });
  const { handleSubmit, control } = methods;

  const [files, setFiles] = useState<File[]>([]);
  const onChange = (newFiles: FileList | null) => {
    if (newFiles !== null) {
      const newFileArray = Array.from(newFiles);
      if (newFileArray.length > 0) {
        setFiles((currentFiles) => {
          return [...currentFiles, ...newFileArray];
        });
        filePickerRef.current!.removeFiles();
      }
    }
  };
  const onSubmit: SubmitHandler<UploadDocumentsType> = (data) => {
    setIsModalVisible(true);
    setButtonLoading(true);
    const jsonDocument = {
      type: data.type,
      comments: data.comments,
      quoteName: data.quoteName,
      quoteID: data.quoteID,
    };

    let submitData = new FormData();
    let fileSize = 0;
    if (files !== null) {
      Array.from(files).forEach((file: any) => {
        submitData.append("files[]", file);
        fileSize += file.size;
        // submitData.append("testfile", file[0]);
      });
    }

    submitData.append("document", JSON.stringify(jsonDocument));
    // console.log(fileSize);
    authAxios
      .post("/upload_documents", submitData, {
        onUploadProgress: (progressEvent) => setProgress(Math.round((progressEvent.loaded / fileSize) * 100)),
      })
      .then((res) => {
        // console.log("res: ", res);
        setButtonLoading(false);
        queryClient.invalidateQueries();
        // Set a flag here
        setUploadComplete(true);
      })
      .catch((err) => {
        setButtonLoading(false);
        setButtonDisabled(true);
        closeModal();
        openErrorModal();
        // console.log(err);
      });
  };
  const onError = (errors: any, e: any) => console.log(errors, e);
  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageHeader pageTitle="Upload Documents" restrictWidth="1200px" />
        <EuiPageContent hasBorder={false} hasShadow={false} paddingSize="none" color="transparent" borderRadius="none">
          <EuiPageContentBody restrictWidth="1200px">
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <EuiPanel hasBorder>
                <EuiFlexGroup wrap={true}>
                  <EuiFlexItem style={{ minWidth: "300px" }}>
                    <Controller
                      name="type"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                        return (
                          <EuiFormRow fullWidth label="Type" isInvalid={!!error} error={error?.message}>
                            <EuiSelect
                              fullWidth
                              options={[
                                {
                                  value: "0",
                                  text: "Select an option",
                                },
                                {
                                  value: "Application",
                                  text: "Application",
                                },
                                {
                                  value: "Photo",
                                  text: "Photo(s)",
                                },
                                {
                                  value: "Endorsement",
                                  text: "Endorsement",
                                },
                                {
                                  value: "Policy Cancellation",
                                  text: "Policy Cancellation",
                                },
                                {
                                  value: "Estimator",
                                  text: "Estimator",
                                },
                                {
                                  value: "Questionnaire",
                                  text: "Questionnaire",
                                },
                                {
                                  value: "Other",
                                  text: "Other",
                                },
                              ]}
                              value={value}
                              onChange={(event) => onChange(event.target.value)}
                              onBlur={onBlur}
                            />
                          </EuiFormRow>
                        );
                      }}
                    />
                    {/* <EuiSpacer />
                    <EuiLink
                      onClick={() => {
                        // refetch();
                        setRecentQuoteSelect(true);
                      }}
                      color="primary"
                    >
                      Attach Quote (optional)
                    </EuiLink> */}
                    {/* <EuiSpacer size="s" />
                    {recentQuoteSelect ? (
                      <Controller
                        control={control}
                        name="quoteName"
                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                          return (
                            <EuiFormRow fullWidth label="Quote" isInvalid={!!error} error={error?.message}>
                              <QuoteAutoCompleteField
                                query={value}
                                onSearchChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                onSearchSelected={(e: any) => {
                                  onChange(e.label);
                                  // recordID for quote is e.key
                                  // setValue("quoteID", e.key);
                                  setValue("quoteID", e.key);
                                }}
                              />
                            </EuiFormRow>
                          );
                        }}
                      />
                    ) : null} */}

                    <EuiSpacer />
                    <Controller
                      name="comments"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                        return (
                          <EuiFormRow fullWidth label="Comments" isInvalid={!!error} error={error?.message}>
                            <EuiTextArea
                              fullWidth
                              placeholder="Comments"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </EuiFormRow>
                        );
                      }}
                    />

                    <EuiFormRow fullWidth label="Documents">
                      <EuiFilePicker
                        accept="image/*, .txt, .pdf, .png, .jpg, .jpeg, .gif"
                        fullWidth
                        id="1"
                        ref={filePickerRef}
                        multiple
                        initialPromptText="Add Documents"
                        onChange={onChange}
                      />
                    </EuiFormRow>

                    <EuiButton
                      isLoading={buttonLoading}
                      disabled={buttonDisabled || files === null || files.length === 0}
                      type="submit"
                    >
                      Upload
                    </EuiButton>
                  </EuiFlexItem>
                  <EuiFlexItem style={{ minWidth: "300px" }}>
                    <EuiFormRow fullWidth label="Files Selected">
                      <EuiPanel hasBorder>
                        <EuiBasicTable
                          items={files}
                          itemId="uploads"
                          columns={columns}
                          tableLayout="auto"
                          noItemsMessage="No Documents Selected"
                        />
                      </EuiPanel>
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiPanel>
            </form>
            {errorModal ? <ErrorModal onClose={closeErrorModal} /> : ""}
            {modal}
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
};

export default UploadDocuments;

export const ErrorModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <span style={{ paddingLeft: "15px" }}>Error</span>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiSpacer />A problem was detected with the uploaded file(s). Please refresh the page to upload another file.
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton onClick={onClose}>OK</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
