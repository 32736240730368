import {
  CENTER_ALIGNMENT,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiLink,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { Fragment, FunctionComponent } from "react";
import { Invoice } from "types/apiTypes";

interface ReceiptProps {
  invoice: Invoice;
}

const ReceiptView: FunctionComponent<ReceiptProps> = ({ invoice }) => {
  return (
    <Fragment>
      <EuiModalHeader css={{ paddingBottom: 0, textAlign: CENTER_ALIGNMENT }}>
        <EuiModalHeaderTitle>{/* <EuiIcon type="alert" /> */}</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody css={{ textAlign: CENTER_ALIGNMENT }}>
        <EuiFormRow fullWidth>
          <EuiIcon type="check" color="green" size="xxl" />
        </EuiFormRow>
        <EuiTitle>
          <h1>Thank you for your payment!</h1>
        </EuiTitle>
        <EuiHorizontalRule margin="xs" size="half" />
        <EuiSpacer size="s" />
        <EuiFormRow>
          <EuiText size="s">A Receipt for this transaction has been sent via email for your records.</EuiText>
        </EuiFormRow>
        <EuiFormRow>
          <EuiLink
            onClick={() => {
              // window.open(`${defaultURL}/email_receipt_pdf/${transactionLine[0].transaction.recordID}`, "_blank");
            }}
          >
            Click here to print a receipt with additional details
          </EuiLink>
        </EuiFormRow>
        <EuiFormRow>
          <>
            <EuiTitle size="m">
              <h2>Total Payment Amount</h2>
            </EuiTitle>
            <EuiTitle size="l">
              <span>SomeText</span>
              {/* <NumberFormat
                  displayType={"text"}
                  value={transactionLine[0].unitPrice}
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                /> */}
            </EuiTitle>
          </>
        </EuiFormRow>
        <EuiFormRow>
          <>
            <EuiTitle size="m">
              <h2>Credit</h2>
              {/* <h2>{paymentMethod(transactionLine[0].transaction.transactionJSON)}</h2> */}
            </EuiTitle>

            <EuiText size="xs">fix this</EuiText>
          </>
        </EuiFormRow>
      </EuiModalBody>
    </Fragment>
  );
};

export default ReceiptView;
