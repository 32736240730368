import { useQuery } from "react-query";

import authAxios from "../../services/authAxios";

function useTOTPEnrollURI() {
  return useQuery<string>(["totpEnrollURI"], async () => {
    const { data } = await authAxios.get("/totp-enroll");
    return data.enrollURI;
  });
}

export default useTOTPEnrollURI;
