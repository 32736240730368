import { useQuery } from "react-query";
import { Policy } from "types/apiTypes";

import authAxios from "../../services/authAxios";

export const getPoliciesPendingIssuance = async () => {
  const { data } = await authAxios.get(`/policies-pending-issuance`);
  return data;
};

function usePoliciesPendingIssuance() {
  const useQueryReturn = useQuery<Policy[]>(["policiesPendingIssuance"], () => getPoliciesPendingIssuance(), {
    placeholderData: [],
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
  });
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

export default usePoliciesPendingIssuance;
