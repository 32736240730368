import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn, // EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiTableSortingType,
  EuiTitle,
} from "@elastic/eui";
import { useAPIURL } from "hooks/useAPIURL";
import moment from "moment";
import { useState } from "react";
import { PolicyDocument } from "types/apiTypes";

import pageAndSort from "../../../services/pageAndSort";

const Documents: React.FC<{ documents: PolicyDocument[] }> = ({ documents }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof PolicyDocument>("docDatetime");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const defaultURL = useAPIURL();

  const columns: EuiBasicTableColumn<PolicyDocument>[] = [
    // {
    //   field: "icon",
    //   render: () => {
    //     return <EuiIcon type="document" color="danger" />;
    //   },
    // },
    {
      field: "docType",
      name: "Type",
      render: (name: string) => {
        if (name === "DEC") {
          return "Declarations Page";
        }
        return name;
      },
      isExpander: true,
    },
    {
      field: "docDatetime",
      name: "Timestamp",
      render: (date: string) => moment(date).format("l"),
      //   width: "20%",
    },
    {
      name: "Actions",
      actions: [
        {
          name: "PDF",
          description: "View PDF",
          type: "icon",
          icon: "copy",
          onClick: (document: PolicyDocument) => {
            window.open(`${defaultURL}/document/${document.recordID}`, "_blank");
          },
        },
      ],
    },
  ];

  const { pageOfItems, totalItemCount } = pageAndSort(documents, { pageIndex, pageSize, sortField, sortDirection });

  const getRowProps = (document: PolicyDocument) => {
    const { recordID } = document;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
    };
  };

  const getCellProps = (document: PolicyDocument, column: any) => {
    const { recordID } = document;
    const { field } = column;
    return {
      className: "wcicTableCellPreWrap",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };
  const sorting: EuiTableSortingType<PolicyDocument> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [15, 10, 5],
    hidePerPageOptions: !showPerPageOptions,
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<PolicyDocument>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPanel hasBorder>
      <EuiTitle>
        <h3>Documents</h3>
      </EuiTitle>
      <EuiSpacer size="m" />
      <EuiBasicTable
        items={pageOfItems}
        itemId="recordID"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
        pagination={pagination}
        onChange={onTableChange}
        sorting={sorting}
        tableLayout="auto"
      />
    </EuiPanel>
  );
};

export default Documents;
