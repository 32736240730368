import {
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
} from "@elastic/eui";
import { FormSelect } from "components/react-hook-form/SelectInput";
import { Fragment, FunctionComponent } from "react";
import { Controller} from "react-hook-form";
import NumberFormat from "react-number-format";

const BankView: FunctionComponent = () => {
  return (
    <Fragment key="bank">
      <EuiFormRow label="Name on Account">
        <Controller
          name={`nameOnAccount` as `${string}`}
          render={({ field: { ref, ...rest } }) => {
            return <EuiFieldText placeholder="Name on Account" {...rest} />;
          }}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label="Account Number">
        <Controller
          name={`accountNumber` as `${string}`}
          render={({ field }) => {
            const { onChange, value, ...rest } = field;
            return (
              <NumberFormat
                placeholder="Account Number"
                customInput={EuiFieldText}
                decimalScale={0}
                value={value}
                onValueChange={(target) => {
                  onChange(target.value);
                }}
                {...rest}
              />
            );
          }}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label="Routing Number">
        <Controller
          name={`routingNumber` as `${string}`}
          render={({ field }) => {
            const { onChange, value, ...rest } = field;
            return (
              <NumberFormat
                placeholder="Routing Number"
                customInput={EuiFieldText}
                decimalScale={0}
                value={value}
                onValueChange={(target) => {
                  onChange(target.value);
                }}
                {...rest}
              />
            );
          }}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="Account Type">
              <FormSelect
                name={`accountType`}
                label={"Account Type"}
                defaultValue="checking"
                rules={{ required: true }}
                options={[
                  { value: "checking", text: "Checking" },
                  { value: "savings", text: "Savings" },
                  { value: "businessChecking", text: "Business Checking" },
                ]}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    </Fragment>
  );
};

export default BankView;
