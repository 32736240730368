import { EuiPanel, EuiSpacer, EuiText, EuiTextColor, EuiTitle } from "@elastic/eui";
import { WCICTextLabel } from "components";
import { Insured } from "types/apiTypes";


const InsuredDetails: React.FC<{ insured: Insured }> = ({ insured }) => {
  return (
    <EuiPanel hasBorder>
      <EuiTitle size="s">
        <h3>Insured Details</h3>
      </EuiTitle>
      <EuiSpacer size="s" />

      <WCICTextLabel label="Name">
        <EuiText style={{ paddingLeft: "10px" }}>{insured.displayName}</EuiText>
      </WCICTextLabel>

      <EuiSpacer size="s" />
      <label className="euiFormLabel euiFormRow__label">
        <EuiTextColor color="subdued">
          <strong>Mailing Address</strong>
        </EuiTextColor>
      </label>
      <EuiText style={{ paddingLeft: "10px" }}>{insured.street1 ? insured.street1 : "Not Provided"}</EuiText>
      {insured.street2 ? <EuiText style={{ paddingLeft: "10px" }}>{insured.street2}</EuiText> : null}
      <EuiText style={{ paddingLeft: "10px" }}>
        {insured.city ? insured.city + ", " : "Not Provided"}
        {insured.state ? insured.state + " " : "Not Provided"}
        {insured.zip ? insured.zip : "Not Provided"}
      </EuiText>
      <EuiSpacer size="s" />
    </EuiPanel>
  );
};

export default InsuredDetails;
