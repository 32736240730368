import { EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow } from "@elastic/eui";
import { FormSelect } from "components/react-hook-form/SelectInput";
import { Fragment, FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

const BankView: FunctionComponent<{ errors: any }> = ({ errors }) => {
  return (
    <Fragment key="bank">
      <EuiFormRow
        label="Name on Account"
        isInvalid={!!errors?.nameOnAccount?.message}
        error={errors?.nameOnAccount?.message}
      >
        <Controller
          name={`nameOnAccount` as `${string}`}
          render={({ field: { ref, ...rest } }) => {
            return (
              <EuiFieldText
                isInvalid={!!errors?.nameOnAccount?.message}
                placeholder="Name on Account"
                {...rest}
                inputRef={ref}
              />
            );
          }}
        />
      </EuiFormRow>
      <EuiFormRow
        fullWidth
        label="Account Number"
        isInvalid={!!errors?.accountNumber?.message}
        error={errors?.accountNumber?.message}
      >
        <Controller
          name={`accountNumber` as `${string}`}
          render={({ field }) => {
            const { onChange, value, ref, ...rest } = field;
            return (
              <NumberFormat
                isInvalid={!!errors?.accountNumber?.message}
                placeholder="Account Number"
                customInput={EuiFieldText}
                allowLeadingZeros
                value={value}
                onValueChange={(target) => {
                  onChange(target.value);
                }}
                inputRef={ref}
                {...rest}
              />
            );
          }}
        />
      </EuiFormRow>
      <EuiFormRow
        fullWidth
        label="Routing Number"
        isInvalid={!!errors?.routingNumber?.message}
        error={errors?.routingNumber?.message}
      >
        <Controller
          name={`routingNumber` as `${string}`}
          render={({ field }) => {
            const { onChange, value, ref, ...rest } = field;
            return (
              <NumberFormat
                isInvalid={!!errors?.routingNumber?.message}
                placeholder="Routing Number"
                customInput={EuiFieldText}
                allowLeadingZeros
                value={value}
                onValueChange={(target) => {
                  onChange(target.value);
                }}
                inputRef={ref}
                {...rest}
              />
            );
          }}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="Account Type">
              <FormSelect
                name={`accountType`}
                label={"Account Type"}
                defaultValue="checking"
                rules={{ required: true }}
                options={[
                  { value: "checking", text: "Checking" },
                  { value: "savings", text: "Savings" },
                  { value: "businessChecking", text: "Business Checking" },
                ]}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    </Fragment>
  );
};

export default BankView;
