import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiPanel,
  EuiSpacer,
  EuiTableSortingType,
  EuiTitle,
} from "@elastic/eui";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Claim } from "types/apiTypes";

import pageAndSort from "../../../services/pageAndSort";

interface TableData {
  claimID: string;
  causeOfLoss: string;
  description: string;
  dateOfLoss: string;
  claim: Claim;
}

const ClaimsHistory: React.FC<{ claims: Claim[] }> = ({ claims, children }) => {
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof TableData>("dateOfLoss");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const dataTable: TableData[] = claims.map((record) => {
    return {
      claimID: record.claimID,
      causeOfLoss: record.causeOfLoss.description,
      description: record.description,
      dateOfLoss: record.dateOfLoss,
      claim: record,
    };
  });

  const columns: EuiBasicTableColumn<TableData>[] = [
    {
      field: "claimID",
      name: "Claim ID",
    },

    {
      field: "causeOfLoss",
      name: "Cause of Loss",
      render: (loss: string) => {
        return loss;
      },
    },

    {
      field: "description",
      name: "Description",
    },
    {
      field: "dateOfLoss",
      name: "Date of Loss",
      render: (date: string) => moment(date).format("l"),
    },
  ];

  const { pageOfItems, totalItemCount } = pageAndSort(dataTable, { pageIndex, pageSize, sortField, sortDirection });

  const getRowProps = (record: TableData) => {
    const { recordID } = record.claim;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      onClick: () => {
        navigate("/claim/" + record.claim.recordID);
      },
    };
  };

  const getCellProps = (record: TableData, column: any) => {
    const { recordID } = record.claim;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };
  const sorting: EuiTableSortingType<TableData> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
    // readOnly: readonly,
  };
  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [15, 10, 5],
    hidePerPageOptions: !showPerPageOptions,
  };
  const onTableChange = ({ page, sort }: CriteriaWithPagination<TableData>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPanel hasBorder>
      <EuiTitle>
        <h3>Claims History</h3>
      </EuiTitle>
      <EuiSpacer size="s" />
      <EuiBasicTable
        items={pageOfItems}
        itemId="recordID"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
        pagination={pagination}
        onChange={onTableChange}
        tableLayout="auto"
        sorting={sorting}
      />

      <EuiSpacer size="s" />
    </EuiPanel>
  );
};

export default ClaimsHistory;
