import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPanel,
  EuiRadioGroup,
  EuiSpacer,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import { yupResolver } from "@hookform/resolvers/yup";
import { FunctionComponent, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Invoice } from "types/apiTypes";
import * as yup from "yup";

import BankView from "./BankView";
import CreditCardView from "./CreditCardView";
import { PaymentFormInputs } from "./PayInvoice";
import PaymentAddressView from "./PaymentAddressView";
import PaymentOptionsGroup from "./PaymentOptionsGroup";
import PaymentAmount from "utilities/PaymentAmountUtil";

interface PaymentViewProps {
  invoice: Invoice;
  waivedLateFee: boolean;
  onSubmit: (data: PaymentFormInputs) => void;
  loading: boolean;
  showError: boolean;
  errorMessage: string;
}

const PaymentSchema = yup.object({
  paymentSource: yup.string().required(),
  paymentPlan: yup.string().required(),

  // card
  fullName: yup.string().when("paymentSource", (paymentSource: string, schema: yup.StringSchema) => {
    if (paymentSource === "card") {
      return schema.max(64, "Cardholder name cannot exceed 64 characters").required().label("Cardholder name");
    }
    return schema;
  }),
  cardNumber: yup.string().when("paymentSource", (paymentSource: string, schema: yup.StringSchema) => {
    if (paymentSource === "card") {
      return schema
        .min(13, "Card number must be at least 13 digits")
        .max(16, "Card number cannot exceed 16 digits")
        .required()
        .label("Card number");
    }
    return schema;
  }),
  month: yup.string().when("paymentSource", (paymentSource: string, schema: yup.StringSchema) => {
    if (paymentSource === "card") {
      return schema.required().label("Month");
    }
    return schema;
  }),
  year: yup.string().when("paymentSource", (paymentSource: string, schema: yup.StringSchema) => {
    if (paymentSource === "card") {
      return schema.required().label("Year");
    }
    return schema;
  }),
  cardCode: yup.string().when("paymentSource", (paymentSource: string, schema: yup.StringSchema) => {
    if (paymentSource === "card") {
      return schema.min(3).max(4).required().label("CVC");
    }
    return schema;
  }),

  // bank
  nameOnAccount: yup.string().when("paymentSource", (paymentSource: string, schema: yup.StringSchema) => {
    if (paymentSource === "bank") {
      return schema.max(22, "Name cannot be longer than 22 characters").required().label("Name on account");
    }
    return schema;
  }),
  accountNumber: yup.string().when("paymentSource", (paymentSource: string, schema: yup.StringSchema) => {
    if (paymentSource === "bank") {
      return schema.max(17, "Account number cannot be longer than 17 numbers").required().label("Account number");
    }
    return schema;
  }),
  routingNumber: yup.string().when("paymentSource", (paymentSource: string, schema: yup.StringSchema) => {
    if (paymentSource === "bank") {
      return schema.length(9, "Routing number cannot be longer than 9").required().label("Routing number");
    }
    return schema;
  }),
  accountType: yup.string().when("paymentSource", (paymentSource: string, schema: yup.StringSchema) => {
    if (paymentSource === "bank") {
      return schema.required().label("Account type");
    }
    return schema;
  }),

  // address
  firstName: yup.string().max(50, "First name cannot be longer than 50 characters").required().label("First Name"),
  lastName: yup.string().max(50, "Last name cannot be longer than 50 characters").required().label("Last Name"),
  address: yup.string().max(60, "Address cannot be longer than 60 characters").required().label("Address"),
  city: yup.string().max(40, "City cannot be longer than 40 characters").required().label("City"),
  state: yup.string().max(40, "State cannot be longer than 40 characters").required().label("State"),
  zip: yup.string().max(20, "Zip cannot be longer than 20 characters").required().label("Zip"),
  email: yup.string().email().label("Email Address"),
});

const PaymentView: FunctionComponent<PaymentViewProps> = ({
  invoice,
  waivedLateFee,
  onSubmit,
  loading,
  showError,
  errorMessage,
}) => {
  const d = new Date();
  const methods = useForm<PaymentFormInputs>({
    resolver: yupResolver(PaymentSchema),
    mode: "onTouched",
    defaultValues: {
      paymentSource: "card",
      paymentPlan: "0",
      fullName: "",
      cardNumber: "",
      cardCode: "",
      month: String(d.getMonth() + 1),
      year: String(d.getFullYear()),
      nameOnAccount: "",
      accountNumber: "",
      routingNumber: "",
      accountType: "checking",
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "NY",
      zip: "",
      email: "",
    },
  });
  const {
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = methods;
  const paymentPlan = watch("paymentPlan");
  const paymentType = watch("paymentSource");

  // console.log("errors:", errors);
  // console.log("invoice: ", invoice);

  useEffect(() => {
    if (showError) {
      if (errorMessage === "9: The ABA code is invalid") {
        setError("routingNumber", { type: "custom", message: "Routing Number Incorrect" });
      }
    }
  }, [showError, errorMessage, setError]);


  return (
    <FormProvider {...methods}>
      <EuiFlexGroup>
        <EuiFlexItem grow={4}>
          <Controller
            name={`paymentPlan` as `${string}`}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <PaymentOptionsGroup
                  installmentPlanPayoff={invoice.installmentPlanPayoff}
                  waivedLateFee={waivedLateFee}
                  paymentPlanOptions={invoice.planOptions}
                  onChange={onChange}
                  selectedPlan={value}
                />
              );
            }}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={6}>
          <EuiForm component="form" onSubmit={handleSubmit(onSubmit)}>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiPanel hasBorder>
                  <EuiTitle size="m">
                    <h2>Payment Details</h2>
                  </EuiTitle>
                  <EuiSpacer size="m" />
                  <EuiFormRow fullWidth label="Payment Source">
                    <Controller
                      name={`paymentSource` as `${string}`}
                      render={({ field }) => {
                        const { onChange, value } = field;
                        return (
                          <EuiRadioGroup
                            name={`paymentSource`}
                            idSelected={value}
                            onChange={onChange}
                            options={[
                              { id: "card", label: "Card" },
                              { id: "bank", label: "Bank Account" },
                              // { value: "agent", text: "Stored Agency Payment" },
                            ]}
                          />
                        );
                      }}
                    />
                  </EuiFormRow>
                  {paymentType === "bank" ? <BankView errors={errors} /> : <CreditCardView errors={errors} />}
                  <EuiSpacer />
                </EuiPanel>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="s" />
            <EuiPanel hasBorder>
              <EuiTitle size="m">
                <h2>Billing Address</h2>
              </EuiTitle>
              <EuiSpacer size="m" />
              <PaymentAddressView errors={errors} />
            </EuiPanel>

            <EuiSpacer size="s" />
            <EuiPanel hasBorder>
              <EuiFormRow
                label="Insured Email (Optional)"
                isInvalid={!!errors?.email?.message}
                error={"Insured Email must be valid"}
              >
                <Controller
                  name={`email` as `${string}`}
                  render={({ field: { ref, onChange, onBlur, value, ...rest } }) => {
                    return (
                      <EuiFieldText
                        isInvalid={!!errors?.email?.message}
                        placeholder="Insureds email address"
                        inputRef={ref}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        {...rest}
                      />
                    );
                  }}
                />
              </EuiFormRow>
            </EuiPanel>
            <EuiSpacer size="s" />
            <EuiPanel color="danger" hidden={!showError} hasBorder>
              <EuiTextColor color={"danger"}>Error: {errorMessage}</EuiTextColor>
            </EuiPanel>
            <EuiSpacer size="s" />
            <EuiButton fullWidth onClick={handleSubmit(onSubmit)} isLoading={loading} fill color="success">
              {
                <NumberFormat
                  displayType={"text"}
                  value={PaymentAmount({paymentPlan,waivedLateFee,invoice})}
                  thousandSeparator={true}
                  prefix={"$"}
                  fixedDecimalScale
                  decimalScale={2}
                  renderText={(num) => {
                    return <>Pay {num}</>;
                  }}
                />
              }
            </EuiButton>
          </EuiForm>
        </EuiFlexItem>
      </EuiFlexGroup>
    </FormProvider>
  );
};

export default PaymentView;
