import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText, EuiTextColor, EuiTitle } from "@elastic/eui";
import { PolicyLocation } from "types/apiTypes";

const Locations: React.FC<{ locations: PolicyLocation[] }> = ({ locations, children }) => {
  return (
    <EuiPanel hasBorder>
      <EuiTitle>
        <h3>Locations</h3>
      </EuiTitle>
      <EuiSpacer size="m" />
      <EuiFlexGroup wrap>
        {locations.map((location, index: number) => {
          return (
            <EuiFlexItem key={location.recordID} grow={false} style={{ width: "280px" }}>
              <EuiPanel hasBorder color="subdued">
                <label className="euiFormLabel euiFormRow__label">
                  <EuiTextColor color="subdued">
                    <strong>Location {index + 1}</strong>
                  </EuiTextColor>
                </label>
                <EuiText style={{ paddingLeft: "10px" }}>
                  {location.address1 ? location.address1 : "Not Provided"}
                </EuiText>
                <EuiText style={{ paddingLeft: "10px" }}>
                  {location.city ? location.city + ", " : "Not Provided"}
                  {location.state ? location.state + " " : "Not Provided"}
                  {location.zip ? location.zip : "Not Provided"}
                </EuiText>
              </EuiPanel>
            </EuiFlexItem>
          );
        })}
      </EuiFlexGroup>
    </EuiPanel>
  );
};

export default Locations;
