import { useQuery } from "react-query";
import { Policy } from "types/apiTypes";

import authAxios from "../../services/authAxios";

const getPolicyQuery = async (query: string) => {
  const { data } = await authAxios.post("/policy_lookup", { policyID: query });
  return data;
};

function usePolicyQuery(query: string, enableQuery: boolean) {
  return useQuery<Policy[]>(["address_autocomplete", { query: query }], () => getPolicyQuery(query), {
    enabled: enableQuery,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    placeholderData: [],
  });
}

export default usePolicyQuery;
