import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiEmptyPrompt,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiSpacer,
  EuiSwitch,
} from "@elastic/eui";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import authAxios from "services/authAxios";

import { useAppDispatch, useAppSelector } from "../app/hooks";
// import { useLocation } from "react-router-dom";
import { otp, selectAuth, selectAuthSMS, selectAuthStatus, selectAuthTOTP } from "../features/authorization/authSlice";

function OTP() {
  const auth = useAppSelector(selectAuth);
  const authTOTP = useAppSelector(selectAuthTOTP);
  const authSMS = useAppSelector(selectAuthSMS);
  const authStatus = useAppSelector(selectAuthStatus);
  const dispatch = useAppDispatch();
  const [otpCode, setOtpCode] = useState("");
  const [trustDevice, setTrustDevice] = useState(false);
  let navigate = useNavigate();
  const { state }: any = useLocation();

  useEffect(() => {
    if (auth === "full") {
      navigate(state?.from || "/");
    }
    if (auth !== "full" && auth !== "limited") {
      navigate("/login", { state: { from: state?.from || "/" } });
    }
  }, [auth, navigate, state]);

  if (!authTOTP && !authSMS) {
    return (
      <EuiPage paddingSize="l" style={{ minHeight: "100vh" }}>
        <EuiPageBody paddingSize="l">
          <EuiPageContent verticalPosition="center" horizontalPosition="center" paddingSize="l">
            <EuiEmptyPrompt
              title={<span>Agent Support Login</span>}
              body={
                <EuiForm
                  component="form"
                  onSubmit={(event) => {
                    event.preventDefault();
                    dispatch(otp({ code: otpCode, trusted: trustDevice }));
                  }}
                >
                  {authStatus === "failed" ? (
                    <EuiCallOut title="OTP Not Valid" color="danger" iconType="alert" />
                  ) : null}
                  <EuiHorizontalRule size="full" />
                  <EuiFormRow label="Email Authentication Code" fullWidth>
                    <EuiFieldText
                      icon="user"
                      placeholder="otp code"
                      value={otpCode}
                      onChange={(e) => setOtpCode(e.target.value)}
                      fullWidth
                    />
                  </EuiFormRow>
                  <EuiSpacer size="l" />
                  <EuiFormRow>
                    <EuiSwitch
                      label="Trust this device"
                      checked={trustDevice}
                      onChange={(e) => setTrustDevice(e.target.checked)}
                    />
                  </EuiFormRow>
                  <EuiFormRow>
                    <EuiButton type="submit" fill>
                      Confirm
                    </EuiButton>
                  </EuiFormRow>
                </EuiForm>
              }
            />
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    );
  }

  return (
    <EuiPage paddingSize="l" style={{ minHeight: "100vh" }}>
      <EuiPageBody paddingSize="l">
        <EuiPageContent verticalPosition="center" horizontalPosition="center" paddingSize="l">
          <EuiEmptyPrompt
            title={<span>Agent Support Login</span>}
            body={
              <EuiForm
                component="form"
                onSubmit={(event) => {
                  event.preventDefault();
                  dispatch(otp({ code: otpCode, trusted: trustDevice }));
                }}
              >
                {authStatus === "failed" ? <EuiCallOut title="OTP Not Valid" color="danger" iconType="alert" /> : null}
                <EuiHorizontalRule size="full" />

                <EuiFormRow label="Authentication Code" fullWidth>
                  <EuiFieldText
                    icon="user"
                    placeholder="otp code"
                    value={otpCode}
                    onChange={(e) => setOtpCode(e.target.value)}
                    fullWidth
                  />
                </EuiFormRow>
                <EuiSpacer size="l" />

                {authSMS ? (
                  <EuiFormRow>
                    <EuiButtonEmpty
                      color={"primary"}
                      onClick={() => {
                        authAxios.get("/send-otp-sms").then(() => console.log("Sent an SMS"));
                      }}
                    >
                      Send me an SMS
                    </EuiButtonEmpty>
                  </EuiFormRow>
                ) : null}

                <EuiFormRow>
                  <EuiButtonEmpty
                    color={"primary"}
                    onClick={() => {
                      authAxios.get("/send-otp-email").then(() => console.log("Sent an Email"));
                    }}
                  >
                    Send me an Email
                  </EuiButtonEmpty>
                </EuiFormRow>

                <EuiSpacer size="l" />
                <EuiFormRow>
                  <EuiSwitch
                    label="Trust this device"
                    checked={trustDevice}
                    onChange={(e) => setTrustDevice(e.target.checked)}
                  />
                </EuiFormRow>
                <EuiFormRow>
                  <EuiButton type="submit" fill>
                    Confirm
                  </EuiButton>
                </EuiFormRow>
              </EuiForm>
            }
          />
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
}

export default OTP;
