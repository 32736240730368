import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiLink,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPanel,
  EuiSelect,
  EuiSpacer,
  EuiTableSortingType,
  EuiToolTip,
} from "@elastic/eui";
import { useAppSelector } from "app/hooks";
import { selectTeamID } from "features/authorization/authSlice";
import moment from "moment";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import authAxios from "services/authAxios";
import pageAndSort from "services/pageAndSort";
import { UploadDocument } from "types/apiTypes";

interface TableData {
  recordID: number;
  type: string;
  comments: string;
  timestamp: string;

  doc: UploadDocument;
}

const teams = [
  { value: "All", text: "All Teams" },
  { value: "T-1", text: "Team 1" },
  { value: "T-2", text: "Team 2" },
];

const getUploadedDocuments = async (teamID: string | undefined) => {
  const { data } = await authAxios.post(`/uploaded_documents`, { teamID: teamID });
  return data;
};

function useUploadedDocuments(teamID: string | undefined) {
  const useQueryReturn = useQuery<UploadDocument[]>(
    ["uploaded_documents", { teamID: teamID }],
    () => getUploadedDocuments(teamID),
    {
      placeholderData: [],
      initialData: [],
      initialDataUpdatedAt: 0,
      staleTime: 1000 * 60 * 15,
      enabled: teamID !== undefined,
    }
  );
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

const UploadedDocumentsEmployee: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const myTeamID = useAppSelector(selectTeamID);
  const [teamID, setTeamID] = useState(myTeamID);

  const { data, isLoading } = useUploadedDocuments(teamID);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof TableData>("timestamp");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const dataTable: TableData[] = data.map((record) => {
    return {
      recordID: record.recordID,
      type: record.type,
      comments: record.comments,
      timestamp: record.timestamp,
      numFiles: record.files.length,
      agentUser: record.agent_user.firstName + " " + record.agent_user.lastName,
      agentID: record.agent.agentID,

      doc: record,
    };
  });

  const columns: EuiBasicTableColumn<TableData>[] = [
    {
      field: "type",
      name: "Type",
      width: "120px",
    },
    {
      field: "comments",
      name: "Comments",
      truncateText: true,
      width: "300px",
    },
    {
      field: "numFiles",
      name: "Document Count",
      width: "100px",
    },
    {
      field: "agentUser",
      name: "Agent",
      width: "100px",
    },
    {
      field: "agentID",
      name: "Agent ID",
      width: "100px",
      render: (agentID: string, rowData: TableData) => {
        // return agentID + " : " + rowData.doc.agent.displayName;
        return (
          <EuiToolTip content={rowData.doc.agent.displayName}>
            <EuiLink>{agentID}</EuiLink>
          </EuiToolTip>
        );
      },
    },
    {
      field: "timestamp",
      name: "Upload Date",
      width: "100px",
      render: (date: string) => moment(date).format("l"),
    },
  ];

  const { pageOfItems, totalItemCount } = pageAndSort(dataTable, { sortField, sortDirection, pageIndex, pageSize });
  const getRowProps = (record: TableData) => {
    const { recordID } = record.doc;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      onClick: () => {
        navigate(`/uploaded_document/${recordID}`);
      },
    };
  };

  const getCellProps = (record: TableData, column: any) => {
    const { recordID } = record.doc;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };

  const sorting: EuiTableSortingType<TableData> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
  };

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [20, 50, 100],
    hidePerPageOptions: !showPerPageOptions,
  };

  const onTableChange = ({ page, sort }: CriteriaWithPagination<TableData>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageHeader
          pageTitle="Upload Transaction History"
          restrictWidth="1200px"
          rightSideItems={[
            <EuiButton
              onClick={() => {
                queryClient.invalidateQueries();
                navigate("/upload_documents");
              }}
            >
              New Upload
            </EuiButton>,
          ]}
        />

        <EuiPageContent hasBorder={false} hasShadow={false} paddingSize="none" color="transparent" borderRadius="none">
          <EuiPageContentBody restrictWidth="1200px">
            <EuiSpacer size="m" />
            <EuiSelect
              options={teams}
              defaultValue={myTeamID}
              onChange={(event) => setTeamID(event.target.value)}
              // onBlur={onBlur}
            />
            <EuiSpacer size="m" />
            <EuiPanel hasBorder>
              <EuiBasicTable
                items={pageOfItems}
                itemId="uploads"
                columns={columns}
                rowProps={getRowProps}
                cellProps={getCellProps}
                pagination={pagination}
                onChange={onTableChange}
                sorting={sorting}
                loading={isLoading}
              />
            </EuiPanel>
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
};

export default UploadedDocumentsEmployee;
