import { useQuery } from "react-query";
import { Cancellation } from "types/apiTypes";

import authAxios from "../../services/authAxios";

export const getPoliciesPendingCancellation = async () => {
  const { data } = await authAxios.get(`/cancelling-policies`);
  return data;
};

function usePoliciesPendingCancellation() {
  const useQueryReturn = useQuery<Cancellation[]>(["policiesPendingCancellation"], getPoliciesPendingCancellation, {
    placeholderData: [],
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: 1000 * 60 * 15,
  });
  return { ...useQueryReturn, data: useQueryReturn.data! };
}

export default usePoliciesPendingCancellation;
